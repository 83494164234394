import React from 'react';
import { TARIFF_PARAMS, TARIFF_PARAMS_2, TARIFF_PARAMS_HOURLY } from "../../../../../data/director/tariff";
import PageHeading from "../../../../assets/pageHeadings/PageHeading";
import Divider from "../../../../drivers/driverInfo/divider/Divider";
import useTariff from "../hooks/useTariff";

const TariffParams = ({ tariff }) => {
  const mapInputs = useTariff();
  const type = tariff?.type;
  const hourlyTariff = type === 'hourly';
  const inputsWidth = hourlyTariff ? '25%' : '52%';
  const inputs = hourlyTariff ? TARIFF_PARAMS_HOURLY : TARIFF_PARAMS;

  return (
    <>
      <PageHeading text='ПАРАМЕТРИ ТАРИФУ' className="small marginBottom" />
      <div className="form" style={{ width: inputsWidth }}>
        {mapInputs(inputs, true)}
        {!hourlyTariff && mapInputs(TARIFF_PARAMS_2, true)}
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Divider />
      </div>
    </>
  );
};

export default TariffParams;
