import AuthFormView from "../authFormView/AuthFormView";
import FormInput from "../../../assets/inputs/FormInput";
import { useState } from "react";
import FormButton from "../../../assets/buttons/formButton/FormButton";
import { checkEmptyInputs } from "../../../../helpers/checkEmptyInputs";
import { RESET_CODE_INPUTS } from "../../../../data/forms/resetCodeForm";
import useAuthService from "../../../../services/authService";
import Spinner from "../../../assets/spinner/Spinner";
import notify from "../../../../helpers/notify";

const initialState = {
    code: '',
    password: '',
    confirm_password: ''
};

const ResetCodeForm = () => {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState(initialState);
    const { resetPasswordReset, loading } = useAuthService();

    const handleSubmit = (e) => {
        e.preventDefault();
        const emptyInputs = checkEmptyInputs(values, setErrors);
        if (emptyInputs > 0) {
            notify("Заповніть форму", "error");
            return;
        } else {
            setValues(initialState);
            setErrors(initialState);
            resetPasswordReset({
                code: values.code,
                password: values.password
            }, setErrors);
        }
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setValues(prevValues => ({ ...prevValues, [name]: value }));
        if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
        }
    };

    const confirmInput = RESET_CODE_INPUTS.find(input => input.name === 'confirm_password');
    confirmInput.pattern = values.password;

    const inputs = RESET_CODE_INPUTS.map(input => (
        <FormInput
            key={input.id}
            value={values[input.name]}
            onChange={handleChange}
            errorClass={errors[input.name] ? 'error' : ''}
            customErrorMessage={errors[input.name] ? errors[input.name] : ''}
            {...input}
        />));

    return (
        <AuthFormView size='big'>
            {!loading && <form onSubmit={handleSubmit}>
                <div className="form--item">
                    {inputs}
                    <FormButton text="Зберегти" marginTop={30} />
                </div>
            </form>}
            {loading && <Spinner />}
        </AuthFormView>
    );
};

export default ResetCodeForm;