import { isDevelopEnv } from './general';

class Logger {
  static colors = {
    error: '#ff2754',
    warn: '#ff9a00',
    success: '#2aff00',
    info: '#00ecff',
    log: '#ffffff',
    saga: '#ff00bb',
    reducer: '#da4fff',
  };

  static enabled = isDevelopEnv();

  clear = console.clear;

  constructor() {}

  header = (text) => {
    Logger.enabled && console.log(`%c${text}`, 'color: white; background-color: black; font-size: 24px; padding: 1em; border-radius: 0.5rem; border-color: white;');
  };

  output = (text, color, ...variables) => {
    Logger.enabled && console.log(`%c${text}`, `color: ${color};`, ...variables);
  };

  error = (name, ...variables) => {
    const color = Logger.colors.error;
    this.output(`✖ ${name || ''}`, color, ...variables);
  };

  warn = (name, ...variables) => {
    const color = Logger.colors.warn;
    this.output(`${name || ''}`, color, ...variables);
  };

  info = (name, ...variables) => {
    const color = Logger.colors.info;
    this.output(`★ ${name || ''}`, color, ...variables);
  };

  log = (name, ...variables) => {
    const color = Logger.colors.log;
    this.output(`${name || ''}`, color, ...variables);
  };

  success = (name, ...variables) => {
    const color = Logger.colors.success;
    this.output(`${name || ''}`, color, ...variables);
  };

  saga = (name, ...variables) => {
    const color = Logger.colors.saga;
    this.output(`[saga] ${name || ''}`, color, ...variables);
  };

  reducer = (name, ...variables) => {
    const color = Logger.colors.reducer;
    this.output(`[reducer] ${name || ''}`, color, ...variables);
  };
}

export default new Logger();
