import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isEmpty, isNil, map, padStart, uniqueId,
} from 'lodash';
import moment from 'moment';
import { getIdByIndex, placeAddressShorten } from '../../../../helpers/general/general';
import { DATE_FORMATS, FARE_TYPES } from '../../../../data/consts';
import svgIconClock from '../../../../img/icons/map/icon.clock.svg';
import CarsIcon from '../../../../img/icons/map/cars';
import './styles.scss';

const TimeLabel = ({ title, time, className }) => {
  const t = moment(time).format(DATE_FORMATS.TIME);
  const d = moment(time).format(DATE_FORMATS.DATE);
  return (
    <div key={uniqueId()} className={classNames('TimeLabel', className)}>
      <div key="label">{ title }</div>
      <div key="value">{ [t, d].join(', ') }</div>
    </div>
  );
};

TimeLabel.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const Cell = ({ title, children, className }) => (
  <div key={uniqueId()} className={classNames('Cell', className)}>
    <div key="label">{ title }</div>
    <div key="value">{ children }</div>
  </div>
);

Cell.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const MarkerInformation = (props) => {
  const {
    id,
    complaint,
    time,
    car,
    comment,
    services,
    addresses,
    client,
    driver,
    status,
    tariff,
    tariffType,
    price,
    paymentType,
    color,
    onClose,
  } = props;
  const ridePoints = map(addresses || [], ({ address }, index) => (
    <div className="Address" key={index}>
      <div className="Index">
        { getIdByIndex(index) }
      </div>
      <div>{ placeAddressShorten(address) }</div>
    </div>
  ));
  const service = map(services || [], ({ name }, index) => (
    <div key={index} className="Services">
      { name }
    </div>
  ));
  const availableFields = {
    isClientAvailable: client && client?.id,
    isCarAvailable: car && car?.registrationNumber,
    isDriverAvailable: driver && driver?.id,
    isCommentAvailable: !isNil(comment) && !isEmpty(comment),
    isServicesAvailable: services && services.length,
    isComplaintAvailable: complaint && complaint.length,
  };

  const onCloseHandler = () => {
    onClose?.(props);
  };

  return (
    <div className="Information">
      <div key="1" className="Column">
        <Cell title="Статус" className="W50">
          <span data-cell="status" style={{ backgroundColor: color }}>
            { status }
          </span>
        </Cell>
        <Cell title="ID замовлення" className="W50">
          <span data-cell="id">
            { `#${padStart(id, 6, '0')}` }
          </span>
        </Cell>
      </div>
      <div key="2" className="Column">
        <Cell title="Тариф" className="W50">
          <span data-cell="tariff">
            { `${tariff} (${tariffType})` }
          </span>
        </Cell>
        <Cell title="Вартість" className="W50">
          <span data-cell="price">
            { `${price}₴ (${paymentType})` }
          </span>
        </Cell>
      </div>
      <div key="3" className="Column">
        <Cell title="Інформація про клієнта" className="W50">
          <span data-cell="client">
            { availableFields.isClientAvailable ? [client?.name, client?.email, client?.phoneNumber].join(', ') : '...' }
          </span>
        </Cell>
        <Cell title="Інформація про водія" className="W50">
          <span data-cell="driver">
            { availableFields.isDriverAvailable ? [driver?.name, driver?.email, driver?.phoneNumber].join(', ') : '...' }
          </span>
        </Cell>
      </div>
      <div key="4" className="Column">
        <Cell title="Адреса" className="W100">
          <span data-cell="addresses">{ ridePoints }</span>
        </Cell>
      </div>

      { availableFields.isServicesAvailable ? (
        <div key="5" className="Column">
          <Cell title="Послуги" className="W100">
            <span data-cell="services">
              { service }
            </span>
          </Cell>
        </div>
      ) : null }
      { availableFields.isCommentAvailable ? (
        <div key="6" className="Column">
          <Cell title="Коментар" className="W100">
            <span data-cell="comment">
              { comment }
            </span>
          </Cell>
        </div>
      ) : null }

      <div key="7" className="Column">
        <Cell title="Інформація про Машину" className="W50">
          <span data-cell="car">
            { availableFields.isCarAvailable ? [car?.brand, car?.color, car?.registrationNumber].join(', ') : '...' }
          </span>
        </Cell>
        <Cell title="Час поіздки" className="W50">
          <span data-cell="duration">
            { time?.scheduledFor ? <TimeLabel title="Заплановано" time={time.scheduledFor} /> : null }
            { time?.startedAt ? <TimeLabel title="Початок" time={time.startedAt} /> : null }
            { time?.finishedAt ? <TimeLabel title="Кінець" time={time.finishedAt} /> : null }
          </span>
        </Cell>
      </div>

      { availableFields.isComplaintAvailable ? (
        <div key="8" className="Column">
          <Cell title="Причина відміни" className="W100">
            <span data-cell="complaint">
              { complaint }
            </span>
          </Cell>
        </div>
      ) : null }

      <div key="close" className="Close" onClick={onCloseHandler}>
        <span>✕</span>
      </div>
      <span key="arrow" className="Arrow">◢</span>
    </div>
  );
};
MarkerInformation.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  status: PropTypes.string,
  tariff: PropTypes.string,
  tariffType: PropTypes.string,
  price: PropTypes.number,
  paymentType: PropTypes.string,
  comment: PropTypes.string,
  car: PropTypes.shape({
    brand: PropTypes.string,
    color: PropTypes.string,
    model: PropTypes.string,
    registrationNumber: PropTypes.string,
  }),
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      entrance: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  time: PropTypes.shape({
    scheduledFor: PropTypes.string,
    startedAt: PropTypes.string,
    finishedAt: PropTypes.string,
  }),
  complaint: PropTypes.string,
  client: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    id: PropTypes.number,
  }),
  driver: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    id: PropTypes.number,
  }),
  onClose: PropTypes.func,
};

const CarCell = ({ title, children, className }) => (
  <div key={uniqueId()} className={classNames('Cell', className)}>
    <span className="title">{ title }:</span>
    <span className="value">{ children }</span>
  </div>
);

CarCell.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export const CarMarkerInformation = (props) => {
  const {
    id,
    downtime,
    car,
    status,
    color,
    name,
    speed,
    phoneNumber,
    lastUpdated,
    onClose,
  } = props;

  const onCloseHandler = () => {
    onClose?.(props);
  };

  return (
    <div className="CarInformation">
      <div key="1" className="Column">
        <CarCell title="ID замовлення" className="W100">
          <span data-cell="id">
            { `#${padStart(id, 6, '0')}` }
          </span>
        </CarCell>
        <CarCell title="Ім’я водія" className="W100">
          <span data-cell="name">
            { name }
          </span>
        </CarCell>
        <CarCell title="Телефон" className="W100">
          <span data-cell="phoneNumber">
            { phoneNumber }
          </span>
        </CarCell>
        <CarCell title="Авто" className="W100">
          <span data-cell="meta">
            { `${car?.brand} ${car?.model}` }
          </span>
        </CarCell>
        <CarCell title="Номер Авто" className="W100">
          <span data-cell="car.registrationNumber">
            { car?.registrationNumber }
          </span>
        </CarCell>
        <CarCell title="Статус" className="W100">
          <span data-cell="status" style={{ color }}>
            { status }
          </span>
        </CarCell>

        <CarCell title="Колір Авто" className="W100">
          <span data-cell="car.color">
            { car?.color }
          </span>
        </CarCell>
        <CarCell title="Швидкість" className="W100">
          <span data-cell="speed">
            { `${speed ? speed.toFixed() : '0'} км/год` }
          </span>
        </CarCell>
        <CarCell title="Простій" className="W100">
          <span data-cell="downtime">
            { downtime || '...' }
          </span>
        </CarCell>
        <CarCell title="Час Оновлення" className="W100">
          <span data-cell="time">
            { lastUpdated ?? '---' }
          </span>
        </CarCell>
      </div>
      <div key="close" className="Close" onClick={onCloseHandler}>
        <span>✕</span>
      </div>
      <span key="arrow" className="Arrow">◢</span>
    </div>
  );
};
CarMarkerInformation.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  color: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  speed: PropTypes.number,
  phoneNumber: PropTypes.string,
  car: PropTypes.shape({
    brand: PropTypes.string,
    color: PropTypes.string,
    model: PropTypes.string,
    registrationNumber: PropTypes.string,
  }),
  driver: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  downtime: PropTypes.string,
  lastUpdated: PropTypes.string,
  onClose: PropTypes.func,
};

export const CarMarker = (props) => {
  const { calling, color, active } = props;

  return (
    <div className="CarMarker" data-active={active}>
      <CarsIcon color={color} calling={calling} />
    </div>
  );
};
CarMarker.defaultProps = {
  active: false,
};
CarMarker.propTypes = {
  color: PropTypes.string,
  active: PropTypes.bool,
  calling: PropTypes.string,
  coords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

const TicketMarker = (props) => {
  const {
    id, icon, color, active = false, fareType,
  } = props;
  const isHourly = fareType === FARE_TYPES.HOURLY;
  return (
    <div className="TicketMarker" data-active={active}>
      <div className="Header" style={{ backgroundColor: color }} data-background={color}>
        <img src={icon} className="MarkerIcon" alt="icon" />{ `#${padStart(id, 6, '0')}` }
        { isHourly && <img src={svgIconClock} className="MarkerHourly" alt="horly" title="Погодинний тариф" /> }
      </div>
    </div>
  );
};

TicketMarker.propTypes = {
  id: PropTypes.number,
  color: PropTypes.string,
  coords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  icon: PropTypes.node,
  active: PropTypes.bool,
  fareType: PropTypes.oneOf([FARE_TYPES.MILEAGE, FARE_TYPES.HOURLY]),
};

export default TicketMarker;
