import "./pagination.scss";
import { ReactComponent as PrevDisabled } from "../../../img/icons/pagination/prev-disabled.svg";
import { ReactComponent as PrevActive } from "../../../img/icons/pagination/prev-active.svg";
import { ReactComponent as NextDisabled } from "../../../img/icons/pagination/next-disabled.svg";
import { ReactComponent as NextActive } from "../../../img/icons/pagination/next-active.svg";
import React from 'react'
import PaginationItem from "./PaginationItem";
import RefreshIcon from "./refreshIcon/RefreshIcon";
import useSorting from "../../../helpers/hooks/useSorting";
import usePagination from "./usePagination";

const Pagination = ({ limitPerView, maxPage, onPageChange, paginationClass, showOnePage = true }) => {
  const { resetSearch } = useSorting();
  const dots = (<span className="pagination__item--dots">...</span>);

  const { displayedPages, isFirstPage, isLastPage, currentPage, handleUrlChange, paginationIndices, pages } = usePagination(limitPerView, maxPage, onPageChange);

  return (
    <div className={`pagination ${paginationClass}`} data-testid="test-pagination">
      <RefreshIcon onClick={() => resetSearch(onPageChange)} />
      {showOnePage && <>
        <PaginationItem
          onClick={() => !isFirstPage(currentPage) ? handleUrlChange(currentPage - 1) : ''}
          content={isFirstPage(currentPage) ? <PrevDisabled /> : <PrevActive />}
          className={isFirstPage(currentPage) ? 'disabled' : ''}
        />
        <PaginationItem
          onClick={() => handleUrlChange(1)}
          content={1}
          className={(isFirstPage(currentPage) ? 'current' : '')}
        />
        {!isFirstPage(paginationIndices[0] - 1) && maxPage >= limitPerView && (dots)}
        {displayedPages}
        {!isLastPage(paginationIndices[paginationIndices.length - 1] + 1, maxPage) && maxPage >= limitPerView && (dots)}
        {pages.length >= 0 && maxPage !== 1 && <PaginationItem
          onClick={() => handleUrlChange(maxPage)}
          content={maxPage}
          className={(isLastPage(currentPage, maxPage) ? 'current' : '')}
        />}
        <PaginationItem
          onClick={() => !isLastPage(currentPage, maxPage) ? handleUrlChange(currentPage + 1) : ''}
          content={isLastPage(currentPage, maxPage) ? <NextDisabled /> : <NextActive />}
          className={isLastPage(currentPage, maxPage) ? 'disabled' : ''}
        />
      </>}
    </div>
  )
};

export default Pagination;
