import { driver } from "../../../data/ordersMap/ordersMap";
import "./appointDriverModal.scss";

const InfoDefault = ({ info, className = '' }) => <div className={`appointDriverModal--orderId-mainInfo_item__defaultInfo ${className}`}>{info}</div>;
const InfoTitle = ({ name }) => <span className="appointDriverModal--orderId-mainInfo_title">{name}:</span>;

const InfoItem = ({ name, children }) =>
    <div className="appointDriverModal--orderId-mainInfo_item">
        <InfoTitle name={name} />
        {children}
    </div>;

const AppointDriverInfo = () => {
    const { orderId, numberplate, color, status, speed, waiting, fullName, phone, auto } = driver;

    return (
        <div className="appointDriverModal--orderId-mainInfo">
            <div className="appointDriverModal--orderId-mainInfo_left">
                <InfoItem name="СТАТУС">
                    <InfoDefault info={status} className="green" />
                </InfoItem>
                <InfoItem name="ID ЗАМОВЛЕННЯ">
                    <div className="orderIdPlate">{`#${orderId}`}</div>
                </InfoItem>
                <InfoItem name="ІМʼЯ ТА ПРІЗВИЩЕ ВОДІЯ">
                    <InfoDefault info={fullName} />
                </InfoItem>
                <InfoItem name="ТЕЛЕФОН">
                    <InfoDefault info={phone} />
                </InfoItem>
                <InfoItem name="АВТО">
                    <InfoDefault info={auto} />
                </InfoItem>
            </div>
            <div className="appointDriverModal--orderId-mainInfo_right">
                <InfoItem name="НОМЕР АВТО">
                    <InfoDefault info={numberplate} />
                </InfoItem>
                <InfoItem name="КОЛІР АВТО">
                    <InfoDefault info={color} />
                </InfoItem>
                <InfoItem name="ШВИДКІСТЬ">
                    <InfoDefault info={`${speed} км/год`} className="green" />
                </InfoItem>
                <InfoItem name="ПРОСТІЙ">
                    <InfoDefault info={`${waiting} сек`} className="yellow" />
                </InfoItem>
            </div>
        </div>
    );
};

export default AppointDriverInfo;