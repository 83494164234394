import React from 'react';
import './orderTariff.scss';
import CustomSelectWithLabel from '../../../../assets/inputs/customSelect/withLabel/CustomSelectWithLabel';
import useOrderTariff from './useOrderTariff';

const OrderTariff = ({ order, setServicesLoading, destination }) => {
  const { onTariffSelect, TARIFFS, isEditing } = useOrderTariff(
    order,
    setServicesLoading,
    destination,
  );

  return (
    <div className="formInput">
      <label>ТАРИФ</label>
      <div className="orderTariff">
        <CustomSelectWithLabel
          options={TARIFFS}
          onClick={onTariffSelect}
          className="fullWidth highZIndex"
          scrollable={true}
          alwaysEnabled={true}
          withoutUrlParams={!isEditing}
          searchParam="fare"
        />
      </div>
    </div>
  );
};

export default OrderTariff;
