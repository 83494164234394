import { Navigate } from 'react-router';
import React from 'react';

import {
  ACTIVE_DRIVERS,
  APPLICATIONS_DRIVERS,
  CHECK_EMAIL,
  CLIENT,
  CLIENTS,
  CLIENT_ORDERS,
  DIRECTOR,
  DIRECTOR_ANALYTICS,
  DIRECTOR_UNITS,
  DIRECTOR_SERVICES,
  DIRECTOR_TARIFFS,
  DIRECTOR_USERS,
  DIRECTOR_USERS_CREATE,
  DIRECTOR_USERS_UPDATE,
  DRIVER_ADD,
  DRIVER_APPLICATION,
  DRIVER_EDIT,
  DRIVER_ORDERS,
  DRIVER_REQUEST,
  ENTER_CODE,
  HELPDESK,
  LOGIN,
  ORDERS,
  ORDERS_CREATE,
  ORDERS_EDIT,
  ORDER_MAPS,
  PASSWORD_RESET,
  CLIENT_CREATE,
} from '../data/consts';

import Login from '../components/auth/Login';
import LoginForm from '../components/auth/right/loginForm/LoginForm';
import CheckEmail from '../components/auth/right/checkEmali/CheckEmail';
import ResetCodeForm from '../components/auth/right/resetPasswordCode/ResetCodeForm';
import ResetPasswordForm from '../components/auth/right/resetPassword/ResetPasswordForm';
import BackButton from '../components/assets/buttons/backButton/BackButton';
import Clients from '../components/clientss/Clients';
import Director from '../components/director/Director';
import Helpdesk from '../components/helpdesk/Helpdesk';
import OrderMap from '../components/maps/OrdersMap';
import ActiveDrivers from '../components/drivers/allDrivers/table/active/ActiveDrivers';
import DriversApplications from '../components/drivers/allDrivers/table/applications/DriversApplications';
import DriverOrders from '../components/drivers/driver/orders/DriverOrders';
import Client from '../components/clientss/clientPage/Client';
import ClientOrders from '../components/clientss/clientPage/ClientOrders';
import CreateOrder from '../components/orders/createOrder/page/CreateOrder';
import EditOrder from '../components/orders/editOrder/EditOrder';
import Tariffs from '../components/director/tariffs/Tariffs';
import Users from '../components/director/users/Users';
import Analytics from '../components/director/analytics/Analytics';
import CreateUser from '../components/director/users/createUser/CreateUser';
import DriverPage from '../components/drivers/driverPage/DriverPage';
import EditApplicationControls from '../components/drivers/allDrivers/table/applications/editTableControls/EditApplicationControls';
import { withDriverInfo } from '../components/drivers/editInfoHOC/withDriverInfo';
import AddDriverControls from '../components/drivers/driver/addDriver/AddDriverControls';
import DriverControls from '../components/drivers/driver/driverControls/DriverControls';
import AllOrders from '../components/orders/allOrders/AllOrders';
import { getLinkWithSearchParams } from '../helpers/general/general';
import Services from '../components/director/director_services/Services';
import { Units } from '../components/director/units/Units';
import ClientCreate from '../components/clientss/clientPage/ClientCreate';

const PendingDriver = withDriverInfo(DriverPage);
const ActiveDriver = withDriverInfo(DriverPage);
const DriverRequest = withDriverInfo(DriverPage);

export const routesNotLoggedIn = [
  {
    path: '*',
    element: (
      <Login formComponent={<LoginForm />} headerText="Авторизація у Eshka" />
    ),
  },
  { path: CHECK_EMAIL, element: <Login formComponent={<CheckEmail />} /> },
  {
    path: ENTER_CODE,
    element: (
      <Login
        formComponent={<ResetCodeForm />}
        headerText="Відновлення паролю"
      />
    ),
  },
  {
    path: PASSWORD_RESET,
    element: (
      <Login
        formComponent={<ResetPasswordForm />}
        headerText="Відновлення паролю"
        backButton={<BackButton navigateTo={LOGIN} />}
      />
    ),
  },
];
export const routesLoggedIn = [
  {
    path: '*',
    element: <Navigate to={getLinkWithSearchParams(ORDER_MAPS)} replace />,
  },
  {
    path: DRIVER_ADD,
    element: (
      <DriverPage
        pageHeading="Додати водія"
        controls={<AddDriverControls />}
        driverStatus="new"
      />
    ),
  },
  {
    path: DRIVER_EDIT,
    element: (
      <ActiveDriver
        pageHeading="Перегляд водія"
        controls={<AddDriverControls />}
        pageControls={<DriverControls />}
        driverStatus="existing"
      />
    ),
  },
  {
    path: DRIVER_REQUEST,
    element: (
      <DriverRequest
        pageHeading="Перегляд водія (запит на редагування)"
        controls={<AddDriverControls />}
        pageControls={<DriverControls />}
        driverStatus="existing"
      />
    ),
  },
  {
    path: DRIVER_APPLICATION,
    element: (
      <PendingDriver
        pageHeading="Перегляд заявки"
        controls={<EditApplicationControls />}
        driverStatus="pending"
      />
    ),
  },
  { path: DRIVER_ORDERS, element: <DriverOrders /> },
  { path: ACTIVE_DRIVERS, element: <ActiveDrivers /> },
  { path: APPLICATIONS_DRIVERS, element: <DriversApplications /> },
  { path: ORDER_MAPS, element: <OrderMap /> },
  { path: ORDERS, element: <AllOrders /> },
  { path: ORDERS_CREATE, element: <CreateOrder /> },
  { path: ORDERS_EDIT, element: <EditOrder /> },
  { path: CLIENTS, element: <Clients /> },
  { path: CLIENT, element: <Client /> },
  { path: CLIENT_CREATE, element: <ClientCreate /> },
  { path: CLIENT_ORDERS, element: <ClientOrders /> },
  { path: DIRECTOR, element: <Director /> },
  { path: DIRECTOR_TARIFFS, element: <Tariffs /> },
  { path: DIRECTOR_USERS, element: <Users /> },
  { path: DIRECTOR_UNITS, element: <Units /> },
  { path: DIRECTOR_USERS_CREATE, element: <CreateUser action="create" /> },
  { path: DIRECTOR_USERS_UPDATE, element: <CreateUser action="update" /> },
  { path: DIRECTOR_ANALYTICS, element: <Analytics /> },
  { path: DIRECTOR_SERVICES, element: <Services /> },
  { path: HELPDESK, element: <Helpdesk /> },
  {
    path: LOGIN,
    element: <Navigate to={getLinkWithSearchParams(ORDERS)} replace />,
  },
];
