import React, { useState, useEffect } from "react";
import "./mapWrapper.scss";
import MyMap from "./MyMap";
import Marker from "../marker/Marker";
import useBindCreators from "../../../store/hooks/useBindCreators";
import { mapActions } from "../../../store/slices/mapSlice";
import useGetState from "../../../store/hooks/useGetState";
import AppointDriverModal from "../modals/AppointDriverModal";

import { GoogleMap } from "@react-google-maps/api";

const MapWrapper = ({
  initialZoom = 11,
  routeStart,
  routeEnd,
  routeWaypoints = [],
  initialCenter,
  drawRoute = false,
  initialMarkers = [],
  strokeColor,
}) => {
  const { newOrderActivated, isMapLoaded } = useGetState();
  const [start, setStart] = useState(routeStart);
  const [end, setEnd] = useState(routeEnd);
  const [draw, setDraw] = useState(false);
  const [center, setCenter] = useState(initialCenter);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [lastClickedMarker, setLastClickedMarker] = useState(null);
  const { enableNewOrder, disableNewOrder, setAllMarkers } = useBindCreators(mapActions);
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setAllMarkers?.();
    setDraw(false);
    setLastClickedMarker(null);
    disableNewOrder?.();
  };

  const showRoute = route => {
    setDraw(true);
    setStart(route.start);
    setEnd(route.end);
  };
  const onMarkerClick = (type, id, position, route) => {
    if (type === 'driver_online' && newOrderActivated) {
      setShowModal(true);
      return;
    }

    if (newOrderActivated) {
      disableNewOrder();
    }

    if (id === lastClickedMarker) {
      setDraw(false);
      setInfoWindowPosition(null);
      setLastClickedMarker(null);
      return;
    }

    setLastClickedMarker(id);

    if (type === 'orders_new_order') {
      enableNewOrder();
    }


    if (type.includes('driver')) {
      setInfoWindowPosition(position);
    }

    if (type.includes('order')) {
      setInfoWindowPosition(null);
    }

    if (type !== 'driver_online' && type !== 'driver_offline') {
      showRoute(route);
    } else {
      setDraw(false);
    }
  };

  const displayedInitialMarkers = initialMarkers.map(item => (
    <Marker
      key={item.id}
      position={item.position}
      icon={item.icon}
      onClick={() => onMarkerClick(item.type, item.id, item.position, item.route)}
      type={item.type}
      id={item.id}
    />
  ));

  useEffect(() => {
    setCenter(initialCenter);
  }, [initialCenter]);

  return (isMapLoaded ?
    <>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        center={center}
        zoom={initialZoom}
      >
        <MyMap
          zoom={initialZoom}
          center={center}
          routeStart={start}
          routeEnd={end}
          routeWaypoints={routeWaypoints}
          drawRoute={drawRoute || draw}
          strokeColor={strokeColor}
          infoWindowPosition={infoWindowPosition}
        >
          {displayedInitialMarkers}
        </MyMap>
      </GoogleMap>
      <AppointDriverModal
        show={showModal}
        onHide={closeModal}
      />
    </> : <></>
  );
};

export default MapWrapper;
