import "./textarea.scss";

const Textarea = ({ id, name, message, onChange, onKeyDown }) =>
    <textarea
        id={id}
        name={name}
        rows="6"
        cols="33"
        value={message}
        onChange={onChange}
        onKeyDown={onKeyDown}
    />;

export default Textarea;