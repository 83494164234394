export const getColor = (status) => {
  switch (status) {
    case 'approved':
    case 'active':
    case 'processing':
    case 'closed':
      return 'green';
    case 'unprocessed':
    case 'blocked':
    case 'new':
      return 'yellow';
    case 'fired':
      return 'grey';
    case 'rejected':
      return 'red';
    default: return 'green';
  }
};
