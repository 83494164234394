import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button/Button';

const TransparentButton = ({
  text, onClick, color = 'blue', withIcon,
}) => <Button transparent text={text} onClick={onClick} color={color} withIcon={withIcon} />;
export default TransparentButton;

TransparentButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
  withIcon: PropTypes.bool,
};
