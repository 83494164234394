import React from "react";
import { Link } from "react-router-dom";
import "./section.scss";

const Section = ({ name, className, redirectTo }) =>
  <Link className={`director--section ${className}`} to={redirectTo}>
    {name}
  </Link>

export default Section;
