import { DRIVER_PERSONAL_INFO_FORM_COL_1, DRIVER_PERSONAL_INFO_FORM_COL_2 } from "../../../../../data/forms/driverPersonalInfoForm";
import FormInput from "../../../../assets/inputs/FormInput";
import useGetState from "../../../../../store/hooks/useGetState";
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import { driverActions } from "../../../../../store/slices/driverSlice";

const PersonalInfoForm = () => {
  const { driver } = useGetState();
  const { setDriver, setDriverErrors } = useBindCreators(driverActions);
  const errors = driver?.errors;

  const driverFormFields = [...DRIVER_PERSONAL_INFO_FORM_COL_1, ...DRIVER_PERSONAL_INFO_FORM_COL_2]

  const mapInputs = (data) => {
    const column = data.map(input =>
      <FormInput
        key={input.id}
        value={driver[input.name] || ''}
        onChange={handleChange}
        errorClass={errors[input.name] ? 'error' : ''}
        customErrorMessage={errors[input.name] ? errors[input.name] : ''}
        isPassword={input.name === 'password' && true}
        {...input}
      />);
    return <>{column}</>
  };

  const handleChange = ({ target }) => {
    const { name: key, value } = target;
    setDriver({ key, value });
    if (errors[key]) {
      setDriverErrors({ [key]: '' });
    }
  };

  const renderDriverInputs = mapInputs(driverFormFields)

  return (
    <div className="personalInfo--form">{renderDriverInputs}</div>
  );
};

export default PersonalInfoForm;
