import React from 'react';
import PropTypes from 'prop-types';
import './formButton.scss';

const FormButton = ({
  color = 'blue',
  text = 'Hello',
  type = 'submit',
  onClick,
  marginTop,
  form,
  disabled
}) => {
  const additionalStyle = marginTop ? { marginTop } : {};

  return (
    <button
      style={additionalStyle}
      className={`btn btn-${color}`}
      onClick={onClick}
      type={type}
      form={form}
      data-testid="form-button"
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default FormButton;

FormButton.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  marginTop: PropTypes.string,
  form: PropTypes.string,
  disabled: PropTypes.bool
};
