import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DEFAULT_PAGINATION } from '../../../data/consts';
import useSorting from '../../../helpers/hooks/useSorting';
import useRidesService from '../../../services/ridesService';
import Orders from '../Orders';
import { OrdersStats } from '../../drivers/OrdersStats';
import styles from './styles.module.scss';

const AllOrders = () => {
  const { getAllRides, loading } = useRidesService();
  const [rides, setRides] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [statistics, setStatistics] = useState({});
  const { paramsObject, urlParamsHandler } = useSorting();
  const currentDate = useMemo(() => dayjs().format('DD.MM.YYYY'), []);

  const currentParamsObject =
    'createdAtFrom' in paramsObject
      ? paramsObject
      : { ...paramsObject, createdAtFrom: currentDate };
  const fetchRides = async (params = currentParamsObject) => {
    const { rides, pagination, statistics } = await getAllRides(params);
    setRides(rides);
    setPagination(pagination);
    setStatistics(statistics);
  };

  useEffect(() => {
    if (!('createdAtFrom' in paramsObject)) {
      urlParamsHandler([{ param: 'createdAtFrom', value: currentDate }]);
    }
  }, []);

  return (
    <Orders
      pageTitle=""
      tableBody={rides}
      loading={loading}
      maxPage={pagination.lastPage}
      fetchOrders={fetchRides}
      className={styles.tableWrapper}
      isOrdersPage
      stats={
        <div className={styles.statsWrapper}>
          <OrdersStats
            loading={loading}
            total={pagination?.total}
            cancelled={statistics?.count_cancelled}
            finished={statistics?.count_finished}
            profit={statistics?.finished_total_cost}
          />
        </div>
      }
    />
  );
};

export default AllOrders;
