import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '../buttonIcons/add/AddIcon';
import './button.scss';

const defaultButtonType = 'button';

export const Button = ({
  text, disabled = false, onClick, color = 'blue', withIcon = false, Icon, transparent = false, fullWidth, id = '', type = defaultButtonType, className = '',
  children,
}) => {
  const buttonClass = `button ${color} ${transparent ? 'transparent' : ''} ${fullWidth ? 'fullWidth' : ''} ${className}`;
  const icon = Icon || <AddIcon />;
  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick} type={type} id={id} data-testid="base-button">
      {text}
      {children}
      {withIcon && icon}
    </button>
  );
};

export default Button;

Button.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  withIcon: PropTypes.bool,
  Icon: PropTypes.element,
  transparent: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  children: PropTypes.node,
};
