import "./users.scss";
import DirectorWrapper from "../section/DirectorWrapper";
import useRedirect from "../../../helpers/hooks/useRedirect";
import { DIRECTOR_USERS_CREATE } from "../../../data/consts";
import UsersTable from "./table/UsersTable";

const Users = () => {
    const redirect = useRedirect();

    return (
        <DirectorWrapper
            pageHeading='Користувачі'
            buttonText='Додати користувача'
            onButtonClick={() => redirect(DIRECTOR_USERS_CREATE)}
        >
            <UsersTable />
        </DirectorWrapper>
    );
};

export default Users;