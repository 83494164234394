import "../clientCard.scss";
import CardItem from "./CardItem";

const CardList = ({ list, onDelete, loading }) =>
    <div className="clientCard--list">
        {list.map(item => (
            <CardItem
                key={item.id}
                onDelete={onDelete}
                loading={loading}
                {...item}
            />
        ))}
    </div>

export default CardList;