export const DRIVER_STATUS = [
  {
    id: 'all',
    name: 'all',
    text: 'Статус: усі',
  },
  {
    id: 'active',
    name: 'active',
    text: 'Працює',
  },
  {
    id: 'updateRequested',
    name: 'updateRequested',
    text: 'Працює: запит на редагування',
  },
  {
    id: 'blocked',
    name: 'blocked',
    text: 'Заблоковано',
  },
  {
    id: 'fired',
    name: 'fired',
    text: 'Звільнено',
  },
];
