import AuthFormView from "../authFormView/AuthFormView";
import FormButton from "../../../assets/buttons/formButton/FormButton";
import { useNavigate } from "react-router";
import { ENTER_CODE } from "../../../../data/consts";
import "./checkEmail.scss";
import useGetState from "../../../../store/hooks/useGetState";

const CheckEmail = () => {
    const navigate = useNavigate();
    const { codeToSendEmail } = useGetState();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(ENTER_CODE);
    };

    return (
        <AuthFormView>
            <form onSubmit={handleSubmit}>
                <div className="form--item">
                    <div className="checkEmailHeader">
                        <p>Перевірте свою</p>
                        <p>електронну пошту</p>
                    </div>
                    <div className="checkEmailText">
                        <p>Ми надіслали вам інструкції щодо відновлення пароля на</p>
                        <p>вашу електронну адресу {codeToSendEmail}. Не</p>
                        <p>отримали листа? Перевірте папку «Спам».</p>
                    </div>
                    <FormButton text="Ок" marginTop={100} />
                </div>
            </form>
        </AuthFormView>
    );
};

export default CheckEmail;