import { PASSWORD_PATTERN } from '../consts';

export const RESET_CODE_INPUTS = [
  {
    id: 1,
    name: 'code',
    type: 'number',
    placeholder: '123456',
    errorMessage: 'Має бути числом.',
    label: 'КОД',
  },
  {
    id: 2,
    name: 'password',
    type: 'password',
    placeholder: '******',
    errorMessage: 'Має бути 8-16 символів, містити щонайменше 1 цифру, 1 велику букву і 1 спецсимвол',
    label: 'ПАРОЛЬ',
    pattern: PASSWORD_PATTERN,
  },
  {
    id: 3,
    name: 'confirm_password',
    type: 'password',
    placeholder: '******',
    errorMessage: 'Паролі не співпадають.',
    label: 'ПІДТВЕРДІТЬ ПАРОЛЬ',
    pattern: PASSWORD_PATTERN,
  },
];
