import React, { Children } from "react";
import PropTypes from 'prop-types';
import "./pageHeading.scss";

const PageHeading = ({ text, style, additionalInfo = '', className, children }) =>
  <div style={style} className={`pageHeading ${className}`}>
    {text}
    {!!additionalInfo && <span className="pageHeading--aditionalInfo">{additionalInfo}</span>}
    {children}
  </div>;
export default PageHeading;


PageHeading.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  additionalInfo: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}
