import { createSlice } from '@reduxjs/toolkit';
import { findIndex, isNil } from 'lodash';
import LocalStorage from '../../helpers/general/localStorage';
import {
  LOCAL_STORE_VIEWPORT_CENTER,
  RIDE_STATUSES,
  currentStatuses,
  finishedStatuses,
  futureStatuses,
  unprocessedStatuses,
} from '../../data/consts';
import { ORDER_MAPS_TAB_NAMES } from '../../data/ordersMap/ordersMap';

const initialState = {
  activeTab: null,
  initialized: false,
  pagination: {
    currentPage: 1,
    total: 0,
  },
  isLoaded: false,
  fetched: false,
  data: [],
  pendingOrders: [],
  newOrderActivated: false,
  mapViewportCenter: {
    lat: 0,
    lng: 0,
  },
  center: {
    lat: 0,
    lng: 0,
  },
  activeOrders: null,
  ordersByStatus: {
    unprocessed: {
      total: 0,
      list: [],
    },
    current: {
      total: 0,
      list: [],
    },
    future: {
      total: 0,
      list: [],
    },
  },
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    init(state) {
      state.initialized = true;
    },
    destructor(state) {
      state.initialized = initialState.initialized;
      state.pagination = initialState.pagination;
      state.data = initialState.data;
      state.fetched = initialState.fetched;
    },
    clear(state) {
      state.pagination = initialState.pagination;
      state.data = initialState.data;
    },
    fetching(state, { payload }) {
      state.fetched = payload;
    },
    setPaginator(state, { payload }) {
      state.pagination = payload;
    },
    setData(state, { payload }) {
      state.data = payload;
    },
    setPendingOrders(state, { payload }) {
      state.pendingOrders = state.initialized ? payload : [];
    },
    setCenter(state, { payload }) {
      if (!payload) {
        const data = JSON.parse(JSON.stringify(state));
        const { mapViewportCenter } = data;
        state.center = mapViewportCenter;
      } else {
        state.center = payload;
      }
    },
    setViewPortCenter(state, { payload }) {
      const ls = LocalStorage(LOCAL_STORE_VIEWPORT_CENTER);
      ls.setState(payload);
      state.mapViewportCenter = payload;
    },
    setTabName(state, { payload }) {
      state.activeTab = payload;
    },
    setActiveOrders(state, { payload }) {
      state.activeOrders = payload;

      const currentOrders = (state.activeOrders ?? []).filter(
        (order) =>
          currentStatuses.includes(order.status) ||
          (order.status === RIDE_STATUSES.APPROVED &&
            order.scheduledFor === null),
      );
      const unprocessedOrders = (state.activeOrders ?? []).filter((order) =>
        unprocessedStatuses.includes(order.status),
      );
      const futureOrders = (state.activeOrders ?? []).filter(
        (order) =>
          futureStatuses.includes(order.status) && order.scheduledFor !== null,
      );
      state.pendingOrders = (state.activeOrders ?? []).filter(
        (order) => order.status === RIDE_STATUSES.PENDING,
      );
      state.ordersByStatus = {
        current: {
          total: currentOrders.length,
          list: currentOrders,
        },
        unprocessed: {
          total: unprocessedOrders.length,
          list: unprocessedOrders,
        },
        future: {
          total: futureOrders.length,
          list: futureOrders,
        },
      };
    },
    mergeOrders(state, { payload }) {
      const index = findIndex(state.activeOrders, { id: payload.id });
      if (index >= 0) {
        if (finishedStatuses.includes(payload.status)) {
          state.activeOrders?.splice(index, 1);
        } else {
          state.activeOrders?.splice(index, 1, payload);
        }
        if (payload.status !== RIDE_STATUSES.PENDING) {
          state.pendingOrders?.splice(index, 1);
        }
      } else {
        state.activeOrders?.unshift(payload);

        if (payload.status === RIDE_STATUSES.PENDING) {
          state.pendingOrders?.unshift(payload);
        }
      }

      const currentOrders = (state.activeOrders ?? []).filter(
        (order) =>
          currentStatuses.includes(order.status) ||
          (order.status === RIDE_STATUSES.APPROVED &&
            order.scheduledFor === null),
      );
      const unprocessedOrders = (state.activeOrders ?? []).filter((order) =>
        unprocessedStatuses.includes(order.status),
      );
      const futureOrders = (state.activeOrders ?? []).filter(
        (order) =>
          futureStatuses.includes(order.status) && order.scheduledFor !== null,
      );

      state.ordersByStatus = {
        current: {
          total: currentOrders.length,
          list: currentOrders,
        },
        unprocessed: {
          total: unprocessedOrders.length,
          list: unprocessedOrders,
        },
        future: {
          total: futureOrders.length,
          list: futureOrders,
        },
      };
    },
    setIsLoaded(state, { payload }) {
      state.isLoaded = payload;
    },
  },
});

const { reducer: map, actions } = mapSlice;
export const mapActions = actions;
export default map;
