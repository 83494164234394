import './tableSearchField.scss';
import { useState, useEffect, useRef } from 'react';
import useSorting from '../../../../helpers/hooks/useSorting';
import { ReactComponent as SearchIcon } from '../../../../img/icons/inputs/search.svg';
import notify from '../../../../helpers/notify';
import useGetState from '../../../../store/hooks/useGetState';
import useBindCreators from '../../../../store/hooks/useBindCreators';
import { searchActions } from '../../../../store/slices/searchSlice';

const TableSearchField = ({ name, handleSearch, placeholder }) => {
  const { urlParamsHandler, getSearchParam, paramsObject } = useSorting();
  const paramInUrl = getSearchParam(name);
  const [value, setValue] = useState(paramInUrl ? paramInUrl : '');
  const firstRender = useRef(true);
  const { search } = useGetState();
  const { setSearchReset } = useBindCreators(searchActions);

  const onChange = ({ target }) => setValue(target.value);

  const onSubmit = () => {
    if (
      (value.length < 3 && value !== '' && name !== 'id') ||
      value.length > 255
    ) {
      notify('Рядок повинен бути між 3 та 255 символами.', 'error');
      return;
    }

    if (search.searchReset) {
      setSearchReset(false);
      return;
    }

    if (value === '' && paramInUrl) {
      urlParamsHandler([], [name]);
      delete paramsObject[name];
      handleSearch(paramsObject);
      return;
    }

    urlParamsHandler([{ param: name, value }], ['page']);
    handleSearch({ ...paramsObject, [name]: value, page: 1 });
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const timer = setTimeout(() => onSubmit(), 500);
    return () => clearTimeout(timer);
  }, [value]);

  useEffect(() => {
    if (!paramInUrl) {
      setValue('');
    }
  }, [paramInUrl]);

  return (
    <form className="tableSearch" onSubmit={(e) => e.preventDefault()}>
      <button className="searchButton" type="submit" disabled={value === ''}>
        <SearchIcon />
      </button>
      <div className="tableSearchInput">
        <input
          onChange={onChange}
          autoComplete="off"
          value={value}
          className="tableSearchInput-field"
          placeholder={placeholder}
        />
      </div>
    </form>
  );
};

export default TableSearchField;
