import { useNavigate } from "react-router";
import SmallLink from "../../../../assets/links/SmallLink";
import { PASSWORD_RESET } from "../../../../../data/consts";
import './controls.scss';
import CheckboxLogin from "../../../../assets/inputs/checkbox-login/CheckboxLogin";

const Controls = ({ checkboxChecked, onChange }) => {
    const navigate = useNavigate();
    const onForgotPassword = () => navigate(PASSWORD_RESET);

    return (
        <div className="loginControls">
            <CheckboxLogin checked={checkboxChecked} onChange={onChange} label='запамʼятати мене' />
            <SmallLink text='Забули пароль?' onClick={onForgotPassword} />
        </div>
    );
};

export default Controls;