export default function AddFileIcon({ className }) {
    return (
        <svg
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className || 'w-[19px] h-[20px] text-[#189AB4]'}
            style={{ width: "20px", height: "20px" }}
        >
            <path
                d="M12.172 4.95l-6.585 6.587a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656L2.758 8.708a6 6 0 108.485 8.486L17.5 10.95"
                stroke="CurrentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}