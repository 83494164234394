import { createSearchRow } from '../../../../helpers/general/general';

export const ACTIVE_DRIVERS_HEADERS = [
  {
    id: 1,
    label: 'СТАТУС',
    name: 'status',
    sortable: false,
    searchable: false,
    className: 'activeDrivers--status',
  },
  {
    id: 2,
    label: 'ПОЗИВНИЙ',
    name: 'calling',
    sortable: true,
    searchable: true,
    className: 'activeDrivers--calling',
  },
  {
    id: 3,
    label: 'ІМʼЯ ТА ПРІЗВИЩЕ',
    name: 'name',
    sortable: true,
    searchable: true,
    className: 'activeDrivers--name',
  },
  {
    id: 4,
    label: 'ТЕЛЕФОН',
    name: 'phoneNumber',
    sortable: false,
    searchable: true,
    className: 'activeDrivers--phone',
  },
  {
    id: 5,
    label: 'АВТОПАРК',
    name: 'park',
    sortable: false,
    searchable: false,
    className: 'activeDrivers--park',
  },
  {
    id: 6,
    label: 'РЕЙТИНГ',
    name: 'rate',
    sortable: false,
    searchable: false,
    className: 'activeDrivers--rate',
  },
  {
    id: 7,
    label: 'БАЛАНС',
    name: 'cash',
    sortable: false,
    searchable: false,
    className: 'activeDrivers--cash',
  },
  {
    id: 8,
    label: 'АВТО',
    name: 'car',
    sortable: false,
    searchable: true,
    className: 'activeDrivers--auto',
  },
  {
    id: 9,
    name: '',
    label: '',
    sortable: false,
    searchable: false,
    className: 'activeDrivers--button',
  },
];

export const ACTIVE_DRIVERS_SEARCH_ROW = createSearchRow(ACTIVE_DRIVERS_HEADERS);
