import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allTariffs: [],
};

const sortTariffsById = (arr) => arr.sort((a, b) => a.id - b.id);

const allTariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    addTariff(state) {
      const newTariff = {
        id: `newTariff-${state.allTariffs.length}`,
        label: '-',
        name: '-',
      };

      state.allTariffs = [...state.allTariffs, newTariff];
    },
    setAllTariffs(state, { payload }) {
      const sortedTariffs = sortTariffsById(payload);
      state.allTariffs = sortedTariffs;
    },
  },
});

const { reducer: tariffs, actions } = allTariffsSlice;
export const allTariffsActions = actions;
export default tariffs;
