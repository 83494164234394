import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchReset: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchReset(state, { payload }) {
      state.searchReset = payload;
    },
  },
});

const { reducer: search, actions } = searchSlice;
export const searchActions = actions;
export default search;
