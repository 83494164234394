import React, { useState, useCallback } from 'react';
import { GoogleMap } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
};

export default function AddressMap({
  coordinates,
  children,
  zoom = 12,
  isLoaded,
}) {
  // eslint-disable-next-line no-unused-vars
  const [_, setMap] = useState(null)

  const onLoad = useCallback((map) => setMap(map), [])
  const onUnmount = useCallback(() => setMap(null), [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  )
}
