import { useNavigate } from 'react-router';
import {
  CREATE_DRIVER_ENDPOINT,
  DRIVERS_ALL,
  DRIVERS_PENDING,
  getDecreaseDriverBalanceEndpoint,
  getDriverActionEndpoint,
  getDriverDelete,
  getDriverEndpoint,
  getDriverUpdatePasswordEndpoint,
  getDriverUpdateStatusEndpoint,
  getIncreaseDriverBalanceEndpoint,
} from '../data/endpoints';
import notify from '../helpers/notify';
import useBindCreators from '../store/hooks/useBindCreators';
import { driverActions } from '../store/slices/driverSlice';
import useHttpService from './httpService';

const useDriversService = () => {
  const navigate = useNavigate();
  const { request, loading } = useHttpService();
  const { setDriverErrors, setDriverStatus } = useBindCreators(driverActions);

  const getDrivers = async (type = 'all', params) => {
    const url = type === 'all' ? DRIVERS_ALL : DRIVERS_PENDING;
    const drivers = await request({ url, params });
    return drivers;
  };

  const getDriver = async (id) => {
    const driver = await request({ url: getDriverEndpoint(id) });
    return driver;
  };

  const postDriverInfo = async (id, action, data) => {
    const url =
      id === null && action === 'create'
        ? CREATE_DRIVER_ENDPOINT
        : getDriverActionEndpoint(id, action);

    try {
      let params = { url, method: 'POST' };
      if (data) {
        params = { ...params, data };
      }

      const response = await request(params);
      return response;
    } catch (err) {
      setDriverErrors(err?.response?.data?.errors);
      notify('Введено невірні дані, перевірте форму', 'error');
      return err.response.data;
    }
  };

  const postDriverPassword = async (id, data) => {
    const url = getDriverUpdatePasswordEndpoint(id);
    try {
      let params = { url, method: 'POST' };
      if (data) {
        params = { ...params, data };
      }
      const response = await request(params);
      return response;
    } catch (err) {
      setDriverErrors(err?.response?.data?.errors);
      notify('Пароль не оновлено', 'error');
      return err.response.data;
    }
  };

  const deleteDriver = async (id) => {
    const url = getDriverDelete(id);
    try {
      let params = { url, method: 'DELETE' };
      params = { ...params };
      const response = await request(params);
      notify('Водія видалено', 'info');
      navigate(-1);
      return response;
    } catch (err) {
      setDriverErrors(err?.response?.data?.errors);
      notify('Водія не видалено, спробуйте ще', 'error');
      return err.response.data;
    }
  };

  const createDriver = (data) => postDriverInfo(null, 'create', data);
  const updateDriver = (id, data) => postDriverInfo(id, 'update', data);
  const updateDriverPassword = (id, data) => postDriverPassword(id, data);
  const approvePendingDriver = (id) => postDriverInfo(id, 'approve');
  const rejectPendingDriver = (id) => postDriverInfo(id, 'reject');

  const updateDriverStatus = async (id, data, isPasswordChange) => {
    await request({
      url: getDriverUpdateStatusEndpoint(id),
      method: 'POST',
      data,
    });
    await setDriverStatus(data.status);
    notify(isPasswordChange ? 'Пароль змінено' : 'Статус змінено', 'info');
  };

  const increaseDriverBalance = async (id, data, cb) => {
    try {
      await request({
        url: getIncreaseDriverBalanceEndpoint(id),
        method: 'POST',
        data,
      });
      cb();
      notify('Баланс поповнено', 'info');
    } catch (e) {
      notify(e.response.data.message);
    }
  };

  const decreaseDriverBalance = async (id, data, cb) => {
    try {
      await request({
        url: getDecreaseDriverBalanceEndpoint(id),
        method: 'POST',
        data,
      });
      cb();
      notify('Кошти списано', 'info');
    } catch (e) {
      notify(e.response.data.message);
    }
  };

  return {
    getDrivers,
    getDriver,
    updateDriver,
    approvePendingDriver,
    rejectPendingDriver,
    createDriver,
    updateDriverStatus,
    updateDriverPassword,
    increaseDriverBalance,
    decreaseDriverBalance,
    deleteDriver,
    loading,
  };
};

export default useDriversService;
