import AuthLeft from "./left/AuthLeft";
import AuthRight from "./right/AuthRight";
import './login.scss';

const Login = ({ formComponent, headerText, backButton }) => {
    return (
        <div className="login">
            <AuthLeft />
            <AuthRight formComponent={formComponent} headerText={headerText} backButton={backButton} />
        </div>
    );
};

export default Login;