import AuthFormView from "../authFormView/AuthFormView";
import FormButton from "../../../assets/buttons/formButton/FormButton";
import Controls from "./additionalControls/Controls";
import useLoginForm from "./useLoginForm";
import Spinner from "../../../assets/spinner/Spinner";

const LoginForm = () => {
    const { authorizing, rememberMe, handleSubmit, inputs, setValues } = useLoginForm();

    return (
        <AuthFormView>
            {!authorizing && <form onSubmit={handleSubmit}>
                <div className="form--item">
                    {inputs}
                    <Controls
                        checkboxChecked={rememberMe}
                        onChange={() => setValues(prevValues => ({ ...prevValues, rememberMe: !rememberMe }))}
                    />
                    <FormButton text="Увійти" />
                </div>
            </form>}
            {authorizing && <Spinner />}
        </AuthFormView>
    );
};

export default LoginForm;
