import './authFormView.scss';

const AuthFormView = ({ children, size }) => {
    return (
        <div className={`authFormView ${size}`}>
            {children}
        </div>
    );
};

export default AuthFormView;