import { PASSWORD_PATTERN } from '../consts';

export const LOGIN_FORM_INPUTS = [
  {
    id: 1,
    name: 'email',
    type: 'email',
    placeholder: 'вашлогін@gmail.com',
    errorMessage: 'Невалідна електронна адреса.',
    label: 'ЛОГІН',
  },
  {
    id: 2,
    name: 'password',
    type: 'password',
    placeholder: '******',
    errorMessage: 'Має бути 8-20 символів.',
    label: 'ПАРОЛЬ',
    pattern: PASSWORD_PATTERN,
  },
];
