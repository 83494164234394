import React, { useState } from 'react';
import { useParams } from 'react-router';
import { DEFAULT_PAGINATION } from '../../../data/consts';
import useSorting from '../../../helpers/hooks/useSorting';
import useRidesService from '../../../services/ridesService';
import Orders from '../../orders/Orders';

const ClientOrders = () => {
  const { getClientRides, loading } = useRidesService();
  const { id } = useParams();
  const [rides, setRides] = useState([]);
  const [client, setClient] = useState('');
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const { paramsObject } = useSorting();

  const fetchRides = async (params = paramsObject) => {
    if (id) {
      const { rides, pagination, client } = await getClientRides(id, params);
      setRides(rides);
      setPagination(pagination);
      setClient(client.name);
    }
  };

  return (
    <Orders
      pageTitle="Клієнти"
      backButton
      pageHeading={`Замовлення клієнта ${client}`}
      tableControlsMargin
      tableBody={rides}
      loading={loading}
      maxPage={pagination.lastPage}
      fetchOrders={fetchRides}
    />
  );
};

export default ClientOrders;
