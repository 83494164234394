import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import FiltersIcon from '../buttonIcons/filters/FiltersIcon';

const FiltersButton = ({ onClick, filled }) => (
  <Button
    color={`blue ${filled ? 'background-filled' : ''}`}
    withIcon
    Icon={<FiltersIcon />}
    text="Редагування колонок"
    onClick={onClick}
  />
);
export default FiltersButton;

FiltersButton.propTypes = {
  onClick: PropTypes.func,
  filled: PropTypes.bool,
};
