export const ORDERS_DATE_INPUTS = [
  {
    id: 'date_inputs_from',
    name: 'createdAtFrom',
    placeholder: 'Дата: від...',
  },
  {
    id: 'date_inputs_to',
    name: 'createdAtTo',
    placeholder: 'Дата: до...',
  },
];
