import { DrawingManager, GoogleMap, Polygon } from '@react-google-maps/api';
import { Button, Modal, Popover, Select, Typography } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';

import notify from '../../../helpers/notify';
import BackButton from '../../assets/buttons/backButton/BackButton';
import SearchAddressForm from '../../assets/inputs/searchAddress/SearchAddressInput';
import PageHeading from '../../assets/pageHeadings/PageHeading';
import ContentWrapper from '../../assets/wrappers/content/ContentWrapper';
import { defaultCenter } from './constants';
import { getUnitsOptions } from './helpers';
import useGetState from '../../../store/hooks/useGetState';
import { useCreateUnitSettingsMutation, useCreateUnitMutation, useDeleteUnitMutation, useGetAllUnitsQuery } from '../../../api/unitsApi';

import { ReactComponent as DeleteIcon } from '../../../img/icons/units/delete.svg';
import { ReactComponent as PolygonIcon } from '../../../img/icons/units/polygon.svg';

import styles from './units.module.scss';

export const Units = () => {
  const [createUnit, { isLoading: isCreateUnitLoading }] = useCreateUnitMutation();
  const [deleteUnit, { isLoading: isDeleteUnitLoading }] = useDeleteUnitMutation();
  const [createUnitSettings] = useCreateUnitSettingsMutation();

  const { data, isFetching, isLoading } = useGetAllUnitsQuery(undefined);

  const [deleteModalOpened, setDeleteModalOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  const [value, setValue] = useState(null);
  const [address, setAddress] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const { isMapLoaded } = useGetState();
  const [polygonPoints, setPolygonPoints] = useState(null);

  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [drawingMode, setDrawingMode] = useState('created');


  const selectedUnitData = data?.find(item => item.id === selectedUnit || (!selectedUnit && item.id === data?.at(0)?.id));

  const handleAddressChange = (event) => {
    const addressChanged = event.target.value !== value;
    const isEmpty = event.target.value === "";
    if (isEmpty || addressChanged) {
      setAddress(null);
    }
    setValue(event.target.value);
  };

  const handleSelect = async ({ formatted_address, geometry, place_id }) => {
    setAddress({
      address: formatted_address,
      center: geometry.location,
      placeId: place_id,
    });
    setValue(formatted_address);
  };

  const handleCreateUnit = () => {
    if (!address) return;
    setValue(null);
    setAddress(null);
    setOpened(false);

    createUnit({
      name: address.address,
      lat: address.center.lat(),
      lng: address.center.lng(),
      placeId: address.placeId,
    }).unwrap()
      .then(() => {
        notify('Відділення створено', 'success');
      })
      .catch((e) => {
        notify(e, 'error');
      })
  };

  const handleMapLoad = (map) => setMap(map);

  const handleSelectOption = (option) => {
    const unit = data?.find(item => item.id === option)
    setPolygonPoints(null);
    setSelectedUnit(unit?.id);
    map?.panTo(unit?.center);
  };

  const handlePolygonComplete = (polygon) => {
    setPolygonPoints(polygon.getPath().getArray());
    setDrawingMode('polygon');
    polygon.setMap(null);
  };

  const handleDeleteModalOpen = () => setDeleteModalOpen((prev) => !prev);

  const handleDeleteUnit = () => {
    if (!selectedUnitData) return;
    setSelectedUnit(null);
    setDrawingMode('created');
    setPolygonPoints(null);
    deleteUnit(selectedUnitData.id)
      .unwrap()
      .then(() => {
        notify('Відділення видалено', 'success');
      })
      .catch((e) => {
        notify(e, 'error');
      })
      .finally(() => setDeleteModalOpen(false));
  }

  const handleLoadPolygon = (polygon) => {
    setPolygon(polygon);
  }

  const handlePolygonEdit = () => {
    setPolygonPoints(polygon?.getPath().getArray());
  }

  const handleSavePolygon = () => {
    createUnitSettings({
      unit_id: selectedUnitData.id,
      polygon: polygon?.getPath().getArray().map(item => ({
        latitude: parseFloat(item.lat()) ?? 0,
        longitude: parseFloat(item.lng()) ?? 0
      })),
    }).unwrap()
      .then(() => {
        notify('Кордони відділення збережено', 'success');
        setPolygonPoints(null);
        setDrawingMode('polygon');
      }).catch((e) => {
        notify(e, 'error');
      });
  };

  return (
    <ContentWrapper pageTitle="Кабінет директора">
      <section>
        <BackButton />
      </section>
      <section className={styles.controls}>
        <PageHeading text="Мапа відділень" />
        <div className={styles.unitsControls}>
          <Select
            placeholder="Виберіть відділення"
            options={getUnitsOptions(data)}
            size='large'
            className={styles.unitsSelect}
            onSelect={handleSelectOption}
            loading={isLoading || isFetching}
            value={selectedUnit ?? data?.at(0)?.id}
          />
          <Popover
            content={
              <div className={styles.content}>
                <SearchAddressForm
                  placeholder="Пошук адреси"
                  label="Назва населеного пункту"
                  value={value}
                  onSelect={handleSelect}
                  onChange={handleAddressChange}
                  icon={null}
                  types={['(cities)']}
                  isLoaded={isMapLoaded}
                />
                {!value && !isCreateUnitLoading && (
                  <Typography.Paragraph className={styles.info}>
                    Почніть вводити потрібну вам назву населеного пункту та оберіть його серед запропонованих.
                  </Typography.Paragraph>
                )}
                {!!address && (
                  <Button
                    block
                    size='large'
                    type='primary'
                    loading={isCreateUnitLoading}
                    onClick={handleCreateUnit}
                  >
                    Додати відділення
                  </Button>
                )}
              </div>
            }
            trigger="click"
            placement="bottomRight"
            open={opened}
            onOpenChange={setOpened}
          >
            <Button type='primary' size='large'>Додати відділення</Button>
          </Popover>
        </div>
      </section>
      <section className={styles.container}>
        {isMapLoaded && (
          <GoogleMap
            id="google-map"
            mapContainerClassName={styles.map}
            onLoad={handleMapLoad}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              zoomControl: false
            }}
            center={selectedUnitData?.center ?? defaultCenter}
            zoom={10}
          >
            {((!!selectedUnitData && !!selectedUnitData.polygon) || polygonPoints) && (
              <Polygon
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.8,
                  strokeWeight: 3,
                  fillColor: 'transparent',
                  fillOpacity: 0.35,
                  editable: !selectedUnitData?.polygon,
                  draggable: !selectedUnitData?.polygon,
                }}
                onLoad={handleLoadPolygon}
                onDragEnd={handlePolygonEdit}
                onMouseUp={handlePolygonEdit}
                path={selectedUnitData?.polygon ?? polygonPoints}
              />
            )}
            {/* {showCircle && (
              <Circle
                options={{
                  strokeColor: '#0057FF',
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: 'transparent',
                  fillOpacity: 0.35,
                  clickable: false,
                  editable: true,
                  draggable: true,
                  radius: defaultRadius,
                }}
                center={selectedUnitData?.center}
                onLoad={handleCircleLoad}
                onCenterChanged={handleSaveRadius}
                onRadiusChanged={handleSaveRadius}
              />
            )} */}
            {!!selectedUnitData && !selectedUnitData.polygon  && drawingMode !== 'circle' && !polygonPoints && (
              <DrawingManager
                drawingMode={google.maps.drawing.OverlayType.POLYGON}
                onPolygonComplete={handlePolygonComplete}
                options={{
                  polygonOptions: {
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: 'transparent',
                    fillOpacity: 0.35,
                    editable: true,
                    draggable: true,
                  },
                  drawingControlOptions: {
                    drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                  }
                }}
              />
            )}
            {!!selectedUnitData && !selectedUnitData.polygon && drawingMode !== 'circle' && !!polygonPoints && (
              <div className={styles.mapControls}>
                <Button size='large' type='primary' onClick={handleSavePolygon}>Зберегти зміни</Button>
                <Button size='large' onClick={() => setPolygonPoints(null)}>Скасувати</Button>
              </div>
            )}
          </GoogleMap>
        )}
        {!!selectedUnitData && (
          <div className={styles.drawerControls}>
            <Button
              className={classNames(styles.button, (drawingMode === 'created' || drawingMode === 'polygon') && styles.active)}
              onClick={() => setDrawingMode(polygonPoints || selectedUnitData.polygon ? 'polygon' : 'created')}
            >
              <PolygonIcon />
            </Button>
            <Button
              className={styles.button}
              onClick={handleDeleteModalOpen}
            >
              <DeleteIcon />
            </Button>
          </div>
        )}
      </section>
      <Modal
        open={deleteModalOpened}
        centered
        okType='danger'
        okText="Так"
        cancelText="Ні"
        className={styles.modal}
        cancelButtonProps={{
          size: 'large'
        }}
        okButtonProps={{
          size: 'large',
          type: 'primary',
          loading: isDeleteUnitLoading
        }}
        onOk={handleDeleteUnit}
        onCancel={handleDeleteModalOpen}
      >
        <Typography.Title level={2} className={styles.modalTitle}>Ви точно бажаєте видалити відділення?</Typography.Title>
      </Modal>
    </ContentWrapper>
  );
};
