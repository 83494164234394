import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const driverApi = createApi({
  reducerPath: 'driverApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ['Driver'],
  endpoints: (builder) => ({
    getDriver: builder.query({
      query: (id) => `/manager/user/driver/${id}/get`,
    }),
    getActiveDriversInRadius: builder.query({
      query: ({ latitude, longitude }) => ({
        url: '/manager/user/driver/allActive',
        params: latitude && longitude && {
          latitude,
          longitude,
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export default driverApi;

export const {
  useGetDriverQuery,
  useLazyGetDriverQuery,
  useLazyGetActiveDriversInRadiusQuery,
  useGetActiveDriversInRadiusQuery,
} = driverApi;
