import { TEXT_PATTERN } from "../consts";

export const DRIVER_VEHICLE_FORM_COL_1 = [
    {
        id: 'brand',
        name: 'brand',
        type: 'text',
        placeholder: '',
        errorMessage: 'Має містити від 1 до 64 символів',
        pattern: TEXT_PATTERN,
        label: 'МАРКА',
    }
];

export const DRIVER_VEHICLE_FORM_COL_2 = [
    {
        id: 'model',
        name: 'model',
        type: 'text',
        placeholder: '',
        errorMessage: 'Має містити від 1 до 64 символів',
        pattern: TEXT_PATTERN,
        label: 'МОДЕЛЬ',
    },
    {
        id: 'manufactureYear',
        name: 'manufactureYear',
        type: 'text',
        placeholder: '',
        errorMessage: 'Введіть валідний рік',
        pattern: '^\\d{4}$',
        label: 'РІК',
    }
];

export const DRIVER_VEHICLE_FORM_COL_3 = [
    {
        id: 'registrationNumber',
        name: 'registrationNumber',
        type: 'text',
        placeholder: '',
        errorMessage: 'Формат АА1234АА',
        label: 'НОМЕРНИЙ ЗНАК',
    },
    {
        id: 'color',
        name: 'color',
        type: 'text',
        placeholder: '',
        errorMessage: 'Має містити від 1 до 64 символів',
        pattern: TEXT_PATTERN,
        label: 'КОЛІР АВТО',
    }
];

export const DRIVER_CAR_ALL_INPUTS = [...DRIVER_VEHICLE_FORM_COL_1, ...DRIVER_VEHICLE_FORM_COL_2, ...DRIVER_VEHICLE_FORM_COL_3];
