import './clientInfo.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import { Select } from 'antd';
import FormInput from '../../../../assets/inputs/FormInput';
import useGetState from '../../../../../store/hooks/useGetState';
import useBindCreators from '../../../../../store/hooks/useBindCreators';
import { orderActions } from '../../../../../store/slices/orderSlice';
import {
  CLIENT_INFO_FORM_COL_1,
  CLIENT_INFO_FORM_COL_2,
} from '../../../../../data/orders/newOrder/clietnInfo';
import Divider from '../../../../drivers/driverInfo/divider/Divider';
import OrderTariff from '../tariff/OrderTariff';
import { useGetActiveDriversInRadiusQuery } from '../../../../../api/driverApi';
import DownIcon from '../../../../../img/icons/inputs/select-arrow-down.svg';
const invalidChars = ['-', '+', 'e', '.'];

const ClientInfo = ({ newOrder, setServicesLoading, destination }) => {
  const { order } = useGetState();
  const { setOrder, setOrderErrors } = useBindCreators(orderActions);
  const isEdit = destination === 'edit';
  const { data: activeDrivers, isLoading } = useGetActiveDriversInRadiusQuery({
    latitude: order?.addresses.at(0)?.latitude,
    longitude: order?.addresses.at(0)?.longitude,
  });

  const errors = order.errors;

  const ref = useRef(null);

  const mapInputs = (data) => {
    const column = data.map(({ pattern, ...input }) => (
      <FormInput
        key={input.id}
        value={order[input.name]}
        onChange={handleChange}
        errorClass={errors[input.name] ? 'error' : ''}
        customErrorMessage={errors[input.name] ? errors[input.name] : ''}
        {...input}
      />
    ));
    return <div className="form--item">{column}</div>;
  };

  const handleChange = ({ target }) => {
    const { name: key, value } = target;
    setOrder({ key, value });
    if (errors[key]) {
      setOrderErrors({ [key]: '' });
    }
  };

  useEffect(() => {
    const input = ref.current?.querySelector(
      '.ant-select-selection-search-input',
    );
    const onKeyDown = (e) => {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    };
    if (input) {
      input.type = 'number';
      input.addEventListener('keydown', onKeyDown);
    }
    return () => {
      if (input) {
        input.removeEventListener('keydown', onKeyDown);
      }
    };
  }, []);

  const isActiveDriver = !!activeDrivers?.drivers?.find(
    (driver) => driver.id === order.driver?.id,
  );

  const drivers = useMemo(() => {
    return (activeDrivers?.drivers ?? [])
      .filter(
        (driver) =>
          !!driver.calling &&
          driver.fare.find((fare) => fare.id === order.fare.id),
      )
      .map((driver) => ({ label: driver.calling, value: driver.id }));
  }, [activeDrivers, order]);

  const selectOptions = useMemo(() => {
    return !isActiveDriver && !!order.driver
      ? [
          ...drivers,
          {
            label: order.driver?.calling,
            value: order.driver?.id,
          },
        ].filter((driver) => !!driver.value)
      : drivers;
  }, [drivers, isActiveDriver, order]);

  const handleSelect = (option) => {
    const calling = selectOptions?.find(
      (driver) => driver.value === option,
    )?.calling;
    setOrder({
      key: 'driver',
      value: {
        id: option,
        calling: calling,
      },
    });
  };

  return (
    <>
      <div className="clientInfo">
        <div>
          <div className="form" style={{ width: '50%' }}>
            {mapInputs(CLIENT_INFO_FORM_COL_1)}
            {mapInputs(CLIENT_INFO_FORM_COL_2)}
          </div>
        </div>
        <div className="orderTariffWrapper">
          <OrderTariff
            order={newOrder}
            setServicesLoading={setServicesLoading}
            destination={destination}
          />
        </div>
        <div ref={ref}>
          <label>НАЗНАЧИТИ ВОДІЯ</label>
          <Select
            showSearch
            value={order?.driver?.id}
            onSelect={handleSelect}
            options={selectOptions}
            className="selectorAntd"
            notFoundContent={
              selectOptions?.length === 0
                ? 'Нічого не знайдено'
                : 'Водія не знайдено'
            }
            loading={isLoading}
            placeholder="Позивний"
            size="large"
            suffixIcon={<img src={DownIcon} alt="" />}
          />
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Divider />
      </div>
    </>
  );
};

export default ClientInfo;
