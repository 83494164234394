import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import ContentWrapper from '../assets/wrappers/content/ContentWrapper';
import AllClientsTable from './allClients/AllClientsTable';
import useClientsService from '../../services/clientsService';
import { CLIENT_STATUSES, DEFAULT_PAGINATION } from '../../data/consts';
import useFiltrationDropdowns from '../../helpers/hooks/useFiltrationDropdowns';
import notify from '../../helpers/notify';
import ClientTableActions from './allClients/ClientTableActions';

const Clients = () => {
  const { getAllClients, loading, updateClientsStatuses } = useClientsService();
  const [clients, setClients] = useState([]);
  const [clientsIds, setClientsIds] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [disable, setDisable] = useState(true);
  const { changeStatus, paramsObject } = useFiltrationDropdowns(
    fetchOrders,
    true,
  );

  async function fetchOrders(params = paramsObject) {
    const { clients, pagination } = await getAllClients(params);
    setClients(clients);
    setPagination(pagination);
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  const onChange = (checkedValues) => {
    setClientsIds(checkedValues);
  };

  const selectedClients = useMemo(() => {
    const filteredClients = clients?.filter((client) =>
      clientsIds?.includes(client.id),
    );

    if (
      clientsIds.length > 0 &&
      filteredClients.every((item) => item.status === CLIENT_STATUSES.ACTIVE)
    ) {
      setDisable(false);
      return {
        status: CLIENT_STATUSES.BLOCKED,
        ids: filteredClients.map((item) => item.id),
      };
    } else if (
      clientsIds.length > 0 &&
      filteredClients.every((item) => item.status === CLIENT_STATUSES.BLOCKED)
    ) {
      setDisable(false);
      return {
        status: CLIENT_STATUSES.ACTIVE,
        ids: filteredClients.map((item) => item.id),
      };
    } else {
      setDisable(true);
    }
  }, [clients, clientsIds]);

  const toggleStatusClients = async () => {
    try {
      await updateClientsStatuses(selectedClients);
      fetchOrders();
      setClientsIds([]);
    } catch (err) {
      notify(err, 'error');
    }
  };

  return (
    <ContentWrapper
      pageTitle={`Клієнти (${pagination.total})`}
      className={'ClientsPage'}
      pageControls={
        <ClientTableActions
          fetchOrders={fetchOrders}
          toggleStatusClients={toggleStatusClients}
          changeStatus={changeStatus}
          disable={disable}
        />
      }
    >
      <div className="allClientTable">
        <Checkbox.Group onChange={onChange} value={clientsIds}>
          <AllClientsTable
            tableBody={clients}
            maxPage={pagination.lastPage}
            loading={loading}
            onPageChange={fetchOrders}
          />
        </Checkbox.Group>
      </div>
    </ContentWrapper>
  );
};

export default Clients;
