import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import './orders.scss';
import ContentWrapper from '../assets/wrappers/content/ContentWrapper';
import BackButton from '../assets/buttons/backButton/BackButton';
import PageHeading from '../assets/pageHeadings/PageHeading';
import useSorting from '../../helpers/hooks/useSorting';
import { ORDERS_STATUS } from '../../data/orders/ordersTypes';
import Filters from '../assets/filters/Filters';
import OrdersTable from './table/OrdersTable';
import useFiltrationDropdowns from '../../helpers/hooks/useFiltrationDropdowns';
import OrdersTableActions from './OrdersTableActions';
import useFaresService from '../../services/faresService';
import useGetDrivers from '../drivers/allDrivers/table/hooks/useGetDrivers';

const Orders = ({
  pageTitle = 'Замовлення',
  backButton = false,
  pageHeading,
  tableControlsMargin = false,
  tableBody,
  maxPage,
  fetchOrders,
  loading,
  children,
  stats,
  className,
  ordersStatus = ORDERS_STATUS,
  isOrdersPage,
}) => {
  const { urlParamsHandler, paramsObject } = useSorting();
  const { getAllDrivers, drivers } = useGetDrivers('all');
  const [showColumnFilters, setShowColumnFilters] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const { changeStatus } = useFiltrationDropdowns(fetchOrders);
  const { getAllFares } = useFaresService();
  const [rates, setRates] = useState(null);
  const fetchFares = async () => {
    const allFares = await getAllFares();
    setRates(allFares);
  };

  const driversOptions = useMemo(
    () =>
      drivers
        ?.map(({ id, calling }) => ({
          id,
          name: calling,
        }))
        .sort((a, b) => a.name - b.name),
    [drivers],
  );
  const rateOptions = useMemo(
    () =>
      rates
        ?.map(({ id, name }) => ({
          id,
          name,
        }))
        .reverse(),
    [rates],
  );

  const onDateChange = (param, date) => {
    const paramsWithFirstPage = { ...paramsObject, page: 1 };
    const prependZero = (current) => (current < 10 ? `0${current}` : current);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const stringDate = `${prependZero(day)}.${prependZero(month + 1)}.${year}`;
    urlParamsHandler([{ param, value: stringDate }]);
    fetchOrders?.({ ...paramsWithFirstPage, [param]: stringDate });
  };

  const handleChangeRate = debounce((value) => {
    const currentValue = value?.map((item) => Number(item.id));
    const paramsWithFirstPage = { ...paramsObject, page: 1 };
    urlParamsHandler([{ param: 'fares', value: currentValue }]);
    fetchOrders?.({ ...paramsWithFirstPage, fares: currentValue });
  }, 1000);

  const handleChangeCallSign = debounce((value) => {
    const currentValue = value?.map((item) => Number(item.id));
    const paramsWithFirstPage = { ...paramsObject, page: 1 };
    urlParamsHandler([{ param: 'drivers', value: currentValue }]);
    fetchOrders?.({ ...paramsWithFirstPage, drivers: currentValue });
  }, 1000);

  const onFilterColumnsButtonClick = () =>
    setShowColumnFilters(!showColumnFilters);

  useEffect(() => {
    fetchOrders();
    fetchFares();
    getAllDrivers({ perPage: 1000 });
  }, []);

  return (
    <ContentWrapper
      pageTitle={pageTitle}
      className={'OrdersTableWrap'}
      pageControls={
        <OrdersTableActions
          changeStatus={changeStatus}
          ordersStatus={ordersStatus}
          onDateChange={onDateChange}
          onFilterColumnsButtonClick={onFilterColumnsButtonClick}
          showColumnFilters={showColumnFilters}
          filled={showFilters}
          isOrdersPage={isOrdersPage}
          handleChangeRate={handleChangeRate}
          handleChangeCallSign={handleChangeCallSign}
          rateOptions={rateOptions}
          driversOptions={driversOptions}
        />
      }
    >
      {backButton && <BackButton />}
      {pageHeading && (
        <PageHeading
          text={pageHeading}
          style={{
            marginTop: '0.5rem',
            display: 'flex',
            gap: '2rem',
            alignItems: 'center',
          }}
        >
          {children}
        </PageHeading>
      )}
      {stats}
      <Filters showFilters={showColumnFilters} />
      <OrdersTable
        showFilters={showColumnFilters}
        small={pageTitle !== 'Замовлення'}
        size="small"
        tableBody={tableBody}
        maxPage={maxPage}
        onPageChange={fetchOrders}
        loading={loading}
        className={className}
      />
    </ContentWrapper>
  );
};

export default Orders;

Orders.propTypes = {
  pageTitle: PropTypes.string,
  backButton: PropTypes.bool,
  pageHeading: PropTypes.string,
  tableControlsMargin: PropTypes.bool,
  tableBody: PropTypes.arrayOf,
  maxPage: PropTypes.number,
  fetchOrders: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
  stats: PropTypes.node,
  className: PropTypes.string,
};
