import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import "./innerWrapper.scss";

const InnerWrapper = ({ children, withoutPadding = false, withoutScroll = false, className }) => {
  const wrapperClassName = `innerWrapper ${withoutPadding ? 'withoutPadding' : withoutScroll ? 'withoutScroll' : ''}`;

  return (
    <div className={classNames(wrapperClassName, className && className)}>
      {children}
    </div>
  );
};

export default InnerWrapper;

InnerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  withoutPadding: PropTypes.bool,
  withoutScroll: PropTypes.bool,
  className: PropTypes.string
}
