import "./customSelectWithLabel.scss";
import CustomSelect from "../CustomSelect"

const CustomSelectWithLabel = (props) => {
    return (
        <>
            <div className="customSelect--label">{props.label}</div>
            <CustomSelect {...props} />
        </>
    );
};

export default CustomSelectWithLabel;