import { RIDE_STATUSES } from '../data/consts';

export const getRideStatus = (status) => {
  switch (status) {
    case RIDE_STATUSES.UNASSIGNED: return 'Нова';
    case RIDE_STATUSES.PENDING: return 'Очікування';
    case RIDE_STATUSES.CANCELLED: return 'Відмова';
    case RIDE_STATUSES.ASSIGNED: return 'Призначена';
    case RIDE_STATUSES.APPROVED: return 'Підтверджений';
    case RIDE_STATUSES.ARRIVED: return 'Прибув';
    case RIDE_STATUSES.STARTED: return 'Почався';
    case RIDE_STATUSES.PAUSED: return 'Пауза';
    case RIDE_STATUSES.RESUMED: return 'Відновлений';
    case RIDE_STATUSES.FINISHED: return 'Завершений';
    case 'all': return 'Статус: усі';
  }
};
