import MessageItem from "./MessageItem";

const DateMessages = ({ date = '', messages = [] }) => {
    const allMessages = messages.map(message => (<MessageItem key={message.id} {...message} />))

    return (
        <>
            <div className='messages--date'>{date}</div>
            {allMessages}
        </>
    );
};

export default DateMessages;