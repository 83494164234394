import "./checkboxLogin.scss";

const CheckboxLogin = ({ label, checked, onChange }) => {
    return (
        <label className="checkbox-container-login">{label}
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <span className="checkmark-login"></span>
        </label>
    );
};

export default CheckboxLogin;