import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './filters.scss';
import { CSSTransition } from 'react-transition-group';
import Checkbox from '../inputs/checkbox/Checkbox';
import {
  FILTERS_1, FILTERS_2, FILTERS_3, FILTERS_4,
} from '../../../data/filters/filters';
import useGetState from '../../../store/hooks/useGetState';
import useBindCreators from '../../../store/hooks/useBindCreators';
import { ordersActions } from '../../../store/slices/ordersSlice';
import { findInArrayBooleanByItem } from '../../../helpers/general/general';

const Filters = ({ showFilters }) => {
  const { activeFilters } = useGetState();
  const { disableFilter, enableFilter } = useBindCreators(ordersActions);
  const nodeRef = useRef(null);
  const changeHandler = (name) => (findInArrayBooleanByItem(activeFilters, name)
    ? disableFilter(name) : enableFilter(name));

  const mapFilters = (filters) => (
    <div className="filters--items-row">
      {filters.map((filter) => (
        <Checkbox
          key={filter.id}
          checked={!!activeFilters.find((item) => item === filter.name)}
          onChange={() => changeHandler(filter.name)}
          {...filter}
        />
      ))}
    </div>
  );

  return (
    <CSSTransition
      in={showFilters}
      nodeRef={nodeRef}
      unmountOnExit
      timeout={{ enter: 0, exit: 200 }}
    >
      <div className="filters" ref={nodeRef}>
        <div className="filters--title">ПОКАЗАТИ КОЛОНКИ</div>
        <div className="filters--items">
          {mapFilters(FILTERS_1)}
          {mapFilters(FILTERS_2)}
          {mapFilters(FILTERS_3)}
          {mapFilters(FILTERS_4)}
        </div>
      </div>
    </CSSTransition>
  );
};

export default Filters;

Filters.propTypes = {
  showFilters: PropTypes.bool,
};
