import "../chatPage.scss";
import ChatForm from "../form/ChatForm";
import Messages from "../messages/Messages";
import ChatAside from "./ChatAside";

const Chat = ({
    messages, sendMessage, chatStatus, changeStatus, chatRef, scrollToBottom, messagesEndRef, canSendMessage
}) => {
    return (
        <div className="chat--container">
            <ChatAside chatStatus={chatStatus} changeStatus={changeStatus} />
            <Messages
                info={messages}
                chatRef={chatRef}
                autoScroll={scrollToBottom}
                messagesEndRef={messagesEndRef}
            />
            {canSendMessage && <ChatForm
                onSubmit={sendMessage}
                canSendMessage={canSendMessage}
            />}
        </div>
    );
};

export default Chat;