import "./userTable.scss";
import DefaultTable from "../../../assets/table/defaultTable/DefaultTable";
import { USERS_HEADERS, USERS_SEARCH_ROW } from "../../../../data/director/usersTable";
import UserTableItem from "./UserTableItem";
import { useEffect, useState } from "react";
import useUsersService from "../../../../services/usersService";
import useUrl from "../../../../helpers/hooks/useUrl";
import { DEFAULT_PAGINATION } from "../../../../data/consts";

const UsersTable = () => {
    const { getUsers, loading } = useUsersService();
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
    const { paramsObject } = useUrl();

    const fetchUsers = async (params) => {
        const { managers, pagination } = await getUsers(params);
        setPagination(pagination);
        setUsers(managers);
    };

    useEffect(() => {
        fetchUsers(paramsObject);
    }, []);

    return (
        <DefaultTable
            headersInfo={USERS_HEADERS}
            searchRow={USERS_SEARCH_ROW}
            bodyInfo={users}
            TableItem={UserTableItem}
            loading={loading}
            onPageChange={fetchUsers}
            maxPage={pagination.lastPage}
        />
    );
};

export default UsersTable;