import React from 'react';
import PropTypes from 'prop-types';
import DriverDocuments from './documents/Documents';
import PersonalInfo from './personalInfo/PersonalInfo';
import DriverTariffs from './tariffs/Tariffs';
import DriverVehicle from './vehicle/Vehicle';
import Spinner from '../../assets/spinner/Spinner';

const DriverInfo = ({
  controls, loading, handleSubmit, setPhotos,
}) => (
  <div className="driverInfo">
    <form id="driverInfoForm" onSubmit={handleSubmit}>
      <PersonalInfo setPhotos={setPhotos} />
      <DriverDocuments setPhotos={setPhotos} />
      <DriverVehicle />
      <DriverTariffs />
      {!loading && controls}
      {loading && (
      <div className="loader">
        <Spinner />
      </div>
      )}
    </form>
  </div>
);

export default DriverInfo;

DriverInfo.propTypes = {
  controls: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setPhotos: PropTypes.func.isRequired,
};
