import { useMemo } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useUrl from './useUrl';
import {
  defaultSearchParams,
  defaultSearchParamsClients,
} from '../../data/consts';
import useBindCreators from '../../store/hooks/useBindCreators';
import { searchActions } from '../../store/slices/searchSlice';

const useSorting = (isClientPage = false) => {
  const navigate = useNavigate();
  const {
    formatUrl,
    currentUrl,
    setSearchParam,
    getSearchParam,
    removeSearchParam,
    appendSearchParam,
    paramsObject,
  } = useUrl();
  const { setSearchReset } = useBindCreators(searchActions);
  const searchParams = isClientPage
    ? defaultSearchParamsClients
    : defaultSearchParams;

  const resetPagination = useCallback(() => {
    const currentPage = getSearchParam('page');
    removeSearchParam('page');
    appendSearchParam('page', currentPage ? currentPage : 1);
  }, [appendSearchParam, removeSearchParam]);

  const setParam = useCallback(
    (param, value) => {
      if (getSearchParam(param)) {
        setSearchParam(param, value);
      } else {
        appendSearchParam(param, value);
      }
    },
    [appendSearchParam, getSearchParam, setSearchParam],
  );

  const urlParamsHandler = useCallback(
    (params, removedParams, resetPage = true) => {
      if (removedParams) {
        removedParams.forEach((param) => removeSearchParam(param));
      }

      if (params) {
        params.forEach(({ param, value }) => {
          setParam(param, value);
          if (resetPage) {
            resetPagination();
          }
        });
      }

      const url = formatUrl(currentUrl);
      navigate(url, { replace: true });
    },
    [currentUrl, formatUrl, navigate, removeSearchParam, setParam],
  );

  const resetSearch = (onPageChange) => {
    const defaultParams = [];
    const removedParams = [];

    for (const key in paramsObject) {
      if (!(key in searchParams)) {
        removedParams.push(key);
      } else {
        defaultParams.push({ param: key, value: searchParams[key] });
      }
    }

    setSearchReset(true);
    urlParamsHandler(defaultParams, removedParams);
    onPageChange(searchParams);
  };

  const sorting = useMemo(
    () => ({
      urlParamsHandler,
      getSearchParam,
      paramsObject,
      resetSearch,
    }),
    [urlParamsHandler, getSearchParam, resetSearch],
  );

  return sorting;
};

export default useSorting;
