import { combineReducers } from '@reduxjs/toolkit';

import auth from '../slices/authSlice';
import driver from '../slices/driverSlice';
import orders from '../slices/ordersSlice';
import order from '../slices/orderSlice';
import map from '../slices/mapSlice';
import user from '../slices/userSlice';
import tariff from '../slices/tariffSlice';
import tariffs from '../slices/allTariffsSlice';
import search from '../slices/searchSlice';
import DriverApi from '../../api/driverApi';
import OrderApi from '../../api/orderApi';
import UnitsApi from '../../api/unitsApi';


const rootReducer = combineReducers({
  auth,
  driver,
  orders,
  order,
  map,
  user,
  tariff,
  tariffs,
  search,
  [DriverApi.reducerPath]: DriverApi.reducer,
  [OrderApi.reducerPath]: OrderApi.reducer,
  [UnitsApi.reducerPath]: UnitsApi.reducer,
});

export default rootReducer;
