import React from "react";
import "./director.scss";
import ContentWrapper from "../assets/wrappers/content/ContentWrapper";
import { SECTIONS } from "../../data/director/sections";
import Section from "./section/Section";

const Director = () => {
  return (
    <ContentWrapper pageTitle="Кабінет директора">
      <div style={{ display: 'flex', flexWrap: "wrap" }}>
        {SECTIONS.map(section => (
          <Section key={section.id} {...section} />
        ))}
      </div>
    </ContentWrapper>
  );
};

export default Director;
