import { useParams } from "react-router";
import React, { useEffect, useState } from "react";
import FileInputButton from "../../../../assets/inputs/fileInputs/fileInput-button/FileInputButton";
import useGetState from "../../../../../store/hooks/useGetState";
import Spinner from "../../../../assets/spinner/Spinner";

const PersonalPhoto = ({ setPhotos }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { driver } = useGetState();
  const photo = driver.photo;

  const imgSrc = (photo && typeof photo === "string" && photo?.includes("data:image/")) ? photo : `data:image/*;base64,${photo}`;

  useEffect(() => {
    if (photo || photo === null || photo.length === 0) {
      setLoading(false);
    }
  }, [photo]);

  return (
    <div className="personalInfo--photo">
      {!photo && (<div className="preview">
        {(!loading || !id) && <div className="preview--square">
          <div className="preview--circle" />
        </div>}
        {(loading && id) && <Spinner />}
      </div>)}
      {photo && typeof photo === "string" && <img src={imgSrc} alt="Driver's photo" />}
      <div className='personalInfo--photo__wrap'>
        <FileInputButton text='Додати фото' id='photoInput' setPhotos={setPhotos} backendField="photo" className={'personalInfo--fileInput'} />
        {id && <span className="driverId">{`ID: #${id}`}</span>}
      </div>
    </div>
  );
};

export default PersonalPhoto;
