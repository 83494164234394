import { useNavigate } from 'react-router';
import { CHECK_EMAIL, LOGIN } from '../data/consts';
import {
  AUTH_ENDPOINT, RESET_PASSWORD_REQUEST, RESET_PASSWORD_RESET, WORKSPACE_ENDPOINT,
} from '../data/endpoints';
import notify from '../helpers/notify';
import { authActions } from '../store/slices/authSlice';
import useHttpService from './httpService';
import useBindCreators from '../store/hooks/useBindCreators';

const useAuthService = () => {
  const { request, loading } = useHttpService();
  const navigate = useNavigate();
  const { setCodeToSendEmail } = useBindCreators(authActions);

  const sendCreds = async (data) => {
    const { apiToken } = await request({ url: AUTH_ENDPOINT, method: 'POST', data });
    return apiToken;
  };

  const getWorkspace = async (token) => {
    const workspace = await request({ url: WORKSPACE_ENDPOINT, headers: { Authorization: `Bearer ${token}` } });
    return workspace;
  };

  const resetPasswordRequest = async (data) => {
    await request({ url: RESET_PASSWORD_REQUEST, method: 'POST', data });
    setCodeToSendEmail(data.email);
    navigate(CHECK_EMAIL);
  };

  const resetPasswordReset = async (data, setErrors) => {
    try {
      await request({ url: RESET_PASSWORD_RESET, method: 'POST', data });
      navigate(LOGIN);
      notify('Пароль успішно змінено', 'info');
    } catch (err) {
      if (err?.response?.data?.errors) {
        setErrors(err?.response?.data?.errors);
      }
      if (err?.response?.data?.message) {
        notify(err?.response?.data?.message, 'error');
      }
      notify('Введено невірні дані, перевірте форму', 'error');
    }
  };

  return {
    sendCreds, getWorkspace, resetPasswordRequest, resetPasswordReset, loading,
  };
};

export default useAuthService;
