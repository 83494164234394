import React from 'react';
import { ORDER_INFO_FORM } from '../../../data/orders/newOrder/clietnInfo';
import useBindCreators from '../../../store/hooks/useBindCreators';
import { orderActions } from '../../../store/slices/orderSlice';
import FormInput from '../../assets/inputs/FormInput';

const OrderInfo = ({ order }) => {
  const { setOrder, setOrderErrors } = useBindCreators(orderActions);
  const errors = order.errors;

  const mapInputs = (data) => {
    const column = data.map(({ pattern, ...input }) => (
      <FormInput
        key={input.id}
        value={order[input.name]}
        onChange={handleChange}
        disabled
        errorClass={errors[input.name] ? 'error' : ''}
        customErrorMessage={errors[input.name] ? errors[input.name] : ''}
        {...input}
      />
    ));
    return <div className={'ColumnOrderInfo'}>{column}</div>;
  };

  const handleChange = ({ target }) => {
    const { name: key, value } = target;
    setOrder({ key, value });
    if (errors[key]) {
      setOrderErrors({ [key]: '' });
    }
  };

  return <>{mapInputs(ORDER_INFO_FORM)}</>;
};

export default OrderInfo;
