import "./vehicle.scss";
import PageHeading from "../../../assets/pageHeadings/PageHeading";
import VehicleForm from "./vehicleForm/VehicleForm";

const DriverVehicle = () => {
    return (
        <div className='infoVehicle--container'>
            <PageHeading text='АВТОМОБІЛЬ' />
            <div className="infoVehicle">
                <VehicleForm />
            </div>
        </div>
    );
};

export default DriverVehicle;
