import { useEffect, useState } from "react";
import notify from "../../../helpers/notify";
import useServicesService from "../../../services/servicesService";

export default function useDirectorServices() {
    const { getAllServices, deleteService, createService, loading, updateService } = useServicesService();
    const [errors] = useState({});
    const [services, setServices] = useState([]);
    const [newInputAdded, setNewInputAdded] = useState(false);

    const handleInputChange = e => {
        const inputId = e.target.id;
        const language = inputId.slice(inputId.indexOf("-") + 1);
        const findChangedService = service => service.id == inputId.slice(0, inputId.indexOf("-"));
        const initialService = services.find(findChangedService);
        const initialServiceIndex = services.indexOf(initialService);

        const newService = {
            id: initialService.id,
            name: {
                ...initialService.name,
                [language]: e.target.value
            }
        };

        if (initialService.type === "new") {
            newService.type = initialService.type;
        }

        const servicesCopy = services;
        servicesCopy.splice(initialServiceIndex, 1, newService);
        setServices([...servicesCopy]);
    };

    const fetchServices = async () => {
        const { services } = await getAllServices();
        setServices(services);
    };

    const addInput = () => {
        setServices(prev => [...prev, {
            id: services.length + 1, type: "new", name: {
                uk: "",
                en: "",
                ru: ""
            }
        }]);
        setNewInputAdded(true);
    };

    const handleUpdate = async (serviceId) => {
        const service = services.find(service => service.id == serviceId);

        if (service.type === "new") {
            return;
        }

        const payload = [];
        for (const language in service.name) {
            if (service.name[language] === "") {
                return;
            }
            payload.push({ locale: language, content: service.name[language] });
        }

        await updateService(serviceId, { name: payload });
    };

    const handleCreate = async () => {
        const newService = services.find(service => service.type === "new");

        const payload = [];
        for (const language in newService.name) {
            if (newService.name[language].length < 3 || newService.name[language].length > 64) {
                notify("Назва має містити від 3 до 64 символів", "error");
                return;
            }
            payload.push({ locale: language, content: newService.name[language] });
        }

        await createService({ name: payload });
        await fetchServices();
        setNewInputAdded(false);
    }

    const handleDelete = async (id, type) => {
        if (type === "new") {
            setServices(prev => prev.filter(service => service.id !== id));
        } else {
            await deleteService(id);
            await fetchServices();
        }

        setNewInputAdded(false);
    };

    useEffect(() => {
        fetchServices();
    }, []);

    return { loading, errors, newInputAdded, handleInputChange, addInput, handleUpdate, handleCreate, handleDelete, services };
}
