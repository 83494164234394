import React from 'react';
import { useLocation } from 'react-router';
import Pagination from '../components/assets/pagination/Pagination';

export const withPagination = (WrappedComponent) => {

  const Paginated = ({ showOnePage = true, ...props }) => {
    const { pathname } = useLocation();

    return (
      <>
        <Pagination
          onPageChange={props.onPageChange}
          paginationClass={pathname.includes('order-maps') ? 'noMargin' : ''}
          maxPage={props.maxPage}
          showOnePage={showOnePage}
        />
        <WrappedComponent {...props} />
      </>
    );
  };

  return Paginated;
};
