import { useState } from 'react';
import BlueLinkButton from '../buttons/linkButton/BlueLinkButton';
import BalanceModal from './BalanceModal';
import useModal from '../../../helpers/hooks/useModal';

const Balance = ({
  user,
  increaseBalance,
  decreaseBalance,
  loading,
  setUser,
}) => {
  const { isOpened, openModal, closeModal } = useModal();

  return (
    <>
      <div className="personalInfo--balance">
        <span className="text">Баланс</span>
        <span className="amount">{user.balance ? user.balance : 0}₴</span>
        <BlueLinkButton text="Списати/Поповнити" onClick={openModal} />
      </div>
      <BalanceModal
        show={isOpened}
        onHide={closeModal}
        id={user.id}
        initialAmount={user.balance || 0}
        increaseBalance={increaseBalance}
        decreaseBalance={decreaseBalance}
        loading={loading}
        setUser={setUser}
      />
    </>
  );
};

export default Balance;
