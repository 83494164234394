import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { DRIVER_CONTROLS_ACTIVE, DRIVER_CONTROLS_BLOCKED, DRIVER_CONTROLS_FIRED } from "../../../../data/drivers/driverControls/driverControls";
import useDriversService from "../../../../services/driversService";
import useGetState from "../../../../store/hooks/useGetState";
import CustomSelect from "../../../assets/inputs/customSelect/CustomSelect";

const DriverControls = () => {
    const { driver } = useGetState();
    const [dropDownOptions, setDropDownOptions] = useState(getDefaultOptions(driver.status));
    const [initialColor, setInitialColor] = useState("green");
    const { updateDriverStatus, deleteDriver } = useDriversService();
    const { id } = useParams();

    function getDefaultOptions(status) {
        switch (status) {
            case "active":
                return DRIVER_CONTROLS_ACTIVE;
            case "blocked":
                return DRIVER_CONTROLS_BLOCKED;
            case "fired":
                return DRIVER_CONTROLS_FIRED;
            default:
                return DRIVER_CONTROLS_ACTIVE;
        }
    }

    const onOptionClick = async (name) => {
        if (name === 'block') {
            setDropDownOptions(DRIVER_CONTROLS_BLOCKED);
            await updateDriverStatus(id, { status: "blocked", password: driver.password });
        } else if (name === 'fire') {
            // setDropDownOptions(DRIVER_CONTROLS_FIRED);
            // window.alert('test')
            const alert = window.confirm(`Ця операція призведе до видалення водія ${driver.firstName} ${driver.lastName}`)
            if (alert) {
                await deleteDriver(id)
            } else {
                setDropDownOptions(getDefaultOptions(driver.status));
                return null
            }
            // await updateDriverStatus(id, { status: "fired", password: driver.password });
        } else {
            setDropDownOptions(DRIVER_CONTROLS_ACTIVE);
            await updateDriverStatus(id, { status: "approved", password: driver.password });
        }
    };

    useEffect(() => {
        setDropDownOptions(getDefaultOptions(driver.status));

        if (driver.status === "blocked") {
            setInitialColor("yellow");
        }

        // if (driver.status === "fired") {
        //     setInitialColor("red");
        // }
    }, [driver.status]);

    if (driver.status) {
        return <CustomSelect options={dropDownOptions} onClick={onOptionClick} initialCustomColor={initialColor} />;
    }
};

export default DriverControls;
