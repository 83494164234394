import React, {
  useRef,
  useEffect,
  Children,
  isValidElement,
  cloneElement,
  useState,
  useCallback,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import DriverInfoWindow from '../infoWindow/DriverInfoWindow';

function MyMap({
  zoom,
  center,
  onIdle,
  routeStart,
  routeEnd,
  routeWaypoints,
  drawRoute,
  children,
  strokeColor = '#D34545',
  infoWindowPosition,
}) {
  const ref = useRef();
  const [map, setMap] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const gMaps = window.google.maps;
  console.log(directionsService);
  useEffect(() => {
    if (ref.current && !map) {
      const mapOptions = {
        center,
        zoom,
        streetViewControl: false,
      };

      const newMap = new gMaps.Map(ref.current, mapOptions);
      setMap(newMap);

      setDirectionsService(new gMaps.DirectionsService());
      setDirectionsRenderer(new gMaps.DirectionsRenderer());
      setInfoWindow(new gMaps.InfoWindow({ content: '' }));
    }
  }, []);

  const hideInfo = useCallback(() => {
    directionsRenderer.setMap(null);
    directionsRenderer.setDirections({ routes: [] });
    infoWindow.close();
  }, [directionsRenderer, infoWindow]);

  function calcRoute() {
    if (routeStart.lat === routeEnd.lat && routeStart.lng === routeEnd.lng) {
      makeMarker(routeStart);
      return;
    }

    const waypoints = routeWaypoints;

    const request = {
      origin: routeStart,
      destination: routeEnd,
      waypoints,
      optimizeWaypoints: true,
      travelMode: gMaps.TravelMode.DRIVING,
    };

    directionsService?.route(request, (result, status) => {
      if (status === 'OK') {
        console.log(result, 'result');
        directionsRenderer.setDirections(result);
      }
    });
  }

  function makeMarker(position) {
    new gMaps.Marker({ position, map });
  }

  useEffect(() => {
    if (map) {
      ['idle', 'closeclick'].forEach((eventName) =>
        gMaps.event.clearListeners(map, eventName),
      );
      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }

      if (infoWindow) {
        infoWindow.addListener('closeclick', hideInfo);
      }
    }
  }, [map, infoWindow, hideInfo]);

  useEffect(() => {
    if (drawRoute && directionsRenderer) {
      directionsRenderer.setMap(map);
      directionsRenderer.setOptions({
        polylineOptions: { strokeColor },
        preserveViewport: true,
      });
      calcRoute();
    } else if (!drawRoute && directionsRenderer) {
      hideInfo();
    }
  }, [drawRoute, routeStart, routeEnd, routeWaypoints, directionsRenderer]);

  useEffect(() => {
    if (infoWindow && infoWindowPosition) {
      const content = ReactDOMServer.renderToString(<DriverInfoWindow />);
      infoWindow.setContent(content);
      infoWindow.setOptions({
        position: infoWindowPosition,
        map,
        shouldFocus: false,
        pixelOffset: {
          width: 0,
          height: -55,
        },
      });
      infoWindow.open({ map });
    } else if (infoWindow && !infoWindowPosition) {
      infoWindow.close();
    }
  }, [infoWindow, infoWindowPosition]);

  useEffect(() => {
    if (map && center) {
      map.panTo(center);
    }
  }, [center, map]);

  return (
    <>
      <div ref={ref} id="map" />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map });
        }
      })}
    </>
  );
}

export default MyMap;
