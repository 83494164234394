import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { checkEmptyInputs } from '../../../helpers/checkEmptyInputs';
import notify from '../../../helpers/notify';
import { DRIVER_BALANCE_MODAL_FORM } from '../../../data/forms/driverBalanceModal';
import FormInputWithIcon from '../inputs/formInputWithIcon/FormInputWithIcon';
import Spinner from '../spinner/Spinner';
import { BALANCE_MODAL_SWITCH } from '../../../data/drivers/switch/modalSwitch';
import Modal from '../modal/Modal';
import Switch from '../switch/Switch';

const BalanceModal = ({
  show,
  onHide,
  id,
  initialAmount,
  increaseBalance,
  decreaseBalance,
  loading,
  setUser,
}) => {
  const [amount, setAmount] = useState('');
  const [errors, setErrors] = useState({});
  const [activeSwitch, setActiveSwitch] = useState('writeOffCash');
  const inputLabel =
    activeSwitch === 'writeOffCash' ? 'СПИСАННЯ' : 'ПОПОВНЕННЯ';

  const setAmountOnSuccess = useCallback((amount) => {
    setUser({ key: 'balance', value: amount });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    const emptyInputs = checkEmptyInputs({ amount }, setErrors);
    if (emptyInputs > 0) {
      return;
    }

    const payload = { amount };
    if (activeSwitch === 'writeOffCash') {
      if (+initialAmount - +amount > 0) {
        await decreaseBalance(id, payload, () =>
          setAmountOnSuccess(+initialAmount - +amount),
        );
      } else {
        notify('Баланс не може бути менше нуля', 'error');
        return;
      }
    } else {
      if (amount > 5000) {
        notify('Сума має бути менша або рівна 5000', 'error');
        return;
      }
      increaseBalance(id, payload, () =>
        setAmountOnSuccess(+initialAmount + +amount),
      );
    }
    onHide();
  };

  const onSwitchClick = ({ target }) => {
    if (!target.classList.contains('active')) {
      setActiveSwitch(target.id);
    }
  };

  const onInputChange = ({ target }) => {
    setAmount(target.value);
    if (errors.amount) {
      setErrors({});
    }
  };

  useEffect(() => {
    if (amount && show === true) {
      setAmount('');
      setErrors({});
      setActiveSwitch('writeOffCash');
    }
  }, [show]);

  const input = DRIVER_BALANCE_MODAL_FORM.map((input) => (
    <FormInputWithIcon
      key={input.id}
      value={amount}
      onChange={onInputChange}
      errorClass={errors[input.name] ? 'error' : ''}
      customErrorMessage={errors[input.name] ? errors[input.name] : ''}
      label={`СУМА ${inputLabel}`}
      {...input}
    />
  ));

  return (
    <Modal
      show={show}
      onHide={onHide}
      onSubmit={onSubmit}
      title="Списати/Поповнити"
    >
      <div style={{ marginTop: '2rem' }}>
        <Switch
          activeSwitch={activeSwitch}
          onClick={onSwitchClick}
          items={BALANCE_MODAL_SWITCH}
        />
      </div>
      <div style={{ marginTop: '2.5rem', marginBottom: '4.375rem' }}>
        {!loading && input}
        {loading && (
          <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BalanceModal;
