import "./analyticsSwitch.scss";
import Switch from "../../../assets/switch/Switch";
import useSorting from "../../../../helpers/hooks/useSorting";

export const SWITCH_ITEMS = [
    {
        id: 'month',
        name: 'Місяць'
    },
    {
        id: 'week',
        name: 'Тиждень'
    },
    {
        id: 'today',
        name: 'Сьогодні'
    }
];

const AnalyticsSwitch = ({ time, setTime, fetchAnalytics }) => {
    const { urlParamsHandler } = useSorting();

    const onSwitchClick = ({ target }) => {
        if (!target.classList.contains('active')) {
            setTime(target.id);
            urlParamsHandler([{ param: 'duration', value: target.id }], [], false);
            fetchAnalytics({ duration: target.id });
        }
    };

    return (
        <div className="analyticsSwitch">
            <Switch
                activeSwitch={time}
                onClick={onSwitchClick}
                items={SWITCH_ITEMS}
                className="big"
            />
        </div>
    );
};

export default AnalyticsSwitch;