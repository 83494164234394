import useBindCreators from '../../../../../store/hooks/useBindCreators';
import { orderActions } from '../../../../../store/slices/orderSlice';
import useFaresService from '../../../../../services/faresService';
import { allTariffsActions } from '../../../../../store/slices/allTariffsSlice';
import { useEffect } from 'react';
import useGetState from '../../../../../store/hooks/useGetState';
import useSorting from '../../../../../helpers/hooks/useSorting';

export default function useOrderTariff(order, setServicesLoading, destination) {
  const { getAllFares, getFare } = useFaresService();
  const { tariffs } = useGetState();
  const { setOrder, setAllTariffs } = useBindCreators({
    ...orderActions,
    ...allTariffsActions,
  });
  const { urlParamsHandler, getSearchParam } = useSorting();
  const urlFareId = getSearchParam('fareId');
  const isEditing = destination === 'edit';
  const TARIFFS = tariffs.map((item) => ({
    ...item,
    text: item.type === 'hourly' ? `${item.name} Погодинний` : item.name,
    name: item.type === 'hourly' ? `${item.name} Погодинний` : item.name,
  }));

  const onTariffSelect = async (name, id) => {
    if (isEditing) {
      urlParamsHandler(
        [
          { param: 'fare', value: name },
          { param: 'fareId', value: id },
        ],
        [],
        false,
      );
      return;
    }
    await fetchFare(id);
  };

  const fetchFares = async () => {
    try {
      setServicesLoading(true);
      const allFares = await getAllFares();
      setAllTariffs(allFares);
    } finally {
      setServicesLoading(false);
    }
  };

  const fetchFare = async (id) => {
    try {
      setServicesLoading(true);
      const fare = await getFare(id);
      setOrder({ key: 'fare', value: { ...fare, name: fare.name.uk } });
    } finally {
      setServicesLoading(false);
    }
  };

  useEffect(() => {
    fetchFares();
  }, []);

  useEffect(() => {
    if (order.fare && isEditing) {
      urlParamsHandler(
        [
          {
            param: 'fare',
            value:
              order.fare.type === 'hourly'
                ? `${order.fare.name} Погодинний`
                : order.fare.name,
          },
          { param: 'fareId', value: order.fare.id },
          { param: 'scheduledFor', value: order.date },
        ],
        [],
        false,
      );
    }
  }, [order.fare]);

  useEffect(() => {
    if (isEditing && urlFareId && urlFareId != 0) {
      fetchFare(urlFareId);
    }
  }, [urlFareId]);

  useEffect(() => {
    if (!order.fare && TARIFFS.length > 0 && !isEditing) {
      fetchFare(TARIFFS[0].id);
    }
  }, [TARIFFS]);

  return { onTariffSelect, TARIFFS, isEditing };
}
