import { useEffect } from "react";
import DateMessages from "./DateMessages";
import "./messages.scss";

const Messages = ({ info, chatRef, autoScroll, messagesEndRef }) => {
    const messages = info.map(item => (<DateMessages key={item.id} {...item} />));

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (autoScroll) {
            scrollToBottom();
        }
    }, [messages, autoScroll]);

    return (

        <div className="chat--messages" ref={chatRef}>
            {messages}
            <div ref={messagesEndRef} />
        </div>
    );
};

export default Messages;