import React from 'react';

import './sortingButton.scss';
import useSorting from '../../../../helpers/hooks/useSorting';

const SortingButton = () => {
  const { getSearchParam } = useSorting();
  const currentSort = getSearchParam('sortDirection');
  const currentClass = currentSort === 'asc' ? 'asc' : 'desc';

  return <div className={`sortingButton ${currentClass}`} data-testid="sorting-button" />;
};

export default SortingButton;
