import { withPagination } from "../../../../helpers/withPagination";
import useTable from "../../../../helpers/hooks/useTable";
import Spinner from "../../spinner/Spinner";

const Table = ({ headersInfo = [], searchRow, bodyInfo = [], TableItem, showFilters, loading = false, onPageChange = () => { } }) => {
    const { tableHeaders, search, body } = useTable(headersInfo, searchRow, bodyInfo, TableItem, onPageChange);

    return (
        <div className="tableWrapper">
            <div className="tableHeader">
                <table>
                    <thead>
                        <tr>{tableHeaders}</tr>
                        {searchRow && <tr className="tableSearchPanel">{search}</tr>}
                    </thead>
                </table>
            </div>
            {!loading && <div className={`scrollableBody ${showFilters ? 'collapsed' : ''}`}>
                <table>
                    <tbody>
                        {body}
                    </tbody>
                </table>
            </div>}
            {loading && <Spinner />}
        </div>
    );
};

const DefaultTable = withPagination(Table);
export default DefaultTable;