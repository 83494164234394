const PaginationItem = ({ onClick, content, className }) => {
    return (
        <div
            className={`pagination__item ${className}`}
            onClick={onClick}
        >
            {content}
        </div>
    );
};

export default PaginationItem;