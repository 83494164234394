import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NavigationPanel from '../../../navigation/panel/NavigationPanel';
import Sidebar from '../../../navigation/sidebar/Sidebar';
import './contentWrapper.scss';

const ContentWrapper = ({
  children,
  pageTitle,
  pageControls,
  className,
  isDriverPage,
  driverStatus,
  redirectToOrders,
  redirectToHelpdesk,
  additionalControls,
  size,
}) => (
  <div className={classNames('contentWrapper', className && className)}>
    <Sidebar />
    <NavigationPanel
      pageTitle={pageTitle}
      pageControls={pageControls}
      isDriverPage={isDriverPage}
      driverStatus={driverStatus}
      redirectToOrders={redirectToOrders}
      redirectToHelpdesk={redirectToHelpdesk}
      additionalControls={additionalControls}
      size={size}
    />
    {children}
  </div>
);

export default ContentWrapper;

ContentWrapper.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.string,
  pageControls: PropTypes.node,
  className: PropTypes.string,
  isDriverPage: PropTypes.bool,
  driverStatus: PropTypes.string,
  redirectToOrders: PropTypes.func,
  redirectToHelpdesk: PropTypes.func,
  additionalControls: PropTypes.node,
  size: PropTypes.string,
};
