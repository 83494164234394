import "./cardItem.scss";
import { ReactComponent as CreditCard } from "../../../../../img/icons/credit_card.svg"

const CardItem = ({ cardPan, id, onDelete, loading }) =>
    <div className="clientCard--list-item">
        <div className="clientCard--list-item_icon">
            <CreditCard />
        </div>
        <div className="clientCard--list-item_card">Картка</div>
        <button disabled={loading} className="clientCard--list-item_delete" onClick={() => onDelete(id)}>Видалити</button>
        <div className="clientCard--list-item_number">{cardPan}</div>
    </div>;

export default CardItem;