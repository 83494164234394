import React from 'react';
import PropTypes from 'prop-types';
import XScrollableTable from '../../assets/table/xScrollable/XScrollableTable';
import OrdersItem from './OrdersItem';
import useGetState from '../../../store/hooks/useGetState';

const OrdersTable = ({
  showFilters,
  small,
  tableBody = [],
  maxPage = 1,
  loading,
  onPageChange = () => {},
  size = 'middle',
  showOnePage = true,
  className,
}) => {
  const { tableHeaders, searchRow } = useGetState();
  return (
    <XScrollableTable
      headersInfo={tableHeaders}
      searchRow={searchRow}
      bodyInfo={tableBody}
      TableItem={OrdersItem}
      showFilters={showFilters}
      small={small}
      size={size}
      maxPage={maxPage}
      showOnePage={showOnePage}
      loading={loading}
      onPageChange={onPageChange}
      className={className}
    />
  );
};

export default OrdersTable;

OrdersTable.propTypes = {
  showFilters: PropTypes.bool,
  small: PropTypes.bool,
  tableBody: PropTypes.arrayOf,
  maxPage: PropTypes.number,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
  size: PropTypes.string,
  showOnePage: PropTypes.bool,
  className: PropTypes.string,
};
