/* eslint-disable no-unused-vars */
import "./editApplicationControls.scss";
import FormButton from "../../../../../assets/buttons/formButton/FormButton";
import { useNavigate, useParams } from "react-router";
import FormButtonsWrapper from "../../../../../assets/buttons/formButtonsWrapper/FormButtonsWrapper";
import useDriversService from "../../../../../../services/driversService";
import useGetState from "../../../../../../store/hooks/useGetState";
import notify from "../../../../../../helpers/notify";

const EditApplicationControls = () => {
    const navigate = useNavigate();
    const redirectBack = () => navigate(-1);
    const { id } = useParams();
    const { rejectPendingDriver, approvePendingDriver } = useDriversService();
    const { driver } = useGetState();
    const driverIsPending = driver.status === "pending";

    const rejectApplication = async () => {
        await rejectPendingDriver(id);
        setTimeout(() => notify("Заявку відхилено", "info"), 0);
        redirectBack();
    };

    const handleSubmit = async () => {
        await approvePendingDriver(id);
        setTimeout(() => notify("Заявку прийнято", "info"), 0);
        redirectBack();
    };

    return (
        <div className={`editApplicationControls ${driverIsPending ? "" : "small"}`}>
            {driverIsPending && <FormButtonsWrapper>
                <FormButton text="Підтвердити" onClick={handleSubmit} type="button" />
                <FormButton text="Відхилити" onClick={rejectApplication} color="red" type="button" />
                <FormButton text="Скасувати" onClick={redirectBack} color="white" type="button" />
            </FormButtonsWrapper>}
        </div>
    );
};

export default EditApplicationControls;