import { createSlice } from '@reduxjs/toolkit';
import { NEW_ORDER_ADDRESSES } from '../../data/orders/newOrder/clietnInfo';
import {
  filterArrayByItemName,
  getDateFromString,
  alphabet,
} from '../../helpers/general/general';
import { getWaitDurationInMinutes } from '../../helpers/dates';
import dayjs from 'dayjs';

const initialState = {
  firstName: '',
  phoneNumber: '+380',
  addresses: [],
  fare: '',
  additionalServices: [],
  price: '',
  actualPrice: '',
  suggestedPrice: '',
  comment: '',
  date: '',
  hours: '',
  type: 'now',
  address_inputs: [...NEW_ORDER_ADDRESSES],
  errors: {},
  duration: '',
  driver: '',
  status: '',
  paymentType: '',
  downtimeDuration: '',
  approvedAt: '',
  distance: '',
};

const newAddressInput = {
  id: 'new-order-address-',
  name: 'new-order-address-',
  type: 'text',
  placeholder: '',
  errorMessage: '',
  label: 'Куди (точка )',
  deletable: false,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrder(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setFetchedOrder(state, { payload }) {
      const fetchedRide = payload;

      const addresses = fetchedRide.points;

      if (fetchedRide.scheduledFor) {
        const scheduledForString = getDateFromString(fetchedRide.scheduledFor);
        const [year, month, day] = scheduledForString.slice(0, 10).split('-');
        state.date = `${day}.${month}.${year}`;
        state.hours = scheduledForString.slice(11, 16);
      }

      state.type = fetchedRide.scheduledFor ? 'scheduledFor' : 'now';
      state.comment = fetchedRide.comment;
      state.firstName = fetchedRide.client.name;
      state.phoneNumber = fetchedRide.client.phoneNumber;
      state.addresses = addresses.map((address, index) => ({
        ...address,
        inputId: `new-order-address-${alphabet[index]}-${index}`,
      }));
      state.additionalServices = fetchedRide.services;
      state.actualPrice = fetchedRide.actualPrice;
      state.price = fetchedRide.actualPrice;
      state.suggestedPrice = fetchedRide.suggestedPrice;
      state.driver = fetchedRide.driver;
      state.status = fetchedRide.status;
      state.fare = {
        ...fetchedRide.fare,
        services: fetchedRide.fare?.services?.map((service) => ({
          ...service,
          name: { uk: service.name },
        })),
      };
      state.paymentType =
        fetchedRide.paymentType === 'cash' ? 'Готівка' : 'Картка';
      state.downtimeDuration = getWaitDurationInMinutes(
        fetchedRide?.downtimeDuration,
      );
      state.approvedAt = dayjs(fetchedRide?.approvedAt).format(
        'DD.MM.YYYY HH:mm:ss',
      );
      state.distance =
        (fetchedRide?.distance / 1000).toFixed(1).toString() + ' км';

      if (fetchedRide.duration) {
        state.duration = fetchedRide.duration;
      }
    },
    setOrderErrors(state, action) {
      state.errors = { ...state.errors, ...action.payload };
    },
    resetOrderErrors(state) {
      state.errors = {};
    },
    addAddressInput(state) {
      state.address_inputs = [...state.address_inputs, newAddressInput].map(
        (item, index, array) => ({
          ...item,
          id: `new-order-address-${alphabet[index]}-${index}`,
          name: `new-order-address-${alphabet[index]}`,
          label: `Куди (точка ${alphabet[index]})`,
          deletable: array.length > 2 && index > 0,
        }),
      );
    },
    addAddress(state, { payload }) {
      const existingAddress = state.addresses.find(
        (address) => address.inputId === payload.inputId,
      );
      const addressesCopy = state.addresses;

      if (existingAddress) {
        addressesCopy.splice(
          addressesCopy.indexOf(existingAddress),
          1,
          payload,
        );
        state.addresses = [...addressesCopy];
        return;
      }

      state.addresses = [...state.addresses, payload];
    },
    removeAddress(state, action) {
      state.addresses = state.addresses.filter(
        (address) => address.inputId !== action.payload,
      );
    },
    deleteAddressInput(state, action) {
      state.address_inputs = filterArrayByItemName(
        state.address_inputs,
        action.payload,
        'id',
      ).map((item, index, array) => ({
        ...item,
        id: `new-order-address-${alphabet[index]}-${index}`,
        name: `new-order-address-${alphabet[index]}`,
        label: `КУДИ (точка ${alphabet[index]})`,
        deletable: array.length > 2 && index > 0,
      }));
      state.addresses = filterArrayByItemName(
        state.addresses,
        action.payload,
        'inputId',
      ).map((item, index) => ({
        ...item,
        inputId: `new-order-address-${alphabet[index]}-${index}`,
      }));
    },
    includeService(state, { payload }) {
      state.additionalServices = [...state.additionalServices, payload];
    },
    excludeService(state, { payload }) {
      state.additionalServices = state.additionalServices.filter(
        (service) => service.id !== payload.id,
      );
    },
    resetOrder(state) {
      state.firstName = '';
      state.phoneNumber = '+380';
      state.addresses = [];
      state.fare = '';
      state.additionalServices = [];
      state.price = '';
      state.duration = '';
      state.actualPrice = '';
      state.suggestedPrice = '';
      state.price = '';
      state.date = '';
      state.hours = '';
      state.type = 'now';
      state.comment = '';
      state.driver = '';
      state.address_inputs = [...NEW_ORDER_ADDRESSES];
      state.errors = {};
      state.status = '';
    },
  },
});

const { reducer: order, actions } = orderSlice;
export const orderActions = actions;
export default order;
