import React from 'react';
import { Select } from 'antd';
import useBindCreators from '../../../../../store/hooks/useBindCreators';
import { orderActions } from '../../../../../store/slices/orderSlice';
import PageHeading from '../../../../assets/pageHeadings/PageHeading';
import Divider from '../../../../drivers/driverInfo/divider/Divider';
import SelectTime from './selectTime/SelectTime';

const PlanDrive = ({ order, destination, children }) => {
  const { setOrder } = useBindCreators(orderActions);
  const handleSelect = (value) => {
    setOrder({ key: 'type', value });
  };
  return (
    <div>
      <PageHeading text="ЗАПЛАНУВАТИ ПОЇЗДКУ" className="small marginBottom" />
      <Select
        options={[
          { label: 'На зараз', value: 'now' },
          { label: 'Попереднє замовлення', value: 'scheduledFor' },
        ]}
        value={order.type}
        onSelect={handleSelect}
        size="large"
        style={{
          width: '100%',
        }}
      />
      {order.type !== 'now' && (
        <SelectTime order={order} destination={destination} />
      )}
      <div
        style={{
          width: '70%',
        }}
      >
        {children}
      </div>

      <Divider />
    </div>
  );
};

export default PlanDrive;
