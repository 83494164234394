import "./sidebar.scss";
import { ReactComponent as SidebarLogo } from "../../../img/logo/sidebar_logo.svg";
import { ReactComponent as SidebarLogoText } from "../../../img/logo/sidebarLogoText.svg";
import { ReactComponent as Logout } from "../../../img/icons/sidebar/logout.svg";
import SidebarItem from "./sidebarItem/SidebarItem";
import { SIDEBAR_DIRECTOR_ICONS, SIDEBAR_ICONS } from "../../../data/sidebar/sidebar";
import useBindCreators from "../../../store/hooks/useBindCreators";
import { authActions } from "../../../store/slices/authSlice";
import useRedirect from "../../../helpers/hooks/useRedirect";
import { LOGIN } from "../../../data/consts";
import useGetState from "../../../store/hooks/useGetState";

const Sidebar = () => {
    const { role } = useGetState();
    const initialIcons = role === "director" ? [...SIDEBAR_ICONS, ...SIDEBAR_DIRECTOR_ICONS] : SIDEBAR_ICONS;

    const icons = initialIcons.map(icon => (<SidebarItem key={icon.id} {...icon} />));
    const { logout } = useBindCreators(authActions);
    const redirect = useRedirect();

    return (
        <nav className='sidebar'>
            <SidebarLogo />
            <SidebarLogoText />
            <div className="sidebar__icons">
                {icons}
            </div>
            <div className="logout" onClick={() => {
                logout();
                redirect(LOGIN);
            }}>
                <Logout />
            </div>
        </nav>
    );
};

export default Sidebar;