import { authActions } from "../../../../store/slices/authSlice";
import { LOGIN_FORM_INPUTS } from "../../../../data/forms/loginForm";
import FormInput from "../../../assets/inputs/FormInput";
import { useState } from "react";
import { checkEmptyInputs } from "../../../../helpers/checkEmptyInputs";
import { useNavigate } from "react-router";
import { ORDER_MAPS } from "../../../../data/consts";
import useBindCreators from "../../../../store/hooks/useBindCreators";
import { useEffect } from "react";
import useGetState from "../../../../store/hooks/useGetState";
import useAuthService from "../../../../services/authService";
import notify from "../../../../helpers/notify";
import { getLinkWithSearchParams } from "../../../../helpers/general/general";

const initialState = {
    email: '',
    password: '',
    rememberMe: false
};

const useLoginForm = () => {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState(initialState);
    const navigate = useNavigate();
    const { email, password, rememberMe } = values;
    const { setToken, setRole, setAuthorizing, setCurrentUserId, setWsChannel, setWsToken } = useBindCreators(authActions);
    const { token, authorizing } = useGetState();
    const { sendCreds, getWorkspace } = useAuthService();

    const resetState = () => {
        setValues(initialState);
        setErrors(initialState);
    };

    const loginUser = async () => {
        try {
            setAuthorizing(true);
            const token = await sendCreds({ email, password });
            if (token && typeof token === 'string') {
                setToken(token);
                navigate(getLinkWithSearchParams(ORDER_MAPS));
            }
        } catch (err) {
            notify('Невірний email або пароль', 'error');
            setAuthorizing(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const emptyInputs = checkEmptyInputs({ email, password }, setErrors);
        if (emptyInputs > 0) {
            return;
        } else {
            loginUser();
        }
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setValues(prevValues => ({ ...prevValues, [name]: value }));
        if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
        }
    };

    const getRole = async () => {
        const workspace = await getWorkspace(token);
        if (workspace) {
            setAuthorizing(false);
            setRole(workspace.position);
            setCurrentUserId(workspace.id);
            setWsChannel(workspace.websocket.channel);
            setWsToken(workspace.websocket.token);
        }
    };

    useEffect(() => {
        if (token) {
            getRole();
        }
    }, [token]);

    useEffect(() => {
        return resetState;
    }, [])

    const inputs = LOGIN_FORM_INPUTS.map(input => (
        <FormInput
            key={input.id}
            value={values[input.name]}
            onChange={handleChange}
            errorClass={errors[input.name] ? 'error' : ''}
            className={'formInput'}
            customErrorMessage={errors[input.name] ? errors[input.name] : ''}
            {...input}
        />));

    return { authorizing, rememberMe, handleSubmit, inputs, setValues };
};

export default useLoginForm;
