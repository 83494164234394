import { HELPDESK_HEADERS, HELPDESK_SEARCH_ROW } from "../../../data/helpdesk/heldeskTable";
import DefaultTable from "../../assets/table/defaultTable/DefaultTable";
import HelpdeskItem from "./HelpdeskItem";

const HelpdeskTable = ({ maxPage, loading, onPageChange, tableBody = [] }) =>
    <DefaultTable
        headersInfo={HELPDESK_HEADERS}
        searchRow={HELPDESK_SEARCH_ROW}
        TableItem={HelpdeskItem}
        bodyInfo={tableBody}
        maxPage={maxPage}
        loading={loading}
        onPageChange={onPageChange}
    />;

export default HelpdeskTable;