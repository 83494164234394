import { getColor } from "../../../../../helpers/getColor";
import useRedirect from "../../../../../helpers/hooks/useRedirect";
import { getStatus } from "../../../../../helpers/getStatus";
import { getPendingDriverLink } from "../../../../../data/urls";

const ApplicationDriverItem = ({ status, id, calling, name, phoneNumber, email }) => {
    const redirect = useRedirect();
    const requestIsUnprocessed = status === 'unprocessed';
    const redirectToDriver = () => redirect(getPendingDriverLink(id));

    return (
        <tr className={requestIsUnprocessed ? 'activeRow' : ''} onClick={redirectToDriver}>
            <td className={`driversApplications--status ${getColor(status)}`}>{getStatus(status)}</td>
            <td className='driversApplications--id'>{`#${id}`}</td>
            <td className='driversApplications--name'>{name}</td>
            <td className='driversApplications--calling'>{calling || '---'}</td>
            <td className='driversApplications--phone'>{phoneNumber}</td>
            <td className='driversApplications--email'>{email}</td>
        </tr>
    );
};

export default ApplicationDriverItem;
