import './ordersItem.scss';
import { getColor } from '../../../helpers/getColor';
import React, { useEffect, useMemo, useState } from 'react';
import OrdersItemControls from './ordersControls/OrdersItemConrols';
import useGetState from '../../../store/hooks/useGetState';
import InfoModal from '../modals/InfoModal';
import { getRideStatus } from '../../../helpers/getRideStatus';
import { getDateFromString } from '../../../helpers/general/general';
import { CANCEL_DRIVE_REASON } from '../../../data/orders/cancelDrive';
import {
  getDurationInMinutes,
  getWaitDurationInMinutes,
} from '../../../helpers/dates';
import classNames from 'classnames';
import dayjs from 'dayjs';

const OrdersItem = (props) => {
  const { itemCells } = useGetState();
  const { status: _status } = props;
  const [status, setStatus] = useState(_status);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const [statusName, setStatusName] = useState(getRideStatus(_status));
  const [data, setData] = useState([]);
  const {
    orderId,
    driver,
    from,
    to,
    priceDriver,
    priceClient,
    wait,
    waitPrice,
    comission,
    client,
    waitComment,
    mileage,
    driveDuration,
    clientComplaint,
    clientReview,
    points,
    cancelReason,
    payment,
    cancellor,
    canceledAt,
  } = props;
  const cancelDate = canceledAt
    ? useMemo(() => dayjs(canceledAt).format('HH:mm:ss'), [])
    : '';
  const translatedCancelReason = CANCEL_DRIVE_REASON.find(
    (reason) => reason.name === cancelReason,
  )?.text;

  const unfinished =
    status === 'approved' ||
    status === 'arrived' ||
    status === 'started' ||
    status === 'paused' ||
    status === 'resumed' ||
    status === 'pending';

  const cancelled = status === 'cancelled';
  const completed = status === 'finished';
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showComplaintModal, setShowComplaintModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);

  useEffect(() => {
    setStatus(_status);
    setStatusName(getRideStatus(_status));
  }, [_status]);

  const customCells = {
    statusCell: (
      <td key="orders-cells-status" title={statusName}>
        <div>
          <span className={`statusText ${getColor(status)}`}>{statusName}</span>
        </div>
      </td>
    ),
    cancellorCell: (
      <td key="orders-cells-cancellor" title={cancellor}>
        <div>
          <span>{cancellor}</span>
          <br />
          <span>{cancelDate}</span>
        </div>
      </td>
    ),
    orderIdCell: (
      <td key="orders-cells-orderId" title={`#${orderId}`}>{`#${orderId}`}</td>
    ),
    driverCell: (
      <td
        key="orders-cells-driver"
        title={driver?.calling}
        className={`orders-cells-driver ${driver ? 'black-driver' : ''} ${
          status === 'approved' ? 'approved' : ''
        }`}
      >
        <span>{driver?.calling ? driver.calling : '---'}</span>
      </td>
    ),
    fromCell: (
      <td key="orders-cells-from" title={from} className="black">
        {from}
      </td>
    ),
    toCell: (
      <td key="orders-cells-to" title={to} className="black">
        {to}
      </td>
    ),
    priceDriverCell: (
      <td
        key="orders-cells-priceDriver"
        title={`₴ ${priceDriver}`}
      >{`₴ ${priceDriver}`}</td>
    ),
    priceClientCell: (
      <td
        key="orders-cells-priceClient"
        title={`₴ ${priceClient}`}
      >{`₴ ${priceClient}`}</td>
    ),
    waitCell: (
      <td key="orders-cells-wait" title={getWaitDurationInMinutes(wait)}>
        {getWaitDurationInMinutes(wait)}
      </td>
    ),
    waitPriceCell: (
      <td
        key="orders-cells-waitPrice"
        title={`₴ ${waitPrice}`}
      >{`₴ ${waitPrice}`}</td>
    ),
    comissionCell: (
      <td
        key="orders-cells-comission"
        title={`₴ ${comission}`}
      >{`₴ ${comission}`}</td>
    ),
    clientCell: (
      <td key="orders-cells-client" className="black-client" title={client}>
        {client}
      </td>
    ),
    paymentType: (
      <td
        key="orders-cells-paymentType"
        title={payment}
        className={classNames(payment === 'Картка' && 'card')}
      >
        {payment}
      </td>
    ),
    waitCommentCell: (
      <td
        key="orders-cells-waitComment"
        title={waitComment}
        onClick={(e) => {
          e.stopPropagation();
          setShowCommentModal(true);
        }}
      >
        <span className={waitComment ? 'text-link' : ''}>
          {waitComment || '---'}
        </span>
      </td>
    ),
    mileageCell: (
      <td key="orders-cells-mileage" title={mileage}>
        {mileage ? (mileage / 1000).toFixed(1).toString() + ' км' : '---'}
      </td>
    ),
    driveDurationCell: (
      <td
        key="orders-cells-driveDuration"
        title={getDurationInMinutes(driveDuration)}
      >
        {getDurationInMinutes(driveDuration)}
      </td>
    ),
    clientReviewCell: (
      <td
        key="orders-cells-clientReview"
        title={clientReview}
        onClick={(e) => {
          e.stopPropagation();
          setShowReviewModal(true);
        }}
      >
        <span className={clientReview ? 'text-link' : ''}>
          {clientReview || '---'}
        </span>
      </td>
    ),
    clientComplaintCell: (
      <td
        key="orders-cells-clientComplaint"
        title={clientComplaint}
        onClick={(e) => {
          e.stopPropagation();
          setShowComplaintModal(true);
        }}
      >
        <span className={clientComplaint ? 'text-link' : ''}>
          {clientComplaint || '---'}
        </span>
      </td>
    ),
  };

  const getTableCell = ({ id }) => {
    const {
      statusCell,
      cancellorCell,
      orderIdCell,
      driverCell,
      fromCell,
      toCell,
      priceDriverCell,
      priceClientCell,
      waitCell,
      waitPriceCell,
      comissionCell,
      clientCell,
      waitCommentCell,
      mileageCell,
      driveDurationCell,
      clientReviewCell,
      clientComplaintCell,
      paymentType,
    } = customCells;

    const slicedId = id.slice(13);

    switch (slicedId) {
      case 'status':
        return statusCell;
      case 'cancellor':
        return cancellorCell;
      case 'orderId':
        return orderIdCell;
      case 'driver':
        return driverCell;
      case 'from':
        return fromCell;
      case 'to':
        return toCell;
      case 'priceDriver':
        return priceDriverCell;
      case 'priceClient':
        return priceClientCell;
      case 'wait':
        return waitCell;
      case 'waitPrice':
        return waitPriceCell;
      case 'commission':
        return comissionCell;
      case 'client':
        return clientCell;
      case 'waitComment':
        return waitCommentCell;
      case 'mileage':
        return mileageCell;
      case 'driveDuration':
        return driveDurationCell;
      case 'clientReview':
        return clientReviewCell;
      case 'clientComplaint':
        return clientComplaintCell;
      case 'cancelReason':
        return (
          <td
            key={translatedCancelReason + mileage}
            title={translatedCancelReason || '---'}
          >
            {translatedCancelReason || '---'}
          </td>
        );
      case 'acceptTime':
        return <td key={id}>{getDateFromString(props[slicedId]) || '---'}</td>;
      case 'arrival':
        return <td key={id}>{getDateFromString(props[slicedId]) || '---'}</td>;
      case 'driveEnd':
        return <td key={id}>{getDateFromString(props[slicedId]) || '---'}</td>;
      case 'driveStart':
        return <td key={id}>{getDateFromString(props[slicedId]) || '---'}</td>;
      case 'byTime':
        return <td key={id}>{getDateFromString(props[slicedId]) || '---'}</td>;
      case 'payment':
        return paymentType;
      default:
        return (
          <td key={id} title={props[slicedId]}>
            {props[slicedId] || '---'}
          </td>
        );
    }
  };

  useEffect(() => {
    setData(itemCells.map(getTableCell));
  }, [props]);

  return (
    <>
      <tr
        className={`${unfinished ? 'activeRow' : ''} ${
          cancelled ? 'redRow' : ''
        } ${completed ? 'greenRow' : ''}`}
        onClick={() => setShowRouteModal((prev) => !prev)}
      >
        {data}
      </tr>
      <OrdersItemControls
        show={showRouteModal}
        unfinished={unfinished}
        points={points}
        orderId={orderId}
        status={status}
        setStatus={setStatus}
        client={client}
        onOpenModal={setShowRouteModal}
      />

      <InfoModal
        show={showCommentModal}
        onHide={() => setShowCommentModal(false)}
        title="Коментар"
        info={waitComment}
      />
      <InfoModal
        show={showComplaintModal}
        onHide={() => setShowComplaintModal(false)}
        title="Скарга"
        info={clientComplaint}
      />
      <InfoModal
        show={showReviewModal}
        onHide={() => setShowReviewModal(false)}
        title="Відгук"
        info={clientReview}
      />
    </>
  );
};

export default OrdersItem;
