import React from 'react';

const RouteStep = ({ name, letter }) => {
  return (
    <div className="userRoute--routeStep">
      <div className="userRoute--routeStep-control">
        <div className="userRoute--routeStep-control_icon">{letter}</div>
        <div className="userRoute--routeStep-control_dots"></div>
      </div>
      {name}
    </div>
  );
};

export default RouteStep;
