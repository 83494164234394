import { useCallback, useState } from 'react';

const useModal = () => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleModal = useCallback(() => {
    setIsOpened((isOpen) => !isOpen);
  }, []);

  const openModal = useCallback(() => {
    setIsOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpened(false);
  }, []);

  return {
    isOpened,
    toggleModal,
    openModal,
    closeModal,
  };
};

export default useModal;
