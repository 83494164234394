import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './customDateInput.scss';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale, CalendarContainer } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import { getDateFromUrl } from '../../../../helpers/dates';
import useSorting from '../../../../helpers/hooks/useSorting';

const MyContainer = ({ className, children }) => (
  <div className="customDate--calendar">
    <CalendarContainer className={className}>
      <div style={{ position: 'relative' }}>{children}</div>
    </CalendarContainer>
  </div>
);

const CustomDateInput = ({
  placeholder, name, onChange, minDate, maxDate, withUrlSearchParam = true,
}) => {
  const [startDate, setStartDate] = useState();
  const { getSearchParam } = useSorting();
  registerLocale('uk', uk);
  const searchParam = getSearchParam(name);

  const changeHandler = (date) => {
    onChange(name, date);
    setStartDate(date);
  };

  useEffect(() => {
    if (withUrlSearchParam) {
      if (searchParam) {
        const [day, month, year] = getDateFromUrl(searchParam);
        setStartDate(new Date().setFullYear(year, +month - 1, day));
      } else {
        setStartDate('');
      }
    }
  }, [searchParam]);

  return (
    <div className="customDate">
      <DatePicker
        className="customDate--input"
        selected={startDate}
        onChange={changeHandler}
        placeholderText={placeholder}
        locale="uk"
        dateFormat="dd/MM/yyyy"
        calendarContainer={MyContainer}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  );
};

export default CustomDateInput;

CustomDateInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  withUrlSearchParam: PropTypes.bool,
};

MyContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
