export const CLIENTS_STATUSES = [
  {
    id: 'all',
    name: 'all',
    text: 'Статус: усі',
  },
  {
    id: 'activeClients',
    name: 'active',
    text: 'Активні',
  },
  {
    id: 'blockedClients',
    name: 'blocked',
    text: 'Заблоковані',
  },
];
