import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const useBindCreators = (creators) => {
  const dispatch = useDispatch();
  const result = useMemo(() => bindActionCreators(creators, dispatch), [creators, dispatch]);
  return result;
};

export default useBindCreators;
