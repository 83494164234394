import React from 'react';
import { ORDERS_DATE_INPUTS } from '../../data/orders/ordersDateInputs';
import CustomDateInput from '../assets/inputs/dateInput/CustomDateInput';
import FiltersButton from '../assets/buttons/filtersButton/FiltersButton';
import TableControls from '../assets/wrappers/tableControls/TableControls';
import CustomSelect from '../assets/inputs/customSelect/CustomSelect';
import CreateOrderButton from './createOrder/button/CreateOrderButton';
import Multiselect from 'multiselect-react-dropdown';

const OrdersTableActions = ({
  ordersStatus,
  changeStatus,
  onDateChange,
  onFilterColumnsButtonClick,
  showColumnFilters,
  handleChangeCallSign,
  handleChangeRate,
  rateOptions,
  driversOptions,
  isOrdersPage,
}) => {
  return (
    <TableControls>
      {!isOrdersPage && <CreateOrderButton />}
      <CustomSelect
        className="marginRight"
        scrollable
        options={ordersStatus}
        onClick={changeStatus}
      />
      {ORDERS_DATE_INPUTS.map((input) => (
        <div key={input.id} className="dateWrapper customDate--wrapper">
          <CustomDateInput onChange={onDateChange} {...input} />
        </div>
      ))}
      {isOrdersPage && (
        <div className="OrderMultiSelect">
          <Multiselect
            displayValue="name"
            options={rateOptions}
            onSelect={handleChangeRate}
            onRemove={handleChangeRate}
            showCheckbox
            hideSelectedList
            placeholder={'Тариф'}
          />
        </div>
      )}
      {isOrdersPage && (
        <div className="OrderMultiSelect">
          <Multiselect
            displayValue="name"
            options={driversOptions}
            onSelect={handleChangeCallSign}
            onRemove={handleChangeCallSign}
            showCheckbox
            hideSelectedList
            placeholder={'Позивний'}
          />
        </div>
      )}

      <div className="filtersButtons">
        <div className="filtersButton-wrapper">
          <FiltersButton
            onClick={onFilterColumnsButtonClick}
            filled={showColumnFilters}
          />
        </div>
      </div>
    </TableControls>
  );
};

export default OrdersTableActions;
