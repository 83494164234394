import "./additionalInfo.scss";
import PageHeading from "../../../../assets/pageHeadings/PageHeading";
import { NEW_ORDER_COMMENTS } from "../../../../../data/orders/newOrder/clietnInfo";
import FormInput from "../../../../assets/inputs/FormInput";
import useGetState from "../../../../../store/hooks/useGetState";
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import { orderActions } from "../../../../../store/slices/orderSlice";

const AdditionalInfo = () => {
    const { order } = useGetState();
    const { errors } = order;
    const { setOrder } = useBindCreators(orderActions);

    const handleChange = (e, inputName) => setOrder({ key: inputName, value: e.target.value });

    const inputs = NEW_ORDER_COMMENTS.map(input => (
        <div key={input.id} className="orderComment--item">
            <FormInput
                value={order[input.name] || ""}
                onChange={(e) => handleChange(e, input.name)}
                errorClass={errors[input.name] ? 'error' : ''}
                customErrorMessage={errors[input.name] ? errors[input.name] : ''}
                {...input}
            />
        </div>
    ));

    return (
        <>
            <PageHeading text='ДОДАТКОВА ІНФОРМАЦІЯ' className="small marginBottom" />
            <div className="orderAppointment">
                {inputs}
            </div>
        </>
    );
};

export default AdditionalInfo;