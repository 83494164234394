import { createSlice } from '@reduxjs/toolkit';
import {
  ALL_ORDERS_HEADERS, ALL_ORDERS_ITEM_CELLS, ALL_ORDERS_SEARCH_ROW, INITIAL_ORDERS_SEARCH_ROW, INITIAL_ORDERS_TABLE_HEADERS, INITIAL_ORDERS_TABLE_ITEM_CELLS, activeFilters,
} from '../../data/orders/allOrdersTable';
import { filterArrayByItem, filterArrayByItemName, findInArrayByItemName } from '../../helpers/general/general';

const activeFiltersNames = activeFilters.map((filter) => filter.name);

const initialState = {
  activeFilters: activeFiltersNames,
  tableHeaders: INITIAL_ORDERS_TABLE_HEADERS,
  searchRow: INITIAL_ORDERS_SEARCH_ROW,
  itemCells: INITIAL_ORDERS_TABLE_ITEM_CELLS,
};

const insertItems = (initialArray, name, targetArray) => {
  const insertedItem = findInArrayByItemName(initialArray, name, 'name');
  const insertedIndex = initialArray.indexOf(insertedItem);
  targetArray.splice(insertedIndex, 0, insertedItem);
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    disableFilter(state, action) {
      const { payload } = action;
      const {
        activeFilters, tableHeaders, searchRow, itemCells,
      } = state;
      state.activeFilters = filterArrayByItem(activeFilters, payload);
      state.tableHeaders = filterArrayByItemName(tableHeaders, payload, 'name');
      state.searchRow = filterArrayByItemName(searchRow, payload, 'name');
      state.itemCells = filterArrayByItemName(itemCells, payload, 'name');
    },
    enableFilter(state, action) {
      const { payload } = action;
      const {
        activeFilters, tableHeaders, searchRow, itemCells,
      } = state;
      state.activeFilters = [...activeFilters, payload];
      insertItems(ALL_ORDERS_HEADERS, payload, tableHeaders);
      insertItems(ALL_ORDERS_SEARCH_ROW, payload, searchRow);
      insertItems(ALL_ORDERS_ITEM_CELLS, payload, itemCells);
    },

  },
});

const { reducer: orders, actions } = ordersSlice;
export const ordersActions = actions;
export default orders;
