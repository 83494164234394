import { useEffect } from "react";
import { useParams } from "react-router";
import { checkStateEmptyInputs } from "../../../../../helpers/checkEmptyInputs";
import notify from "../../../../../helpers/notify";
import useUsersService from "../../../../../services/usersService";
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import useGetState from "../../../../../store/hooks/useGetState";
import { userActions } from "../../../../../store/slices/userSlice";
import FormInput from "../../../../assets/inputs/FormInput";

export default function useCreateUserForm(action) {
    const { user } = useGetState();
    const { setUser, setUserErrors, resetUserErrors, resetUser } = useBindCreators(userActions);
    const { createUser, updateUser, getUser } = useUsersService();
    const { errors } = user;
    const { id } = useParams();

    const handleInputChange = ({ target }) => {
        const { name: key, value } = target;
        setUser({ key, value });
        if (errors[key]) {
            setUserErrors({ [key]: '' });
        }
    };

    const fetchUser = async () => {
        const user = await getUser(id);
        for (const key in user) {
            setUser({ key, value: user[key] });
        }
    };

    const mapInputs = (data) => {
        const column = data.map(input =>
            <FormInput
                key={input.id}
                value={user[input.name]}
                onChange={handleInputChange}
                errorClass={errors[input.name] ? 'error' : ''}
                customErrorMessage={errors[input.name] ? errors[input.name] : ''}
                {...input}
            />);
        return <div className="form--item">
            {column}
        </div>
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        resetUserErrors();
        const { firstName, lastName, email, phoneNumber, password, position } = user;
        const userInfo = { firstName, lastName, phoneNumber, email, password, position };
        const emptyInputs = checkStateEmptyInputs(userInfo, setUserErrors);

        if (emptyInputs > 0) {
            notify('Заповніть таблицю', 'error');
            return;
        }

        action === "create" ? createUser(userInfo) : updateUser(id, userInfo);
    };

    useEffect(() => {
        if (action === "update") {
            fetchUser();
        }
        return () => {
            resetUserErrors();
            resetUser();
        };
    }, []);

    return { mapInputs, handleSubmit };
}
