export const CLIENT_CONTROLS_ACTIVE = [
  {
    id: 'active',
    name: 'active',
    text: 'Активний',
    customText: 'Активний',
    color: 'green',
  },
  {
    id: 'block',
    name: 'block',
    text: 'Заблокувати',
    customText: 'Заблокований',
    color: 'red',
  },
];

export const CLIENTS_CONTROLS_BLOCKED = [
  {
    id: 'unblock',
    name: 'unblock',
    text: 'Розблокувати',
    customText: 'Заблокований',
    color: 'green',
  },
];
