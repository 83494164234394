import useSorting from "../../../../helpers/hooks/useSorting";
import SortingButton from "../../buttons/sorting/SortingButton";

const TableHeader = ({ label, sortable, className, name, smallText, onSorting }) => {
    const { urlParamsHandler, getSearchParam, paramsObject } = useSorting();
    const currentSortField = getSearchParam('sortField');
    const currentSortType = getSearchParam('sortDirection');

    const onClick = () => {
        if (sortable) {
            const targetSorting = currentSortType === 'asc' ? 'desc' : 'asc';
            urlParamsHandler([{ param: 'sortField', value: name }, { param: 'sortDirection', value: targetSorting }]);
            onSorting({ ...paramsObject, sortField: name, sortDirection: targetSorting });
        }
    };

    return (
        <th className={className} onClick={onClick}>
            <div className={`tableHeaderWrapper ${sortable ? 'sortable' : ''}`}>
                <span>{label}</span>
                {sortable && currentSortField === name && <SortingButton />}
            </div>
            <span className="smallTHText" style={smallText ? {} : { visibility: 'hidden' }}>{`${smallText}`}</span>
        </th>
    );
};

export default TableHeader;