import './tariffs.scss';
import DirectorWrapper from '../section/DirectorWrapper';
import InnerWrapper from '../../assets/wrappers/innerWrapper/InnerWrapper';
import TariffTable from './table/TariffTable';
import useBindCreators from '../../../store/hooks/useBindCreators';
import { allTariffsActions } from '../../../store/slices/allTariffsSlice';
import useFaresService from '../../../services/faresService';
import React, { useEffect, useMemo, useState } from 'react';
import { tariffActions } from '../../../store/slices/tariffSlice';
import useServicesService from '../../../services/servicesService';
import useGetState from '../../../store/hooks/useGetState';
import { useGetAllUnitsQuery } from '../../../api/unitsApi';
import { getUnitsOptions } from '../units/helpers';

const Tariffs = () => {
  const { tariff, tariffs } = useGetState();
  const {
    setTariffErrors,
    resetTariffErrors,
    resetTariff,
    setAllTariffs,
    addTariff,
    setFetchedTariff,
    includeService,
    excludeService,
  } = useBindCreators({ ...tariffActions, ...allTariffsActions });

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [activeSlide, setActiveSlide] = useState('');

  const tariffsOfUnit = useMemo(
    () =>
      tariffs?.filter((item) => {
        return !(
          Array.isArray(item.unit) ||
          !item.unit ||
          item.unit.id !== selectedUnit
        );
      }),
    [tariffs, selectedUnit],
  );

  const handleSelectUnit = (option) => {
    const unit = data?.find((item) => item.id === option);
    const tariffsOfUnit = tariffs?.filter((item) => {
      return !(
        Array.isArray(item.unit) ||
        !item.unit ||
        item.unit.id !== unit.id
      );
    });
    setSelectedUnit(unit?.id);
    if (tariffsOfUnit?.at(0)) {
      setActiveSlide(tariffsOfUnit?.at(0)?.id);
      fetchFare(tariffsOfUnit?.at(0)?.id);
    } else {
      resetTariff();
    }
  };

  const { data, isFetching, isLoading } = useGetAllUnitsQuery(undefined);

  const { getAllFares, getFare, updateFare, createFare, loading } =
    useFaresService();
  const { getAllServices } = useServicesService();
  const [services, setServices] = useState([]);

  const fetchServices = async () => {
    const { services } = await getAllServices();
    setServices(services);
  };

  const fetchFares = async () => {
    const firstUnitId = data?.at(0)?.id;
    const allFares = await getAllFares().then((tariffs) => {
      const tariffsOfUnit = tariffs?.filter((item) => {
        return !(
          Array.isArray(item.unit) ||
          !item.unit ||
          item.unit.id !== firstUnitId
        );
      });
      setActiveSlide(tariffsOfUnit?.at(0)?.id);
      setSelectedUnit(firstUnitId);
      fetchFare(tariffsOfUnit?.at(0)?.id);
      return tariffs;
    });
    setAllTariffs(allFares);
  };

  const fetchFare = async (id) => {
    const fare = await getFare(id);
    setFetchedTariff(fare);
  };

  const changeTariff = (id) => {
    if (!id.toString().includes('newTariff')) {
      fetchFare(id);
    } else {
      resetTariff();
    }
    setActiveSlide(id);
  };

  useEffect(() => {
    if (tariffs) {
      const newTariff = tariffs.find((tariff) =>
        tariff?.id?.toString().includes('newTariff'),
      );
      if (newTariff) {
        changeTariff(newTariff?.id);
      }
    }
  }, [tariffs]);

  useEffect(() => {
    if (data) {
      fetchFares();
    }
  }, [data]);

  useEffect(() => {
    if (services.length === 0) {
      fetchServices();
    }
  }, [services]);

  return (
    <DirectorWrapper
      pageHeading="Тарифи"
      buttonText="Додати тариф"
      onButtonClick={() => {
        addTariff();
        setServices([]);
      }}
    >
      <InnerWrapper>
        <TariffTable
          fetchFares={fetchFares}
          updateFare={updateFare}
          createFare={createFare}
          loading={loading}
          services={services}
          tariff={tariff}
          setTariffErrors={setTariffErrors}
          resetTariffErrors={resetTariffErrors}
          resetTariff={resetTariff}
          setAllTariffs={setAllTariffs}
          includeService={includeService}
          excludeService={excludeService}
          unitsLoading={isFetching || isLoading}
          unitsOptions={getUnitsOptions(data)}
          selectedUnit={selectedUnit ?? data?.at(0)?.id}
          handleSelectUnit={handleSelectUnit}
          activeSlide={activeSlide}
          changeTariff={changeTariff}
          tariffsOfUnit={tariffsOfUnit}
        />
      </InnerWrapper>
    </DirectorWrapper>
  );
};

export default Tariffs;
