import { getAllMapRidesEndpoint } from '../data/endpoints';
import useHttpService from './httpService';
import LocalStorage from '../helpers/general/localStorage';
import { LOCAL_STORE_VIEWPORT_CENTER } from '../data/consts';

const useMapServices = () => {
  const { request, loading } = useHttpService();
  const getAllRides = async (params) => {
    const url = getAllMapRidesEndpoint(params);
    return await request({ url });
  };
  const setCurrentCoords = async () => {
    const ls = LocalStorage(LOCAL_STORE_VIEWPORT_CENTER);
    const { lat: latitude, lng: longitude } = ls.getState();

    const url = '/location/save';
    return await request({
      url,
      method: 'POST',
      data: { latitude, longitude },
    });
  };
  const invalidateCurrentCoords = async () => {
    const url = '/location/invalidate';
    return await request({ url, method: 'POST' });
  };

  return {
    getAllRides,
    setCurrentCoords,
    invalidateCurrentCoords,
    loading,
  };
};

export default useMapServices;
