import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import { hideCustomSelect } from "../../../helpers/general/general";
import FormButton from "../buttons/formButton/FormButton";

import "./modal.scss";


const Modal = ({
  show,
  onHide,
  onSubmit,
  customButtons = false,
  submitButtonText = 'Виконати',
  cancelButtonText = 'Скасувати',
  title,
  hideButtons = false,
  children,
  dropdownsArePresent = false,
  className = ''
}) => {
  const appRoot = document.getElementById('root');

  const closeOnEscape = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onHide();
    }
  };

  const closeDropdowns = ({ target }) => {
    if (!target.classList.contains('selected')) {
      hideCustomSelect();
    }
  };

  const onModalClick = (e) => {
    e.stopPropagation();
    if (dropdownsArePresent) {
      closeDropdowns(e);
    }
  }

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscape);
    return () => document.body.removeEventListener('keydown', closeOnEscape);
  }, []);

  const modal = <CSSTransition
    in={show}
    unmountOnExit
    timeout={{ enter: 0, exit: 300 }}
  >
    <div className="modal" onClick={onHide}>
      <div className={`modal-content ${className}`} onClick={onModalClick}>
        <div className="modal-content--header">
          <div className="modal-content--header--title">{title}</div>
          <span className="modal-content--header--close" onClick={onHide}></span>
        </div>
        <div className="modal-content--body">
          {children}
        </div>
        <div className="modal-content--footer">
          {(!customButtons && !hideButtons) &&
            <>
              <FormButton text={submitButtonText} onClick={onSubmit} />
              <FormButton text={cancelButtonText} onClick={onHide} color="white-outline" type="button" />
            </>
          }
          {!hideButtons && customButtons}
        </div>
      </div>
    </div>
  </CSSTransition>;

  return ReactDOM.createPortal(modal, appRoot);
};

export default Modal;
