import React, { useState } from 'react';
import { useParams } from 'react-router';
import Orders from '../../../orders/Orders';
import useRidesService from '../../../../services/ridesService';
import useSorting from '../../../../helpers/hooks/useSorting';
import { DEFAULT_PAGINATION } from '../../../../data/consts';
import { OrdersStats } from '../../OrdersStats';
import { DRIVER_ORDERS_STATUS } from '../../../../data/orders/ordersTypes';

const DriverOrders = () => {
  const { getDriverRides, loading } = useRidesService();
  const { id } = useParams();
  const [rides, setRides] = useState([]);
  const [driver, setDriver] = useState('');
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [statistics, setStatistics] = useState({});
  const { paramsObject } = useSorting();

  const fetchRides = async (params = paramsObject) => {
    if (id) {
      const { rides, pagination, driver, statistics } = await getDriverRides(id,  { status: "finished", ...params });
      setRides(rides);
      setPagination(pagination);
      setDriver(driver.name);
      setStatistics(statistics);
    }
  };

  return (
    <Orders
      pageTitle="Водії"
      backButton
      pageHeading={`Замовлення водія ${driver}`}
      tableControlsMargin
      tableBody={rides}
      loading={loading}
      maxPage={pagination.lastPage}
      fetchOrders={fetchRides}
      ordersStatus={DRIVER_ORDERS_STATUS}
    >
      <OrdersStats
        loading={loading}
        total={pagination?.total}
        cancelled={statistics?.count_cancelled}
        finished={statistics?.count_finished}
        profit={statistics?.finished_total_cost}
      />
    </Orders>
  );
};

export default DriverOrders;
