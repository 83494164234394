import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getChatLink, getClientOrdersLink } from '../../../data/urls';
import useRedirect from '../../../helpers/hooks/useRedirect';
import notify from '../../../helpers/notify';
import useClientsService from '../../../services/clientsService';

export default function useClient() {
  const [client, setClient] = useState({});
  const supportChatId = client.supportChat;
  const { id } = useParams();
  const redirect = useRedirect();
  const redirectToOrders = () => redirect(getClientOrdersLink(id));

  const redirectToHelpdesk = () => {
    if (!supportChatId) {
      notify('Звернення на службу підтримки відсутні', 'info');
      return;
    } else {
      redirect(getChatLink(supportChatId));
    }
  };

  const { getClient, deleteClientCard, loading } = useClientsService();

  const fetchClient = async () => {
    const client = await getClient(id);
    setClient(client);
  };

  const deleteCard = async (cardId) => {
    await deleteClientCard(id, cardId);
    fetchClient();
  };

  useEffect(() => {
    fetchClient();
  }, []);

  return {
    redirectToHelpdesk,
    redirectToOrders,
    loading,
    client,
    deleteCard,
    id,
    setClient,
  };
}
