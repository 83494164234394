export default class ViewPortCenterDispatcher {
  static DEFAULT_TIMEOUT = 1;

  _uid = null;

  _dispatcher = null;

  _timeoutMinutes = null;

  constructor() {
    this._timeoutMinutes = ((process.env.REACT_APP_CENTER_DISPATCHER_TIMEOUT_MINUTES || ViewPortCenterDispatcher.DEFAULT_TIMEOUT) * 60) * 1000;
  }

  start(dispatcher) {
    if (this._uid) {
      this.destroy();
    }

    if (!this._dispatcher) {
      this._dispatcher = dispatcher;
      this._uid = setInterval(
        this._dispatcher,
        this._timeoutMinutes,
      );
    }
  }

  destroy() {
    if (this._uid) {
      clearInterval(this._uid);
      this._uid = null;
      this._dispatcher = null;
    }
  }
}
