export const checkEmptyInputs = (values, setErrors) => {
  let count = 0;
  for (const key of Object.keys(values)) {
    if (!values[key]) {
      setErrors((prevErrors) => ({ ...prevErrors, [key]: 'Заповніть поле.' }));
      count++;
    }
  }

  return count;
};

export const checkStateEmptyInputs = (values, setErrors) => {
  let count = 0;
  for (const key of Object.keys(values)) {
    if (!values[key] && values[key] !== 0) {
      setErrors({ [key]: 'Заповніть поле.' });
      count++;
    }
  }

  return count;
};
