export function getAddressFromVisicom(place) {
  const {
    street_type,
    street,
    name,
    country,
    settlement,
    postal_code,
    address,
  } = place.properties;
  return {
    id: place.id || ' ',
    address:
      `${street_type ? street_type + ' ' : ''}${street ? street + ', ' : ''}${
        name ? name + ',' : ''
      }${settlement ? settlement + ', ' : ''}${country ? country + ', ' : ''}${
        postal_code || ''
      }${address || ''}`.trim() || ' ',
    longitude: place.geo_centroid.coordinates[0],
    latitude: place.geo_centroid.coordinates[1],
    placeId: place.id || ' ',
  };
}
