import { TECH_SERTIFICATE_INPUTS } from "../../../../../data/drivers/fileInputs/technicalСertificate/techSertificateInputs";
import useGetState from "../../../../../store/hooks/useGetState";
import FileInputField from "../../../../assets/inputs/fileInputs/fileInput-field/FileInputField";
import InfoCategory from "../../infoCategory/InfoCategory";
import classNames from 'classnames'

const TechSertificate = ({ setPhotos, className }) => {
    const { driver } = useGetState();
    const updatedCar = driver?.updateRequest?.car;

    const inputs = TECH_SERTIFICATE_INPUTS.map(input =>
    (<FileInputField
        key={input.id}
        className={'isDriverPage'}
        initialImage={driver.car[input.id]}
        fileUpdated={updatedCar?.[input.name]}
        setPhotos={setPhotos}
        {...input}
    />))

    return (
        <div className={classNames('infoVehicle--techSertificate', className && className)}>
            <InfoCategory text='Фото техпаспорта' color={'blue'} />
            <div className="infoVehicle--techSertificate-inputs">
                {inputs}
            </div>
        </div>
    );
};

export default TechSertificate;
