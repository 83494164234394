import React from 'react';
import PropTypes from 'prop-types';

import './documents.scss';
import PageHeading from '../../../assets/pageHeadings/PageHeading';
import DriverLicense from './driverLicense/DriverLicense';
import InsurancePolicy from './insurancePolicy/InsurancePolicy';
import TechSertificate from '../vehicle/techCertificate/TechSertificate';

const DriverDocuments = ({ setPhotos }) => (
  <div className="infoDocuments-container">
    <PageHeading text="ДОКУМЕНТИ" />
    <div className="infoDocuments">
      <DriverLicense setPhotos={setPhotos} />
      <InsurancePolicy setPhotos={setPhotos} />
      <TechSertificate setPhotos={setPhotos} className="isDriverPage" />
    </div>
  </div>
);

export default DriverDocuments;

DriverDocuments.propTypes = {
  setPhotos: PropTypes.func.isRequired,
};
