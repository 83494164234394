import React from "react";
import Button from "../../assets/buttons/button/Button";
import FormButton from "../../assets/buttons/formButton/FormButton";
import PageHeading from "../../assets/pageHeadings/PageHeading";
import InnerWrapper from "../../assets/wrappers/innerWrapper/InnerWrapper";
import DirectorWrapper from "../section/DirectorWrapper";
import ServiceItem from "./ServiceItem";
import useDirectorServices from "./useDirectorServices";

export default function Services() {
  const { loading, errors, newInputAdded, handleInputChange, addInput, handleUpdate, handleCreate, handleDelete, services } = useDirectorServices();
  return (
    <DirectorWrapper pageHeading='Послуги'>
      <InnerWrapper>
        <PageHeading text='ПОСЛУГИ' className="small marginBottom" />
        <div style={{ width: "35%" }}>
          {services.map(service =>
            <ServiceItem
              key={service.id}
              input={service}
              handleInputChange={handleInputChange}
              errors={errors}
              deleteService={handleDelete}
              loading={loading}
              onBlur={handleUpdate}
            />)}
          <div style={{ width: "242px" }}>
            {!newInputAdded && <Button withIcon={true} text='Додати послугу' fullWidth={true} onClick={addInput} />}
            {newInputAdded && <FormButton text="Зберегти" marginTop="1rem" onClick={handleCreate} />}
          </div>
        </div>
      </InnerWrapper>
    </DirectorWrapper>
  )
}
