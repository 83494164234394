import React from 'react'
import FormInput from '../../assets/inputs/FormInput'
import "./serviceItem.scss"

export default function ServiceItem({ input, handleInputChange, errors, deleteService, loading, onBlur }) {
    const { id, name, type } = input;

    return (
        <div className="serviceItem">
            <FormInput
                id={`${id}-uk`}
                value={name.uk}
                onChange={handleInputChange}
                errorClass={errors[name.uk] ? 'error' : ''}
                customErrorMessage={errors[name.uk] ? errors[name.uk] : ''}
                label="Назва (укр)"
                onBlur={() => onBlur(id, "uk", name.uk)}
            />
            <FormInput
                id={`${id}-en`}
                value={name.en}
                onChange={handleInputChange}
                errorClass={errors[name.en] ? 'error' : ''}
                customErrorMessage={errors[name.en] ? errors[name.en] : ''}
                label="Назва (eng)"
                onBlur={() => onBlur(id, "en", name.en)}
            />
            <FormInput
                id={`${id}-ru`}
                value={name.ru}
                onChange={handleInputChange}
                errorClass={errors[name.ru] ? 'error' : ''}
                customErrorMessage={errors[name.ru] ? errors[name.ru] : ''}
                label="Назва (рус)"
                onBlur={() => onBlur(id, "ru", name.ru)}
            />
            <button disabled={loading} className="clientCard--list-item_delete" onClick={() => deleteService(id, type)}>Видалити</button>
        </div>
    )
}
