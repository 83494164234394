import React from 'react';
import PropTypes from 'prop-types';
import './newOrder.scss';
import InnerWrapper from '../../../assets/wrappers/innerWrapper/InnerWrapper';
import ClientInfo from './clientInfo/ClientInfo';
import FormButtonsWrapper from '../../../assets/buttons/formButtonsWrapper/FormButtonsWrapper';
import Address from './address/Address';
import AdditionalServices from './additionalServices/AdditionalServices';
import Payment from './payment/Payment';
import useNewOrder from './useNewOrder';
import PlanDrive from './planDrive/PlanDrive';
import { Button } from 'antd';
import { useNavigate } from 'react-router';
import AdditionalInfo from './additionalInfo/AdditionalInfo';
import OrderInfo from '../../editOrder/OrderInfo';

const NewOrder = ({ destination, id }) => {
  const navigate = useNavigate();
  const handleClick = () => navigate(-1);
  const { loading, setLoading, handleOrder, order } = useNewOrder(
    destination,
    id,
  );

  return (
    <InnerWrapper>
      <form className="createNewOrder">
        <ClientInfo
          newOrder={order}
          setServicesLoading={setLoading}
          destination={destination}
          orderId={id}
        />
        <div className="newOrderWrap">
          <div className="newOrderColumn">
            <Address order={order} destination={destination} />
          </div>
          <div className="newOrderColumn">
            <PlanDrive order={order} destination={destination}></PlanDrive>
            {destination === 'edit' && <OrderInfo order={order} />}
            <Payment destination={destination} />
          </div>
          <div className="newOrderColumn">
            <AdditionalServices order={order} servicesLoading={loading} />
            <AdditionalInfo />
          </div>
        </div>
        <div className="newOrderControls" style={{ width: '47.5%' }}>
          <FormButtonsWrapper>
            <Button
              onClick={handleOrder}
              size="large"
              type="primary"
              htmlType="button"
              block
            >
              {destination === 'edit'
                ? 'Редагувати замовлення'
                : 'Створити замовлення'}
            </Button>
            <Button
              onClick={handleClick}
              size="large"
              type="primary"
              htmlType="button"
              block
              danger
            >
              Скасувати
            </Button>
          </FormButtonsWrapper>
        </div>
      </form>
    </InnerWrapper>
  );
};

export default NewOrder;

NewOrder.propTypes = {
  destination: PropTypes.string,
  id: PropTypes.string,
};
