import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ToastContainer } from 'react-toastify';
import { ConfigProvider } from 'antd';
import uaUk from 'antd/locale/uk_UA';
import React, { useEffect } from 'react';
import AdminRoutes from './routes/AdminRoutes';
import './styles/index.scss';
import useCentrifuge from './helpers/hooks/useCentrifuge';
import useBindCreators from './store/hooks/useBindCreators';
import { mapActions } from './store/slices/mapSlice';
import { getDefaultLatLng } from './helpers/general/general';
import useMapServices from './services/mapServices';
import LocalStorage from './helpers/general/localStorage';
import { LOCAL_STORE_VIEWPORT_CENTER } from './data/consts';
import { useJsApiLoader } from '@react-google-maps/api';
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapLibs = ["places", 'drawing'];

const App = () => {
  const { centrifugeConnection } = useCentrifuge();
  const { setViewPortCenter, setCenter, setIsLoaded } = useBindCreators(mapActions);
  const { invalidateCurrentCoords } = useMapServices();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY ?? '',
    libraries: mapLibs,
    language: "uk",
    region: "uk",
    nonce: 'google-map-script',
  });

  useEffect(() => {
    setIsLoaded(isLoaded);
  }, [isLoaded]);

  const onWindowClose = () => {
    invalidateCurrentCoords();
  };

  function componentDidMount() {
    let coords = getDefaultLatLng();

    const ls = LocalStorage(LOCAL_STORE_VIEWPORT_CENTER);

    const cache = ls.getState();

    if (cache?.lat && cache?.lng) {
      coords = cache;
    }

    setViewPortCenter(coords);
    setCenter();

    window.addEventListener('beforeunload', onWindowClose);
  }

  useEffect(() => { componentDidMount(); }, []);

  return (
    <div data-testid="test-app">
      <ToastContainer />
      <ConfigProvider locale={uaUk}>
        <AdminRoutes centrifugeConnection={centrifugeConnection} />
      </ConfigProvider>
    </div>
  );
};
export default App;
