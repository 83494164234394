import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './customSelect.scss';
import CustomSelectOption from './CustomSelectOption';
import { hideCustomSelect } from '../../../../helpers/general/general';
import useUrl from '../../../../helpers/hooks/useUrl';

const CustomSelect = ({
  options = [],
  scrollable,
  onClick,
  initialCustomColor,
  className,
  alwaysEnabled = false,
  withoutUrlParams = false,
  searchParam = 'status',
}) => {
  const { paramsObject } = useUrl();
  const [selectText, setSelectText] = useState(
    () => (options[0]?.customText ? options[0]?.customText : options[0]?.text),
  );
  const optionsWithoutActive = options.filter((option) => option?.text !== selectText);
  const [customColor, setCustomColor] = useState(initialCustomColor);
  const [disabled, setDisabled] = useState(false);

  const onOptionClick = (text, name, color, id) => {
    setSelectText(text);
    onClick(name, id);
    if (color) {
      setCustomColor(color);
    }
  };

  const onContainerClick = ({ target }) => {
    if (optionsWithoutActive.length < 1) {
      return;
    }
    const optionsClassList = target.previousSibling.classList;
    const optionsAreActive = optionsClassList.contains('active');
    hideCustomSelect();
    if (optionsAreActive) {
      optionsClassList.remove('active');
    } else {
      optionsClassList.add('active');
    }
  };

  useEffect(() => {
    const closeOnDocumentClick = ({ target }) => {
      if (!target.classList.contains('selected')) {
        hideCustomSelect();
      }
    };

    document.addEventListener('click', closeOnDocumentClick);

    return () => {
      document.removeEventListener('click', closeOnDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (optionsWithoutActive.length < 1 && !alwaysEnabled) {
      setDisabled(true);
    }
  }, [optionsWithoutActive]);

  useEffect(() => {
    if (!withoutUrlParams) {
      !paramsObject[searchParam]
        ? setSelectText(options[0]?.customText ? options[0]?.customText : options[0]?.text)
        : setSelectText(options.find((option) => option.name === paramsObject[searchParam])?.text);
    }
  }, [paramsObject]);

  useEffect(() => {
    if (!selectText) {
      setSelectText(options[0]?.customText ? options[0]?.customText : options[0]?.text);
    }
  }, [options]);

  useEffect(() => {
    setCustomColor(initialCustomColor);
  }, [initialCustomColor]);

  return (
    <div className="select-container">
      <div className={`select-box ${className}`}>
        <div data-testid="test-customSelect" className={`options-container ${scrollable ? 'scrollable' : ''}`}>
          {optionsWithoutActive.map((option) => (
            <CustomSelectOption
              key={option.id}
              onClick={() => {
                onOptionClick(
                  option?.customText ? option?.customText : option.text,
                  option.name,
                  option.color,
                  option.id,
                );
              }}
              {...option}
            />
          ))}
        </div>
        <div className={`selected ${customColor} ${disabled ? 'disabled' : ''}`} onClick={onContainerClick}>
          {selectText}
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  options: PropTypes.arrayOf,
  scrollable: PropTypes.bool,
  onClick: PropTypes.func,
  initialCustomColor: PropTypes.string,
  className: PropTypes.string,
  alwaysEnabled: PropTypes.bool,
  withoutUrlParams: PropTypes.bool,
  searchParam: PropTypes.string,
};
