import "../helpdesk.scss";
import { getColor } from "../../../helpers/getColor";
import { getChatStatus } from "../../../helpers/getStatus";
import useRedirect from "../../../helpers/hooks/useRedirect";
import { getDateFromString } from "../../../helpers/general/general";
import { getChatLink } from "../../../data/urls";

const HelpdeskItem = ({ status, role, id, owner, phoneNumber, createdAt, message }) => {
    const redirect = useRedirect();
    const redirectToChat = () => redirect(getChatLink(id));
    const translatedRole = role === "driver" ? "Водій" : "Клієнт";

    return (
        <tr onClick={redirectToChat}>
            <td className={`helpdesk--status ${getColor(status)}`}>{getChatStatus(status)}</td>
            <td className='helpdesk--user'>{translatedRole}</td>
            <td className='helpdesk--id'>{`#${id}`}</td>
            <td className='helpdesk--name'>{owner}</td>
            <td className='helpdesk--phone'>{phoneNumber}</td>
            <td className='helpdesk--date'>{getDateFromString(createdAt)}</td>
            <td className='helpdesk--message'>{message}</td>
        </tr>
    );
};

export default HelpdeskItem;