import { useLocation, useSearchParams } from 'react-router-dom';

const useUrl = () => {
  const [currentSearchParams] = useSearchParams();
  const { pathname, search } = useLocation();
  const stringUrl = `${pathname}${search}`;
  const currentUrl = getUrl(stringUrl);

  const paramsObject = {};

  for (let entry of currentSearchParams.entries()) {
    if (!paramsObject[entry[0]]) {
      paramsObject[entry[0]] =
        entry[0] === 'fares' || entry[0] === 'drivers'
          ? entry[1].split(',')
          : entry[1];
    }
  }

  function getUrl(url) {
    return new URL(`${process.env.REACT_APP_LOCALHOST}${url}`);
  }

  const searchParams = currentUrl.searchParams;
  const setSearchParam = (param, value) => searchParams.set(param, value);
  const getSearchParam = (param) => searchParams.get(param);
  const removeSearchParam = (param) => searchParams.delete(param);
  const appendSearchParam = (param, value) => searchParams.append(param, value);

  const formatUrl = (url) => {
    const { pathname, search } = url;
    const finalPathname = pathname.replace('/api/', '/');
    const finalUrl = `${finalPathname}${search}`;
    return finalUrl;
  };

  return {
    getUrl,
    formatUrl,
    currentUrl,
    setSearchParam,
    getSearchParam,
    removeSearchParam,
    appendSearchParam,
    stringUrl,
    paramsObject,
  };
};

export default useUrl;
