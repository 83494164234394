import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tariff: {
    commission: '',
    deliveryPrice: '',
    downtimePrice: '',
    freeWaitingMinutes: '',
    id: '',
    increasePriceStep: '',
    intercityPerKilometerPrice: '',
    minimalMinutes: '',
    minimalPrice: '',
    name: {
      uk: '',
      en: '',
      ru: '',
    },
    perKilometerPrice: '',
    perMinutePrice: '',
    radius: '',
    tipsStep: '',
    type: 'mileage',
    services: [],
    icon: '',
    errors: {},
  },
};

const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {
    setTariff(state, action) {
      const { key, value } = action.payload;
      state.tariff[key] = value;
    },
    resetTariff(state) {
      state.tariff = initialState.tariff;
    },
    setTariffErrors(state, action) {
      state.tariff.errors = { ...state.tariff.errors, ...action.payload };
    },
    resetTariffErrors(state) {
      state.tariff.errors = {};
    },
    setFetchedTariff(state, { payload }) {
      state.tariff = { ...initialState.tariff, ...payload };
    },
    setServicePrice(state, { payload }) {
      const { inputId, value } = payload;
      const { services } = state.tariff;
      const targetService = services.find((service) => service.id === inputId);
      const initialServiceIndex = services.indexOf(targetService);

      const newService = {
        ...targetService,
        price: value,
      };

      const servicesCopy = services;
      servicesCopy.splice(initialServiceIndex, 1, newService);

      state.tariff.services = [...servicesCopy];
    },
    setName(state, { payload }) {
      const { language, value } = payload;
      state.tariff.name = {
        ...state.tariff.name,
        [language]: value,
      };
    },
    includeService(state, { payload }) {
      state.tariff.services = [...state.tariff.services, payload];
    },
    excludeService(state, { payload }) {
      state.tariff.services = state.tariff.services.filter((item) => item.id !== payload);
    },
  },
});

const { reducer: tariff, actions } = tariffSlice;
export const tariffActions = actions;
export default tariff;
