import React from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from '../../assets/wrappers/content/ContentWrapper';
import BackButton from '../../assets/buttons/backButton/BackButton';
import TableControls from '../../assets/wrappers/tableControls/TableControls';
import PageHeading from '../../assets/pageHeadings/PageHeading';
import TransparentButton from '../../assets/buttons/transparentButton/TransparentButton';

const DirectorWrapper = ({
  children, pageHeading, buttonText, onButtonClick, additionalControls,
}) => (
  <ContentWrapper pageTitle="Кабінет директора">
    <BackButton />
    <TableControls marginTop>
      <PageHeading text={pageHeading} />
      {buttonText && onButtonClick && (
      <div className="controlsWrapper--buttons">
        <TransparentButton withIcon text={buttonText} onClick={onButtonClick} />
      </div>
      )}
      {additionalControls}
    </TableControls>
    {children}
  </ContentWrapper>
);

export default DirectorWrapper;

DirectorWrapper.propTypes = {
  children: PropTypes.node,
  pageHeading: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  additionalControls: PropTypes.node,
};
