import { useState } from "react";
import notify from "../../../../helpers/notify";
import BlueLinkButton from "../../../assets/buttons/linkButton/BlueLinkButton";
import Textarea from "../../../assets/inputs/textarea/Textarea";
import AddFile from "./AddFile";
import "./chatForm.scss";

const ChatForm = ({ onSubmit }) => {
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);

    const submitForm = (e) => {
        e.preventDefault();

        if (!message && !file) {
            notify("Введіть повідомлення або завантажте зображення", "error");
            return;
        }

        if (message.length > 2048) {
            notify("Максимальна довжина повідомлення - 2048 символів", "error");
            return;
        }

        const data = new FormData();
        if (message) {
            data.append("text", message);
        }

        if (file) {
            data.append("attachment", file);
        }

        onSubmit(data);
        setMessage("");
        setFile(null);
    };

    const uploadImage = (event) => {
        event.preventDefault();
        const { files } = event.target;
        if (files && files.length === 0) {
            return;
        }

        const file = files?.[0];
        const fileReader = new FileReader();

        fileReader.onload = () => setFile(file);
        if (file) {
            fileReader.readAsDataURL(file);
        }
    };

    const onMessageInput = e => setMessage(e.target.value);

    const handleEnterClick = e => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
            submitForm(e);
        }
    };

    return (
        <div className="chatForm">
            <form>
                {!file && <Textarea
                    id='sendChatMessage'
                    message={message}
                    onChange={onMessageInput}
                    onKeyDown={handleEnterClick}
                />}
                <AddFile file={file} uploadImage={uploadImage} />
                <BlueLinkButton
                    id="chat--sendMessage"
                    text="Відправити"
                    onClick={submitForm}
                />
            </form>
        </div>
    );
};

export default ChatForm;