import React from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

const GoogleMap = (props) => {
  const {
    scrollwheel,
    mapTypeControl,
    apiKey,
    zoom,
    center,
    mapTypeId,
    zoomControl,
    fullscreenControl,
    children,
    onInitialize,
  } = props;
  const onMapInitializeHandler = ({ map, maps }) => {
    const instance = { Maps: maps, instance: map };
    GoogleMap.setInstance(instance);
    onInitialize?.(GoogleMap.getInstance());
  };
  const getMapOptions = () => ({
    bootstrapURLKeys: { key: apiKey },
    center,
    zoom,
    defaultZoom: zoom,
    yesIWantToUseGoogleMapApiInternals: true,
    options: {
      disableDefaultUI: true,
      mapTypeId,
      panControl: true,
      zoomControl,
      scrollwheel,
      fullscreenControl,
      mapTypeControl,
      streetViewControl: false,
    },
    onGoogleApiLoaded: onMapInitializeHandler,
  });
  const options = getMapOptions();

  return (
    <GoogleMapReact {...options}>
      { children }
    </GoogleMapReact>
  );
};
GoogleMap.setInstance = (instance) => {
  window.document.$GoogleMapInstance = instance;
};
GoogleMap.getInstance = () => window.document.$GoogleMapInstance;
GoogleMap.defaultProps = {
  mapTypeId: 'roadmap',
  fullscreenControl: true,
  zoomControl: true,
  mapTypeControl: true,
  scrollwheel: true,
  zoom: 10,
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};
GoogleMap.propTypes = {
  zoom: PropTypes.number,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  children: PropTypes.node,
  apiKey: PropTypes.string,
  fullscreenControl: PropTypes.bool,
  zoomControl: PropTypes.bool,
  mapTypeControl: PropTypes.bool,
  scrollwheel: PropTypes.bool,
  mapTypeId: PropTypes.string,
  onInitialize: PropTypes.func,
};

export default GoogleMap;
