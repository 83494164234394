import QueryString from 'querystring';
import {
  MAP_RIDES,
  DIRECTOR_RIDE,
  DIRECTOR_USERS,
  HELPDESK,
  MANAGER_SUPPORT_CHAT,
  MANAGER_USER,
} from './consts';
import { getDriverInfoLink, getUserUpdateLink } from './urls';

export const MANAGER_AUTH = '/manager/authentication';
export const AUTH_ENDPOINT = `${MANAGER_AUTH}/login/authenticate`;
export const WORKSPACE_ENDPOINT = '/manager/workspace/information/get';
export const DRIVERS_ALL = `${MANAGER_USER}/driver/all`;
export const DRIVERS_PENDING = `${MANAGER_USER}/driver/allPending`;
export const RIDE = '/manager/ride';
export const FARES = `${RIDE}/fare/all`;
export const CREATE_FARE = `${DIRECTOR_RIDE}/fare/create`;
export const USERS_ALL = `${MANAGER_USER}/manager/all`;
export const USERS_CREATE = '/director/user/manager/create';
export const GET_ALL_RIDES = `${RIDE}/all`;
export const CLIENTS = `${MANAGER_USER}/client`;
export const CLIENT_CREATE = `${MANAGER_USER}/client/create`;
export const UPDATE_CLIENTS_STATUSES = `${MANAGER_USER}/client/bulkUpdateStatus`;
export const GET_ALL_HELPDESK_CHATS = HELPDESK;
export const ALL_CLIENTS_ENDPOINT = `${CLIENTS}/all`;
export const CREATE_DRIVER_ENDPOINT = `${MANAGER_USER}/driver/create`;
export const GET_SERVICES = `${DIRECTOR_RIDE}/service/all`;
export const CREATE_SERVICE = `${DIRECTOR_RIDE}/service/create`;
export const RESET_PASSWORD_REQUEST = `${MANAGER_AUTH}/resetPassword/request`;
export const RESET_PASSWORD_RESET = `${MANAGER_AUTH}/resetPassword/reset`;
export const GET_ANALYTICS = '/director/statistics/get';
export const CREATE_RIDE = `${RIDE}/create`;
export const ESTIMATE_RIDE = `${RIDE}/estimate`;
export const ESTIMATE_CURRENT_RIDE = `${RIDE}/estimateCurrentRide`;

export const getChatMessagesEndpoint = (id) => `/chat/${id}/messages`;
export const getChatMessageActionEndpoint = (id) => `/chat/${id}/message`;
export const getUpdateChatStatusEndpoint = (id) =>
  `${MANAGER_SUPPORT_CHAT}${id}/updateStatus`;
export const getClientEndpoint = (id) => `${CLIENTS}/${id}/get`;
export const getDeleteClientCardEndpoint = (clientId, cardId) =>
  `${CLIENTS}/${clientId}/card/${cardId}/delete`;
export const getDriverEndpoint = (id) => getDriverInfoLink(id);
export const getDecreaseClientBalanceEndpoint = (id) =>
  `${MANAGER_USER}/client/${id}/decreaseBalance`;
export const getIncreaseClientBalanceEndpoint = (id) =>
  `${MANAGER_USER}/client/${id}/increaseBalance`;

export const getDriverActionEndpoint = (id, action) =>
  `${MANAGER_USER}/driver/${id}/${action}`;
export const getFareEndpoint = (id) => `${RIDE}/fare/${id}/get`;
export const getDeleteFareEndpoint = (id) =>
  `${DIRECTOR_RIDE}/fare/${id}/delete`;
export const getUpdateFareEndpoint = (id) =>
  `${DIRECTOR_RIDE}/fare/${id}/update`;
export const getDriverRidesEndpoint = (id) =>
  `${MANAGER_USER}/driver/${id}/rides`;
export const getClientRidesEndpoint = (id) =>
  `${MANAGER_USER}/client/${id}/rides`;
export const getCancelRideEndpoint = (id) => `${RIDE}/${id}/cancel`;
export const getFinishRideEndpoint = (id) => `${RIDE}/${id}/finish`;
export const getChatAttachmentEndpoint = (id) => `/file/chat/${id}/attachment`;
export const getUserUpdateEndpoint = (id) => getUserUpdateLink(id);
export const getUserGetEnptoint = (id) => `${DIRECTOR_USERS}/manager/${id}/get`;
export const getUserDeleteEndpoint = (id) =>
  `${DIRECTOR_USERS}/manager/${id}/delete`;
export const getServiceUpdateEndpoint = (id) =>
  `${DIRECTOR_RIDE}/service/${id}/update`;
export const getServiceDeleteEndpoint = (id) =>
  `${DIRECTOR_RIDE}/service/${id}/delete`;
export const getDriverUpdateStatusEndpoint = (id) =>
  `${MANAGER_USER}/driver/${id}/updateStatus`;
export const getDriverUpdatePasswordEndpoint = (id) =>
  `${MANAGER_USER}/driver/${id}/updatePassword`;
export const getDriverDelete = (id) => `${MANAGER_USER}/driver/${id}/delete`;
export const getClientUpdateStatusEndpoint = (id) =>
  `${MANAGER_USER}/client/${id}/updateStatus`;
export const getClientUpdateInfoEndpoint = (id) =>
  `${MANAGER_USER}/client/${id}/update`;
export const getDecreaseDriverBalanceEndpoint = (id) =>
  `${MANAGER_USER}/driver/${id}/decreaseBalance`;
export const getIncreaseDriverBalanceEndpoint = (id) =>
  `${MANAGER_USER}/driver/${id}/increaseBalance`;
export const getUpdateRideEndpoint = (id) => `${RIDE}/${id}/update`;
export const getRideEndpoint = (id) => `${RIDE}/${id}/get`;

export const getAllMapRidesEndpoint = (params) => {
  const uriParams = QueryString.stringify(params);

  return `${MAP_RIDES}${uriParams ? `?${uriParams}` : ''}`;
};
