export const getDateFromUrl = (string) => string.split('.');

export const getDurationInMinutes = (duration) => {
  if (duration && typeof duration === 'number') {
    const minutes = parseFloat((duration / 60).toFixed(2));

    return `${minutes.toFixed(2)} хв`;
  }
  return '';
};

export const getWaitDurationInMinutes = (waitDuration = 0) => {
  const value = typeof waitDuration === 'string' ? parseFloat(waitDuration) : waitDuration;
  const minutes = (value / 60);
  return `${minutes} хв`;
}
