import notify from '../helpers/notify';
import {
  getChatMessageActionEndpoint, getChatMessagesEndpoint, getUpdateChatStatusEndpoint, GET_ALL_HELPDESK_CHATS,
} from '../data/endpoints';
import useHttpService from './httpService';

const useHelpdeskService = () => {
  const { request, loading } = useHttpService();

  const getAllChats = async (params) => {
    const chats = await request({ url: GET_ALL_HELPDESK_CHATS, params });
    return chats;
  };

  const getChatMessages = async (id, page) => {
    const messages = await request({ url: getChatMessagesEndpoint(id), params: { page } });
    return messages;
  };

  const sendHelpdeskMessage = async (id, data) => {
    try {
      await request({ url: getChatMessageActionEndpoint(id), method: 'POST', data });
    } catch {
      notify('Повинен бути файл типу: jpeg, jpg, png розміром менше 5 мегабайт', 'error');
    }
  };

  const updateChatStatus = async (id, data) => {
    await request({ url: getUpdateChatStatusEndpoint(id), method: 'POST', data });
    notify('Статус оновлено', 'info');
  };

  return {
    getAllChats, getChatMessages, sendHelpdeskMessage, updateChatStatus, loading,
  };
};

export default useHelpdeskService;
