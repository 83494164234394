import React, { useEffect, useMemo, useState } from 'react';
import {
  first,
  isEmpty,
  isNil,
  last,
  map,
  reject,
  uniqueId,
  values,
} from 'lodash';
import classNames from 'classnames';
import { Button } from 'antd';
import { CheckCircleOutlined, SyncOutlined } from '@ant-design/icons';

import ContentWrapper from '../assets/wrappers/content/ContentWrapper';
import CreateOrderButton from '../orders/createOrder/button/CreateOrderButton';
import TableControls from '../assets/wrappers/tableControls/TableControls';
import {
  CAR_ICONS,
  ORDER_MAPS_TABS,
  RIDE_ICONS,
} from '../../data/ordersMap/ordersMap';
import MapTab from './tab/MapTab';
import useSorting from '../../helpers/hooks/useSorting';
import {
  getIdByIndex,
  polylineToCoords,
  transformDriverRides,
} from '../../helpers/general/general';
import { DRIVER_STATUSES, ORDER_MAPS, RIDE_STATUSES } from '../../data/consts';
import useRedirect from '../../helpers/hooks/useRedirect';
import FiltersButton from '../assets/buttons/filtersButton/FiltersButton';
import Filters from '../assets/filters/Filters';
import Spinner from '../assets/spinner/Spinner';
import OrdersTable from '../orders/table/OrdersTable';
import useGetState from '../../store/hooks/useGetState';
import useBindCreators from '../../store/hooks/useBindCreators';
import { mapActions } from '../../store/slices/mapSlice';
import useMapServices from '../../services/mapServices';
import GoogleMap from './google/map';
import TicketMarker, {
  CarMarker,
  CarMarkerInformation,
  MarkerInformation,
} from './google/marker';
import CarsStreamCollections from '../../helpers/dispatchers/CarsStreamCollections';
import ViewPortCenterDispatcher from '../../helpers/dispatchers/ViewPortCenterDispatcher';
import GoogleMapPolyline from './google/polyline';
import TriggerButton from '../assets/buttons/triggerButton';
import svgIconShowCars from '../../img/icons/cars.svg';
import './styles.scss';
import { useGetActiveRides } from '../../helpers/hooks/useGetActiveRides';
import { useLazyGetActiveDriversInRadiusQuery } from '../../api/driverApi';

const availableStatuses = values(RIDE_STATUSES);
const markerInformationBootstrap = (options) => {
  const { onClose } = options;
  return function MInformation(marker) {
    const {
      id,
      complaint,
      cancelReason,
      scheduledFor,
      arrivedAt,
      approvedAt,
      startedAt,
      finishedAt,
      comment,
      fare,
      services,
      client,
      driver,
      clientPrice,
      fareType,
      paymentType,
      points,
      status,
    } = marker;
    const [position] = points;
    const { latitude, longitude } = position;
    const coords = { lat: +latitude, lng: +longitude };
    const { header, color } = RIDE_ICONS[status];

    return (
      <div key={uniqueId(id)} {...coords}>
        <MarkerInformation
          id={id}
          status={header}
          color={color}
          tariff={fare}
          comment={comment}
          client={client}
          driver={driver}
          car={driver?.car}
          addresses={points}
          services={services}
          time={{
            scheduledFor,
            startedAt: startedAt || approvedAt || arrivedAt,
            finishedAt,
          }}
          complaint={complaint || cancelReason}
          tariffType={fareType === 'mileage' ? 'кілометраж' : 'погодинний'}
          price={clientPrice}
          paymentType={paymentType === 'cash' ? 'готівка' : 'безготівка'}
          onClose={onClose}
        />
      </div>
    );
  };
};
const markerCarInformationBootstrap = (options) => {
  const { onClose } = options;
  return function MCInformation(marker) {
    const {
      id,
      downtime,
      rideId,
      car,
      phoneNumber,
      latitude,
      longitude,
      name,
      rideStatus,
      speed,
      lastCoordinatesUpdated,
    } = marker;
    const coords = { lat: +latitude, lng: +longitude };

    const header = RIDE_ICONS[rideStatus]?.header;
    const color = RIDE_ICONS[rideStatus]?.color;

    return (
      <div key={uniqueId(id)} {...coords}>
        <CarMarkerInformation
          id={rideId}
          name={name}
          car={car}
          speed={speed}
          downtime={downtime}
          phoneNumber={phoneNumber}
          status={header || '...'}
          color={color || '#ff0000'}
          lastUpdated={lastCoordinatesUpdated}
          onClose={onClose}
        />
      </div>
    );
  };
};
const selectedRidePathBootstrap = (options) => (marker) => {
  const { points, polyline } = marker;

  if (isEmpty(polyline) || isEmpty(points) || points.length === 1) return null;

  const markers = map(points || [], ({ latitude, longitude }, index) => (
    <div
      key={uniqueId(index.toString())}
      data-lat={+latitude}
      data-lng={+longitude}
      className="MarkerPath"
    >
      {getIdByIndex(index)}
    </div>
  ));
  const start = first(points);
  const end = last(points);
  const range = {
    start: {
      lat: +start.latitude,
      lng: +start.longitude,
    },
    end: {
      lat: +end.latitude,
      lng: +end.longitude,
    },
  };
  const coords = [
    range.start,
    ...(polylineToCoords(polyline) || []),
    range.end,
  ];

  return { coords, points, markers };
};

const ticketMarkersBuilderBootstrap = (options) => {
  const { activeMarker, excluded, onClick } = options;
  return (markers) => {
    const builder = (data, key) => {
      const { id, points, status, fareType } = data;

      if (excluded?.length && excluded.includes(status)) return null;
      if (!availableStatuses.includes(status)) return null;
      if (points && !points.length) return null;

      const [position] = points;
      const { latitude, longitude } = position;
      const coords = { lat: +latitude, lng: +longitude };
      const { icon, header, color } = RIDE_ICONS[status];
      const isActive = activeMarker && activeMarker?.id === id;

      const onClickHandler = () => {
        onClick?.(data);
      };

      return (
        <div
          key={`markers_${key}`}
          className="MarkerWrapper--Ticket"
          {...coords}
          onClick={onClickHandler}
        >
          <TicketMarker
            id={id}
            active={isActive}
            icon={icon}
            color={color}
            header={header}
            fareType={fareType}
          />
        </div>
      );
    };
    return reject(map(markers || [], builder), isNil);
  };
};
const carsMarkersBuilderBootstrap = (options) => {
  const { activeMarker, onClick } = options;
  return (markers) => {
    const builder = (data, key) => {
      const { id, calling, rideStatus, status } = data;
      const { latitude, longitude } = data;
      const coords = { lat: +latitude, lng: +longitude };
      const { color } = CAR_ICONS[status];
      const header = RIDE_ICONS[rideStatus]?.header;
      const isActive = activeMarker && activeMarker?.id === id;

      const onClickHandler = () => {
        onClick?.(data);
      };

      return (
        <div
          key={`cars_${key}`}
          className="MarkerWrapper--Car"
          {...coords}
          onClick={onClickHandler}
        >
          <CarMarker
            calling={calling}
            active={isActive}
            header={header || '...'}
            color={color}
            coords={coords}
          />
        </div>
      );
    };
    return reject(map(markers || [], builder), isNil);
  };
};

const OrderMap = () => {
  const state = useGetState();
  const redirect = useRedirect();
  const { map: googleMap } = state;

  const { center, initialized, fetched, pendingOrders } = googleMap;
  const { paramsObject } = useSorting();
  const [activeTab, setActiveTab] = useState(first(ORDER_MAPS_TABS));
  const [isMapReady, setMapReady] = useState(false);
  const [isViewPortChanging, setViewPortChanging] = useState(false);
  const [isCarsVisible, setCarsVisibility] = useState(true);
  const [
    fetchActiveDrivers,
    { isLoading, isFetching, isSuccess, fulfilledTimeStamp },
  ] = useLazyGetActiveDriversInRadiusQuery();
  const [cars, setCars] = useState([]);
  const [activeMarker, setActiveMarker] = useState();
  const [activeCarMarker, setActiveCarMarker] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const { setTabName, clear, setViewPortCenter, setCenter, destructor, init } =
    useBindCreators(mapActions);
  const { setCurrentCoords, invalidateCurrentCoords } = useMapServices();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (center.lat && center.lng && firstLoad) {
      fetchActiveDrivers({ latitude: center.lat, longitude: center.lng }).then(
        ({ data }) => {
          setCars(data?.drivers ?? []);
          setFirstLoad(false);
        },
      );
    }
  }, [center.lat && center.lng, firstLoad]);

  const componentDidMount = async () => {
    if (!initialized) {
      init();
      setTabName(activeTab?.name);
      setCurrentCoords();
    }
  };
  const componentWillUnmount = () => {
    destructor();
    invalidateCurrentCoords();
  };

  const calcAllTicketMarkers = () => {
    // маркеры поездок
    const markersBuilder = OrderMap.markersBuilderBootstrap({
      activeMarker,
      excluded: [
        RIDE_STATUSES.APPROVED,
        RIDE_STATUSES.ARRIVED,
        RIDE_STATUSES.ASSIGNED,
        RIDE_STATUSES.CANCELLED,
        RIDE_STATUSES.FINISHED,
        RIDE_STATUSES.PAUSED,
        RIDE_STATUSES.RESUMED,
        RIDE_STATUSES.STARTED,
        RIDE_STATUSES.UNASSIGNED,
      ],
      onClick: onMarkerClick,
    });
    const markers = markersBuilder(pendingOrders);

    // путь выбранной поездки ;
    const selectedRidePath = OrderMap.selectedRidePath();
    const path = activeMarker ? selectedRidePath(activeMarker) : null;

    // информация выбранной поездки ;
    const selectedMarkerInfo = OrderMap.markerInformationBootstrap({
      onClose: onCloseMarkerInfo,
    });
    const markerInfo = activeMarker ? selectedMarkerInfo(activeMarker) : null;

    return reject(
      [
        markerInfo,
        ...(markers || []),
        ...(path?.markers || []),
        path?.coords ? (
          <GoogleMapPolyline
            key={`polyline_${activeMarker?.id}`}
            coords={path?.coords}
          />
        ) : null,
      ],
      isNil,
    );
  };
  const calcAllCarsMarkers = () => {
    const visibleCars = isCarsVisible
      ? cars
      : cars.filter((car) => car.status === DRIVER_STATUSES.FREE);

    const selectedCar = cars.find((car) => car.id === activeCarMarker?.id);
    // иформация о выбранной машине/водителе
    const selectedCarMarkerInfo = OrderMap.markerCarInformationBootstrap({
      onClose: onCloseCarMarkerInfo,
    });

    const carInfo = activeCarMarker
      ? selectedCarMarkerInfo(selectedCar ?? activeCarMarker)
      : null;

    // cars
    const carsBootstrap = OrderMap.carsMarkersBuilderBootstrap({
      activeMarker: activeCarMarker,
      onClick: onCarMarkerClick,
    });
    const carsMarkers = carsBootstrap(visibleCars);

    return reject([carInfo, ...(carsMarkers || [])], isNil);
  };

  const onChangeViewPort = (mode) => {
    onCloseMarkerInfo();
    onCloseCarMarkerInfo();
    setViewPortChanging(mode);
  };
  const onChangeCarsVisibility = (mode) => {
    onCloseCarMarkerInfo();
    setCarsVisibility(mode);
  };
  const onCircleDragStart = (position) => {
    invalidateCurrentCoords();
  };
  const onCircleDragEnd = (position) => {
    setViewPortCenter(position);
    setCenter(position);
    setViewPortChanging(false);
    setCurrentCoords();
  };

  const onResetTab = () => {
    setActiveTab(null);
    redirect(ORDER_MAPS);
    setShowFilters(false);
    setTabName(null);
  };

  const onTabSelect = (tab) => {
    if (activeTab && activeTab?.id === tab?.id) return;

    clear();
    setActiveTab(tab);
  };
  const onFilterButtonClick = () => setShowFilters(!showFilters);
  const onCarsStreamUpdated = () => {
    const cars_ = OrderMap.CarsStream.getData();
    setCars(cars_);
  };

  const CarsStreamEvents = {
    constructor: () => {
      OrderMap.CarsStream.addEventListener(
        CarsStreamCollections.events.updated,
        onCarsStreamUpdated,
      );
      OrderMap.ViewPortCenterDispatcher.start(setCurrentCoords);
    },
    destructor: () => {
      OrderMap.CarsStream.removeEventListener(
        CarsStreamCollections.events.updated,
        onCarsStreamUpdated,
      );
      OrderMap.ViewPortCenterDispatcher.destroy();
    },
  };
  const onMarkerClick = (marker) => {
    onCloseCarMarkerInfo();
    setActiveMarker(marker);
  };
  const onCloseMarkerInfo = () => {
    setActiveMarker(null);
  };
  const onCarMarkerClick = (marker) => {
    onCloseMarkerInfo();
    setActiveCarMarker(marker);
  };
  const onCloseCarMarkerInfo = () => {
    setActiveCarMarker(null);
  };
  const onGoogleMapReady = () => {
    setMapReady(true);
  };

  useEffect(() => {
    componentDidMount();
    return () => {
      componentWillUnmount();
    };
  }, []);
  useEffect(() => {
    !isViewPortChanging
      ? CarsStreamEvents.constructor()
      : CarsStreamEvents.destructor();

    return () => {
      CarsStreamEvents.destructor();
    };
  }, [isViewPortChanging]);

  const activeRides = useGetActiveRides(paramsObject);

  const tabs = map(ORDER_MAPS_TABS, (tab) => (
    <MapTab
      {...tab}
      key={tab.id}
      onClick={() => onTabSelect(tab)}
      count={activeRides[tab.name]?.total}
      className={activeTab?.name === tab.name ? 'active' : ''}
    />
  ));

  // данные в таблице
  const tableData = useMemo(
    () => OrderMap.parseTableData(activeRides[activeTab?.name].list),
    [activeRides, activeTab?.name],
  );

  const ticketMarkers = useMemo(() => {
    if (!isMapReady) return;

    return !isViewPortChanging ? calcAllTicketMarkers() : null;
  }, [isMapReady, isViewPortChanging, activeMarker, pendingOrders]);

  const carsMarkers = useMemo(() => {
    if (!isMapReady) return;

    return !isViewPortChanging ? calcAllCarsMarkers() : null;
  }, [isMapReady, isViewPortChanging, activeCarMarker, isCarsVisible, cars]);

  const handleRefetchDrivers = async () => {
    try {
      const { data } = await fetchActiveDrivers({
        latitude: center.lat,
        longitude: center.lng,
      });
      setCars(data?.drivers ?? []);
    } catch (error) {
      console.error(error);
    }
  };

  const showSuccess =
    isSuccess &&
    !!fulfilledTimeStamp &&
    fulfilledTimeStamp + 10000 > Date.now();

  return (
    <ContentWrapper
      className={`OrderMap page__map ${
        activeTab ? `OrderMap--${activeTab.name}` : 'OrderMap--collapsed'
      } ${showFilters ? 'OrderMap--filters' : ''}`}
      pageTitle="Карта замовлень"
      additionalControls={showFilters && <Filters showFilters={showFilters} />}
      pageControls={
        !!initialized && (
          <div className="toolbar__map">
            <div key="toolbar" className="Toolbar">
              <div>
                <CreateOrderButton disabled={!initialized} />
              </div>
              {activeTab && (
                <FiltersButton
                  key="filters"
                  onClick={onFilterButtonClick}
                  filled={showFilters}
                />
              )}
              <TriggerButton
                key="cars"
                face={{
                  on: <img src={svgIconShowCars} alt="show cars" />,
                  off: <img src={svgIconShowCars} alt="show cars" />,
                }}
                className="ViewCarsTrigger"
                initialState={isCarsVisible}
                onClick={onChangeCarsVisibility}
              />
              <Button
                loading={isLoading || isFetching}
                className={classNames(
                  'RefetchDriversTrigger',
                  isLoading || (isFetching && 'RefetchDriversTrigger--loading'),
                )}
                onClick={handleRefetchDrivers}
                disabled={isLoading || isFetching}
                size="large"
                icon={showSuccess ? <CheckCircleOutlined /> : <SyncOutlined />}
              >
                {showSuccess ? 'Оновлено успішно' : 'Оновити водіїв'}
              </Button>
            </div>
          </div>
        )
      }
    >
      {!initialized ? (
        <Spinner />
      ) : (
        <>
          <div
            className={`ordersMap--contentWrapper ${
              activeTab?.name ? 'collapsed' : ''
            }`}
          >
            <div className="table-wrapper">
              <TableControls>
                <div key="tabs" className="Tabs">
                  {tabs}
                </div>
              </TableControls>
              <div className="ordersMap--table">
                <OrdersTable
                  loading={fetched}
                  tableBody={tableData}
                  showFilters={showFilters}
                  size="small"
                  showOnePage={false}
                />
              </div>
            </div>
            <div className="ordersMap--map collapsed">
              <GoogleMap center={center} onInitialize={onGoogleMapReady}>
                {ticketMarkers}
                {carsMarkers}
              </GoogleMap>
            </div>
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

OrderMap.ViewPortCenterDispatcher = new ViewPortCenterDispatcher();
OrderMap.createUriParams = ({ tab, page }) => {
  const params = [{ param: 'page', value: page }];
  if (tab) params.push({ param: 'tab', value: tab });
  return params;
};
OrderMap.parseTableData = transformDriverRides;
OrderMap.markersBuilderBootstrap = ticketMarkersBuilderBootstrap;
OrderMap.selectedRidePath = selectedRidePathBootstrap;
OrderMap.markerInformationBootstrap = markerInformationBootstrap;
OrderMap.markerCarInformationBootstrap = markerCarInformationBootstrap;
OrderMap.carsMarkersBuilderBootstrap = carsMarkersBuilderBootstrap;

OrderMap.CarsStream = CarsStreamCollections.singleton();

export default OrderMap;
