import {
  CREATE_FARE,
  FARES,
  getDeleteFareEndpoint,
  getFareEndpoint,
  getUpdateFareEndpoint,
} from '../data/endpoints';
import useHttpService from './httpService';
import notify from '../helpers/notify';
import useBindCreators from '../store/hooks/useBindCreators';
import { tariffActions } from '../store/slices/tariffSlice';
import useRedirect from '../helpers/hooks/useRedirect';
import { DIRECTOR } from '../data/consts';

const useFaresService = () => {
  const { request, loading } = useHttpService();
  const { setTariffErrors } = useBindCreators(tariffActions);
  const redirect = useRedirect();

  const getAllFares = async () => {
    const { fares } = await request({ url: FARES });
    const transformedTariffs = transformFares(fares);
    return transformedTariffs;
  };

  const getFare = async (id) => {
    const fare = await request({ url: getFareEndpoint(id) });
    return fare;
  };

  const deleteFare = async (id) => {
    await request({ url: getDeleteFareEndpoint(id), method: 'DELETE' });
    notify('Тариф успішно видалено', 'info');
  };

  const updateFare = async (id, data) => {
    try {
      await request({ url: getUpdateFareEndpoint(id), method: 'POST', data });
      notify('Тариф успішно змінено', 'info');
    } catch (err) {
      setTariffErrors(err.response.data.errors);
      notify('Перевірте таблицю', 'error');
    }
  };

  const createFare = async (unitId, data) => {
    try {
      await request({
        url: CREATE_FARE,
        method: 'POST',
        data: { ...data, unit_id: unitId },
      });
      notify('Тариф успішно створено', 'info');
      redirect(DIRECTOR);
    } catch (err) {
      setTariffErrors(err.response.data.errors);
      notify('Перевірте таблицю', 'error');
    }
  };

  const transformFares = (initialFares) =>
    initialFares.map(({ id, name, type, unit, icon }) => ({
      id,
      name,
      type,
      unit,
      icon,
    }));

  return {
    getAllFares,
    getFare,
    deleteFare,
    updateFare,
    createFare,
    loading,
  };
};

export default useFaresService;
