import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const toastSettings = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: false,
  theme: 'colored',
};

export const transformErrorResponse = (error) => {
  if (error?.data?.message) {
    return error.data.message;
  }
  if (error?.data?.errors?.name && Array.isArray(error?.data?.errors?.name)) {
    return error?.data?.errors?.name.join('\n');
  }

  if (typeof error?.data?.error === 'string') {
    return error?.data?.error;
  }

  if (typeof error === 'string') {
    return error;
  }

  return 'Щось пішло не так';
};

const notify = (message, type) => {
  if (type === 'info') {
    toast.info(transformErrorResponse(message), toastSettings);
  } else if (type === 'error') {
    toast.error(transformErrorResponse(message), toastSettings);
  } else {
    toast.success(transformErrorResponse(message), toastSettings);
  }
};

export default notify;
