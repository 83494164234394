import { Buffer } from 'buffer';
import useHttpService from './httpService';

const useFilesService = () => {
  const { request, loading } = useHttpService();
  const urlBase = '/file/driver';
  const urlBaseUpdate = '/file/driverUpdateRequest';

  const getBase64Image = async (url) => {
    const binary = await request({ url, responseType: 'arraybuffer' });
    const base64 = Buffer.from(binary, 'binary').toString('base64');
    return base64;
  };

  const getFile = (url, isUpdateRequested = false) => getBase64Image(`${isUpdateRequested ? urlBaseUpdate : urlBase}/${url}`);

  const getphoto = (id, isUpdateRequested) => getFile(`${id}/photo`, isUpdateRequested);
  const getLicenseFront = (id, isUpdateRequested) => getFile(`${id}/licenseFrontPhoto`, isUpdateRequested);
  const getLicenseBack = (id, isUpdateRequested) => getFile(`${id}/licenseBackPhoto`, isUpdateRequested);
  const getInsurancePolicy = (id, isUpdateRequested) => getFile(`${id}/carInsurancePolicyPhoto`, isUpdateRequested);
  const getRegistrationCertificateFront = (id, isUpdateRequested) => getFile(`${id}/carFrontRegistrationCertificatePhoto`, isUpdateRequested);
  const getRegistrationCertificateBack = (id, isUpdateRequested) => getFile(`${id}/carBackRegistrationCertificatePhoto`, isUpdateRequested);
  const getCarFront = (id, isUpdateRequested) => getFile(`${id}/carFrontPhoto`, isUpdateRequested);
  const getCarBack = (id, isUpdateRequested) => getFile(`${id}/carBackPhoto`, isUpdateRequested);
  const getCarLeft = (id, isUpdateRequested) => getFile(`${id}/carLeftPhoto`, isUpdateRequested);
  const getCarRight = (id, isUpdateRequested) => getFile(`${id}/carRightPhoto`, isUpdateRequested);
  const getcarFrontSeats = (id, isUpdateRequested) => getFile(`${id}/carFrontSeatsPhoto`, isUpdateRequested);
  const getcarBackSeats = (id, isUpdateRequested) => getFile(`${id}/carBackSeatsPhoto`, isUpdateRequested);

  return {
    getBase64Image,
    getphoto,
    getLicenseFront,
    getLicenseBack,
    getInsurancePolicy,
    getRegistrationCertificateFront,
    getRegistrationCertificateBack,
    getCarFront,
    getCarBack,
    getCarLeft,
    getCarRight,
    getcarFrontSeats,
    getcarBackSeats,
    loading,
  };
};

export default useFilesService;
