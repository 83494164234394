import { getRideStatus } from '../../helpers/getRideStatus';

export const ORDERS_TYPE = [
  {
    id: 'orders_type_all',
    name: 'all',
    text: 'Тип поїздки: усі',
  },
  {
    id: 'orders_type_by_time',
    name: 'scheduled',
    text: 'На назначений час',
  },
  {
    id: 'orders_type_asap',
    name: 'forNow',
    text: 'На зараз',
  },
];

export const ORDERS_STATUS = [
  {
    id: 'orders_status_all',
    name: 'all',
    text: getRideStatus('all'),
  },
  {
    id: 'orders_status_cancelled',
    name: 'cancelled',
    text: getRideStatus('cancelled'),
  },
  // {
  //     id: 'orders_status_approved',
  //     name: 'approved',
  //     text: getRideStatus('approved')
  // },
  // {
  //     id: 'orders_status_arrived',
  //     name: 'arrived',
  //     text: getRideStatus('arrived')
  // },
  // {
  //     id: 'orders_status_started',
  //     name: 'started',
  //     text: getRideStatus('started')
  // },
  // {
  //     id: 'orders_status_paused',
  //     name: 'paused',
  //     text: getRideStatus('paused')
  // },
  // {
  //     id: 'orders_status_resumed',
  //     name: 'resumed',
  //     text: getRideStatus('resumed')
  // },
  {
    id: 'orders_status_finished',
    name: 'finished',
    text: getRideStatus('finished'),
  },
  // {
  //     id: 'orders_status_pending',
  //     name: 'pending',
  //     text: getRideStatus('pending')
  // },
];

export const DRIVER_ORDERS_STATUS = [
  {
    id: 'orders_status_finished',
    name: 'finished',
    text: getRideStatus('finished'),
  },
  {
    id: 'orders_status_cancelled',
    name: 'cancelled',
    text: getRideStatus('cancelled'),
  },
];
