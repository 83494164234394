import { useEffect, useState } from "react";
import ContentWrapper from "../assets/wrappers/content/ContentWrapper";
import TableControls from "../assets/wrappers/tableControls/TableControls";
import CustomSelect from "../assets/inputs/customSelect/CustomSelect";
import { HELPDESK_STATUS } from "../../data/helpdesk/heldeskTable";
import HelpdeskTable from "./table/HelpdeskTable";
import useHelpdeskService from "../../services/helpdeskService";
import { DEFAULT_PAGINATION } from "../../data/consts";
import useFiltrationDropdowns from "../../helpers/hooks/useFiltrationDropdowns";

const Helpdesk = () => {
    const { getAllChats, loading } = useHelpdeskService();
    const [chats, setChats] = useState([]);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
    const { changeStatus, paramsObject } = useFiltrationDropdowns(fetchChats);

    async function fetchChats(params = paramsObject) {
        const { chats, pagination } = await getAllChats(params);
        setChats(chats);
        setPagination(pagination);
    }

    useEffect(() => {
        fetchChats();
    }, []);

    return (
        <ContentWrapper pageTitle="Служба підтримки">
            <TableControls>
                <CustomSelect options={HELPDESK_STATUS} onClick={changeStatus} />
            </TableControls>
            <HelpdeskTable
                tableBody={chats}
                maxPage={pagination.lastPage || 1}
                onPageChange={fetchChats}
                loading={loading}
            />
        </ContentWrapper>
    );
};

export default Helpdesk;