import React from 'react';
import PropTypes from 'prop-types';
import MapWrapper from "../../../../maps/mapComponent/MapWrapper";
import "./routeModalMap.scss";

const RouteModalMap = ({ points }) => {
  const route = points.map(point => ({
    location: {
      lat: +point.latitude,
      lng: +point.longitude
    },
    stopover: true
  }));

  return (
    <div className="routeModalMap">
      {points.length > 0 && <MapWrapper
        initialZoom={12}
        routeStart={route[0].location}
        routeEnd={route[route.length - 1].location}
        routeWaypoints={route.slice(1, [route.length - 1])}
        drawRoute={true}
        strokeColor="#0B0B0B"
        initialCenter={route[0].location}
      />}
    </div>
  );
};

export default RouteModalMap;

RouteModalMap.propTypes = {
  points: PropTypes.arrayOf
}
