import "./refreshIcon.scss";
import { ReactComponent as UpdateIcon } from "../../../../img/icons/update_icon.svg";

const RefreshIcon = ({ onClick }) =>
    <div className="refreshIcon" onClick={onClick} title="Скинути фільтри">
        {/* <span className="refreshIcon--info">12</span> */}
        <div className="refreshIcon--icon">
            <UpdateIcon />
        </div>
    </div>

export default RefreshIcon;