import styles from './tariffTable.module.scss';
import React, { useEffect, useState } from 'react';
import TariffName from './name/TariffName';
import TariffParams from './params/TariffParams';
import TariffServices from './tariffServices/TariffServices';
import FormButtonsWrapper from '../../../assets/buttons/formButtonsWrapper/FormButtonsWrapper';
import FormButton from '../../../assets/buttons/formButton/FormButton';
import DeleteTariffModal from '../modals/DeleteTariffModal';
import TariffSwitch from './switch/TariffSwitch';
import Carousel from './carousel/Carousel';
import PageHeading from '../../../assets/pageHeadings/PageHeading';
import Spinner from '../../../assets/spinner/Spinner';
import { getRequestBody, getRequestBodyErrors } from './getTariffTableBody';
import { Select } from 'antd';

const TariffTable = ({
  fetchFares,
  updateFare,
  createFare,
  loading,
  services,
  setTariffErrors,
  resetTariffErrors,
  resetTariff,
  setAllTariffs,
  tariff,
  includeService,
  excludeService,
  unitsLoading,
  unitsOptions,
  selectedUnit,
  handleSelectUnit,
  activeSlide,
  changeTariff,
  tariffsOfUnit,
}) => {
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const tariffCreation = !tariff?.id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    resetTariffErrors();
    const body = getRequestBody(tariff, tariffCreation);
    const errorsInBody = getRequestBodyErrors(
      { ...body, ...body.name },
      setTariffErrors,
    );

    let servicesErrors = 0;
    for (const service of body.services) {
      if (!service.price.toString()) {
        setTariffErrors({ [service.name.uk]: 'Заповніть поле.' });
        servicesErrors++;
      }
    }

    if (!errorsInBody && servicesErrors < 1) {
      const name = [];

      for (const language in body.name) {
        name.push({ locale: language, content: body.name[language] });
      }

      body.services = body.services.map(({ id, price }) => ({ id, price }));
      body.name = name;

      if (tariffCreation) {
        await createFare(selectedUnit, body);
        return;
      }

      await updateFare(tariff.id, {
        ...body,
        unit_id: selectedUnit,
      });
      await fetchFares();
    }
  };

  const resetFormOnExit = () => {
    resetTariffErrors();
    resetTariff();
    setAllTariffs([]);
  };

  useEffect(() => {
    return resetFormOnExit;
  }, []);

  return (
    <>
      <section className={styles.sectionUnits}>
        <PageHeading text="ВІДДІЛЕННЯ" className="small" />
        <Select
          loading={unitsLoading}
          onSelect={handleSelectUnit}
          options={unitsOptions}
          value={selectedUnit}
          size="large"
          className={styles.unitsSelect}
          placeholder="Виберіть відділення"
        />
      </section>
      {selectedUnit ? (
        <>
          <PageHeading text="ТАРИФИ" className="small marginBottom" />
          <Carousel
            activeSlide={activeSlide}
            changeTariff={changeTariff}
            tariffsOfUnit={tariffsOfUnit}
          />
          {!loading && (
            <>
              <TariffSwitch />
              <form className="tariffForm">
                <fieldset>
                  <TariffName />
                  <TariffParams tariff={tariff} />
                  <TariffServices
                    services={services}
                    tariff={tariff}
                    includeService={includeService}
                    excludeService={excludeService}
                  />
                  <div className="newOrderControls">
                    <FormButtonsWrapper>
                      <FormButton
                        text={
                          tariffCreation ? 'Створити тариф' : 'Редагувати тариф'
                        }
                        type="submit"
                        onClick={handleSubmit}
                      />
                      <FormButton
                        text="Видалити тариф"
                        color="white"
                        type="button"
                        onClick={() => setModalIsVisible(true)}
                      />
                    </FormButtonsWrapper>
                  </div>
                </fieldset>
              </form>
              <DeleteTariffModal
                show={modalIsVisible}
                onHide={() => setModalIsVisible(false)}
                tariff={tariff}
              />
            </>
          )}
        </>
      ) : (
        <p className={styles.noUnit}>Оберіть відділення</p>
      )}
      {loading && <Spinner />}
    </>
  );
};

export default TariffTable;
