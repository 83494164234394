import { PASSWORD_PATTERN } from '../consts';

export const NEW_USER_INFO_FORM_COL_1 = [
  {
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    placeholder: 'Іван',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ]{1,64}$',
    label: 'ІМʼЯ',
  },
  {
    id: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'email@mail.com',
    errorMessage: 'Невалідна електронна адреса.',
    label: 'ЛОГІН',
  },
];

export const NEW_USER_INFO_FORM_COL_2 = [
  {
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    placeholder: 'Іванович',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ]{1,64}$',
    label: 'ПРІЗВИЩЕ',
  },
  {
    id: 'password',
    name: 'password',
    type: 'password',
    placeholder: '******',
    errorMessage: 'Має бути 8-16 символів, містити щонайменше 1 цифру, 1 велику букву і 1 спецсимвол',
    label: 'ПАРОЛЬ',
    pattern: PASSWORD_PATTERN,
  },
];

export const NEW_USER_INFO_FORM_COL_3 = [
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    type: 'text',
    placeholder: '+380501231212',
    errorMessage: 'Формат: +380xxxxxxxxx',
    label: 'ТЕЛЕФОН',
  },
];

export const NEW_USER_CHECKBOXES = [
  {
    id: 'checkbox-admin',
    label: 'Диспетчер',
    position: 'dispatcher',
  },
  {
    id: 'checkbox-director',
    label: 'Директор',
    position: 'director',
  },
];
