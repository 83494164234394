import { getLinkWithSearchParams } from "../../helpers/general/general";
import { ORDER_MAPS, ACTIVE_DRIVERS, CLIENTS, DIRECTOR, DRIVERS_BASE, HELPDESK, ORDERS } from "../consts";
import { ReactComponent as SidebarOrders } from "../../img/icons/sidebar/sidebar_orders.svg";
import { ReactComponent as SidebarClients } from "../../img/icons/sidebar/sidebar_clients.svg";
import { ReactComponent as SidebarDrivers } from "../../img/icons/sidebar/sidebar_drivers.svg";
import { ReactComponent as SidebarHelp } from "../../img/icons/sidebar/sidebar_help.svg";
import { ReactComponent as SidebarDirector } from "../../img/icons/sidebar/sidebar_director.svg";
import { ReactComponent as SidebarMap } from "../../img/icons/sidebar/sidebar_map.svg";

const activeColor = '#0081FF';
const defaultColor = '#FDFDFD';

export const SIDEBAR_ICONS = [
    {
        id: 1,
        icon: <SidebarMap fill={defaultColor} />,
        activeIcon: <SidebarMap fill={activeColor} />,
        navigateTo: getLinkWithSearchParams(ORDER_MAPS),
        title: 'Карта',
        active: 'maps'
    },
    {
        id: 2,
        icon: <SidebarClients fill={defaultColor} />,
        activeIcon: <SidebarClients fill={activeColor} />,
        navigateTo: getLinkWithSearchParams(CLIENTS, true),
        title: 'Клієнти',
        active: ['clients', 'client']
    },
    {
        id: 3,
        icon: <SidebarDrivers fill={defaultColor} />,
        activeIcon: <SidebarDrivers fill={activeColor} />,
        navigateTo: getLinkWithSearchParams(ACTIVE_DRIVERS),
        title: 'Водії',
        active: DRIVERS_BASE
    },
    {
        id: 4,
        icon: <SidebarOrders fill={defaultColor} />,
        activeIcon: <SidebarOrders fill={activeColor} />,
        navigateTo: getLinkWithSearchParams(ORDERS),
        title: 'Замовлення',
        active: ['orders/all', 'orders/create', 'orders/']
    },
    {
        id: 5,
        icon: <SidebarHelp fill={defaultColor} />,
        activeIcon: <SidebarHelp fill={activeColor} />,
        navigateTo: getLinkWithSearchParams(HELPDESK),
        title: 'Підтримка',
        active: 'supportChat'
    }
];

export const SIDEBAR_DIRECTOR_ICONS = [
    {
        id: 6,
        icon: <SidebarDirector fill={defaultColor} />,
        activeIcon: <SidebarDirector fill={activeColor} />,
        navigateTo: DIRECTOR,
        title: 'Кабінет директора',
        active: 'director'
    }
];
