import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useDriversService from "../../../services/driversService";
import useBindCreators from "../../../store/hooks/useBindCreators";
import { driverActions } from "../../../store/slices/driverSlice";
import useGetState from "../../../store/hooks/useGetState";
import { ALL_INPUTS } from "../../../store/slices/driverSlice";

export const withDriverInfo = (WrappedComponent) => {
    const WithDriver = (props) => {
        const { driver } = useGetState();
        const { updateRequest } = driver;
        const { setFetchedDriver, setIsLoading } = useBindCreators(driverActions);
        const { id } = useParams();
        const { getDriver } = useDriversService();
        const [initialDriver, setInitialDriver] = useState({});

        const checkUpdateRequest = (current = initialDriver, requested = updateRequest) => {
            for (const key in requested) {
                const currentValue = current[key];
                const updatedValue = requested[key];
                if (typeof currentValue !== 'object' && currentValue !== updatedValue) {
                    addYellowClassToInput(key);
                }

                if (typeof currentValue === 'object' && currentValue !== updatedValue) {
                    checkUpdateRequest(currentValue, updatedValue);
                }
            }
        };

        const fetchDriver = async () => {
            setIsLoading(true);
            const fetchedDriver = await getDriver(id);

            setInitialDriver(fetchedDriver);
            if (fetchedDriver.updateRequest) {
                const updatedDriver = getUpdatedDriver(fetchedDriver, fetchedDriver.updateRequest);
                setFetchedDriver(updatedDriver);
                return;
            }

            setFetchedDriver(fetchedDriver);
            setIsLoading(false);
        };

        const addYellowClassToInput = (name) => {
            const inputs = ALL_INPUTS.find(array => array.find(input => input.name === name));
            inputs?.forEach(input => input.className = 'yellow');
        };

        const getUpdatedDriver = (initial, updated) => {
            const updatedDriver = {
                ...initial,
                ...updated,
                car: { ...initial.car, ...updated.car },
                driverLicense: { ...initial.driverLicense, ...updated.driverLicense }
            };

            return updatedDriver;
        };

        useEffect(() => {
            fetchDriver();
            return () => ALL_INPUTS.forEach(array => array.forEach(input => input.className = ''));
        }, []);

        useEffect(() => {
            if (initialDriver && updateRequest) {
                checkUpdateRequest();
            }
        }, [initialDriver, updateRequest]);

        return <WrappedComponent {...props} />;
    };

    return WithDriver;
};
