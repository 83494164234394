import "./charts.scss";
import ChartItem from "./item/ChartItem";
import Spinner from "../../../assets/spinner/Spinner";

const charts = [
    {
        id: 1,
        name: 'Дохід',
        type: 'income',
    },
    {
        id: 2,
        name: 'Поїздок',
        type: 'ridesCount',
    },
    {
        id: 3,
        name: 'Відмов',
        type: 'cancelledRidesCount',
    }
];

const Charts = ({ analytics, loading }) => {
    let analyticsArr = [];

    for (const date in analytics?.income?.values) {
        const analyticsItem = {
            income: analytics?.income?.values[date]?.value,
            ridesCount: 0,
            cancelledRidesCount: 0
        };
        analyticsItem.ridesCount = analytics?.ridesCount?.values[date]?.value;
        analyticsItem.cancelledRidesCount = analytics?.cancelledRidesCount?.values[date]?.value;
        analyticsArr.push(analyticsItem);
    }

    return (
        <div className="charts--wrapper">
            {charts.map(chart => (<ChartItem key={chart.id} analytics={analytics} {...chart} data={analyticsArr} />))}
            {loading && <div style={{ position: "absolute", left: "50%", top: "60%" }}>
                <Spinner />
            </div>}
        </div>
    );
};

export default Charts;