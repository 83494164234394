import React, { useCallback } from "react";

import { getEditOrderLink } from '../../../../../data/urls';
import useRedirect from '../../../../../helpers/hooks/useRedirect';
import Button from '../../../../assets/buttons/button/Button';
import StepsList from "./StepsList";

import "./userRoute.scss";

const UserRoute = ({ points, client, orderId, onShowCancelModal }) => {
  const redirect = useRedirect();

  const handleEditClick = useCallback(() => {
    redirect(getEditOrderLink(orderId));
  }, [orderId]);

  const handleCloseModal = () => {
    onShowCancelModal(true)
  };

  return (
    <div className="userRoute">
      <div className="userRoute--header">МАРШРУТ КЛІЄНТА</div>
      <div className="userRoute--clientName">{client}</div>
      <div className="userRoute--routeSteps">
        <StepsList points={points} />
      </div>
      <div className='userRoute--controls'>
        <Button text="Редагувати замовлення" color="orders" onClick={handleEditClick} />
        <Button text="Скасувати чи завершити" color="orders" onClick={handleCloseModal} />
      </div>
    </div>
  );
};

export default UserRoute;
