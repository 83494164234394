import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { checkStateEmptyInputs } from '../../../../helpers/checkEmptyInputs';
import notify from '../../../../helpers/notify';
import useRidesService from '../../../../services/ridesService';
import useBindCreators from '../../../../store/hooks/useBindCreators';
import useGetState from '../../../../store/hooks/useGetState';
import { orderActions } from '../../../../store/slices/orderSlice';
import { currentStatuses } from '../../../../data/consts';

const compareAddresses = (a, b) => {
  if (a.inputId < b.inputId) {
    return -1;
  }
  if (a.inputId > b.inputId) {
    return 1;
  }
  return 0;
};

export default function useNewOrder(destination, id) {
  const { order } = useGetState();
  const {
    firstName,
    phoneNumber,
    price,
    fare,
    addresses,
    type,
    date,
    hours,
    comment,
    driver,
    suggestedPrice,
    status,
  } = order;
  const [loading, setLoading] = useState(false);
  const { setOrderErrors, resetOrder } = useBindCreators(orderActions);
  const { createRide, updateRide, estimateRide, estimateCurrentRide } =
    useRidesService();
  const navigate = useNavigate();
  const isEditing = destination === 'edit';
  const [firstLoad, setFirstLoad] = useState(true);

  const calcEstimate = useMemo(() => {
    return debounce(
      async (
        { addresses, fare, additionalServices, price, firstName, phoneNumber },
        firstLoad,
      ) => {
        const sortedAddresses = [...addresses].sort(compareAddresses);
        const finalAddresses = [...sortedAddresses]
          .filter((address) => address !== undefined)
          .map(({ latitude, longitude, id, placeId, address }) => ({
            latitude,
            longitude,
            placeId: id || placeId,
            address,
          }));

        const points =
          fare.type === 'hourly' ? finalAddresses.slice(0, 1) : finalAddresses;

        const newOrder = {
          firstName,
          phoneNumber,
          points,
          fare: fare.id,
          services: additionalServices.map((service) => ({ id: service.id })),
        };
        if (isEditing) {
          await estimateCurrentRide(
            { ...newOrder, price: parseFloat(price), rideId: Number(id) },
            () => {
              setFirstLoad(false);
            },
            firstLoad,
          );
        } else {
          await estimateRide(newOrder);
        }
      },
      300,
    );
  }, []);

  useEffect(() => {
    if (fare.id && order.phoneNumber.length > 12 && order.firstName) {
      if (fare.type !== 'hourly' && order.addresses.length > 1) {
        try {
          calcEstimate(order, firstLoad);
        } catch (error) {
          notify('Заповніть таблицю', 'error');
        }
        return;
      }
      if (fare.type === 'hourly' && order.addresses.length > 0) {
        try {
          calcEstimate(order, firstLoad);
        } catch (error) {
          notify('Заповніть таблицю', 'error');
        }
        return;
      }
    }
    return () => {
      calcEstimate.cancel();
    };
  }, [
    order.addresses.length,
    order.additionalServices.length,
    fare.id,
    fare.type,
    order.phoneNumber,
    order.firstName,
    order.addresses,
  ]);

  const handleOrder = async (e) => {
    e.preventDefault();
    const emptyInputs = checkStateEmptyInputs(
      { firstName, phoneNumber: phoneNumber === '+380' ? '' : phoneNumber },
      setOrderErrors,
    );

    if (emptyInputs > 0) {
      notify('Заповніть таблицю', 'error');
      return;
    }

    if (addresses.length === 0) {
      notify('Оберіть адреси', 'error');
      return;
    }

    if (type === 'scheduledFor' && (!date || !hours)) {
      notify('Виберіть дату для попереднього замовлення', 'error');
      return;
    }

    const sortedAddresses = [...order.addresses].sort(compareAddresses);
    const finalAddresses = [...sortedAddresses];

    const newOrder = {
      firstName,
      phoneNumber,
      fare: fare.id,
      points: finalAddresses
        .filter((address) => address !== undefined)
        .map(({ latitude, longitude, id, placeId, address }) => ({
          latitude,
          longitude,
          placeId: id || placeId,
          address,
        })),
      services: order.additionalServices.map((service) => ({ id: service.id })),
    };

    if (!!driver?.id) {
      newOrder.driver = Number(driver.id);
    }

    if (date && hours && type === 'scheduledFor') {
      if (!currentStatuses.includes(status)) {
        newOrder.scheduledFor = `${date} ${hours}`;
      }
    }

    if (price) {
      newOrder.price = parseFloat(price || suggestedPrice);
    }

    if (comment) {
      newOrder.comment = comment;
    }

    if (isEditing && id) {
      await updateRide(
        id,
        { ...newOrder, price: parseFloat(price || suggestedPrice) },
        () => navigate(-1),
      );
      return;
    }

    await createRide(
      { ...newOrder, price: parseFloat(price || suggestedPrice) },
      () => {
        navigate(-1);
      },
    );
  };

  useEffect(() => () => resetOrder(), []);
  return {
    loading,
    setLoading,
    handleOrder,
    order,
  };
}
