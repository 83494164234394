import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import './styles.scss';

const TriggerButton = (props) => {
  const {
    initialState, face, className, onClick, disabled,
  } = props;
  const [state, setState] = useState(false);

  const onClickHandler = () => {
    setState((prev) => !prev);
    onClick?.(!state);
  };

  useEffect(() => { setState(initialState); }, [initialState]);

  return (
    <button className={ClassNames('TriggerButton', className)} data-state={state} onClick={onClickHandler} disabled={disabled} type="button">
      {!state ? face.off : face.on}
    </button>
  );
};
TriggerButton.defaultProps = {
  face: {
    on: <button>ON</button>,
    off: <button>OFF</button>,
  },
  initialState: false,
};
TriggerButton.propTypes = {
  className: PropTypes.string,
  face: PropTypes.shape({
    on: PropTypes.element,
    off: PropTypes.element,
  }),
  initialState: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TriggerButton;
