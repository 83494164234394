export const BALANCE_MODAL_SWITCH = [
  {
    id: 'writeOffCash',
    name: 'Списати',
  },
  {
    id: 'addCash',
    name: 'Поповнити',
  },
];

export const CANCEL_DRIVE_MODAL_SWITCH = [
  {
    id: 'cancel',
    name: 'Скасування',
  },
  {
    id: 'end',
    name: 'Завершення',
  },
];
