import React from 'react';
import PropTypes from 'prop-types';
import './formButtonsWrapper.scss';

const FormButtonsWrapper = ({ children }) => (
  <div className="formButtonsWrapper" data-testid="formButtonsWrapper">
    {children}
  </div>
);

export default FormButtonsWrapper;

FormButtonsWrapper.propTypes = {
  children: PropTypes.node,
};
