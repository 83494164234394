import React from 'react';
import './personalInfo.scss';
import PageHeading from '../../../assets/pageHeadings/PageHeading';
import PersonalPhoto from './photo/PersonalPhoto';
import PersonalInfoForm from './form/PersonalInfoForm';
import useGetState from '../../../../store/hooks/useGetState';
import Balance from '../../../assets/balance/Balance';
import useDriversService from '../../../../services/driversService';
import useBindCreators from '../../../../store/hooks/useBindCreators';
import { driverActions } from '../../../../store/slices/driverSlice';

const PersonalInfo = ({ setPhotos }) => {
  const { driver } = useGetState();
  const { increaseDriverBalance, decreaseDriverBalance, loading } =
    useDriversService();
  const { setDriver } = useBindCreators(driverActions);
  const setAmountOnSuccess = (amount) => setDriver(amount);
  return (
    <div className="personalInfo--container">
      <PageHeading text="ОСОБИСТА ІНФОРМАЦІЯ" />
      <div className="personalInfo">
        <PersonalPhoto setPhotos={setPhotos} />
        <PersonalInfoForm />
        <Balance
          user={driver}
          loading={loading}
          increaseBalance={increaseDriverBalance}
          decreaseBalance={decreaseDriverBalance}
          setUser={setAmountOnSuccess}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
