import { CANCEL_DRIVE_MODAL_SWITCH } from "../../../data/drivers/switch/modalSwitch";
import { CANCEL_DRIVE_CUSTOM_REASON, CANCEL_DRIVE_REASON } from "../../../data/orders/cancelDrive";
import Modal from "../../assets/modal/Modal";
import Switch from "../../assets/switch/Switch";
import CustomSelectWithLabel from "../../assets/inputs/customSelect/withLabel/CustomSelectWithLabel";
import FormInput from "../../assets/inputs/FormInput";
import "./cancelDriveModal.scss";
import useCancelDriveModal from "./useCancelDriveModal";

const CancelDriveModal = ( props ) => {
    const { show, onHide, orderId, status, setStatus, onSubmitCancel} = props ;

    const {
        submitButtonText,
        rideIsFinishable,
        onSelectOptionClickReason,
        onSubmit,
        onSwitchClick,
        onCustomReasonChange,
        activeSwitch,
        cancelIsActive,
        customCancelReason,
        customReason,
        cancelReason
    } = useCancelDriveModal({ orderId, setStatus, onHide, show, status });

    return (
        <Modal
            show={show}
            onHide={onHide}
            onSubmit={() => {
              onSubmit?.();
              cancelReason !== 'noReason' && onSubmitCancel?.();
            }}
            title="Скасувати/Завершити"
            submitButtonText={submitButtonText}
            cancelButtonText="Назад"
            dropdownsArePresent={true}
        >
            <div style={{ marginTop: '2rem' }}>
                {rideIsFinishable && <Switch
                    activeSwitch={activeSwitch}
                    onClick={onSwitchClick}
                    items={CANCEL_DRIVE_MODAL_SWITCH}
                />}
            </div>
            <div style={{ marginTop: "1.5rem", marginBottom: "3.5rem" }}>
                {cancelIsActive &&
                    <>
                        <CustomSelectWithLabel
                            options={CANCEL_DRIVE_REASON}
                            onClick={onSelectOptionClickReason}
                            className='fullWidth highZIndex'
                            label='ПРИЧИНА'
                            scrollable={true}
                            withoutUrlParams={true}
                        />
                        <div style={{ marginTop: '1.5rem' }} className="cancelDrive--customReason">
                            <fieldset disabled={!customCancelReason}>
                                {CANCEL_DRIVE_CUSTOM_REASON.map(input => (
                                    <FormInput
                                        key={input.id}
                                        value={customReason}
                                        onChange={onCustomReasonChange}
                                        {...input}
                                    />))}
                            </fieldset>
                        </div>
                    </>
                }
            </div>
        </Modal>
    );
};

export default CancelDriveModal;
