import { baseVisicomUrl, VISICOM_API_KEY } from '../../data/consts';
import axios from 'axios';
import { getAddressFromVisicom } from '../getAddressFromVisicom';
import { getOptionsAutocompleteFromVisicom } from '../getOptionsAutocompleteFromVisicom';

export const getMarkerLocation = async (lng, lat) => {
  const url = `${baseVisicomUrl}?categories=adr_address&n=${lng},${lat}&l=2&key=${VISICOM_API_KEY}`;
  return await axios
    .get(url)
    .then((response) => {
      return getAddressFromVisicom(response.data.features[0]);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const getInputAddress = async (data) => {
  const url = `${baseVisicomUrl}?text=${data}&l=10&country=ua&near=30.520330881659195,50.45496344476998&key=${VISICOM_API_KEY}`;
  return await axios
    .get(url)
    .then((response) => {
      return getOptionsAutocompleteFromVisicom(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};
