import React from 'react';
import './client.scss';
import ContentWrapper from '../../assets/wrappers/content/ContentWrapper';
import BackButton from '../../assets/buttons/backButton/BackButton';
import ClientForm from './clientForm/ClientForm';
import useClientsService from '../../../services/clientsService';
import notify from '../../../helpers/notify';
import useRedirect from '../../../helpers/hooks/useRedirect';
import { CLIENTS } from '../../../data/consts';

const ClientCreate = () => {
  const { createClient } = useClientsService();
  const redirect = useRedirect();
  const onFinish = async (values) => {
    try {
      await createClient(values);
      redirect(CLIENTS);
    } catch (err) {
      notify(err, 'error');
    }
  };
  return (
    <ContentWrapper pageTitle="Створення клієнта">
      <BackButton />
      <ClientForm onFinish={onFinish} />
    </ContentWrapper>
  );
};

export default ClientCreate;
