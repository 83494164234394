export const DRIVER_CONTROLS_ACTIVE = [
    {
        id: 'working',
        name: 'working',
        text: 'Працює',
        customText: 'Працює',
        color: 'green'
    },
    {
        id: 'block',
        name: 'block',
        text: 'Заблокувати',
        customText: 'Заблокований',
        color: 'yellow'
    },
    {
        id: 'fire',
        name: 'fire',
        text: 'Видалити',
        customText: 'Видалений',
        color: ''
    },
];

export const DRIVER_CONTROLS_BLOCKED = [
    {
        id: 'unblock',
        name: 'unblock',
        text: 'Розблокувати',
        customText: 'Заблокований',
        color: 'green'
    },
    {
        id: 'fire',
        name: 'fire',
        text: 'Видалити',
        customText: 'Звільнений',
        color: ''
    },
];

export const DRIVER_CONTROLS_FIRED = [
    {
        id: 'fire',
        name: 'fire',
        text: 'Звільнений',
        customText: 'Звільнений',
        color: ''
    },
    {
        id: 'working',
        name: 'working',
        text: 'Працює',
        customText: 'Працює',
        color: 'green'
    },
    {
        id: 'block',
        name: 'block',
        text: 'Видалити',
        customText: 'Заблокований',
        color: 'yellow'
    },
];
