import { useState, useCallback } from 'react';
import axios from 'axios';
import useGetState from '../store/hooks/useGetState';

const useHttpService = () => {
  const { token } = useGetState();
  const [loading, setLoading] = useState(false);

  const axiosInstance = useCallback(axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }), [token]);

  const request = async (config) => {
    try {
      setLoading(true);
      const response = await axiosInstance.request(config);
      if (response) {
        setLoading(false);
        return response.data;
      }
    } catch (e) {
      setLoading(false);
      return Promise.reject(e);
    }
  };

  return { request, loading };
};

export default useHttpService;
