import React from 'react';
import "./additionalServices.scss";
import PageHeading from "../../../../assets/pageHeadings/PageHeading";
import Checkbox from "../../../../assets/inputs/checkbox/Checkbox";
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import { orderActions } from "../../../../../store/slices/orderSlice";
import Spinner from "../../../../assets/spinner/Spinner";

const AdditionalServices = ({ order, servicesLoading }) => {
  const additionalServices = order?.fare?.services || [];
  const { excludeService, includeService } = useBindCreators(orderActions);

  const changeHandler = (service) => {
    const serviceIsActive = order?.additionalServices?.find(active => active.id === service.id);
    serviceIsActive ? excludeService(service) : includeService(service);
  };


  return (
    <>
      <PageHeading text='ПОБАЖАННЯ' className="small marginBottom" />
      <div className="additionalServices">
        {!servicesLoading &&
          additionalServices.map((service) =>
            <Checkbox
              key={service.id}
              checked={!!order.additionalServices?.find(item => item.id === service.id)}
              onChange={() => changeHandler(service)}
              label={service.name.uk}
              withIcon={true}
              additionalInfo={service.price}
            />)
        }
        {servicesLoading &&
          <div style={{ position: "absolute", left: "50%", marginBottom: "50px" }}>
            <Spinner />
          </div>
        }
      </div>
    </>
  );
};

export default AdditionalServices;
