import { createSlice } from '@reduxjs/toolkit';
import { DRIVER_PERSONAL_INFO_FORM_COL_1, DRIVER_PERSONAL_INFO_FORM_COL_2 } from '../../data/forms/driverPersonalInfoForm';
import { DRIVER_VEHICLE_FORM_COL_1, DRIVER_VEHICLE_FORM_COL_2, DRIVER_VEHICLE_FORM_COL_3 } from '../../data/forms/driverVehicleForm';

export const ALL_INPUTS = [
  DRIVER_PERSONAL_INFO_FORM_COL_1,
  DRIVER_PERSONAL_INFO_FORM_COL_2,
  DRIVER_VEHICLE_FORM_COL_1,
  DRIVER_VEHICLE_FORM_COL_2,
  DRIVER_VEHICLE_FORM_COL_3,
];

const initialState = {
  driver: {
    supportChat: '',
    balance: '',
    birthday: '',
    calling: '',
    password: '',
    car: {
      backRegistrationCertificatePhoto: '',
      brand: '',
      color: '',
      frontRegistrationCertificatePhoto: '',
      insurancePolicyPhoto: '',
      manufactureYear: '',
      model: '',
      owner: '',
      registrationNumber: '',
      seatsNumber: '',
      type: '',
      frontPhoto: '',
      backPhoto: '',
      leftPhoto: '',
      rightPhoto: '',
      frontSeatsPhoto: '',
      backSeatsPhoto: '',
    },
    driverLicense: {
      frontPhoto: false,
      backPhoto: false,
      identificationNumber: '',
    },
    email: '',
    fares: [],
    firstName: '',
    id: '',
    lastName: '',
    phoneNumber: '+380',
    rate: '',
    status: '',
    updateRequest: null,
    photo: '',
    errors: {},
    base64FrontPhoto: '',
    base64BackPhoto: '',
    base64LeftPhoto: '',
    base64RightPhoto: '',
    base64FrontSeatsPhoto: '',
    base64BackSeatsPhoto: '',
    base64LicenseFront: '',
    base64LicenseBack: '',
    base64CertificateFront: '',
    base64CertificateBack: '',
    base64Insurance: '',
    base64Photo: '',
    licenseFrontPhoto: '',
    licenseBackPhoto: '',
    carInsurancePolicyPhoto: '',
    isLoading: false,
  },
};

const driverSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
    setDriver(state, action) {
      const { key, value } = action.payload;
      state.driver[key] = value;
    },
    setVehicle(state, action) {
      const { key, value } = action.payload;
      state.driver.car[key] = value;
    },
    includeTariff(state, { payload }) {
      state.driver.fares = [...state.driver.fares, payload];
    },
    excludeTariff(state, { payload }) {
      state.driver.fares = state.driver.fares.filter((item) => item.id !== payload);
    },
    setDriverErrors(state, action) {
      state.driver.errors = { ...state.driver.errors, ...action.payload };
    },
    resetDriverErrors(state) {
      state.driver.errors = {};
    },
    resetDriver(state) {
      state.driver = initialState.driver;
    },
    setdriverLicenseId(state, { payload }) {
      if (state.driver.errors.identificationNumber) {
        state.driver.errors.identificationNumber = '';
      }
      state.driver.driverLicense.identificationNumber = payload;
    },
    setFetchedDriver(state, { payload }) {
      state.driver = { ...payload, errors: {} };
    },
    setDriverStatus(state, action) {
      state.driver.status = action.payload;
    },
    setDriverPhotos(state, { payload }) {
      state.driver.base64BackPhoto = payload.backPhoto;
      state.driver.base64FrontPhoto = payload.frontPhoto;
      state.driver.base64LeftPhoto = payload.leftPhoto;
      state.driver.base64RightPhoto = payload.rightPhoto;
      state.driver.base64FrontSeatsPhoto = payload.frontSeatsPhoto;
      state.driver.base64BackSeatsPhoto = payload.backSeatsPhoto;
      state.driver.base64LicenseFront = payload.frontLicense;
      state.driver.base64LicenseBack = payload.backLicense;
      state.driver.base64CertificateFront = payload.frontCertificate;
      state.driver.base64CertificateBack = payload.backCertificate;
      state.driver.base64Insurance = payload.insurance;
    },
    setIsLoading(state, action) {
      state.driver.isLoading = action.payload;
    },
  },
});

const { reducer: driver, actions } = driverSlice;
export const driverActions = actions;
export default driver;
