import "./deleteTariffModal.scss";
import React, { useState } from "react";
import Modal from "../../../assets/modal/Modal";
import FormButton from "../../../assets/buttons/formButton/FormButton";
import useFaresService from "../../../../services/faresService";
import useRedirect from "../../../../helpers/hooks/useRedirect";
import { DIRECTOR } from "../../../../data/consts";
import Spinner from "../../../assets/spinner/Spinner";
import notify from "../../../../helpers/notify";

const DeleteTariffModal = ({ show, onHide, tariff }) => {
  const [deleting, setDeleting] = useState(false);
  const { deleteFare } = useFaresService();
  const redirect = useRedirect();

  const onSubmit = async () => {

    if (!tariff?.id) {
      onHide();
      redirect(DIRECTOR);
      notify('Створення тарифу скасовано', 'info');
      return;
    }

    setDeleting(true);
    await deleteFare(tariff?.id);
    setDeleting(false);
    onHide();
    redirect(DIRECTOR);
  };

  const modalButtons = <div className="teleteTariff-buttons">
    <FormButton text="Так" color="red" onClick={onSubmit} />
    <FormButton text="Ні" color="white" onClick={onHide} />
  </div>;

  const spinner =
    <div style={{ position: 'relative', paddingTop: '110px' }}>
      <Spinner />
    </div>;

  return <Modal
    show={show}
    onHide={onHide}
    title="Ви точно бажаєте видалити тариф?"
    customButtons={deleting ? spinner : modalButtons}
  />;
};

export default DeleteTariffModal;
