import './authRight.scss';
import { ReactComponent as Logo } from '../../../img/logo/login_logo.svg';
import Headers from '../../assets/headers/Headers';

const AuthRight = ({ formComponent, headerText, backButton }) => {
    return (
        <div className="authRight">
            <div className='loginLogo'>
                <Logo />
            </div>
            {backButton &&
                <div className='backButtonWrapper'>
                    {backButton}
                </div>}
            <div className='loginHeader'>
                <Headers type='h2' text={headerText} />
            </div>
            {formComponent}
        </div>
    );
};

export default AuthRight;