import {
  DRIVER_LICENSE_INPUTS
} from "../../../../../data/drivers/fileInputs/driverLicense/driverLicenseInputs";
import useGetState from "../../../../../store/hooks/useGetState";
import FileInputField from "../../../../assets/inputs/fileInputs/fileInput-field/FileInputField";
import InfoCategory from "../../infoCategory/InfoCategory";

const DriverLicense = ({ setPhotos }) => {
  const { driver } = useGetState();
  const updatedLicense = driver?.updateRequest?.driverLicense;

  return (
    <div className='driverLicense-container'>
      <InfoCategory text='Водійські права' color={'blue'} />
      <div className="driverLicense">
        {DRIVER_LICENSE_INPUTS.map(input => (
          <FileInputField
            key={input.id}
            className={'isDriverPage'}
            initialImage={driver[input.id]}
            fileUpdated={updatedLicense?.[input.name]}
            setPhotos={setPhotos}
            {...input}
          />)
        )}
      </div>
    </div>
  );
};

export default DriverLicense;
