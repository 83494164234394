import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { mapActions } from '../store/slices/mapSlice';

const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ['Order'],
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: (id) => `/manager/ride/${id}/get`,
    }),
    updateOrder: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `manager/ride/${id}/update`,
        method: 'POST',
        body,
      }),
    }),
    getActiveOrders: builder.query({
      query: (params) => ({
        url: '/manager/ride/allActive',
        params,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(mapActions.setActiveOrders(data.rides));
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export default orderApi;

export const { useUpdateOrderMutation, useGetOrderQuery, useGetActiveOrdersQuery } = orderApi;
