import { useEffect, useState } from "react";
import CustomSelect from "../../assets/inputs/customSelect/CustomSelect";
import { CLIENTS_CONTROLS_BLOCKED, CLIENT_CONTROLS_ACTIVE } from "../../../data/clientss/clientControls/clientControls";
import useClientsService from "../../../services/clientsService";

const ClientControls = ({ client }) => {
    const [dropDownOptions, setDropDownOptions] = useState(getDefaultOptions(client.status));
    const [initialColor, setInitialColor] = useState("green");
    const { updateClientStatus } = useClientsService();

    function getDefaultOptions(status) {
        switch (status) {
            case "active":
                return CLIENT_CONTROLS_ACTIVE;
            case "blocked":
                return CLIENTS_CONTROLS_BLOCKED;
            default:
                return CLIENT_CONTROLS_ACTIVE;
        }
    }

    const onOptionClick = async (name) => {
        if (name === 'block') {
            setDropDownOptions(CLIENTS_CONTROLS_BLOCKED);
            await updateClientStatus(client.id, { status: "blocked" });
        } else if (name === 'unblock') {
            setDropDownOptions(CLIENT_CONTROLS_ACTIVE);
            await updateClientStatus(client.id, { status: "active" });
        }
    };

    useEffect(() => {
        setDropDownOptions(getDefaultOptions(client.status));

        if (client.status === "blocked") {
            setInitialColor("red")
        }

    }, [client.status]);

    if (client.status) {
        return <CustomSelect options={dropDownOptions} onClick={onOptionClick} initialCustomColor={initialColor} />;
    }
};

export default ClientControls;