import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './tableControls.scss';

const TableControls = ({ children, marginTop }) => (
  <div className={classNames('controlsWrapper', marginTop)}>
    {children}
  </div>
);

export default TableControls;

TableControls.propTypes = {
  children: PropTypes.node,
  marginTop: PropTypes.bool,
};
