import {
  FILTERS_1, FILTERS_2, FILTERS_3, FILTERS_4,
} from '../filters/filters';
import {
  createSearchRow, removeHeadersBracketsText, getHeadersBracketsText, findInArrayBooleanByItem, findInArrayByItem,
} from '../../helpers/general/general';

export const allOrdersFilters = [...FILTERS_1, ...FILTERS_2, ...FILTERS_3, ...FILTERS_4];
const sortableFields = ['id', 'driver', 'departure', 'destination', 'acceptTime', 'approvedAt', 'name', 'arrivedAt', 'startedAt', 'finishedAt'];
const searchableFields = ['id', 'driver', 'departure', 'destination', 'fare', 'paymentType', 'phone', 'phoneNumber', 'email'];
export const activeFilters = allOrdersFilters.filter((item) => item.defaultChecked);

export const createHeaders = ({ id, label, name }) => ({
  id: id.replace('filters', 'orders'),
  label: removeHeadersBracketsText(label).toUpperCase(),
  name,
  sortable: findInArrayBooleanByItem(sortableFields, name),
  searchable: findInArrayBooleanByItem(searchableFields, name),
  sortField: findInArrayByItem(sortableFields, name),
  className: '',
  smallText: getHeadersBracketsText(label),
});

export const createCells = ({ id, name }) => ({
  id: id.replace('filters', 'orders-cells'),
  name,
  className: '',
});

export const INITIAL_ORDERS_TABLE_HEADERS = activeFilters.map(createHeaders);
export const INITIAL_ORDERS_TABLE_ITEM_CELLS = activeFilters.map(createCells);
export const INITIAL_ORDERS_SEARCH_ROW = createSearchRow(INITIAL_ORDERS_TABLE_HEADERS);

export const ALL_ORDERS_ITEM_CELLS = allOrdersFilters.map(createCells);
export const ALL_ORDERS_HEADERS = allOrdersFilters.map(createHeaders);
export const ALL_ORDERS_SEARCH_ROW = createSearchRow(ALL_ORDERS_HEADERS);
