import React from 'react';
import './payment.scss';
import FormInputWithIcon from '../../../../assets/inputs/formInputWithIcon/FormInputWithIcon';
import { NEW_ORDER_PAYMENT } from '../../../../../data/orders/newOrder/clietnInfo';
import useGetState from '../../../../../store/hooks/useGetState';
import useBindCreators from '../../../../../store/hooks/useBindCreators';
import { orderActions } from '../../../../../store/slices/orderSlice';
const Payment = ({ destination }) => {
  const { order } = useGetState();
  const { errors } = order;
  const { setOrder, setOrderErrors } = useBindCreators(orderActions);

  const onInputChange = (e) => {
    setOrder({ key: 'price', value: e.target.value });
    if (errors.price) {
      setOrderErrors({ price: '' });
    }
  };

  const priceInputs = NEW_ORDER_PAYMENT.map((input, index) => {
    if (destination === 'edit' && index === 0) return null;
    return (
      <div key={input.id} className="orderCost--item">
        <FormInputWithIcon
          value={order[input.name]}
          onChange={(e) => onInputChange(e)}
          errorClass={errors[input.name] ? 'error' : ''}
          customErrorMessage={errors[input.name] ? errors[input.name] : ''}
          {...input}
        />
      </div>
    );
  });

  return (
    <div className="paymentAppointment">
      <div>
        <div className="orderAppointment">{priceInputs}</div>
      </div>
    </div>
  );
};

export default Payment;
