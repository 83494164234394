import { DIRECTOR_USERS, MANAGER_SUPPORT_CHAT, MANAGER_USER } from './consts';

export const getChatLink = (id) => `${MANAGER_SUPPORT_CHAT}chat/${id}/messages`;
export const getClientLink = (id) => `${MANAGER_USER}/client/${id}/get`;
export const getClientOrdersLink = (id) => `${MANAGER_USER}/client/${id}/orders`;
export const getDriverRequestLink = (id) => `${MANAGER_USER}/driver/${id}/request/get`;
export const getDriverInfoLink = (id) => `${MANAGER_USER}/driver/${id}/get`;
export const getPendingDriverLink = (id) => `${MANAGER_USER}/driver/${id}/pending/get`;
export const getDriverRidesLink = (id) => `${MANAGER_USER}/driver/${id}/rides`;
export const getUserUpdateLink = (id) => `${DIRECTOR_USERS}/manager/${id}/update`;
export const getEditOrderLink = (id) => `/orders/${id}/edit/?fareId=0&fare=""&scheduledFor=""`;
