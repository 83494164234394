import { DRIVER_INFO_PASSWORD_PATTERN } from '../consts';

export const DRIVER_PERSONAL_INFO_FORM_COL_1 = [
  {
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 букв',
    label: 'ІМʼЯ',
    className: '',
  },
  {
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 букв',
    label: 'ПРІЗВИЩЕ',
    className: '',
  },
  {
    id: 'calling',
    name: 'calling',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ПОЗИВНИЙ',
    className: '',
  },
];

export const DRIVER_PERSONAL_INFO_FORM_COL_2 = [
  {
    id: 'phoneNumber',
    name: 'phoneNumber',
    type: 'text',
    placeholder: '',
    errorMessage: 'Формат: +380xxxxxxxxx',
    label: 'НОМЕР ТЕЛЕФОНУ',
    className: '',
  },
  {
    id: 'password',
    name: 'password',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має бути 8-16 символів, містити щонайменше 1 цифру, 1 велику букву і 1 спецсимвол',
    label: 'ПАРОЛЬ',
    pattern: DRIVER_INFO_PASSWORD_PATTERN,
  },

];
