import { createSlice } from '@reduxjs/toolkit';

const token = localStorage.getItem('token');
const role = localStorage.getItem('role');
const currentUserId = localStorage.getItem('currentUserId');
const wsToken = localStorage.getItem('wsToken');
const wsChannel = localStorage.getItem('wsChannel');

const initialState = {
  token: token || null,
  role: role || null,
  authorizing: false,
  currentUserId: currentUserId || null,
  wsToken: wsToken || null,
  wsChannel: wsChannel || null,
  codeToSendEmail: '',
};

const setLocalStorage = (name, item) => localStorage.setItem(name, item);
const removeLocalStorage = (name) => localStorage.removeItem(name);

const setItem = (state, name, item) => {
  state[name] = item;
  setLocalStorage(name, item);
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, { payload }) {
      setItem(state, 'token', payload);
    },
    setCodeToSendEmail(state, { payload }) {
      state.codeToSendEmail = payload;
    },
    setRole(state, { payload }) {
      setItem(state, 'role', payload);
    },
    setCurrentUserId(state, { payload }) {
      setItem(state, 'currentUserId', payload);
    },
    setAuthorizing(state, { payload }) {
      state.authorizing = payload;
    },
    setWsToken(state, { payload }) {
      setItem(state, 'wsToken', payload);
    },
    setWsChannel(state, { payload }) {
      setItem(state, 'wsChannel', payload);
    },
    logout(state) {
      state.token = null;
      state.role = null;
      state.currentUserId = null;
      ['token', 'role', 'currentUserId'].forEach((item) => removeLocalStorage(item));
    },
  },
});

const { reducer: auth, actions } = authSlice;
export const authActions = { ...actions };
export default auth;
