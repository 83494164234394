import notify from '../helpers/notify';
import {
  CREATE_SERVICE,
  getServiceDeleteEndpoint,
  getServiceUpdateEndpoint,
  GET_SERVICES,
} from '../data/endpoints';
import useHttpService from './httpService';

const useServicesService = () => {
  const { request, loading } = useHttpService();

  const getAllServices = async () => {
    const services = await request({ url: GET_SERVICES });
    return services;
  };

  const createService = async (data) => {
    await request({ url: CREATE_SERVICE, method: 'POST', data });
    notify('Послугу створено', 'info');
  };

  const updateService = async (id, data) => {
    await request({ url: getServiceUpdateEndpoint(id), method: 'POST', data });
    notify('Послугу оновлено', 'info');
  };

  const deleteService = async (id) => {
    await request({ url: getServiceDeleteEndpoint(id), method: 'DELETE' });
    notify('Послугу видалено', 'info');
  };

  return {
    getAllServices,
    updateService,
    createService,
    deleteService,
    loading,
  };
};

export default useServicesService;
