import "./tariffs.scss";
import useGetState from "../../../../store/hooks/useGetState";
import PageHeading from "../../../assets/pageHeadings/PageHeading";
import Checkbox from "../../../assets/inputs/checkbox/Checkbox";
import useBindCreators from "../../../../store/hooks/useBindCreators";
import { driverActions } from "../../../../store/slices/driverSlice";
import useFaresService from "../../../../services/faresService";
import { allTariffsActions } from "../../../../store/slices/allTariffsSlice";
import { useEffect } from "react";

const DriverTariffs = () => {
    const { driver, tariffs } = useGetState();
    const activeTariffs = driver?.fares || [];
    const { includeTariff, excludeTariff, setAllTariffs } = useBindCreators({ ...driverActions, ...allTariffsActions });
    const { getAllFares } = useFaresService();

    const middleIndex = tariffs.length / 2;
    const firstColumn = tariffs.slice(0, middleIndex);
    const secondColumn = tariffs.slice(middleIndex, tariffs.length);

    const fetchFares = async () => {
        const allFares = await getAllFares();
        setAllTariffs(allFares);
    };

    const changeHandler = (id, name) => {
        const tariffIsActive = activeTariffs?.find(active => active.id === id);
        tariffIsActive ? excludeTariff(id) : includeTariff({ id, name });
    };

    const mapTariffs = (INFO) => {
        return INFO.map(({ id, name, type }) =>
        (<Checkbox
            key={id}
            checked={activeTariffs?.find(item => item.id === id) ? true : false}
            onChange={() => changeHandler(id, name)}
            label={type !== "hourly" ? name : `${name} (Погодинний)`}
        />));
    };

    useEffect(() => {
        fetchFares();
    }, []);

    return (
        <div className='infoTariffs--container'>
            <PageHeading text='ТАРИФНІ ПЛАНИ' />
            <div className="infoTariffs">
                {firstColumn.length > 0 && <div className="infoTariffs--tariffs-col">
                    {mapTariffs(tariffs)}
                </div>}
                {/*<div className="infoTariffs--tariffs-col">*/}
                {/*    {mapTariffs(secondColumn)}*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default DriverTariffs;
