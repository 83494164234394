import classNames from 'classnames'
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import { driverActions } from "../../../../../store/slices/driverSlice";
import "./fileInputButton.scss";

const FileInputButton = ({ id, text, setPhotos, backendField, className }) => {
    const { setDriver } = useBindCreators(driverActions);

    const uploadImage = ({ target }) => {
        const { files } = target;
        if (files.length === 0) {
            return;
        }

        const file = files[0];
        setPhotos(prev => ({ ...prev, [backendField]: file }));

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => setDriver({ key: 'photo', value: fileReader.result });
    };

    return (
        <div className={classNames('fileInput-button', className ? className : '')}>
            <input type="file" id={id} className="fileInput-button--file" onChange={uploadImage} />
            <label htmlFor={id}>{text}</label>
        </div>
    );
};

export default FileInputButton;
