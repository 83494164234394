import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Headers from '../../assets/headers/Headers';
import './navigationPanel.scss';
import BackButton from '../../assets/buttons/backButton/BackButton';
import Button from '../../assets/buttons/button/Button';

const NavigationPanel = ({
  pageTitle, pageControls, isDriverPage, driverStatus, redirectToOrders, redirectToHelpdesk, additionalControls, size = 'small',
}) => (
  <div className={classNames('navPanel', isDriverPage && 'isDriverPage', size)}>
    {isDriverPage ? (
      <>
        <BackButton />
        {driverStatus === 'existing' && (
        <div className="controlsWrapper--buttons isDriverPage">
          <Button text="Історія поїздок" onClick={redirectToOrders} />
          <Button text="Заявки на службу підтримки" onClick={redirectToHelpdesk} />
        </div>
        )}
      </>
    ) : (
      <Headers type="h2" text={pageTitle} />
    )}
    {pageControls}
    {!!additionalControls && (
      <div className="additionalControls">
        {additionalControls}
      </div>
    )}
  </div>
);

export default NavigationPanel;

NavigationPanel.propTypes = {
  pageTitle: PropTypes.string,
  pageControls: PropTypes.node,
  isDriverPage: PropTypes.bool,
  driverStatus: PropTypes.string,
  redirectToOrders: PropTypes.func,
  redirectToHelpdesk: PropTypes.func,
  additionalControls: PropTypes.node,
  size: PropTypes.string,
};
