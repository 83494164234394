import InnerWrapper from "../../../assets/wrappers/innerWrapper/InnerWrapper";
import DirectorWrapper from "../../section/DirectorWrapper";
import "./createUser.scss";
import CreateUserForm from "./form/CreateUserForm";
import NewUserRights from "./rights/NewUserRights";
import FormButtonsWrapper from "../../../assets/buttons/formButtonsWrapper/FormButtonsWrapper";
import FormButton from "../../../assets/buttons/formButton/FormButton";
import useRedirect from "../../../../helpers/hooks/useRedirect";

const CreateUser = ({ action }) => {
    const redirect = useRedirect();
    return (
        <DirectorWrapper pageHeading={action === "create" ? "Додати користувача" : "Оновити користувача"}>
            <InnerWrapper withoutScroll={true}>
                <CreateUserForm action={action} />
                <NewUserRights />
                <div className="newOrderControls">
                    <FormButtonsWrapper>
                        <FormButton text={action === "create" ? "Додати користувача" : "Оновити користувача"} form="addNewUser" type="submit" />
                        <FormButton text="Скасувати" color="white" type="button" onClick={() => redirect(-1)} />
                    </FormButtonsWrapper>
                </div>
            </InnerWrapper>
        </DirectorWrapper>
    );
};

export default CreateUser;