import React from 'react';
import PropTypes from 'prop-types';
import useGetState from '../../../../../store/hooks/useGetState';
import FileInputField from '../../../../assets/inputs/fileInputs/fileInput-field/FileInputField';
import InfoCategory from '../../infoCategory/InfoCategory';

const InsurancePolicy = ({ setPhotos }) => {
  const { driver } = useGetState();
  const updatedInsurance = driver?.updateRequest?.car?.insurancePolicyPhoto;

  return (
    <div className="insurancePolicy">
      <InfoCategory text="Страховий поліс" color="blue" />
      <div className="insurancePolicy--content">
        <FileInputField
          id="base64Insurance"
          title="Фото *"
          className="isDriverPage"
          initialImage={driver.carInsurancePolicyPhoto}
          fileUpdated={updatedInsurance}
          setPhotos={setPhotos}
          backendField="carInsurancePolicyPhoto"
        />
      </div>
    </div>
  );
};

export default InsurancePolicy;

InsurancePolicy.propTypes = {
  setPhotos: PropTypes.func,
};
