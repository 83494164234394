import React from 'react';
import PropTypes from 'prop-types';
import './customSelect.scss';

const CustomSelectOption = ({
  id, name, text, onClick,
}) => {
  const handleClick = () => {
    onClick?.(id);
  };
  return (
    <div className="option" onClick={handleClick}>
      <input
        type="radio"
        className="radio"
        id={id}
        name={name}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  );
};

export default CustomSelectOption;

CustomSelectOption.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
