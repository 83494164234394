import React from "react";
import classNames from 'classnames';
import "./checkbox.scss";
import { ReactComponent as CashIcon } from "../../../../img/icons/inputs/cash_icon.svg";

const Checkbox = ({ label, checked, onChange, fitContent = false, withIcon = false, additionalInfo = '', icon = <CashIcon />, className = '', style }) => {
  return (
    <label className={classNames('checkbox-container', fitContent && 'fitContent', className)} style={style}>
      {label}
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="checkmark"></span>
      {withIcon &&
        <div className="checkboxIcon">
          {additionalInfo}
          <div className="checkboxIcon--icon">
            {icon}
          </div>
        </div>}
    </label>
  );
};

export default Checkbox;
