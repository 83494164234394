import "./fileInputField.scss";
import { ReactComponent as ChangePhoto } from "../../../../../img/icons/inputs/change_photo.svg";
import { ReactComponent as ZoomPhoto } from "../../../../../img/icons/inputs/zoom-icon.svg";
import { useEffect, useState } from 'react'
import classNames from 'classnames'

const FileInputButton = ({ id, title, image = 'false', initialImage = false, fileUpdated = false, backendField, setPhotos = () => { }, className }) => {
    
    const [zoomModal, setZoomModal] = useState(false)

    const setLabelImage = (label, url, newFileUploaded = false) => {
        label.setAttribute('image', 'true');
        const backgroundUrl = newFileUploaded ? `url(${url})` : `url(data:image/*;base64,${url})`;
        label.style.backgroundImage = backgroundUrl;
    };

    const uploadImage = ({ target }) => {
        const { files } = target;
        if (files.length === 0) {
            return;
        }

        const label = target.nextElementSibling;
        const file = files[0];
        const fileReader = new FileReader();

        fileReader.onload = () => setLabelImage(label, fileReader.result, true);
        fileReader.readAsDataURL(file);
        setPhotos(prev => ({ ...prev, [backendField]: file }));
    };

    useEffect(() => {
        if (initialImage) {
            const label = document.querySelector(`label[for=${id}]`);
            setLabelImage(label, initialImage);
        }
    }, [initialImage]);
    
    const handleZoom = (e) => {
        e.preventDefault()
        setZoomModal(prev => !prev)
    }

    return (
        <div className={classNames('fileInput-field', className && className)}>
            <span className="inputTitle">{title}</span>
            <div className='fileInput-field__item'>
                <div className='fileInput-field__wrap'>
                    <input type="file" id={id} className="fileInput-field--file" onChange={uploadImage} />
                    <label htmlFor={id} image={image} className={`file-input-label ${fileUpdated ? "yellow" : ""}`} />
                    <div className="change-photo-img">
                        <ChangePhoto />
                    </div>
                </div>
                {initialImage && (
                    <button className="button blue zoom-photo__btn" onClick={(e) => handleZoom(e)}>
                        <ZoomPhoto />
                    </button>
                )
                }
            </div>
            {zoomModal && (
                <div className='zoom-photo__overlay'>
                    <div className='zoom-photo__modal'>
                        <img className='zoom-photo__img' src={`data:image/*;base64,${initialImage}`} alt=''/>
                        <button className='zoom-photo__close' onClick={e => handleZoom(e)}>X</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileInputButton;
