import { useEffect } from "react";
import CustomSelect from "../../../assets/inputs/customSelect/CustomSelect";
import { HELPDESK_STATUS } from "../../../../data/helpdesk/heldeskTable";
import useSorting from "../../../../helpers/hooks/useSorting";

const ChatAside = ({ chatStatus, changeStatus }) => {
    const { urlParamsHandler } = useSorting();
    const onStatusChange = value => {
        urlParamsHandler([{ param: 'status', value }]);
        changeStatus({ status: value });
    };

    useEffect(() => {
        if (chatStatus) {
            urlParamsHandler([{ param: 'status', value: chatStatus }]);
        }
    }, [chatStatus]);

    return (
        <div className="chat--container-aside">
            <CustomSelect
                options={HELPDESK_STATUS.slice(1)}
                onClick={onStatusChange}
            />
        </div>
    );
};

export default ChatAside;