import {
  getUserDeleteEndpoint, getUserGetEnptoint, getUserUpdateEndpoint, USERS_ALL, USERS_CREATE,
} from '../data/endpoints';
import useRedirect from '../helpers/hooks/useRedirect';
import notify from '../helpers/notify';
import useBindCreators from '../store/hooks/useBindCreators';
import { userActions } from '../store/slices/userSlice';
import useHttpService from './httpService';

const useUsersService = () => {
  const { request, loading } = useHttpService();
  const { setUserErrors } = useBindCreators(userActions);
  const redirect = useRedirect();

  const getUsers = async (params) => {
    const users = await request({ url: USERS_ALL, params });
    return users;
  };

  const getUser = async (id) => {
    const user = await request({ url: getUserGetEnptoint(id) });
    return user;
  };

  const createUser = async (data) => {
    try {
      const response = await request({ url: USERS_CREATE, method: 'POST', data });
      notify(response.message, 'info');
      redirect(-1);
    } catch (err) {
      setUserErrors(err.response.data.errors);
    }
  };

  const updateUser = async (id, data) => {
    try {
      const response = await request({ url: getUserUpdateEndpoint(id), method: 'POST', data });
      notify(response.message, 'info');
      redirect(-1);
    } catch (err) {
      setUserErrors(err.response.data.errors);
    }
  };

  const deleteUser = async (id) => {
    await request({ url: getUserDeleteEndpoint(id), method: 'DELETE' });
    notify('Користувача успішно видалено', 'info');
  };

  return {
    getUsers, createUser, updateUser, getUser, deleteUser, loading,
  };
};

export default useUsersService;
