import TableHeader from "../../components/assets/table/tableHeaders/TableHeader";
import TableSearchItem from "../../components/assets/inputs/tableSearch/TableSearchItem";

const useTable = (headersInfo = [], searchRow = [], bodyInfo = [], TableItem, onSorting) => {
    const tableHeaders = headersInfo.map(header => (<TableHeader key={header.id} {...header} onSorting={onSorting} />));
    const search = searchRow.map(item => (<TableSearchItem key={item.id} {...item} handleSearch={onSorting} />))
    const body = bodyInfo.map(item => (<TableItem key={item.id} handleRefetch={onSorting} {...item} />));

    return { tableHeaders, search, body };
};

export default useTable;