import "./mapTab.scss";

const MapTab = ({ text, className = '', count = 0, onClick = () => { } }) => {
    return (
        <div className={`ordersMap--tab ${className}`} onClick={onClick}>
            {text} ({count})
        </div>
    );
};

export default MapTab;
