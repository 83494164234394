import { take } from 'lodash';
import { decode } from '@googlemaps/polyline-codec';
import Md5 from 'md5';
import {
  INITIAL_SEARCH_PARAMS,
  INITIAL_SEARCH_PARAMS_CLIENTS,
} from '../../data/consts';

export const findInArrayByItem = (array, field) =>
  array.find((item) => item === field);
export const findInArrayByItemName = (array, field, itemName) =>
  array.find((item) => item[itemName] === field);
export const findInArrayBooleanByItem = (array, field) =>
  Boolean(findInArrayByItem(array, field));
export const findInArrayBooleanByName = (array, field, itemName) =>
  Boolean(findInArrayByItemName(array, field, itemName));
export const filterArrayByItem = (array, field) =>
  array.filter((item) => item !== field);
export const filterArrayByItemName = (array, field, itemName) =>
  array.filter((item) => item[itemName] !== field);
export const createRowCallback = (obj) => ({
  ...obj,
  id: `${obj.id}-search`,
  className: obj.className ? obj.className : '',
});
export const createSearchRow = (array) => array.map(createRowCallback);
export const getHeadersBracketsText = (label) => {
  const bracketIndex = label.indexOf('(');
  return ~bracketIndex ? label.slice(bracketIndex) : '';
};
export const removeHeadersBracketsText = (label) => {
  const bracketIndex = label.indexOf('(');
  return ~bracketIndex ? label.slice(0, bracketIndex) : label;
};
export const hideCustomSelect = () => {
  const activeSelects = document.querySelectorAll('.options-container.active');
  if (activeSelects) {
    activeSelects.forEach((select) => select.classList.remove('active'));
  }
};
export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
export const getLinkWithSearchParams = (pathname, isClientPage = false) =>
  `${pathname}/${
    isClientPage ? INITIAL_SEARCH_PARAMS_CLIENTS : INITIAL_SEARCH_PARAMS
  }`;
export const getDateFromString = (string) => {
  if (string) {
    const date = string.slice(0, 10);
    const time = string.slice(11, 19);
    return `${date} ${time}`;
  }
  return '';
};
export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const polylineToCoords = (polyline) =>
  decode(polyline, 5).map(([latitude, longitude]) => ({
    lat: +latitude,
    lng: +longitude,
  }));
export const transformDriverRides = (rides) =>
  rides.map((ride) => {
    const servicesArray = (ride ?? []).services.map((service) => service.name);
    const servicesString = servicesArray.join(', ');

    return {
      id: ride.id,
      status: ride.status,
      cancellor: ride.cancellor,
      orderId: ride.id,
      driver: ride.driver,
      from: ride.departure,
      to: ride.destination,
      type: ride.scheduledFor ? 'На назначений час' : 'На зараз',
      tariff: ride.fare,
      priceDriver: ride.driverPrice,
      priceClient: ride.clientPrice,
      payment: ride.paymentType === 'cash' ? 'Готівка' : 'Картка',
      wait: ride.downtimeDuration,
      waitPrice: ride.overtimePrice,
      comission: ride.commission,
      acceptTime: ride.approvedAt,
      byTime: ride.scheduledFor,
      phone: ride.client.phoneNumber,
      email: ride.client.email,
      client: ride.client.name,
      waitComment: ride.comment,
      services: servicesString,
      mileage: ride.distance,
      arrival: ride.arrivedAt,
      driveStart: ride.startedAt,
      driveDuration: ride.duration,
      driveEnd: ride.finishedAt,
      cancelReason: ride.cancelReason,
      clientReview: ride.review,
      clientComplaint: ride.complaint,
      points: ride.points,
      canceledAt: ride.canceledAt,
    };
  });
export const getIdByIndex = (index) =>
  String.fromCharCode(97 + index).toUpperCase();
export const placeAddressShorten = (address) => {
  let value = `${address || ''}`.trim();
  const split = (value.split(',') || []).map((v) => v.trim());
  const extract = take(split, 3);

  if (extract.length) {
    const join = extract.join(', ').trim();
    if (join.length) value = join;
  }

  return value;
};
export const isDevelopEnv = () => process.env.NODE_ENV === 'development';
export const isEmulationsEnabled = () =>
  process.env.REACT_APP_EMULATIONS &&
  Number(process.env.REACT_APP_EMULATIONS) === 1;
export const getDefaultLatLng = () => ({
  lat: +(process.env.REACT_APP_INITIAL_CENTER_LAT ?? 50.41510933781177),
  lng: +(process.env.REACT_APP_INITIAL_CENTER_LNG ?? 30.03448444726154),
});
export const isObjectsEqual = (a, b) => {
  const a1 = JSON.stringify(a);
  const b1 = JSON.stringify(b);

  const a2 = Md5(a1);
  const b2 = Md5(b1);

  return a2 === b2;
};
export const jsonToObject = (json) => {
  let obj;
  try {
    obj = JSON.parse(json);
  } catch (error) {
    return {};
  }
  return obj;
};
