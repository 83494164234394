import './clients.scss';
import { CLIENTS_HEADERS } from '../../../data/clientss/table/clientsTable';
import DefaultTable from '../../assets/table/defaultTable/DefaultTable';
import ClientTableItem from './ClientTableItem';

const AllClientsTable = ({
  maxPage,
  loading,
  onPageChange,
  tableBody = [],
}) => (
  <DefaultTable
    headersInfo={CLIENTS_HEADERS}
    TableItem={ClientTableItem}
    bodyInfo={tableBody}
    maxPage={maxPage}
    loading={loading}
    onPageChange={onPageChange}
  />
);

export default AllClientsTable;
