import { getLinkWithSearchParams } from '../../helpers/general/general';
import {
  DIRECTOR_TARIFFS, DIRECTOR_USERS, DIRECTOR_ANALYTICS, DIRECTOR_SERVICES, DIRECTOR_UNITS,
} from '../consts';

export const SECTIONS = [
  {
    id: 1,
    name: 'Тарифи',
    className: 'blue',
    redirectTo: DIRECTOR_TARIFFS,
  },
  {
    id: 2,
    name: 'Користувачі',
    className: 'green',
    redirectTo: getLinkWithSearchParams(DIRECTOR_USERS),
  },
  {
    id: 3,
    name: 'Аналітика',
    className: 'purple',
    redirectTo: DIRECTOR_ANALYTICS,
  },
  {
    id: 4,
    name: 'Послуги',
    className: 'purple',
    redirectTo: DIRECTOR_SERVICES,
  },
  {
    id: 5,
    name: 'Мапа відділень',
    className: 'purple',
    redirectTo: DIRECTOR_UNITS,
  },
];
