import React from 'react';
import ContentWrapper from '../../../assets/wrappers/content/ContentWrapper';
import BackButton from '../../../assets/buttons/backButton/BackButton';
import NewOrder from '../table/NewOrder';

const CreateOrder = () => {
  return (
    <ContentWrapper className={'WithOutNav isOverflowAuto'} size="small">
      <div className="createNewOrderPage">
        <BackButton />
        <NewOrder />
      </div>
    </ContentWrapper>
  );
};

export default CreateOrder;
