export const CLIENT_INFO_FORM_COL_1 = [
  {
    id: 'name',
    name: 'firstName',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9]{1,64}$',
    label: 'ІМʼЯ',
  },
];

export const CLIENT_INFO_FORM_COL_2 = [
  {
    id: 'phone',
    name: 'phoneNumber',
    type: 'text',
    placeholder: '',
    errorMessage: 'Формат: +380xxxxxxxxx',
    label: 'ТЕЛЕФОН',
  },
];

export const NEW_ORDER_ADDRESSES = [
  {
    id: 'new-order-address-A-0',
    name: 'new-order-address-A',
    type: 'text',
    placeholder: '',
    errorMessage: '',
    label: 'ЗВІДКИ (точка А)',
    deletable: false,
  },
  {
    id: 'new-order-address-B-1',
    name: 'new-order-address-B',
    type: 'text',
    placeholder: '',
    errorMessage: '',
    label: 'КУДИ (точка B)',
    deletable: false,
  },
];

export const NEW_ORDER_PAYMENT = [
  {
    id: 'new_order_cost',
    name: 'suggestedPrice',
    type: 'number',
    placeholder: '0',
    errorMessage: 'Введіть суму',
    label: 'ПРОРАХОВАНА ВАРТІСТЬ ПОЇЗДКИ',
    disabled: true,
  },
  {
    id: 'new_order_alternative_cost',
    name: 'price',
    type: 'number',
    placeholder: '0',
    errorMessage: 'Введіть суму',
    label: 'ЦІНА',
  },
];

export const NEW_ORDER_HOURS = [
  {
    id: 'new-order-hours',
    name: 'hours',
    type: 'text',
    placeholder: '15:20',
    errorMessage: 'Введіть час: формат 15:20',
  },
];

export const NEW_ORDER_COMMENTS = [
  {
    id: 'new_order_comment_driver',
    name: 'comment',
    type: 'text',
    placeholder: 'ваш коментар...',
    errorMessage: '',
    label: 'КОМЕНТАР ДЛЯ ВОДІЯ',
  },
];

export const ORDER_INFO_FORM = [
  {
    id: 'paymentType',
    name: 'paymentType',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9]{1,64}$',
    label: 'ОПЛАТА',
  },
  {
    id: 'downtimeDuration',
    name: 'downtimeDuration',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9]{1,64}$',
    label: 'ПРОСТІЙ',
  },
  {
    id: 'approvedAt',
    name: 'approvedAt',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9]{1,64}$',
    label: 'ЧАС ПРИЙНЯТТЯ',
  },
  {
    id: 'distance',
    name: 'distance',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9]{1,64}$',
    label: 'ПРОБІГ',
  },
];
