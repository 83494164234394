export const SWITCH_ITEMS = [
  {
    id: 'all',
    name: 'Активні',
  },
  {
    id: 'allPending',
    name: 'Заявки',
  },
];
