import React from 'react';
import "./createOrderModal.scss";
import Modal from "../../../assets/modal/Modal";
import CreateOrderMap from "./map/CreateOrderMap";
import FormButtonsWrapper from "../../../assets/buttons/formButtonsWrapper/FormButtonsWrapper";
import FormButton from "../../../assets/buttons/formButton/FormButton";

const CreateOrderModal = ({ show, onHide, isLoaded, coordinates, onMarkerDrag, markerLabel, onConfirm }) => {
  const addAddress = () => {
    onConfirm();
    onHide();
  };

  const modalCustomButtons =
    <div className="createOrderModal--buttons">
      <FormButtonsWrapper>
        <FormButton text="Додати адресу" onClick={addAddress} type="button" />
        <FormButton text="Скасувати" onClick={onHide} color="white" type="button" />
      </FormButtonsWrapper>
    </div>;

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="medium-1"
      customButtons={modalCustomButtons}
    >
      <div className="createOrderModal--wrapper">
        <CreateOrderMap
          isLoaded={isLoaded}
          coordinates={coordinates}
          onMarkerDrag={onMarkerDrag}
          label={markerLabel}
        />
      </div>
    </Modal>
  );
};

export default CreateOrderModal;
