import React from 'react';
import AddressMap from "../../../createOrder/table/address/AddressMap";
import { Marker, InfoWindow } from '@react-google-maps/api';

const CreateOrderMap = ({ isLoaded, coordinates, onMarkerDrag, label }) => {
  return (
    <div className="createOrderModal--map">
      <AddressMap
        coordinates={coordinates}
        isLoaded={isLoaded}
      >
        {label && <InfoWindow
          position={coordinates}
          options={{
            pixelOffset: {
              height: -40,
              width: 0,
              equals: () => false,
            },
          }}
        >
          <div>
            <span>{label}</span>
          </div>
        </InfoWindow>}
        <Marker
          position={coordinates}
          draggable={true}
          onDragEnd={onMarkerDrag}
        />
      </AddressMap>
    </div>
  );
};

export default CreateOrderMap;
