import React, { useState } from "react";
import CancelDriveModal from "../../modals/CancelDriveModal";
import ShowRouteModal from "../../modals/showRouteModal/ShowRouteModal";

import "../ordersItem.scss";

const OrdersItemControls = ({ show, unfinished, points, orderId, status, setStatus, client, onOpenModal }) => {

  const [showCancelModal, setShowCancelModal] = useState(false);

  return (
    <>
      <CancelDriveModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        onSubmitCancel={() => onOpenModal(false)}
        orderId={orderId}
        setStatus={setStatus}
        status={status}
      />
      <ShowRouteModal
        show={show}
        onHide={() => onOpenModal(false)}
        points={points}
        onShowCancelModal={setShowCancelModal}
        orderId={orderId}
        status={status}
        client={client}
        unfinished={unfinished}
      />
    </>
  );
};

export default OrdersItemControls;
