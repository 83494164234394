import {
  CREATE_RIDE,
  ESTIMATE_RIDE,
  getCancelRideEndpoint,
  getClientRidesEndpoint,
  getDriverRidesEndpoint,
  getFinishRideEndpoint,
  getRideEndpoint,
  getUpdateRideEndpoint,
  GET_ALL_RIDES,
  ESTIMATE_CURRENT_RIDE,
} from '../data/endpoints';
import notify from '../helpers/notify';
import useBindCreators from '../store/hooks/useBindCreators';
import { orderActions } from '../store/slices/orderSlice';
import useHttpService from './httpService';
import { transformDriverRides } from '../helpers/general/general';

const useRidesService = () => {
  const { request, loading } = useHttpService();
  const { setOrderErrors, setOrder } = useBindCreators(orderActions);

  const getRides = async (url, params) => {
    const response = await request({ url, params });
    const transformedRides = transformDriverRides(response.rides);
    const result = { rides: transformedRides, pagination: response.pagination };
    if (response.driver) {
      result.driver = response.driver;
    }

    if (response.client) {
      result.client = response.client;
    }

    if (response.statistics) {
      result.statistics = response.statistics;
    }

    return result;
  };

  const getDriverRides = (id, params) =>
    getRides(getDriverRidesEndpoint(id), params);
  const getClientRides = (id, params) =>
    getRides(getClientRidesEndpoint(id), params);

  const getAllRides = (params) => getRides(GET_ALL_RIDES, params);

  const cancelRide = async (id, data) => {
    await request({ url: getCancelRideEndpoint(id), method: 'POST', data });
  };

  const finishRide = async (id) => {
    await request({ url: getFinishRideEndpoint(id), method: 'POST' });
  };

  const getRide = async (id) => {
    const ride = await request({ url: getRideEndpoint(id) });
    return ride;
  };

  const createRide = async (data, cb) => {
    try {
      const createdRide = await request({
        url: CREATE_RIDE,
        method: 'POST',
        data,
      });
      notify('Замовлення створено', 'info');
      setOrder({ key: 'actualPrice', value: createdRide.actualPrice });
      cb();
    } catch (err) {
      if (err?.response?.data?.errors) {
        setOrderErrors(err?.response?.data?.errors);
        if (err?.response?.data?.errors?.scheduledFor) {
          notify(
            'Має бути час з поточного моменту до 00:00 наступного дня.',
            'error',
          );
          setOrderErrors({
            hours: 'Має бути час з поточного моменту до 00:00 наступного дня.',
          });
        }
      }
      notify('Перевірте таблицю', 'error');
    }
  };

  const updateRide = async (id, data, cb) => {
    try {
      const updatedRide = await request({
        url: getUpdateRideEndpoint(id),
        method: 'POST',
        data,
      });
      notify('Замовлення створено, вартість встановлено', 'info');
      setOrder({ key: 'price', value: updatedRide.actualPrice });
      cb();
    } catch (err) {
      if (err?.response?.data?.errors) {
        setOrderErrors(err?.response?.data?.errors);
        if (err?.response?.data?.errors?.scheduledFor) {
          notify(
            'Має бути час з поточного моменту до 00:00 наступного дня.',
            'error',
          );
          setOrderErrors({
            hours: 'Має бути час з поточного моменту до 00:00 наступного дня.',
          });
        }
        return;
      }

      if (err?.response?.data?.message) {
        notify(err?.response?.data?.message, 'error');
      }
      notify('Перевірте таблицю', 'error');
    }
  };

  const estimateRide = async (data, cb) => {
    try {
      const estimatedPrice = await request({
        url: ESTIMATE_RIDE,
        method: 'POST',
        data,
      });
      notify('Вартість прораховано', 'info');
      setOrder({ key: 'suggestedPrice', value: estimatedPrice.price });
      setOrder({ key: 'price', value: estimatedPrice.price });
      cb();
    } catch (err) {
      if (err?.response?.data?.errors) {
        setOrderErrors(err?.response?.data?.errors);
        if (err?.response?.data?.errors?.scheduledFor) {
          notify(
            'Має бути час з поточного моменту до 00:00 наступного дня.',
            'error',
          );
          setOrderErrors({
            hours: 'Має бути час з поточного моменту до 00:00 наступного дня.',
          });
        }
        notify('Перевірте таблицю', 'error');
      }
    }
  };

  const estimateCurrentRide = async (data, cb, skipNotification) => {
    try {
      const estimatedPrice = await request({
        url: ESTIMATE_CURRENT_RIDE,
        method: 'POST',
        data,
      });
      if (!skipNotification) {
        notify('Вартість прораховано', 'info');
        setOrder({ key: 'price', value: estimatedPrice.price });
      }
      setOrder({ key: 'suggestedPrice', value: estimatedPrice.price });
      cb();
    } catch (err) {
      if (err?.response?.data?.errors) {
        setOrderErrors(err?.response?.data?.errors);
        if (err?.response?.data?.errors?.scheduledFor) {
          notify(
            'Має бути час з поточного моменту до 00:00 наступного дня.',
            'error',
          );
          setOrderErrors({
            hours: 'Має бути час з поточного моменту до 00:00 наступного дня.',
          });
        }
        notify('Перевірте таблицю', 'error');
      }
    }
  };

  return {
    getDriverRides,
    getAllRides,
    cancelRide,
    finishRide,
    getClientRides,
    createRide,
    updateRide,
    getRide,
    estimateRide,
    estimateCurrentRide,
    loading,
  };
};

export default useRidesService;
