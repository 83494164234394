export const FILTERS_1 = [
  {
    id: 'filters-status',
    label: 'Статус',
    name: 'status',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-cancellor',
    label: 'Хто скасував',
    name: 'cancellor',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-orderId',
    label: 'ID',
    name: 'id',
    fitContent: true,
    defaultChecked: false,
  },
  {
    id: 'filters-driver',
    label: 'Позивний',
    name: 'driver',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-from',
    label: 'Звідки',
    name: 'departure',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-to',
    label: 'Куди',
    name: 'destination',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-type',
    label: 'Тип поїздки',
    name: 'type',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-tariff',
    label: 'Тариф',
    name: 'fare',
    fitContent: true,
    defaultChecked: true,
  },
];

export const FILTERS_2 = [
  {
    id: 'filters-priceDriver',
    label: 'Ціна (вод)',
    name: 'driverPrice',
    fitContent: true,
    defaultChecked: false,
  },
  {
    id: 'filters-priceClient',
    label: 'Ціна (кл)',
    name: 'clientPrice',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-payment',
    label: 'Оплата',
    name: 'paymentType',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-wait',
    label: 'Простій (хвилин)',
    name: 'downtimeDuration',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-waitPrice',
    label: 'Ціна за простій',
    name: 'overtimePrice',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-acceptTime',
    label: 'Час прийняття',
    name: 'approvedAt',
    fitContent: true,
    defaultChecked: true,
  },
];

export const FILTERS_3 = [
  {
    id: 'filters-byTime',
    label: 'На Час',
    name: 'scheduledFor',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-phone',
    label: 'Телефон',
    name: 'phoneNumber',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-client',
    label: 'Клієнт',
    name: 'name',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-waitComment',
    label: 'Коментар',
    name: 'comment',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-services',
    label: 'Послуги',
    name: 'services',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-mileage',
    label: 'Пробіг (км)',
    name: 'distance',
    fitContent: true,
    defaultChecked: false,
  },
];

export const FILTERS_4 = [
  {
    id: 'filters-arrival',
    label: 'Подача',
    name: 'arrivedAt',
    fitContent: true,
    defaultChecked: false,
  },
  {
    id: 'filters-driveStart',
    label: 'Початок поїздки',
    name: 'startedAt',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-driveEnd',
    label: 'Завершення',
    name: 'finishedAt',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-cancelReason',
    label: 'Скасування',
    name: 'cancelReason',
    fitContent: true,
    defaultChecked: true,
  },
  {
    id: 'filters-clientReview',
    label: 'Відгук Клієнта',
    name: 'review',
    fitContent: true,
    defaultChecked: false,
  },
  {
    id: 'filters-clientComplaint',
    label: 'Скарга',
    name: 'complaint',
    fitContent: true,
    defaultChecked: false,
  },
];
