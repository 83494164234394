import BlueLinkButton from "../../../../assets/buttons/linkButton/BlueLinkButton";
import { getColor } from "../../../../../helpers/getColor";
import useRedirect from "../../../../../helpers/hooks/useRedirect";
import { getStatus } from "../../../../../helpers/getStatus";
import { getLinkWithSearchParams } from "../../../../../helpers/general/general";
import { getDriverInfoLink, getDriverRequestLink, getDriverRidesLink } from "../../../../../data/urls";

const ActiveDriversItem = ({ balance, car,  calling, id, isUpdateRequested, name, phoneNumber, rate, status }) => {
    const redirect = useRedirect();
    const { brand, model, owner } = car;
    const carOwnedByPark = owner === 'park';
    const parkColor = carOwnedByPark ? 'purple' : 'pink';
    const cashColor = balance < 100 ? 'pink' : 'black';
    const driverFired = status === 'Звільнений';
    const carOwner = carOwnedByPark ? 'Eshka' : 'Приватний'

    const redirectToDriver = ({ target }) => {
        if (target.tagName !== 'BUTTON') {
            isUpdateRequested ? redirect(getDriverRequestLink(id)) : redirect(getDriverInfoLink(id));
        }
    };

    const redirectToOrders = () => redirect((getLinkWithSearchParams(getDriverRidesLink(id))));

    return (
        <tr className={isUpdateRequested ? 'activeRow' : ''} onClick={redirectToDriver}>
            <td className='activeDrivers--status'>
                <div className="activeDrivers--status-wrapper">
                    <span className={`statusText ${getColor(status)}`}>{getStatus(status)}</span>
                    <span className="withRequest">{isUpdateRequested && 'Запит'}</span>
                    <span className="withRequest">{isUpdateRequested && 'на редагування'}</span>
                </div>
            </td>
            <td className='activeDrivers--calling'>{calling || '---'}</td>
            <td className='activeDrivers--name'>{name}</td>
            <td className='activeDrivers--phone'>{phoneNumber}</td>
            <td className={`activeDrivers--park ${parkColor}`}>{!driverFired && carOwner}</td>
            <td className='activeDrivers--rate'>{!driverFired && rate}</td>
            <td className={`activeDrivers--cash ${cashColor}`}>{!driverFired && `₴ ${balance}`}</td>
            <td className='activeDrivers--auto'>{`${brand} ${model}`}</td>
            <td className='activeDrivers--button'><BlueLinkButton text="Історія" onClick={redirectToOrders} /></td>
        </tr>
    );
};

export default ActiveDriversItem;
