import React from 'react';
import "./address.scss";
import PageHeading from "../../../../assets/pageHeadings/PageHeading";
import Button from "../../../../assets/buttons/button/Button";
import Divider from "../../../../drivers/driverInfo/divider/Divider";
import CreateOrderModal from "../../../modals/createOrderModal/CreateOrderModal";
import useAddress from "./useAddress";

const Address = ({ order, destination }) => {

  const {
    showModal,
    addAddressInput,
    isLoaded,
    hideModal,
    onMarkerDrag,
    addModalAddress,
    getMarkerCoordinates,
    addressInputs,
    additionalInputs,
    markerLabel,
    canAddAddress
  } = useAddress(order, destination);

  return (
    <>
      <PageHeading text='АДРЕСА ПОДАЧІ' className="small marginBottom pageHeaderOrder" >
        {canAddAddress && <Button className="addressButton" withIcon={true} text='Додати проміжну адресу' onClick={() => addAddressInput()} />}
      </PageHeading>
      <div className="orderAddress">
        {isLoaded && addressInputs}
        {additionalInputs.length > 0 && additionalInputs}
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Divider />
      </div>
      <CreateOrderModal
        show={showModal}
        onHide={hideModal}
        isLoaded={isLoaded}
        coordinates={getMarkerCoordinates()}
        onMarkerDrag={onMarkerDrag}
        markerLabel={markerLabel}
        onConfirm={addModalAddress}
      />
    </>
  );
};

export default Address;
