import { useGetActiveOrdersQuery } from "../../api/orderApi";
import useGetState from "../../store/hooks/useGetState";

export const useGetActiveRides = (params) => {
  useGetActiveOrdersQuery(params);

  const { ordersByStatus } = useGetState();

  return ordersByStatus;
};
