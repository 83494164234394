import React from 'react';
import PropTypes from 'prop-types';

import ContentWrapper from '../../assets/wrappers/content/ContentWrapper';
import InnerWrapper from '../../assets/wrappers/innerWrapper/InnerWrapper';
import DriverInfo from '../driverInfo/DriverInfo';
import useRedirect from '../../../helpers/hooks/useRedirect';
import { getLinkWithSearchParams } from '../../../helpers/general/general';
import useDriverInfo from '../driverInfo/useDriverInfo';
import { getChatLink, getDriverRidesLink } from '../../../data/urls';
import notify from '../../../helpers/notify';
import useGetState from '../../../store/hooks/useGetState';

const DriverPage = ({
  pageHeading, controls, pageControls, driverStatus,
}) => {
  const {
    loading, handleSubmit, setPhotos, supportChatId, id,
  } = useDriverInfo(driverStatus);
  const { driver } = useGetState();
  const redirect = useRedirect();

  const redirectToOrders = () => redirect((getLinkWithSearchParams(getDriverRidesLink(id))));

  const redirectToHelpdesk = () => {
    if (!supportChatId) {
      notify('Звернення на службу підтримки відсутні', 'info');
    } else {
      redirect(getChatLink(supportChatId));
    }
  };

  return (
    <ContentWrapper
      pageTitle={pageHeading}
      pageControls={pageControls}
      isDriverPage
      driverStatus={driverStatus}
      redirectToOrders={redirectToOrders}
      redirectToHelpdesk={redirectToHelpdesk}
    >
      <InnerWrapper className="isDriverPage" withoutPadding>
        <DriverInfo
          controls={controls}
          loading={driver.isLoading || loading}
          handleSubmit={handleSubmit}
          setPhotos={setPhotos}
        />
      </InnerWrapper>
    </ContentWrapper>
  );
};
export default DriverPage;

DriverPage.propTypes = {
  pageHeading: PropTypes.string,
  pageControls: PropTypes.node,
  controls: PropTypes.node.isRequired,
  driverStatus: PropTypes.string.isRequired,
};
