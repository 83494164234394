import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { CloseCircleOutlined } from '@ant-design/icons';
import './address.scss';
import useGetState from '../../../../../store/hooks/useGetState';
import useBindCreators from '../../../../../store/hooks/useBindCreators';
import { orderActions } from '../../../../../store/slices/orderSlice';
import { ReactComponent as LocationIcon } from '../../../../../img/icons/inputs/input_location.svg';
import { ReactComponent as DeleteIcon } from '../../../../../img/icons/delete.svg';
import { alphabet } from '../../../../../helpers/general/general';
import {
  getInputAddress,
  getMarkerLocation,
} from '../../../../../helpers/visicom/visicomHelpers';

export default function useAddress(order, destination) {
  const [showModal, setShowModal] = useState(false);
  const { center, isMapLoaded } = useGetState();
  const [lastActiveAddress, setLastActiveAddress] = useState({});
  const [lastActiveInput, setLastActiveInput] = useState('');
  const [markerAddress, setMarkerAddress] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [optionsAddress, setOptionsAddress] = useState([]);
  const { address_inputs: ADDRESS_INPUTS, errors, fare } = order;
  const { addAddressInput, deleteAddressInput, addAddress, removeAddress } =
    useBindCreators(orderActions);
  const tariffByHours = fare?.type === 'hourly';
  const addressesLength = ADDRESS_INPUTS.length;
  const [fetchedAddressesAdded, setFetchedAddressesAdded] = useState(false);

  const addressesClassName = `orderAddress large`;

  const hideModal = () => {
    setShowModal(false);
    setMarkerAddress({});
  };

  const onIconClick = (inputId) => {
    setLastActiveAddress({});
    setLastActiveInput(inputId);
    const lastActiveAddress = order.addresses.find(
      (address) => address.inputId == inputId,
    );
    if (lastActiveAddress) {
      setLastActiveAddress(lastActiveAddress);
    }
    setShowModal(true);
  };

  const onDeleteAddressInput = (inputId) => {
    deleteAddressInput(inputId);
    setInputValues((prev) => {
      const values = prev;
      delete values[inputId];
      const newVal = Object.values(values).reduce((acc, value, index) => {
        const key = `new-order-address-${alphabet[index]}-${index}`;
        return { ...acc, [key]: value };
      }, {});
      return newVal;
    });
  };

  const onMarkerDrag = async (e, params) => {
    const { latLng } = e;
    if (latLng) {
      const lat = latLng.lat().toString();
      const lng = latLng.lng().toString();
      const decodedAddress = await getMarkerLocation(lng, lat);
      setMarkerAddress(decodedAddress);
    }
  };

  const addModalAddress = async () => {
    setLastActiveAddress({ ...markerAddress, inputId: lastActiveInput });
    addAddress({ ...markerAddress, inputId: lastActiveInput });
    setInputValues((prev) => ({
      ...prev,
      [lastActiveInput]: markerAddress.address,
    }));
  };

  const getMarkerCoordinates = () => {
    if (markerAddress?.latitude) {
      return { lat: +markerAddress.latitude, lng: +markerAddress.longitude };
    }

    if (lastActiveAddress?.latitude) {
      return {
        lat: +lastActiveAddress.latitude,
        lng: +lastActiveAddress.longitude,
      };
    }

    return center;
  };
  const onChange = async (e, inputId) => {
    if (e.target.value === '') {
      removeAddress(inputId);
      setOptionsAddress([]);
    }
    setInputValues((prev) => ({ ...prev, [inputId]: e.target.value }));
    const data = encodeURIComponent(e.target.value);
    const response = await getInputAddress(`Київ, ${data}`);
    const mappedOptions = response?.map((item) => ({
      label: item.address,
      value: item,
    }));

    setOptionsAddress(mappedOptions ? mappedOptions : []);
  };

  const onSelectAddress = (address, inputId, value) => {
    setInputValues((prev) => ({
      ...prev,
      [inputId]: address,
    }));
    addAddress({ ...value.value, inputId });
    setLastActiveAddress(value.value);
    setMarkerAddress('');
  };

  const handleClear = (e, inputId) => {
    setInputValues((prev) => ({ ...prev, [inputId]: '' }));
    removeAddress(inputId);
  };
  const onFocus = (e) => {
    if (e.target.value === '') {
      setOptionsAddress([]);
    }
  };

  const mapInputs = (inputs) => {
    return inputs.map((input, index) => (
      <div key={input.id} className="addressItem" id={input.id}>
        <div className="addressInput">
          <label htmlFor="">{input.label}</label>
          <Autocomplete
            getItemValue={(item) => item.label}
            items={optionsAddress}
            renderItem={(item, isHighlighted) => (
              <div style={{ background: isHighlighted ? '#EEF8FF' : 'white' }}>
                {item.label}
              </div>
            )}
            inputProps={{ onFocus: (e) => onFocus(e, input.id) }}
            value={inputValues[input.id]}
            onChange={(e) => onChange(e, input.id)}
            onSelect={(address, value) =>
              onSelectAddress(address, input.id, value)
            }
          />
          <LocationIcon
            onClick={() => onIconClick(input.id)}
            className="locationIcon"
            title="Обрати на мапі"
          />
          {inputValues[input.id] && (
            <button
              onClick={(e) => handleClear(e, input.id)}
              type="button"
              className="clearButton"
            >
              <CloseCircleOutlined />
            </button>
          )}
        </div>
        {input.deletable && (
          <div className="addressInput--delete">
            <DeleteIcon
              title="Видалити проміжну адресу"
              onClick={() => onDeleteAddressInput(input.id)}
            />
          </div>
        )}
      </div>
    ));
  };

  const addressInputs = mapInputs(
    tariffByHours ? ADDRESS_INPUTS.slice(0, 1) : ADDRESS_INPUTS.slice(0, 2),
  );
  const additionalInputs = mapInputs(ADDRESS_INPUTS.slice(2));
  const markerLabel = markerAddress?.latitude
    ? markerAddress.address
    : lastActiveAddress.address;
  const canAddAddress = !tariffByHours && addressesLength < 5;

  useEffect(() => {
    if (order.addresses && destination === 'edit' && !fetchedAddressesAdded) {
      order.addresses.forEach(({ inputId, address }, index) => {
        setInputValues((prev) => ({ ...prev, [inputId]: address }));
        if (index > 1) {
          addAddressInput();
        }
        setFetchedAddressesAdded(true);
      });
    }
  }, [destination, order.addresses]);

  return {
    showModal,
    addAddressInput,
    addressesClassName,
    isLoaded: isMapLoaded,
    hideModal,
    onMarkerDrag,
    addModalAddress,
    getMarkerCoordinates,
    addressInputs,
    additionalInputs,
    canAddAddress,
    markerLabel,
  };
}
