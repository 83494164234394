import { useEffect, useState } from "react";
import useSorting from "../../../helpers/hooks/useSorting";
import useAnalyticsService from "../../../services/analyticsService";
import DirectorWrapper from "../section/DirectorWrapper";
import "./analytics.scss";
import Charts from "./charts/Charts";
import AnalyticsSwitch from "./switch/AnalyticsSwitch";

const Analytics = () => {
    const { urlParamsHandler, getSearchParam, paramsObject } = useSorting();
    const termParamInUrl = getSearchParam('duration');
    const [time, setTime] = useState(termParamInUrl ? termParamInUrl : 'month');
    const [analytics, setAnalytics] = useState({});
    const { getAnalytics, loading } = useAnalyticsService();

    const fetchAnalytics = async (params = paramsObject) => {
        const analytics = await getAnalytics(params);
        setAnalytics(analytics);
    };

    useEffect(() => {
        if (!termParamInUrl) {
            urlParamsHandler([{ param: 'duration', value: 'month' }], [], false)
        }
    }, [time]);

    useEffect(() => {
        fetchAnalytics({ duration: "month" });
    }, []);

    return (
        <DirectorWrapper
            pageHeading='Аналітика'
            additionalControls={
                <AnalyticsSwitch
                    time={time}
                    setTime={setTime}
                    fetchAnalytics={fetchAnalytics}
                />}
        >
            <Charts analytics={analytics} loading={loading} />
        </DirectorWrapper>
    );
};

export default Analytics;