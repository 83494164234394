import { checkStateEmptyInputs } from '../../../../helpers/checkEmptyInputs';
import notify from '../../../../helpers/notify';

export const getRequestBody = (tariff, tariffCreation) => {
  const body = JSON.parse(JSON.stringify(tariff));
  delete body.errors;

  if (body.type !== 'hourly') {
    delete body.minimalMinutes;
    delete body.perMinutePrice;
  } else {
    delete body.deliveryPrice;
    delete body.downtimePrice;
    delete body.freeWaitingMinutes;
    delete body.intercityPerKilometerPrice;
    delete body.perKilometerPrice;
  }

  if (tariffCreation) {
    delete body.id;
  }

  return body;
};

export const getRequestBodyErrors = (data, setTariffErrors) => {
  const emptyInputs = checkStateEmptyInputs(data, setTariffErrors);

  if (emptyInputs > 0) {
    notify('Заповніть таблицю', 'error');
    return true;
  }

  return false;
};
