import "./dateInputWithLabel.scss";
import CustomDateInput from "../CustomDateInput";

const DateInputWithLabel = (props) => {
    return (
        <div className="dateInputWithLabel">
            <div className="customSelect--label">{props.label}</div>
            <CustomDateInput {...props} />
        </div>
    );
};

export default DateInputWithLabel;