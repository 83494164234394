import { Statistic } from 'antd';
import React from 'react';
import styles from "./styles.module.scss"

const formatCurrency = (value = 0) => {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  return parsedValue.toLocaleString('uk-UA', {
    style: 'currency',
    currency: 'UAH',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2
  });
}

export const OrdersStats = ({ loading, total = 0, finished = 0, cancelled = 0, profit = 0 }) => {
  return (
    <div style={{
      display: 'flex',
      gap: '0.75rem'
    }}>
      <Statistic
        title="Загальна кількість поїздок"
        loading={loading}
        value={total}
        className={styles.stats}
      />
      <Statistic
        title="Завершених"
        loading={loading}
        value={finished}
        className={styles.stats}
      />
      <Statistic
        title="Скасованих"
        loading={loading}
        value={cancelled}
        className={styles.stats}
      />
      <Statistic
        title="Сумма виконаних"
        loading={loading}
        className={styles.stats}
        value={formatCurrency(profit)}
      />
    </div>
  );
}
