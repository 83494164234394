import "./newUserRights.scss";
import Checkbox from "../../../../assets/inputs/checkbox/Checkbox";
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import { userActions } from "../../../../../store/slices/userSlice";
import { NEW_USER_CHECKBOXES } from "../../../../../data/director/newUserForm";
import useGetState from "../../../../../store/hooks/useGetState";

const NewUserRights = () => {
    const { setUser } = useBindCreators(userActions);
    const { user } = useGetState();

    const checkboxes = NEW_USER_CHECKBOXES.map(item =>
    (<div key={item.id} className="newUser--rights-item">
        <Checkbox
            checked={user.position === item.position}
            onChange={() => setUser({ key: 'position', value: item.position })}
            label={item.label}
        />
    </div>));

    return (
        <>
            <div className="newUser--rights-title">
                Права
                <span className="newUser--rights-title_span"> (дозволи, доступи)</span>
            </div>
            <div className="newUser--rights">
                {checkboxes}
            </div>
        </>
    );
};

export default NewUserRights;