import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import "./sidebarItem.scss";

const SidebarItem = ({ icon, activeIcon, navigateTo, title, active }) => {
    const { pathname } = useLocation();
    const isInUrl = item => pathname.includes(item);

    const isActive = () => {
        if (!Array.isArray(active)) {
            return isInUrl(active);
        } else {
            for (const item of active) {
                if (isInUrl(item)) {
                    return true;
                }
            }
        }
    };

    const itemIsActive = isActive();
    const itemClass = `sidebarItem ${itemIsActive ? 'active' : ''}`;
    const destination = itemIsActive ? '#' : navigateTo;
    const image = itemIsActive ? activeIcon : icon;

    return (
        <>
            <Link
                className={itemClass}
                to={destination}
                title={title}
            >
                {image}
            </Link>
            <div className="item-text">
                {title}
            </div>
        </>
    );
};

export default SidebarItem;