export const getStatus = (status) => {
  switch (status) {
    case 'active':
      return 'Працює';
    case 'fired':
      return 'Звільнений';
    case 'blocked':
      return 'Заблокований';
    case 'approved':
      return 'Підтверджено';
    case 'unprocessed':
      return 'Неопрацьовано';
    case 'rejected':
      return 'Відхилено';
  }
};

export const getChatStatus = (status) => {
  switch (status) {
    case 'new':
      return 'Новий';
    case 'processing':
      return 'Обробляється';
    case 'closed':
      return 'Закрито';
  }
};
