import "./marker.scss";
import { useState, useEffect } from 'react';

const Marker = (options) => {
    const { position: initialPosition, onClick, map, icon, type, id } = options;
    const [marker, setMarker] = useState();
    const gMaps = window.google.maps;
    const position = new gMaps.LatLng(initialPosition.lat, initialPosition.lng);

    useEffect(() => {
        if (!marker) {
            setMarker(new gMaps.Marker());
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);
    useEffect(() => {
        if (marker) {
            marker.setOptions({
                map: map,
                position: position,
                icon: icon,
                clickable: true,
            });
            if (id && type) {
                marker.setLabel({
                    text: id?.toString(),
                    className: `marker-label ${type}`
                })
            }
            if (onClick) {
                gMaps.event.clearInstanceListeners(marker);
                marker.addListener('click', onClick);
            }
        }
    }, [marker, options]);

    return null;
};

export default Marker;
