import { createSearchRow } from '../../helpers/general/general';

export const HELPDESK_STATUS = [
  {
    id: 'helpdesk_status_all',
    name: 'all',
    text: 'Статус: усі',
  },
  {
    id: 'helpdesk_status_progress',
    name: 'processing',
    text: 'Обробляється',
  },
  {
    id: 'helpdesk_status_new',
    name: 'new',
    text: 'Новий',
  },
  {
    id: 'helpdesk_status_closed',
    name: 'closed',
    text: 'Закрито',
  },
];

export const HELPDESK_HEADERS = [
  {
    id: 1,
    label: 'СТАТУС',
    name: 'status',
    sortable: false,
    searchable: false,
    className: 'helpdesk--status',
  },
  {
    id: 2,
    label: 'КОРИСТУВАЧ',
    name: 'user',
    sortable: true,
    searchable: false,
    className: 'helpdesk--user',
  },
  {
    id: 3,
    label: 'ID СКАРГИ',
    name: 'id',
    sortable: true,
    searchable: true,
    className: 'helpdesk--id',
  },
  {
    id: 4,
    label: 'ІМʼЯ ТА ПРІЗВИЩЕ',
    name: 'name',
    sortable: true,
    searchable: true,
    className: 'helpdesk--name',
  },
  {
    id: 5,
    label: 'ТЕЛЕФОН',
    name: 'phone',
    sortable: false,
    searchable: true,
    className: 'helpdesk--phone',
  },
  {
    id: 7,
    label: 'ДАТА',
    name: 'date',
    sortable: true,
    searchable: true,
    className: 'helpdesk--date',
  },
  {
    id: 8,
    label: '',
    name: 'message',
    sortable: false,
    searchable: false,
    className: 'helpdesk--message',
  },
];

export const HELPDESK_SEARCH_ROW = createSearchRow(HELPDESK_HEADERS);
