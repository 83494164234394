import { DRIVERS_APPLICATIONS_HEADERS, DRIVERS_APPLICATIONS_SEARCH_ROW } from "../../../../../data/drivers/tables/applications/driversApplications";
import "./driversApplications.scss";
import ApplicationDriverItem from "./ApplicationDriverItem";
import DefaultTable from "../../../../assets/table/defaultTable/DefaultTable";
import wrapDrivers from "../../wrapDrivers";

const Drivers = wrapDrivers(DefaultTable);

const DriversApplications = () =>
    <Drivers
        destination="allPending"
        headersInfo={DRIVERS_APPLICATIONS_HEADERS}
        searchRow={DRIVERS_APPLICATIONS_SEARCH_ROW}
        TableItem={ApplicationDriverItem}
    />;

export default DriversApplications;