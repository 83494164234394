import "./controls.scss";
import FormButton from "../../../assets/buttons/formButton/FormButton";
import { useNavigate } from "react-router";
import FormButtonsWrapper from "../../../assets/buttons/formButtonsWrapper/FormButtonsWrapper";

const AddDriverControls = () => {
    const navigate = useNavigate();
    const handleClick = () => navigate(-1);

    return (
        <div className="addDriverControls">
            <FormButtonsWrapper>
                <FormButton text="Зберегти" form="driverInfoForm" />
                <FormButton text="Скасувати" onClick={handleClick} color="white" type="button" />
            </FormButtonsWrapper>
        </div>
    );
};

export default AddDriverControls;