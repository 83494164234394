import ContentWrapper from '../../assets/wrappers/content/ContentWrapper';
import useGetDrivers from './table/hooks/useGetDrivers';
import useAllDrivers from './useAllDrivers';
import { useEffect } from 'react';
import useUrl from '../../../helpers/hooks/useUrl';
import DriversTableActions from './DriversTableActions';
import './../drivers.scss';
import useSorting from '../../../helpers/hooks/useSorting';

const wrapDrivers = (WrappedComponent) => {
  return function WrappedDrivers(props) {
    const { selectOptions, onSwitchClick, activeSwitch } = useAllDrivers();
    const { drivers, getAllDrivers, pagination, loading, onSelectOptionClick } =
      useGetDrivers(props.destination);
    const { paramsObject } = useUrl();
    const { urlParamsHandler } = useSorting();

    useEffect(() => {
      getAllDrivers({
        ...paramsObject,
        sortField: 'calling',
        sortDirection: 'asc',
      });
      urlParamsHandler([
        { param: 'sortField', value: 'calling' },
        { param: 'sortDirection', value: 'asc' },
      ]);
    }, []);

    return (
      <ContentWrapper
        pageTitle="Водії"
        className={'DriversTableWrap'}
        pageControls={
          <DriversTableActions
            activeSwitch={activeSwitch}
            onSwitchClick={onSwitchClick}
            onSelectOptionClick={onSelectOptionClick}
            selectOptions={selectOptions}
          />
        }
      >
        <WrappedComponent
          {...props}
          bodyInfo={drivers}
          maxPage={pagination.lastPage}
          loading={loading}
          onPageChange={getAllDrivers}
        />
      </ContentWrapper>
    );
  };
};

export default wrapDrivers;
