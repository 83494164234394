import { GET_ANALYTICS } from '../data/endpoints';
import notify from '../helpers/notify';
import useHttpService from './httpService';

const useAnalyticsService = () => {
  const { request, loading } = useHttpService();

  const getAnalytics = async (params) => {
    try {
      const analytics = await request({ url: GET_ANALYTICS, params });
      return analytics;
    } catch (error) {
      notify("Не вдалося отримати дані", 'error');
      return {};
    }
  };

  return { getAnalytics, loading };
};

export default useAnalyticsService;
