import React from "react";
import { isNil } from 'lodash';

export default function CarsIcon({ color, calling }) {

  return (
    <svg width="29" height="43" viewBox="0 0 29 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.4094 14.5309C28.4094 7.24263 21.7325 0.347801 14.4487 0.347801C6.71565 0.347801 0.409363 6.70639 0.409363 14.539C0.409363 20.6984 9.71119 35.4713 14.4487 42.3478C18.8711 35.9255 28.4094 21.2351 28.4094 14.5309L26.8232 14.5378L25.3813 14.5309C25.3813 20.0352 19.9511 25.8992 14.4487 25.8992C8.49718 25.8992 3.4636 20.5548 3.4636 14.5309C3.4636 8.50693 8.51278 3.1615 14.4723 3.1615C19.9745 3.1615 25.3813 9.0266 25.3813 14.5309L26.8232 14.5378L28.4094 14.5309Z" fill="#686866" />
      <ellipse cx="14.4224" cy="14.5382" rx="10.9588" ry="11.3767" fill={color} />
      {!isNil(calling) && (
        <text style={{
          fontSize: 10,
          fill: "#000000",
          textAlign: "center",
        }} x="50%" y="38%" dominantBaseline="middle" textAnchor="middle">{calling}
        </text>
      )}
    </svg>

  )
}
