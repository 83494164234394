import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';

const BlueLinkButton = ({
  onClick, text = 'Прийняти', id = '', type = 'button',
}) => <Button type={type} id={id} text={text} onClick={onClick} color="link--blue" />;
export default BlueLinkButton;

BlueLinkButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
};
