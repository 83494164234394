import React from 'react';
import TableControls from '../../assets/wrappers/tableControls/TableControls';
import Switch from '../../assets/switch/Switch';
import { SWITCH_ITEMS } from '../../../data/drivers/switch/switch';
import CustomSelect from '../../assets/inputs/customSelect/CustomSelect';
import AddDriverButton from '../driver/addDriver/AddDriverButton';

const DriversTableActions = ({
  activeSwitch,
  onSwitchClick,
  selectOptions,
  onSelectOptionClick,
}) => {
  return (
    <TableControls>
      <AddDriverButton />
      <Switch
        activeSwitch={activeSwitch}
        onClick={onSwitchClick}
        items={SWITCH_ITEMS}
      />
      <CustomSelect options={selectOptions} onClick={onSelectOptionClick} />
    </TableControls>
  );
};

export default DriversTableActions;
