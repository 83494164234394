import { DRIVER_ADD } from "../../../../data/consts";
import useRedirect from "../../../../helpers/hooks/useRedirect";
import Button from "../../../assets/buttons/button/Button";

const AddDriverButton = () => {
    const redirect = useRedirect();
    const onClick = () => redirect(DRIVER_ADD);
    return <Button withIcon={true} text='Додати водія' onClick={onClick} />;
};

export default AddDriverButton;