import FormInput from "../../../../assets/inputs/FormInput";
import useGetState from "../../../../../store/hooks/useGetState";
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import { driverActions } from "../../../../../store/slices/driverSlice";
import {
  DRIVER_CAR_ALL_INPUTS
} from "../../../../../data/forms/driverVehicleForm";

const VehicleForm = () => {
  const { driver } = useGetState();
  const vehicle = driver.car;
  const errors = driver.errors;
  const { setVehicle, setDriverErrors } = useBindCreators(driverActions);

  const mapInputs = (data) => {
    const column = data.map(input =>
      <FormInput
        key={input.id}
        value={vehicle[input.name] ? vehicle[input.name] : ''}
        onChange={handleChange}
        errorClass={errors[input.name] ? 'error' : ''}
        customErrorMessage={errors[input.name] ? errors[input.name] : ''}
        {...input}
      />);
    return <>{column}</>
  };

  const handleChange = ({ target }) => {
    const { name: key, value } = target;
    setVehicle({ key, value });
    if (errors[key]) {
      setDriverErrors({ [key]: '' });
    }
  };

  const renderFields = mapInputs(DRIVER_CAR_ALL_INPUTS)

  return (
    <div className="infoVehicle--form">
      <div className="form personalInfo--form">
        {renderFields}
      </div>
    </div>
  );
};

export default VehicleForm;
