import useBindCreators from "../../../../../store/hooks/useBindCreators";
import useGetState from "../../../../../store/hooks/useGetState";
import { tariffActions } from "../../../../../store/slices/tariffSlice";
import Switch from "../../../../assets/switch/Switch";

export const SWITCH_ITEMS = [
    {
        id: 'mileage',
        name: 'Кілометраж'
    },
    {
        id: 'taximeter',
        name: 'Таксометр'
    },
    {
        id: 'hourly',
        name: 'Погодинний'
    }
];

const TariffSwitch = () => {
    const { tariff } = useGetState();
    const { setTariff } = useBindCreators(tariffActions);

    const onSwitchClick = ({ target }) => {
        if (!target.classList.contains('active')) {
            setTariff({ key: 'type', value: target.id });
        }
    };

    return (
        <div style={{ marginBottom: '32px' }}>
            <Switch
                activeSwitch={tariff.type}
                onClick={onSwitchClick}
                items={SWITCH_ITEMS}
                className="big"
            />
        </div>
    );
};

export default TariffSwitch;