import { useParams } from 'react-router';
import React, { useEffect } from 'react';
import ContentWrapper from '../../assets/wrappers/content/ContentWrapper';
import BackButton from '../../assets/buttons/backButton/BackButton';
import NewOrder from '../createOrder/table/NewOrder';
import useRidesService from '../../../services/ridesService';
import useBindCreators from '../../../store/hooks/useBindCreators';
import { orderActions } from '../../../store/slices/orderSlice';

const EditOrder = () => {
  const { id } = useParams();
  const { getRide } = useRidesService();
  const { setFetchedOrder } = useBindCreators(orderActions);

  const fetchRide = async () => {
    const ride = await getRide(id);

    const order = {
      ...ride,
    };

    if (ride.driver) {
      order.driver = {
        id: ride.driver.id,
        calling: ride.driver.calling,
      };
    }

    setFetchedOrder(order);
  };

  useEffect(() => {
    fetchRide();
  }, []);

  return (
    <ContentWrapper className={'WithOutNav isOverflowAuto'}>
      <BackButton />
      <NewOrder destination="edit" id={id} />
    </ContentWrapper>
  );
};

export default EditOrder;
