import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { ReactComponent as BackIcon } from '../../../../img/icons/backButton.svg';
import './backButton.scss';

const BackButton = ({ navigateTo }) => {
  const navigate = useNavigate();
  const handleClick = () => (navigateTo ? navigate(navigateTo) : navigate(-1));

  return (
    <div className="backButton" onClick={handleClick} data-testid="back-button">
      <BackIcon />
      <span className="backText">Назад</span>
    </div>
  );
};

export default BackButton;

BackButton.propTypes = {
  navigateTo: PropTypes.string,
};
