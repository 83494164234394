import "./appointDriverModal.scss";
import Modal from "../../assets/modal/Modal";
import Divider from "../../drivers/driverInfo/divider/Divider";
import { ReactComponent as Rating } from "../../../img/icons/map/driver_rating.svg";
import { useEffect, useState } from "react";
import FormButton from "../../assets/buttons/formButton/FormButton";
import { driver } from "../../../data/ordersMap/ordersMap";
import AppointDriverInfo from "./AppointDriverInfo";

const AppointDriverModal = ({ show, onHide }) => {
    const [submitted, setSubmitted] = useState(false);
    const modalTitle = submitted ? 'Водія призначено!' : 'Дійсно хочете призначити цього водія?';
    const onSubmit = () => setSubmitted(true);
    const { orderId, name, rating } = driver;

    useEffect(() => {
        return () => setSubmitted(false);
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            onSubmit={onSubmit}
            title={modalTitle}
            hideButtons={submitted}
            customButtons={<FormButton text='Призначити' onClick={onSubmit} />}
        >
            <div className="appointDriverModal--orderId">
                <span>ID замовлення:</span>
                <span>{`#${orderId}`}</span>
            </div>
            <Divider className="smallMargin" />
            <div className="appointDriverModal--orderId-shortInfo">
                <div className="appointDriverModal--orderId-photo" />
                <div className="appointDriverModal--orderId-info">
                    <div className="appointDriverModal--orderId-info_name">{name}</div>
                    <Rating />
                    <span className="appointDriverModal--orderId-info_rating">
                        {rating}
                    </span>
                </div>
            </div>
            <Divider className="smallMargin" />
            <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                <AppointDriverInfo />
            </div>
        </Modal>
    );
};

export default AppointDriverModal;
