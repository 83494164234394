import "../../../../clientss/clientPage/clientForm/clientForm.scss"
import Divider from "../../../../drivers/driverInfo/divider/Divider";
import { NEW_USER_INFO_FORM_COL_1, NEW_USER_INFO_FORM_COL_2, NEW_USER_INFO_FORM_COL_3 } from "../../../../../data/director/newUserForm";
import useCreateUserForm from "./useCreateUserForm";

const CreateUserForm = ({ action }) => {
    const { mapInputs, handleSubmit } = useCreateUserForm(action);
    return (
        <div className="clientInfo--form">
            <form id="addNewUser" onSubmit={handleSubmit}>
                <fieldset>
                    {mapInputs(NEW_USER_INFO_FORM_COL_1)}
                    {mapInputs(NEW_USER_INFO_FORM_COL_2)}
                    {mapInputs(NEW_USER_INFO_FORM_COL_3)}
                </fieldset>
            </form>
            <Divider />
        </div>
    );
};

export default CreateUserForm;