export const DRIVER_REQUESTS = [
  {
    id: 'all',
    name: 'all',
    text: 'Статус: усі',
  },
  {
    id: 'approved',
    name: 'approved',
    text: 'Підтверджено',
  },
  {
    id: 'unprocessed',
    name: 'unprocessed',
    text: 'Неопрацьовано',
  },
  {
    id: 'rejected',
    name: 'rejected',
    text: 'Відхилено',
  },
];
