import { getUserUpdateLink } from "../../../../data/urls";
import useRedirect from "../../../../helpers/hooks/useRedirect";
import useUsersService from "../../../../services/usersService";
import "./userTable.scss";

const UserTableItem = ({ id, name, position, phoneNumber, email, handleRefetch }) => {
    const { deleteUser } = useUsersService();
    const userIsDirector = position === 'director';
    const titleClassName = userIsDirector ? 'blue' : '';
    const title = userIsDirector ? 'Директор' : 'Диспетчер';
    const redirect = useRedirect();

    const onDelete = async () => {
        await deleteUser(id);
        await handleRefetch();
    };

    const redirectToUser = (e) => {
        if (e.target.className !== "directorUsers--delete-button") {
            redirect(getUserUpdateLink(id));
        }
    };

    return (
        <tr onClick={redirectToUser}>
            <td className="directorUsers--id">{`#${id}`}</td>
            <td className="directorUsers--name">{name}</td>
            <td className={`directorUsers--title ${titleClassName}`}>{title}</td>
            <td className="directorUsers--phone">{phoneNumber}</td>
            <td className="directorUsers--email">{email}</td>
            <td className="directorUsers--delete">
                <div className="directorUsers--delete-button" onClick={onDelete}>Видалити</div>
            </td>
        </tr>
    );
};

export default UserTableItem;