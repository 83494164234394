import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import FormInputWithIcon from '../formInputWithIcon/FormInputWithIcon';

const SearchAddressForm = ({
  strictBounds = false,
  types = [],
  isLoaded,
  ...props
}) => {
  const [autocomplete, setAutocomplete] = useState();
  const options = {
    componentRestrictions: {
      country: ['ua'],
    },
    strictBounds,
    types,
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      props.onSelect(autocomplete.getPlace());
    }
  };

  const onLoadHandler = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  if (!isLoaded) return null;
  const bounds = strictBounds
    ? new google.maps.LatLngBounds(
        new google.maps.LatLng(50.213273, 30.2392), // Юго-Западные координаты границы Киева
        new google.maps.LatLng(50.578124, 30.811169), // Северо-Восточные координаты границы Киева
      )
    : undefined;
  return (
    <Autocomplete
      onPlaceChanged={onPlaceChanged}
      onLoad={onLoadHandler}
      options={options}
      bounds={bounds}
    >
      <FormInputWithIcon {...props} />
    </Autocomplete>
  );
};

export default SearchAddressForm;
