import React from 'react';
import PropTypes from 'prop-types';
import './formInputWithIcon.scss';
import { CloseCircleOutlined } from '@ant-design/icons'
import { ReactComponent as CashIcon } from '../../../../img/icons/inputs/cash_icon.svg';

const FormInputWithIcon = (
  {
    label,
    onChange,
    id,
    errorMessage,
    errorClass,
    customErrorMessage,
    icon = <CashIcon />,
    hideIcon,
    allowClear = false,
    ...inputProps
  }
) => {
  const handleClear = () => {
    onChange({ target: { value: '' } });
  }
  return <>
    <label htmlFor={id} className="iconInput--label">{label}</label>
    <div className={`iconInput ${errorClass ? 'error' : ''}`}>
      <div className="iconInput--input">
        <input
          {...inputProps}
          id={id}
          onChange={onChange}
          autoComplete="off"
          onSelect={() => { }}
        />
      </div>
      {!hideIcon && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icon}
        </div>
      )}
      {allowClear && (
        <button onClick={handleClear} type='button' className='clearButton'>
          <CloseCircleOutlined />
        </button>
      )}
    </div>
    <span className={`iconInput--errorMessage ${errorClass}`}>{customErrorMessage || errorMessage}</span>
  </>;
};

export default FormInputWithIcon;

FormInputWithIcon.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  errorMessage: PropTypes.string,
  errorClass: PropTypes.string,
  customErrorMessage: PropTypes.string,
  icon: PropTypes.element,
  hideIcon: PropTypes.bool,
};
