export function getAddressFromVisicomPlace(data) {
  const { properties, geo_centroid } = data;
  return {
    id: data.id || ' ',
    address:
      `${properties.address ? properties.address + ' ' : ''}${
        properties.name ? properties.name + ',' : ''
      }`.trim() || ' ',
    longitude: geo_centroid.coordinates[0],
    latitude: geo_centroid.coordinates[1],
    placeId: data.id || ' ',
  };
}
