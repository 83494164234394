import React from 'react';

import Modal from '../../../assets/modal/Modal';
import RouteModalMap from './map/RouteModalMap';
import UserRoute from './userRoute/UserRoute';

import './showRouteModal.scss';

const ShowRouteModal = ({
  show,
  onHide,
  points,
  client,
  unfinished,
  status,
  orderId,
  onShowCancelModal,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      hideButtons={true}
      className="medium noPadding"
    >
      <div className="routeModal--wrapper">
        <RouteModalMap points={points} />
        <UserRoute
          points={points}
          client={client}
          orderId={orderId}
          onShowCancelModal={onShowCancelModal}
        />
      </div>
    </Modal>
  );
};

export default ShowRouteModal;
