import useBindCreators from "../../../../../../store/hooks/useBindCreators";
import { orderActions } from "../../../../../../store/slices/orderSlice";
import DateInputWithLabel from "../../../../../assets/inputs/dateInput/withLabel/DateInputWithLabel";
import { ReactComponent as ClockIcon } from "../../../../../../img/icons/inputs/input_clock.svg";
import "./selectTime.scss";
import useSorting from "../../../../../../helpers/hooks/useSorting";
import React from 'react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';

const SelectTime = ({ order, destination }) => {
    const hours = order.hours;
    const errors = order.errors;
    const { setOrder, setOrderErrors } = useBindCreators(orderActions);
    const { urlParamsHandler } = useSorting();
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 7);
    const isEditing = destination === "edit";

    function onDateChange(_, date) {
        const prependZero = (current) => current < 10 ? `0${current}` : current;
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const stringDate = `${prependZero(day)}.${prependZero(+month + 1)}.${year}`;
        if (isEditing) {
            urlParamsHandler([{ param: 'scheduledFor', value: stringDate }], [], false);
        }
        setOrder({ key: 'date', value: stringDate });
    }

    return (
        <div className="orderAppointment selectTime">
            <div className="selectTime--item customDate--wrapper">
                <DateInputWithLabel
                    label="ОБЕРІТЬ ДАТУ"
                    placeholder="Оберіть дату поїздки"
                    name="scheduledFor"
                    onChange={onDateChange}
                    minDate={today}
                    maxDate={tomorrow}
                    withUrlSearchParam={isEditing}
                />
            </div>
            <div style={{ width: '50%', marginLeft: '0.25rem' }}>
                <div className='customSelect--label'>ЧАС</div>
                <TimePicker
                  showSecond={false}
                  format={'HH:mm'}
                  showNow={false}
                  value={hours ? dayjs(hours, 'HH:mm') : undefined}
                  status={errors.hours ? 'error' : ""}
                  size='large'
                  clearIcon={false}
                  name='hours'
                  suffixIcon={<ClockIcon />}
                  onChange={(_, value) => {
                    setOrder({ key: 'hours', value });
                    if (errors.hours) {
                        setOrderErrors({ hours: '' });
                    }
                  }}
                  changeOnBlur
                  locale={{
                    lang: {
                      ok: "Підтвердити",
                      locale: "uk",
                      today: "Сьогодні",
                      clear: "Очистити",
                      timeSelect: "Обрати час",
                      dateSelect: "Обрати дату",
                      monthSelect: "Обрати місяць",
                      yearSelect: "Обрати рік",
                      weekSelect: "Обрати неділю",
                      yearPlaceholder: "Обрати рік",
                      monthPlaceholder: "Обрати місяць",
                      weekPlaceholder: "Обрати неділю",
                      backToToday: "Назад до сьогодні",
                      now: "Сьогодні",
                      yearFormat: "YYYY",
                      dateFormat: "DD.MM.YYYY",
                      dayFormat: "DD",
                      monthFormat: "MM",
                      nextYear: "Наступний рік",
                      nextMonth: "Наступний місяць",
                      previousYear: "Попередній рік",
                      previousMonth: "Попередній місяць",
                      year: "Рік",
                      month: "Місяць",
                      nextDecade: "Наступний десятилітній",
                      previousDecade: "Попередній десятилітній",
                      decadeSelect: "Обрати десятилітній",
                      dateTimeFormat: "DD.MM.YYYY HH:mm",
                      previousCentury: "Попередній век",
                      nextCentury: "Наступний век",
                      placeholder: "Обрати час",
                      shortMonths: ["Січ", "Лют", "Бер", "Кві", "Тра", "Чер", "Лип", "Сер", "Вер", "Жов", "Лис", "Гру"],
                      monthBeforeYear: true,
                      quarterFormat: "Q",
                      shortWeekDays: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                      quarterPlaceholder: "Обрати квартал",
                      rangeMonthPlaceholder: ["Обрати місяць", "Обрати місяць"],
                      rangeYearPlaceholder: ["Обрати рік", "Обрати рік"],
                      rangePlaceholder: ["Обрати місяць", "Обрати місяць"],
                      rangeQuarterPlaceholder: ["Обрати квартал", "Обрати квартал"],
                      rangeWeekPlaceholder: ["Обрати неділю", "Обрати неділю"],
                    },
                    timePickerLocale: {
                      placeholder: "Оберіть час",
                      rangePlaceholder: ["Обрати час", "Обрати час"],
                    }
                  }}
                  style={{
                    padding: "10px 11px",
                    width: "100%",
                  }}
                />
            </div>
        </div>
    );
};

export default SelectTime;
