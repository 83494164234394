import React from 'react';
import PropTypes from 'prop-types';
import './headers.scss';

const Headers = ({ type, text }) => {
  switch (type) {
    case ('h1'):
      return <h1 className="pageHeader">{text}</h1>;
    case ('h2'):
      return <h2 className="pageHeader">{text}</h2>;
    case ('h3'):
      return <h3 className="pageHeader">{text}</h3>;
    case ('h4'):
      return <h4 className="pageHeader">{text}</h4>;
    case ('h5'):
      return <h5 className="pageHeader">{text}</h5>;
    case ('h6'):
      return <h6 className="pageHeader">{text}</h6>;
    default:
      return <h1 className="pageHeader">{text}</h1>;
  }
};

export default Headers;

Headers.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};
