import React from 'react';
import './client.scss';
import ContentWrapper from '../../assets/wrappers/content/ContentWrapper';
import BackButton from '../../assets/buttons/backButton/BackButton';
import TableControls from '../../assets/wrappers/tableControls/TableControls';
import PageHeading from '../../assets/pageHeadings/PageHeading';
import Button from '../../assets/buttons/button/Button';
import InnerWrapper from '../../assets/wrappers/innerWrapper/InnerWrapper';
import ClientControls from './ClientControls';
import ClientForm from './clientForm/ClientForm';
import ClientCard from './clientCard/ClientCard';
import useClient from './useClient';
import Spinner from '../../assets/spinner/Spinner';
import useClientsService from '../../../services/clientsService';
import notify from '../../../helpers/notify';
import Balance from '../../assets/balance/Balance';

const Client = () => {
  const {
    redirectToHelpdesk,
    redirectToOrders,
    loading,
    client,
    deleteCard,
    id,
    setClient,
  } = useClient();
  const { updateClientInfo, increaseClientBalance, decreaseClientBalance } =
    useClientsService();

  const onFinish = async (values) => {
    try {
      await updateClientInfo(id, values);
    } catch (err) {
      notify(err, 'error');
    }
  };
  const setAmountOnSuccess = (amount) =>
    setClient({ ...client, balance: amount.value });

  return (
    <ContentWrapper
      pageTitle="Клієнти"
      pageControls={<ClientControls client={client} />}
    >
      <BackButton />
      <TableControls marginTop>
        <PageHeading text="Інформація про клієнта" additionalInfo={`#${id}`} />
        <div className="controlsWrapper--buttons">
          <Button text="Історія поїздок" onClick={redirectToOrders} />
          <Button text="Служба підтримки" onClick={redirectToHelpdesk} />
        </div>
      </TableControls>
      <InnerWrapper withoutPadding>
        {loading ? (
          <Spinner />
        ) : (
          <div className={'FormWrapBalance'}>
            <ClientForm client={client} onFinish={onFinish} />
            <Balance
              user={client}
              loading={loading}
              increaseBalance={increaseClientBalance}
              decreaseBalance={decreaseClientBalance}
              setUser={setAmountOnSuccess}
            />
          </div>
        )}
        <ClientCard
          cards={client.cards || []}
          onDelete={deleteCard}
          loading={loading}
        />
      </InnerWrapper>
    </ContentWrapper>
  );
};

export default Client;
