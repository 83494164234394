export const INITIAL_SEARCH_PARAMS =
  '?sortField=id&sortDirection=desc&perPage=15&page=1';
export const INITIAL_SEARCH_PARAMS_CLIENTS =
  '?sortField=id&sortDirection=asc&perPage=15&page=1';
export const DIRECTOR_RIDE = '/director/ride';
export const MANAGER_USER = '/manager/user';
export const CHAT = '/chat';
export const MANAGER_SUPPORT_CHAT = '/manager/supportChat/';
export const DRIVERS_BASE = `${MANAGER_USER}/driver/`;
export const LOGIN = '/login';
export const PASSWORD_RESET = '/reset_password';
export const CHECK_EMAIL = '/check_email';
export const ENTER_CODE = '/enter_code';
export const ACTIVE_DRIVERS = `${DRIVERS_BASE}all`;
export const APPLICATIONS_DRIVERS = `${DRIVERS_BASE}allPending`;
export const ORDER_MAPS = '/order-maps';
export const ORDERS = '/orders/all';
export const ORDERS_CREATE = '/orders/create';
export const ORDERS_EDIT = '/orders/:id/edit';
export const CLIENTS = `${MANAGER_USER}/client/all`;
export const CLIENT = `${MANAGER_USER}/client/:id/get`;
export const CLIENT_CREATE = `${MANAGER_USER}/client/create`;
export const CLIENT_ORDERS = `${MANAGER_USER}/client/:id/orders`;
export const HELPDESK = `${MANAGER_SUPPORT_CHAT}all`;
export const HELPDESK_CHAT = `${MANAGER_SUPPORT_CHAT}chat/:id/messages`;
export const DIRECTOR = '/director';
export const DRIVER_ADD = `${MANAGER_USER}/driver/add`;
export const DRIVER_EDIT = `${MANAGER_USER}/driver/:id/get`;
export const DRIVER_REQUEST = `${MANAGER_USER}/driver/:id/request/get`;
export const DRIVER_APPLICATION = `${MANAGER_USER}/driver/:id/pending/get`;
export const DRIVER_ORDERS = `${MANAGER_USER}/driver/:id/rides`;
export const DIRECTOR_TARIFFS = `${DIRECTOR_RIDE}/fares/all`;
export const DIRECTOR_USERS = '/director/user';
export const DIRECTOR_ANALYTICS = '/director/analytics';
export const DIRECTOR_SERVICES = '/director/services';

export const DIRECTOR_UNITS = '/director/units';
export const DIRECTOR_USERS_CREATE = `${DIRECTOR_USERS}/manager/create`;
export const DIRECTOR_USERS_UPDATE = `${DIRECTOR_USERS}/manager/:id/update`;
export const MAP_RIDES = '/manager/ride/all';

export const defaultSearchParams = {
  page: 1,
  perPage: 15,
  sortField: 'id',
  sortDirection: 'desc',
};

export const defaultSearchParamsClients = {
  page: 1,
  perPage: 15,
  sortField: 'id',
  sortDirection: 'asc',
};

export const defaultSearchParamsDriver = {
  page: 1,
  perPage: 15,
  sortField: 'id',
  sortDirection: 'asc',
};

export const DEFAULT_PAGINATION = { currentPage: 1, total: 1, lastPage: 1 };
export const PASSWORD_PATTERN =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,16}$';
export const DRIVER_INFO_PASSWORD_PATTERN = '^[A-Za-z0-9!@#$%^&*]{8,16}$';
export const TEXT_PATTERN = '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9.\\s]{1,64}$';
export const RIDE_STATUSES = {
  UNASSIGNED: 'unassigned',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  ASSIGNED: 'assigned',
  APPROVED: 'approved',
  ARRIVED: 'arrived',
  STARTED: 'started',
  PAUSED: 'paused',
  RESUMED: 'resumed',
  FINISHED: 'finished',
};
export const SOCKET_EVENTS = {
  CHAT_MESSAGE: 'chatMessage',
  DRIVER_LOCATION: 'driverLocation',
  DRIVERS_IN_RADIUS: 'driversInRadius',
  RIDE_UPDATED: 'rideUpdated',
};
export const DRIVER_STATUSES = {
  FREE: 'free',
  ACTIVE: 'active',
  BUSY: 'busy',
  APPROVED: 'approved',
  ARRIVED: 'arrived',
  BLOCKED: 'blocked',
};
export const CLIENT_STATUSES = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
};
export const FARE_TYPES = {
  MILEAGE: 'mileage',
  HOURLY: 'hourly',
};
export const DATE_FORMATS = {
  TIME: 'HH:mm',
  DATE: 'DD.MM.YYYY',
};
export const LOCAL_STORE_VIEWPORT_CENTER = 'local_store_viewport_center';

export const finishedStatuses = [
  RIDE_STATUSES.CANCELLED,
  RIDE_STATUSES.FINISHED,
];
export const currentStatuses = [
  RIDE_STATUSES.STARTED,
  RIDE_STATUSES.PAUSED,
  RIDE_STATUSES.RESUMED,
  RIDE_STATUSES.ARRIVED,
];
export const unprocessedStatuses = [
  RIDE_STATUSES.UNASSIGNED,
  RIDE_STATUSES.PENDING,
  RIDE_STATUSES.ASSIGNED,
];
export const futureStatuses = [RIDE_STATUSES.APPROVED];
export const VISICOM_API_KEY = process.env.REACT_APP_VISICOM_MAPS_API_KEY;
export const baseVisicomUrl =
  'https://api.visicom.ua/data-api/5.0/uk/geocode.json';
