import React from 'react';
import PropTypes from 'prop-types';
import { ORDERS_CREATE } from '../../../../data/consts';
import useRedirect from '../../../../helpers/hooks/useRedirect';
import Button from '../../../assets/buttons/button/Button';

const CreateOrderButton = ({ disabled }) => {
  const redirect = useRedirect();
  const onClick = () => redirect(ORDERS_CREATE);
  return <Button disabled={disabled} withIcon text="Створити замовлення" onClick={onClick} />;
};

export default CreateOrderButton;

CreateOrderButton.propTypes = {
  disabled: PropTypes.bool,
};
