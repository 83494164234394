import { useEffect, useRef, useState } from 'react';
import Centrifuge from 'centrifuge';
import { findIndex } from 'lodash';
import { flushSync } from 'react-dom';
import useBindCreators from '../../store/hooks/useBindCreators';
import useGetState from '../../store/hooks/useGetState';
import { authActions } from '../../store/slices/authSlice';
import { RIDE_STATUSES, SOCKET_EVENTS } from '../../data/consts';
import notify from '../notify';
import CarsStreamCollections from '../dispatchers/CarsStreamCollections';
import { mapActions } from '../../store/slices/mapSlice';
import music from '../../assets/icq.mp3';
import { useLazyGetActiveDriversInRadiusQuery } from '../../api/driverApi';

export default function useCentrifuge() {
  const centrifuge = useRef();
  const {
    token, wsToken, wsChannel, currentUserId, activeOrders, map,
  } = useGetState();
  const [centrifugeConnection, setCentrifugeConnection] = useState(null);
  const { logout } = useBindCreators(authActions);
  const { mergeOrders } = useBindCreators(mapActions);

  const [getActiveDrivers] = useLazyGetActiveDriversInRadiusQuery();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      if (shouldUpdate) {
        const store = CarsStreamCollections.singleton();
        getActiveDrivers({
          latitude: map.center.lat,
          longitude: map.center.lng,
        }).unwrap().then((data) => {
          store.updateAll(data.drivers);
        });
      }
    }, 1000 * 30);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [shouldUpdate]);

  const onSocketEventsHandler = (ctx) => {
    const { data } = ctx;
    const { type, payload } = data;
    switch (type) {
      case SOCKET_EVENTS.CHAT_MESSAGE: {
        if (currentUserId != payload.user.id) {
          notify(`У вас нове повідомлення в чаті ${payload.chatId}`, 'info');
        }
        return;
      }
      case SOCKET_EVENTS.DRIVER_LOCATION: {
        const store = CarsStreamCollections.singleton();
        store.addItem(payload);
        return;
      }
      case SOCKET_EVENTS.DRIVERS_IN_RADIUS: {
        flushSync(() => {
          setShouldUpdate(false);
        });
        if (data?.payload?.drivers && Array.isArray(data.payload.drivers)) {
          const store = CarsStreamCollections.singleton();

          const { drivers } = data.payload;
          store.updateAll(drivers);
          setShouldUpdate(true);
        }
        return;
      }
      case SOCKET_EVENTS.RIDE_UPDATED: {
        const orderIdx = findIndex(activeOrders, { id: data.payload.ride.id });

        if (orderIdx === -1 && data.payload.ride.status === RIDE_STATUSES.UNASSIGNED) {
          const audio = new Audio(music);
          audio.play();
        }

        data?.payload?.ride && mergeOrders(data.payload.ride);
      }
      default: {}
    }
  };
  const configureWebSocket = () => {
    if (token && wsChannel && wsToken) {
      const centrifuge = new Centrifuge(
        `${process.env.REACT_APP_API_WS_PROTOCOL}://${process.env.REACT_APP_API_WS_URL}/centrifugo/connection/websocket`,
        { subscribeEndpoint: `${process.env.REACT_APP_API_URL}/websocket/subscribe` },
      );
      centrifuge.addListener
      centrifuge.current = centrifuge;

      centrifuge?.current.setToken(wsToken);
      centrifuge?.current.subscribe(wsChannel, onSocketEventsHandler);
      setCentrifugeConnection(centrifuge?.current.subscribe(wsChannel));
      centrifuge.current?.connect();
    }
  };

  useEffect(() => {
    if (!token) logout();
    if (!token && centrifuge?.current) centrifuge.current?.disconnect();
  }, [token]);
  useEffect(() => { configureWebSocket(); }, [wsToken, wsChannel, token]);

  return { centrifugeConnection };
}
