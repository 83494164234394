import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logger from '../../../../helpers/general/logger';
import GoogleMap from '../map';
import './styles.scss';

const GoogleMapPolyline = (props) => {
  let _instance = null;
  const { coords } = props;

  const componentDidMount = () => {
    const GMap = GoogleMap.getInstance();
    if (GMap && coords && coords.length) {
      _instance = new GMap.Maps.Polyline({
        path: coords,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeWeight: 2,
      });
      _instance.setMap(GMap.instance);
    }
  };
  const componentWillUnmount = () => {
    if (_instance) {
      _instance.setVisible(false);
      _instance.setMap(null);
      _instance = null;
    }
  };

  useEffect(() => {
    componentDidMount();
    return () => componentWillUnmount();
  }, []);

  return (
    <div className="GoogleMapsPolyline" lat={0} lng={0} />
  );
};
GoogleMapPolyline.propTypes = {
  coords: PropTypes.array,
};

export default GoogleMapPolyline;
