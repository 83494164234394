import { DRIVER_STATUSES, RIDE_STATUSES } from '../consts';
import svgRideApproved from '../../img/icons/map/ride.approved.svg';
import svgRideArrived from '../../img/icons/map/ride.arrived.svg';
import svgRideAssigned from '../../img/icons/map/ride.assigned.svg';
import svgRideFinished from '../../img/icons/map/ride.finished.svg';
import svgRidePaused from '../../img/icons/map/ride.paused.svg';
import svgRideCancelled from '../../img/icons/map/ride.cancelled.svg';
import svgRidePending from '../../img/icons/map/pending_order.svg';
import svgRideResumed from '../../img/icons/map/ride.resumed.svg';
import svgRideStarted from '../../img/icons/map/ride.started.svg';
import svgRideUnassigned from '../../img/icons/map/ride.unassigned.svg';

import svgCarArrived from '../../img/icons/map/car.arrived.svg';
import svgCarResumed from '../../img/icons/map/car.resumed.svg';
import svgCarCancelled from '../../img/icons/map/car.cancelled.svg';

export const driver = {
  orderId: '3595124',
  name: 'Артур',
  rating: '4,98',
  status: 'В дорозі',
  fullName: 'Артур Дорошенко',
  phone: '+38 067 234 56 78',
  auto: 'Mercedes S-class',
  numberplate: 'KA 3456 OT',
  color: 'Чорний',
  speed: '22',
  waiting: '3',
};

export const ORDER_MAPS_TAB_NAMES = {
  ALL: 'all',
  CURRENT: 'current',
  PREVIOUS: 'future',
  BROADCAST: 'unprocessed',
};

export const ORDER_MAPS_TABS = [
  {
    id: 1,
    text: 'Гарячі',
    name: ORDER_MAPS_TAB_NAMES.BROADCAST,
    uriParams: {
      type: 'broadcast',
      status: RIDE_STATUSES.PENDING,
    },
  },
  {
    id: 2,
    text: 'Виконуються',
    name: ORDER_MAPS_TAB_NAMES.CURRENT,
    uriParams: {
      type: 'processing',
    },
  },
  {
    id: 3,
    text: 'Попередні замовлення',
    name: ORDER_MAPS_TAB_NAMES.PREVIOUS,
    uriParams: {
      type: 'scheduled',
      status: RIDE_STATUSES.APPROVED,
    },
  },
];
export const RIDE_ICONS = {
  [RIDE_STATUSES.UNASSIGNED]: {
    color: '#E1763A',
    header: 'Водій не призначений',
    icon: svgRideUnassigned,
  },
  [RIDE_STATUSES.PENDING]: {
    color: '#FFB800',
    header: 'Чекає на прийняття',
    icon: svgRidePending,
  },
  [RIDE_STATUSES.CANCELLED]: {
    color: '#EF2424',
    header: 'Поїздка відмінена',
    icon: svgRideCancelled,
  },
  [RIDE_STATUSES.ASSIGNED]: {
    color: '#2BC155',
    header: 'Водій прийняв',
    icon: svgRideAssigned,
  },
  [RIDE_STATUSES.APPROVED]: {
    color: '#2D41A8',
    header: 'Замовлення в роботі',
    icon: svgRideApproved,
  },
  [RIDE_STATUSES.ARRIVED]: {
    color: '#EAC131',
    header: 'Водій на місці',
    icon: svgRideArrived,
  },
  [RIDE_STATUSES.STARTED]: {
    color: '#45C86A',
    header: 'Поїздку розпочато',
    icon: svgRideStarted,
  },
  [RIDE_STATUSES.PAUSED]: {
    color: '#24B2DF',
    header: 'Поїздка на паузі',
    icon: svgRidePaused,
  },
  [RIDE_STATUSES.RESUMED]: {
    color: '#2A970F',
    header: 'Продовження шляху',
    icon: svgRideResumed,
  },
  [RIDE_STATUSES.FINISHED]: {
    color: '#02BF92',
    header: 'Поїздка завершена',
    icon: svgRideFinished,
  },
};
export const CAR_ICONS = {
  [DRIVER_STATUSES.APPROVED]: {
    color: '#EF2424',
    icon: svgCarCancelled,
  },
  [DRIVER_STATUSES.ACTIVE]: {
    color: '#EF2424',
    icon: svgCarCancelled,
  },
  [DRIVER_STATUSES.ARRIVED]: {
    color: '#EF2424',
    icon: svgCarCancelled,
  },
  [DRIVER_STATUSES.ACTIVE]: {
    color: '#EF2424',
    icon: svgCarCancelled,
  },
  [DRIVER_STATUSES.BUSY]: {
    color: '#EAC131',
    icon: svgCarArrived,
  },
  [DRIVER_STATUSES.FREE]: {
    color: '#2DCB13',
    icon: svgCarResumed,
  },
  [DRIVER_STATUSES.BLOCKED]: {
    color: '#FFFFFF',
    icon: svgCarResumed,
  },
};
