import { createSearchRow } from '../../../../helpers/general/general';

export const DRIVERS_APPLICATIONS_HEADERS = [
  {
    id: 1,
    label: 'СТАТУС',
    name: 'status',
    sortable: false,
    searchable: false,
    className: 'driversApplications--status',
  },
  {
    id: 2,
    label: 'ID',
    name: 'id',
    sortable: true,
    searchable: true,
    className: 'driversApplications--id',
  },
  {
    id: 3,
    label: 'ІМʼЯ ТА ПРІЗВИЩЕ',
    name: 'name',
    sortable: true,
    searchable: true,
    className: 'driversApplications--name',
  },
  {
    id: 4,
    label: 'ПОЗИВНИЙ',
    name: 'name',
    sortable: true,
    searchable: true,
    className: 'driversApplications--calling',
  },
  {
    id: 5,
    label: 'ТЕЛЕФОН',
    name: 'phone',
    sortable: false,
    searchable: true,
    className: 'driversApplications--phone',
  },
  {
    id: 6,
    label: 'ЕЛЕКТРОННА АДРЕСА',
    name: 'email',
    sortable: false,
    searchable: true,
    className: 'driversApplications--email',
  },
];

export const DRIVERS_APPLICATIONS_SEARCH_ROW = createSearchRow(DRIVERS_APPLICATIONS_HEADERS);
