import React from 'react';
import './filtersIcon.scss';

const FiltersIcon = () => (
  <span className="icon-filter__wrapper" data-testid="filter-button-icon">
    <div className="icon-filter icon-filter-1" title="icon-filter" />
    <div className="icon-filter icon-filter-2" title="icon-filter" />
    <div className="icon-filter icon-filter-3" title="icon-filter" />
  </span>
);

export default FiltersIcon;
