import { curry } from '../general/curry';
import useSorting from './useSorting';

export default function useFiltrationDropdowns(fetchData, isClientPage = false) {
    const { urlParamsHandler, paramsObject } = useSorting(isClientPage);

    function onDropdownChange(key = "type", value) {
        const paramsWithFirstPage = { ...paramsObject, page: 1 };

        if (value === 'all') {
            urlParamsHandler([{ param: 'page', value: 1 }], [key]);
            delete paramsWithFirstPage[key];
            fetchData(paramsWithFirstPage);
        }

        if (value !== 'all') {
            urlParamsHandler([{ param: key, value }, { param: 'page', value: 1 }]);
            fetchData({ ...paramsWithFirstPage, [key]: value });
        }
    }

    const changeDropdownCurried = curry(onDropdownChange);
    const changeType = value => changeDropdownCurried("type", value);
    const changeStatus = value => changeDropdownCurried("status", value);

    return { changeType, changeStatus, paramsObject };
}
