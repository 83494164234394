import "./infoModal.scss";
import Modal from "../../assets/modal/Modal";

const InfoModal = ({ show, onHide, title, info }) => {
    return (
        <Modal show={show} onHide={onHide} title={title} hideButtons={true}>
            <div className="infoModal--body">
                {info}
            </div>
        </Modal>
    );
};

export default InfoModal;