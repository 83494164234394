import React from 'react'
import AddFileIcon from '../../../../img/icons/AddFileIcon';

export default function AddFile({ file, uploadImage }) {
    return (
        <div style={{ display: "flex" }}>
            <div className="chatForm-addPhoto">
                <AddFileIcon className="chatForm-addPhoto--icon" />
                <input
                    type="file"
                    id="addPhoto"
                    style={{ display: "none" }}
                    onChange={uploadImage}
                />
                <label
                    htmlFor="addPhoto"
                    className="chatForm-addPhoto--label"
                >
                    Додати фото
                </label>
            </div>
            {file && <p>{file?.name}</p>}
        </div>
    )
}
