import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer/rootReducer';
import DriverApi from '../api/driverApi';
import OrderApi from '../api/orderApi';
import UnitsApi from '../api/unitsApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    DriverApi.middleware,
    OrderApi.middleware,
    UnitsApi.middleware
  ),
});

export default store;
