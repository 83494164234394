import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes } from 'react-router-dom';
import { HELPDESK_CHAT } from '../data/consts';
import useGetState from '../store/hooks/useGetState';
import { routesLoggedIn, routesNotLoggedIn } from './routes';
import ChatPage from '../components/helpdesk/chat/chatPage/ChatPage';

const AdminRoutes = ({ centrifugeConnection }) => {
  const { token, role } = useGetState();
  const isLoggedIn = token && role;
  const notLoggedIn = useRoutes(routesNotLoggedIn);
  const loggedIn = useRoutes([
    ...routesLoggedIn,
    { path: HELPDESK_CHAT, element: <ChatPage websocket={centrifugeConnection} /> },
  ]);

  if (!isLoggedIn) {
    return notLoggedIn;
  }

  return loggedIn;
};

export default AdminRoutes;

AdminRoutes.propTypes = {
  centrifugeConnection: PropTypes.func,
};
