import "./switch.scss";

const Switch = ({ activeSwitch, items = [], onClick, className = '' }) =>
    <div className={`switchWrapper ${className}`}>
        {items.map(item => (<div
            key={item.id}
            id={item.id}
            onClick={onClick}
            className={`switchItem ${activeSwitch === item.id ? 'active' : ''}`}
        >
            {item.name}
        </div>))}
    </div>

export default Switch;
