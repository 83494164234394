import { createSearchRow } from '../../../helpers/general/general';

export const CLIENTS_HEADERS = [
  {
    id: 0,
    label: '',
    name: 'check',
    sortable: false,
    searchable: false,
    className: 'allClients--check',
  },
  {
    id: 1,
    label: 'СТАТУС',
    name: 'status',
    sortable: false,
    searchable: false,
    className: 'allClients--status',
  },
  {
    id: 2,
    label: 'ІМʼЯ ТА ПРІЗВИЩЕ',
    name: 'name',
    sortable: true,
    searchable: false,
    className: 'allClients--name',
  },
  {
    id: 3,
    label: 'ТЕЛЕФОН',
    name: 'phoneNumber',
    sortable: false,
    searchable: false,
    className: 'allClients--phone',
  },
  {
    id: 4,
    label: 'КАРТА',
    name: 'card',
    sortable: true,
    searchable: false,
    className: 'allClients--card',
  },
  {
    id: 5,
    label: 'БАЛАНС',
    name: 'balance',
    sortable: false,
    searchable: false,
    className: 'allClients--balance',
  },
  {
    id: 6,
    label: 'ДАТА РЕЄСТРАЦІЇ',
    name: 'registration_date',
    sortable: true,
    searchable: false,
    className: 'allClients--date',
  },
  {
    id: 7,
    label: 'ОСТАННЄ ЗАМОВЛЕНЯ',
    name: 'last_ride_date',
    sortable: true,
    searchable: false,
    className: 'allClients--order',
  },
  {
    id: 8,
    label: 'ОПЕРАЦІЙНА СИСТЕМА',
    name: 'os',
    sortable: false,
    searchable: false,
    className: 'allClients--op',
  },
  {
    id: 9,
    label: 'ВЕРСІЯ ДОДАТКА',
    name: 'appVersion',
    sortable: false,
    searchable: false,
    className: 'allClients--op',
  },
  {
    id: 10,
    label: 'К-ТЬ ЗАМОВЛЕНЬ',
    name: 'buttons',
    sortable: true,
    searchable: false,
    className: 'allClients--buttons',
    smallText: '(загальні/виконані)',
  },
];

export const CLIENTS_SEARCH_ROW = createSearchRow(CLIENTS_HEADERS);
