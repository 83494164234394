export const TECH_SERTIFICATE_INPUTS = [
  {
    id: 'frontRegistrationCertificatePhoto',
    title: 'СТОРІНКА 1 *',
    name: 'frontRegistrationCertificatePhoto',
    backendField: 'carFrontRegistrationCertificatePhoto',
  },
  {
    id: 'backRegistrationCertificatePhoto',
    title: 'СТОРІНКА 2 *',
    name: 'backRegistrationCertificatePhoto',
    backendField: 'carBackRegistrationCertificatePhoto',
  },
];
