
import { useEffect } from "react";
import { useState } from "react";
import notify from "../../../helpers/notify";
import { hideCustomSelect } from "../../../helpers/general/general";
import useRidesService from "../../../services/ridesService";

export default function useCancelDriveModal({ orderId, setStatus, onHide, show, status }) {
    const [activeSwitch, setActiveSwitch] = useState('cancel');
    const [cancelReason, setCancelReason] = useState('noReason');
    const [customReason, setCustomReason] = useState('');
    const cancelIsActive = activeSwitch === 'cancel';
    const customCancelReason = cancelReason === 'anotherReason';
    const notifyMessage = cancelIsActive ? 'Поїздку скасовано' : 'Поїздку завершено';
    const submitButtonText = cancelIsActive ? 'Скасувати' : 'Завершити';
    const rideIsFinishable = status === "started" || status === "paused" || status === "resumed";
    const { cancelRide, finishRide } = useRidesService();

    const onSelectOptionClickReason = reason => {
        setCancelReason(reason);
        hideCustomSelect();
    };

    const onSubmit = () => {
        const handleCancel = async () => {
            if (cancelReason === 'noReason') {
                notify("Оберіть причину", "error");
                return;
            }

            if ((customCancelReason && customReason.length < 1) || (customCancelReason && customReason.length > 255)) {
                return;
            }

            else {
                const data = {
                    reasonType: cancelReason
                };

                if (customReason) {
                    data.complaint = customReason
                }

                await cancelRide(orderId, data);
                setStatus("cancelled");
                notify(notifyMessage, 'info');
                onHide();
            }
        };

        const handleFinish = async () => {
            await finishRide(orderId);
            setStatus("finished");
            notify(notifyMessage, 'info');
            onHide();
        };

        cancelIsActive ? handleCancel() : handleFinish();
    };

    const onSwitchClick = ({ target }) => {
        if (!target.classList.contains('active')) {
            setActiveSwitch(target.id);
        }
    };

    const onCustomReasonChange = ({ target }) => {
        setCustomReason(target.value);
    };

    useEffect(() => {
        if (show) {
            setActiveSwitch('cancel');
            setCancelReason('noReason');
            setCustomReason('');
        }
    }, [show]);

    return {
        submitButtonText,
        rideIsFinishable,
        onSelectOptionClickReason,
        onSubmit,
        onSwitchClick,
        onCustomReasonChange,
        activeSwitch,
        cancelIsActive,
        customCancelReason,
        customReason,
        cancelReason
    };
}
