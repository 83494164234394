import "./clientCard.scss";
import PageHeading from "../../../assets/pageHeadings/PageHeading";
import Divider from "../../../drivers/driverInfo/divider/Divider";
import CardList from "./card/CardList";

const ClientCard = ({ cards, onDelete, loading }) =>
    <div className="clientCard">
        <PageHeading text='БАНКІВСЬКА КАРТА' />
        <CardList list={cards} onDelete={onDelete} loading={loading} />
        <Divider />
    </div>

export default ClientCard;