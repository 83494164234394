import React from 'react';
import { Checkbox } from 'antd';
import { getClientLink, getClientOrdersLink } from '../../../data/urls';
import useRedirect from '../../../helpers/hooks/useRedirect';
import BlueLinkButton from '../../assets/buttons/linkButton/BlueLinkButton';

const ClientTableItem = ({
  status,
  cards,
  balance,
  name,
  phoneNumber,
  ridesCount,
  finishedRidesCount,
  registration_date,
  last_ride_date,
  id,
  os,
  appVersion,
}) => {
  const redirect = useRedirect();
  const redirectToClient = ({ target }) => {
    switch (target.tagName) {
      case 'BUTTON':
        return redirect(getClientOrdersLink(id));
      case 'TD':
        return redirect(getClientLink(id));
      case 'INPUT':
        return;
      default:
        return;
    }
  };

  return (
    <tr onClick={redirectToClient}>
      <td className="allClients--check">
        <Checkbox value={id} />
      </td>
      <td
        className={`allClients--status ${
          status === 'active' ? 'green' : 'red'
        }`}
      >
        {status === 'active' ? 'Активний' : 'Заблокований'}
      </td>
      <td className="allClients--name">{name}</td>
      <td className="allClients--phone">{phoneNumber}</td>
      <td className="allClients--card">
        {cards.length > 0
          ? cards[0]?.cardPan || cards[0]?.id + '-карта без номеру'
          : '---'}
      </td>
      <td className="allClients--balance">{balance}</td>
      <td className="allClients--date">{registration_date}</td>
      <td className="allClients--order">{last_ride_date || '---'}</td>
      <td className="allClients--op">{os || '---'}</td>
      <td className="allClients--op">{appVersion || '---'}</td>
      <td className="allClients--buttons">
        <BlueLinkButton text={`${ridesCount}/${finishedRidesCount}`} />
      </td>
    </tr>
  );
};

export default ClientTableItem;
