export const TARIFF_NAME = [
  {
    id: 'tariff-name-uk',
    name: 'uk',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9!@#$%^&()* ]{1,64}$',
    label: 'НАЗВА ТАРИФУ',
    target: 'name',
  },
  {
    id: 'tariff-name-ru',
    name: 'ru',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9!@#$%^&()* ]{1,64}$',
    label: 'НАЗВАНИЕ ТАРИФА (РУС)',
    target: 'name',
  },
  {
    id: 'tariff-name-en',
    name: 'en',
    type: 'text',
    placeholder: '',
    errorMessage: 'Має містити від 1 до 64 символів',
    pattern: '^[A-Za-zА-Яа-я-ёЁЇїІіЄєҐґ0-9!@#$%^&()* ]{1,64}$',
    label: 'Naming the tariff (Eng)'.toUpperCase(),
    target: 'name',
  },
];
export const TARIFF_ICON = [
  {
    id: 'icon',
    name: 'icon',
    type: 'number',
    placeholder: '',
    errorMessage: 'Введіть валідний номер іконки',
    label: 'НОМЕР ІКОНКИ ДЛЯ ТАРИФУ',
  },
];

export const TARIFF_PARAMS = [
  {
    id: 'tariff-deliveryPrice',
    name: 'deliveryPrice',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ПОДАЧА',
  },
  {
    id: 'tariff-perKilometerPrice',
    name: 'perKilometerPrice',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ВАРТІСТЬ ЗА 1 КМ (МІСТО)',
  },
  {
    id: 'tariff-minimalPrice',
    name: 'minimalPrice',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ПОДАЧА З УРАХУВАННЯМ 1 КМ',
  },
  {
    id: 'tariff-freeWaitingMinutes',
    name: 'freeWaitingMinutes',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'БЕЗКОШТОВНЕ ОЧІКУВАННЯ',
    icon: 'хв',
  },
  {
    id: 'tariff-radius',
    name: 'radius',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'РАДІУС АВТОНАЗНАЧЕННЯ (М)',
    icon: '',
  },
  {
    id: 'tariff-commission',
    name: 'commission',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'КОМІСІЯ ПАРКУ ДЛЯ ВОДІЯ',
    icon: '%',
  },
  {
    id: 'tariff-tipsStep',
    name: 'tipsStep',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ЧАЙОВІ ВІД (КРОК)',
  },
  {
    id: 'tariff-increasePriceStep',
    name: 'increasePriceStep',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ЗБІЛЬШЕННЯ ВАРТОСТІ (КРОК)',
  },
];

export const TARIFF_PARAMS_HOURLY = [
  {
    id: 'tariff-minimalPrice',
    name: 'minimalPrice',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'МІН. ЦІНА',
  },
  {
    id: 'tariff-minimalMinutes',
    name: 'minimalMinutes',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'МІН. КІЛЬКІСТЬ ХВИЛИН',
  },
  {
    id: 'tariff-perMinutePrice',
    name: 'perMinutePrice',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ВАРТІСТЬ ЗА ОДНУ ХВ.',
  },
  {
    id: 'tariff-radius',
    name: 'radius',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'РАДІУС АВТОНАЗНАЧЕННЯ (М)',
    icon: 'м',
  },
  {
    id: 'tariff-commission',
    name: 'commission',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'КОМІСІЯ ПАРКУ ДЛЯ ВОДІЯ',
    icon: '%',
  },
  {
    id: 'tariff-tipsStep',
    name: 'tipsStep',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ЧАЙОВІ ВІД (КРОК)',
  },
  {
    id: 'tariff-increasePriceStep',
    name: 'increasePriceStep',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ЗБІЛЬШЕННЯ ВАРТОСТІ (КРОК)',
  },
];

export const TARIFF_PARAMS_2 = [
  {
    id: 'empty-1',
  },
  {
    id: 'tariff-intercityPerKilometerPrice',
    name: 'intercityPerKilometerPrice',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ВАРТІСТЬ ЗА 1 КМ (МІЖМІСТО)',
  },
  {
    id: 'empty-2',
  },
  {
    id: 'tariff-downtimePrice',
    name: 'downtimePrice',
    type: 'number',
    placeholder: '',
    errorMessage: '',
    label: 'ПРОСТІЙ 1 ХВИЛИНА',
  },
];
