import { useState } from "react";
import "./formInput.scss";
import classNames from 'classnames';
import useDriversService from '../../../services/driversService';
import useGetState from '../../../store/hooks/useGetState';

const FormInput = ({ label, isPassword, onChange, id, errorMessage, errorClass, customErrorMessage, className, onBlur = () => { }, hideLabel = false, value, ...inputProps }) => {
    const [blurred, setBlurred] = useState('false');

    const {updateDriverStatus} = useDriversService()

    const {driver} = useGetState()

    const handleChangePassword = async (e) => {
        e.preventDefault()
        await updateDriverStatus(driver.id, { status: driver.status, password: value }, true)
    }

    return (
        <div className={classNames('formInput', isPassword && 'formInput-isPassword')}>
            <div className='formInput--wrap'>
                {!hideLabel && <label htmlFor={id}>{label}</label>}
                <input
                    {...inputProps}
                    id={id}
                    onChange={onChange}
                    autoComplete='off'
                    value={value}
                    onBlur={() => {
                        setBlurred('true');
                        onBlur();
                    }}
                    blurred={blurred}
                    className={`${errorClass} ${className}`}
                    onInvalid={(e) => e.preventDefault()}
                />
                <span className={errorClass ? errorClass : undefined}>{customErrorMessage ? customErrorMessage : errorMessage}</span>
            </div>
            {isPassword && (
                <button className='formInput-password__btn' onClick={(e) => handleChangePassword(e)}>Змінити пароль</button>
            )}
        </div>
    );
};

export default FormInput;
