import { useState } from "react";
import { useEffect } from "react";
import { getChatAttachmentEndpoint } from "../../../../data/endpoints";
import useFilesService from "../../../../services/filesService";
import Spinner from "../../../assets/spinner/Spinner";

const MessageItem = ({ user, content, side, id, type }) => {
    const { getBase64Image, loading } = useFilesService();
    const [file, setFile] = useState("");
    const imgSrc = (file && file?.includes("data:image/")) ? file : `data:image/*;base64,${file}`;

    const fetchAttachment = async () => {
        const photo = await getBase64Image(getChatAttachmentEndpoint(id));
        setFile(photo);
    };

    useEffect(() => {
        if (type === "attachment") {
            fetchAttachment();
        }
    }, []);

    return (
        <div className={`message--item ${side} ${type === "attachment" ? "withFile" : ""}`}>
            {
                loading &&
                <div style={{ position: "absolute", left: "50%", top: "50%" }}>
                    <Spinner />
                </div>
            }
            {file && <img style={{ width: "254px", height: "254px" }} src={imgSrc} alt="Driver's photo" />}
            {side === 'inbox' && <div className='message--item-author'>{user.name}</div>}
            {type === "message" && <span className='message--item-text'>{content}</span>}
        </div>
    );
};

export default MessageItem;