export const DRIVER_LICENSE_INPUTS = [
  {
    id: 'licenseFrontPhoto',
    title: 'ФОТО ПЕРЕДНЬОЇ СТОРОНИ *',
    name: 'frontPhoto',
    backendField: 'driverLicenseFrontPhoto',
  },
  {
    id: 'licenseBackPhoto',
    title: 'ФОТО ЗАДНЬОЇ СТОРОНИ *',
    name: 'backPhoto',
    backendField: 'driverLicenseBackPhoto',
  },
];
