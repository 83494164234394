import { ACTIVE_DRIVERS_HEADERS, ACTIVE_DRIVERS_SEARCH_ROW } from "../../../../../data/drivers/tables/active/activeDrivers";
import DefaultTable from "../../../../assets/table/defaultTable/DefaultTable";
import wrapDrivers from "../../wrapDrivers";
import "./activeDrivers.scss";
import ActiveDriversItem from "./ActiveDriversItem";

const Drivers = wrapDrivers(DefaultTable);

const ActiveDrivers = () =>
    <Drivers
        destination="all"
        headersInfo={ACTIVE_DRIVERS_HEADERS}
        searchRow={ACTIVE_DRIVERS_SEARCH_ROW}
        TableItem={ActiveDriversItem}
    />;

export default ActiveDrivers;