import { useSelector } from 'react-redux';

const useGetState = () => {
  const state = useSelector((state) => state);
  const { auth, driver, orders, order, map, user, tariff, tariffs, search } =
    state;
  const {
    token,
    role,
    authorizing,
    currentUserId,
    wsToken,
    wsChannel,
    codeToSendEmail,
  } = auth;
  const { activeFilters, tableHeaders, searchRow, itemCells } = orders;
  const {
    markers,
    newOrderActivated,
    center,
    pendingOrders,
    ordersByStatus,
    isLoaded,
  } = map;

  return {
    token,
    role,
    authorizing,
    driver: driver.driver,
    activeFilters,
    tableHeaders,
    searchRow,
    itemCells,
    order,
    map,
    isMapLoaded: isLoaded,
    markers,
    newOrderActivated,
    center,
    user,
    tariff: tariff.tariff,
    tariffs: tariffs.allTariffs,
    search,
    currentUserId,
    wsToken,
    wsChannel,
    codeToSendEmail,
    pendingOrders,
    ordersByStatus,
  };
};

export default useGetState;
