import { createSearchRow } from '../../helpers/general/general';

export const USERS_HEADERS = [
  {
    id: 1,
    label: 'ID',
    name: 'id',
    sortable: true,
    searchable: true,
    className: 'directorUsers--id',
  },
  {
    id: 2,
    label: 'ІМʼЯ ТА ПРІЗВИЩЕ',
    name: 'name',
    sortable: true,
    searchable: true,
    className: 'directorUsers--name',
  },
  {
    id: 3,
    label: 'ПОСАДА',
    name: 'buttons',
    sortable: false,
    searchable: false,
    className: 'directorUsers--title',
  },
  {
    id: 4,
    label: 'ТЕЛЕФОН',
    name: 'phone',
    sortable: false,
    searchable: false,
    className: 'directorUsers--phone',
  },
  {
    id: 5,
    label: 'EMAIL',
    name: 'email',
    sortable: false,
    searchable: false,
    className: 'directorUsers--email',
  },
  {
    id: 6,
    label: '',
    name: 'delete',
    sortable: false,
    searchable: false,
    className: 'directorUsers--delete',
  },
];

export const USERS_SEARCH_ROW = createSearchRow(USERS_HEADERS);
