import {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useUrl from '../../../helpers/hooks/useUrl';
import PaginationItem from './PaginationItem';

const usePagination = (limitPerView = 4, maxPage = 1, onPageChange) => {
  const navigate = useNavigate();
  const {
    appendSearchParam, getSearchParam, currentUrl, formatUrl, setSearchParam, paramsObject,
  } = useUrl();
  const currentPage = Number(getSearchParam('page'));
  const [pages, setPages] = useState([]);
  const isFirstPage = (currentPage) => currentPage === 1;
  const isLastPage = (currentPage, maxPage) => currentPage === maxPage;
  const isFirstRun = useRef(true);

  const handleUrlChange = (newPage) => {
    if (currentPage) {
      setSearchParam('page', newPage);
      const formattedUrl = formatUrl(currentUrl);
      navigate(formattedUrl);
    } else {
      appendSearchParam('page', newPage);
      const formattedUrl = formatUrl(currentUrl);
      navigate(formattedUrl, { replace: true });
    }
  };

  const getPaginationItems = (limitPerView, maxPage, currentPage) => {
    if (maxPage <= 1 || limitPerView < 1) {
      return [];
    }
    const diff = maxPage - currentPage;

    if (diff > limitPerView) {
      if (currentPage === 1) {
        ++currentPage;
      }
      return Array(limitPerView).fill(0).map((_, index) => index + currentPage);
    }

    const result = Array(diff).fill(0).map((_, index) => index + currentPage);

    for (let i = 0; i < limitPerView - diff; ++i) {
      result.unshift(currentPage - 1 - i);
    }

    return result;
  };

  const paginationIndices = useMemo(() => getPaginationItems(limitPerView, maxPage, currentPage)
    .filter((pageIndex) => pageIndex > 1 && pageIndex < maxPage), [maxPage, limitPerView, currentPage]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (currentPage && onPageChange) {
      onPageChange(paramsObject);
    }
  }, [currentPage]);

  useEffect(() => {
    if (
      !isFirstPage(paginationIndices[0] - 1)
            && maxPage >= limitPerView
            && !isLastPage(paginationIndices[paginationIndices.length - 1] + 1, maxPage)
    ) {
      paginationIndices.pop();
    }
    setPages(paginationIndices);
  }, [limitPerView, paginationIndices]);

  const displayedPages = pages.map((page) => (
    <PaginationItem
      key={page}
      onClick={() => handleUrlChange(page)}
      content={page}
      className={(currentPage === page ? 'current' : '')}
    />
  ));

  return {
    displayedPages, isFirstPage, isLastPage, currentPage, handleUrlChange, paginationIndices, pages,
  };
};

export default usePagination;
