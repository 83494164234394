import React from 'react';
import Slider from 'react-slick';

import './carousel.scss';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 3,
};

const Carousel = ({ activeSlide, changeTariff, tariffsOfUnit }) => {
  const tariffsPresent = (
    <Slider {...settings}>
      {tariffsOfUnit.map(({ id, name, type, icon }) => (
        <div
          key={id}
          onClick={() => changeTariff(id)}
          className={`slideItem ${activeSlide === id ? 'active' : ''}`}
        >
          <h3>
            ({icon}){'  '}
            {type === 'hourly' ? `${name} (Погод.)` : name}
          </h3>
        </div>
      ))}
    </Slider>
  );

  const tariffsAbsent = (
    <div style={{ position: 'relative', marginBottom: '30px' }} />
  );

  return tariffsOfUnit.length === 0 ? tariffsAbsent : tariffsPresent;
};

export default Carousel;
