import './clientForm.scss';
import { Form } from 'antd';
import Divider from '../../../drivers/driverInfo/divider/Divider';
import FormInput from '../../../assets/inputs/FormInput';
import FormButton from '../../../assets/buttons/formButton/FormButton';
import { normalizePhone } from '../../../../helpers/general/normalizers';

const ClientForm = ({ client, onFinish }) => {
  const initialValues = {
    firstName: client?.firstName,
    lastName: client?.lastName,
    phoneNumber: client?.phoneNumber,
  };

  return (
    <div className="clientInfo--form padding">
      <Form initialValues={initialValues} onFinish={onFinish}>
        <fieldset>
          <div className="form--item">
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'Заповніть поле.' }]}
            >
              <FormInput
                value={'firstName'}
                label={'ІМʼЯ'}
                errorClass=""
                customErrorMessage=""
              />
            </Form.Item>
          </div>
          <div className="form--item">
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'Заповніть поле.' }]}
            >
              <FormInput
                value={'+380'}
                label={'ПРІЗВИЩЕ'}
                errorClass=""
                customErrorMessage=""
              />
            </Form.Item>
          </div>
          <div className="form--item">
            <Form.Item
              name="phoneNumber"
              rules={[
                {
                  pattern: '^(?:\\+38)?(0[5-9][0-9]\\d{7})$',
                  message: 'Формат: +380xxxxxxxxx',
                  required: true,
                },
              ]}
              normalize={normalizePhone}
            >
              <FormInput
                value={'phoneNumber'}
                label={'ТЕЛЕФОН'}
                errorClass=""
                customErrorMessage=""
              />
            </Form.Item>
          </div>
        </fieldset>
        <FormButton text="Зберегти" htmlType="submit" />
      </Form>
      <Divider />
    </div>
  );
};

export default ClientForm;
