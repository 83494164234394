import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { checkEmptyInputs, checkStateEmptyInputs } from "../../../helpers/checkEmptyInputs";
import useRedirect from "../../../helpers/hooks/useRedirect";
import notify from "../../../helpers/notify";
import useDriversService from "../../../services/driversService";
import useBindCreators from "../../../store/hooks/useBindCreators";
import useGetState from "../../../store/hooks/useGetState";
import { driverActions } from "../../../store/slices/driverSlice";

export default function useDriverInfo(driverStatus) {
    const { driver } = useGetState();
    const { setDriverErrors, resetDriverErrors, resetDriver } = useBindCreators(driverActions);
    const { updateDriver, createDriver, loading } = useDriversService();
    const { id } = useParams();
    const supportChatId = driver?.supportChat;
    const redirect = useRedirect();

    const [photos, setPhotos] = useState({
        carBackRegistrationCertificatePhoto: "",
        carFrontRegistrationCertificatePhoto: "",
        carInsurancePolicyPhoto: "",
        driverLicenseBackPhoto: "",
        driverLicenseFrontPhoto: "",
        photo: ""
    });

    useEffect(() => {
        return () => {
            resetDriver();
            resetDriverErrors();
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        resetDriverErrors();
        const { firstName, lastName, calling, phoneNumber, email, car, fares, driverLicense, password } = driver;
        const { brand, color, manufactureYear, model, type, registrationNumber, seatsNumber } = car;
        const driverInfo = {
            firstName,
            lastName,
            phoneNumber,
            password };
        const emptyInputs = checkStateEmptyInputs(
            {
                ...driverInfo,
                brand,
                color,
                manufactureYear,
                model,
                registrationNumber,
            }, setDriverErrors);

        if (emptyInputs > 0) {
            notify('Заповніть таблицю', 'error');
            return;
        }
        if (driver.fares.length === 0) {
            notify('Оберіть тарифи', 'error');
            return;
        }

        const newDriver = {
            ...driverInfo,
            calling,
            brand,
            color,
            manufactureYear,
            model,
            registrationNumber,
            fares: fares.map(fare => ({ fare: fare.id }))
        };

        for (const photo in photos) {
            if (photos[photo]) {
                newDriver[photo] = photos[photo]
            }
        }

        const formData = new FormData();

        for (let i = 0; i < newDriver.fares.length; i++) {
            formData.append(`fares[${i}][fare]`, newDriver.fares[i].fare);
        }

        for (const key in newDriver) {
            if (key !== "fares") {
                formData.append(key, newDriver[key]);
            }
        }

        if (driverStatus === "existing") {
            const res = await updateDriver(id, formData);
            if (!res.errors) {
                setTimeout(() => notify("Водія оновлено", "info"), 0);
                redirect(-1);
                return;
            }
        }

        if (driverStatus === "new") {

            if (!photos.photo) {
                notify("Завантажте фото водія", 'error');
                return;
            }

            const emptyPhotos = checkEmptyInputs(photos, setDriverErrors);

            if (emptyPhotos > 0) {
                notify("Завантажте необхідні фото (помічені зірочками)", 'error');
                return;
            }

            const res = await createDriver(formData);
            if (!res.errors) {
                setTimeout(() => notify("Водія створено", "info"), 0);
                redirect(-1);
            }
        }
    };

    return { loading, handleSubmit, setPhotos, supportChatId, id };

}
