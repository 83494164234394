import React from "react";
import useTariff from "../hooks/useTariff";
import PageHeading from "../../../../assets/pageHeadings/PageHeading";

const TariffServices = ({ services, tariff, includeService, excludeService }) => {
  const mapInputs = useTariff();

  const inputs = services.map(service => ({
    name: service.name.uk,
    id: service.id,
    label: service.name.uk.toUpperCase(),
    type: "number",
    target: "service",
    disabled: !tariff?.services.find(tariffService => tariffService.id === service.id)
  }));

  const checkboxHandler = (serviceId, serviceIsActive) => {
    serviceIsActive ? excludeService(serviceId) : includeService({ ...services.find(service => service.id == serviceId), price: 0 });
  };

  return (
    <>
      <PageHeading text="ДОДАТКОВІ ПОБАЖАННЯ" className="small marginBottom" />
      <div className="form" style={{ width: '35%' }}>
        {mapInputs(inputs, true, checkboxHandler, tariff.services)}
      </div>
    </>
  )
};

export default TariffServices;
