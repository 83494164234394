import { findIndex, clone } from 'lodash';
import Logger from '../general/logger';
import EventDispatcher from './EventDispatcher';

let $instance = null;
export default class CarsStreamCollections extends EventDispatcher {
  static events = {
    updated: 'event.data.updated',
  };

  static singleton = () => {
    if (!$instance) $instance = new CarsStreamCollections();
    return $instance;
  };

  _data = [];

  constructor() {
    super();
  }

  getData() {
    return this._data;
  }

  updateAll(data) {
    this._data = data;
    this.dispatchEvent({ type: CarsStreamCollections.events.updated, data: null });
  }

  addItem(data) {
    if (data?.id) {
      let collection = clone(this._data);

      const { id } = data;
      const index = findIndex(collection, { id });

      index === -1
        ? collection = [...collection, data]
        : collection[index] = data;

      this._data = collection;
      this.dispatchEvent({ type: CarsStreamCollections.events.updated, data: null });
    }
  }
}
