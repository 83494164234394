import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: '+380',
  email: '',
  password: '',
  position: 'dispatcher',
  errors: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetUser() {
      return initialState;
    },
    setUserErrors(state, action) {
      state.errors = { ...state.errors, ...action.payload };
    },
    resetUserErrors(state) {
      state.errors = {};
    },
  },
});

const { reducer: user, actions } = userSlice;
export const userActions = actions;
export default user;
