import { getLinkWithSearchParams } from "../../../helpers/general/general";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ACTIVE_DRIVERS, APPLICATIONS_DRIVERS } from "../../../data/consts";
import { DRIVER_REQUESTS } from "../../../data/drivers/driverStatus/driverRequests";
import { DRIVER_STATUS } from "../../../data/drivers/driverStatus/driverStatus";

const useAllDrivers = () => {
    const { pathname } = useLocation();
    const currentTab = pathname.includes(APPLICATIONS_DRIVERS) ? 'allPending' : 'all';
    const navigate = useNavigate();
    const [activeSwitch, setActiveSwitch] = useState(currentTab);
    const requestsEnabled = activeSwitch === 'allPending';
    const selectOptions = requestsEnabled ? DRIVER_REQUESTS : DRIVER_STATUS;

    const onSwitchClick = ({ target }) => {
        if (!target.classList.contains('active')) {
            setActiveSwitch(target.id);
            navigate(activeSwitch === 'all' ? `${getLinkWithSearchParams(APPLICATIONS_DRIVERS)}&status=unprocessed` : getLinkWithSearchParams(ACTIVE_DRIVERS))
        }
    };

    return { selectOptions, onSwitchClick, activeSwitch };
};

export default useAllDrivers;
