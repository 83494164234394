import "./chartItem.scss";
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

const ChartItem = ({ name, type, analytics, data }) =>
    <div className="chartItem">
        <div className="chartItem--info">
            <span className="chartItem--info-value">
                {`${analytics[type]?.total || 0} ${type === "income" ? "грн" : ""}`}
            </span>
            <div className="chartItem--info-name">
                {name}
            </div>
        </div>
        <div className="chartItem--chart">
            <ResponsiveContainer width="100%" height="100%" >
                <AreaChart
                    data={data}
                    margin={0}
                >
                    <Area
                        type="monotone"
                        dataKey={type}
                        stroke="#488DFE"
                        fill="#E5EEF9"
                        fillOpacity={1}
                        strokeWidth={2}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    </div>

export default ChartItem;