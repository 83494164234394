import React from 'react';
import { TARIFF_ICON, TARIFF_NAME } from "../../../../../data/director/tariff";
import PageHeading from "../../../../assets/pageHeadings/PageHeading";
import Divider from "../../../../drivers/driverInfo/divider/Divider";
import useTariff from "../hooks/useTariff";

const TariffName = () => {
  const mapInputs = useTariff();

  return (
    <>
      <PageHeading text="НАЗВА ТАРИФУ" className="small marginBottom" />
      <div className="form" style={{ width: '50%' }}>
        {mapInputs(TARIFF_NAME, false)}
        {mapInputs(TARIFF_ICON, false)}
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Divider />
      </div>
    </>
  );
};

export default TariffName;
