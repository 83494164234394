import AuthFormView from "../authFormView/AuthFormView";
import { LOGIN_FORM_INPUTS } from "../../../../data/forms/loginForm";
import FormInput from "../../../assets/inputs/FormInput";
import { useState } from "react";
import FormButton from "../../../assets/buttons/formButton/FormButton";
import { checkEmptyInputs } from "../../../../helpers/checkEmptyInputs";
import useAuthService from "../../../../services/authService";
import notify from "../../../../helpers/notify";
import Spinner from "../../../assets/spinner/Spinner";

const initialState = {
    email: '',
};

const ResetPasswordForm = () => {
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState(initialState);
    const { resetPasswordRequest, loading } = useAuthService();

    const handleSubmit = (e) => {
        e.preventDefault();
        const emptyInputs = checkEmptyInputs(values, setErrors);
        if (emptyInputs > 0) {
            notify("Введіть пошту", "error");
            return;
        } else {
            setValues(initialState);
            setErrors(initialState);
            resetPasswordRequest({ email: values.email });
        }
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setValues(prevValues => ({ ...prevValues, [name]: value }));
        if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
        }
    };

    const inputs = LOGIN_FORM_INPUTS.slice(0, 1).map(input => (
        <FormInput
            key={input.id}
            value={values[input.name] || ""}
            onChange={handleChange}
            errorClass={errors[input.name] ? 'error' : ''}
            className={'formInput'}
            customErrorMessage={errors[input.name] ? errors[input.name] : ''}
            {...input}
        />));

    return (
        <AuthFormView>
            {!loading && <form onSubmit={handleSubmit}>
                <div className="form--item">
                    <p style={{ marginBottom: 50 }}>Введіть свою електронну адресу, і ми допоможемо вам скинути пароль</p>
                    {inputs}
                    <FormButton text="Відновити" marginTop={77} />
                </div>
            </form>}
            {loading && <Spinner />}
        </AuthFormView>
    );
};

export default ResetPasswordForm;
