import { withPagination } from "../../../../helpers/withPagination";
import "./xScrollableTable.scss";
import useTable from "../../../../helpers/hooks/useTable";
import Spinner from "../../spinner/Spinner";
import React from 'react';

const Table = ({
  headersInfo = [],
  searchRow = [],
  bodyInfo = [],
  TableItem,
  showFilters,
  small,
  loading,
  onPageChange = () => { },
  size = "middle",
  className = ""
}) => {
  const { tableHeaders, search, body } = useTable(headersInfo, searchRow, bodyInfo, TableItem, onPageChange);

  return (
    <div className={`table-container ${showFilters ? 'collapsed' : ''} ${small ? 'small' : ''} size-${size} ${className}`} >
      <table>
        <thead>
          <tr>
            {tableHeaders}
          </tr>
          <tr className="tableSearchPanel sticky">{search}</tr>
        </thead>
        {!loading && <tbody>
          {body}
        </tbody>}
      </table>
      {loading && <Spinner />}
    </div>
  );
};

const XScrollableTable = withPagination(Table);
export default XScrollableTable;
