export const CANCEL_DRIVE_REASON = [
  {
    id: 'noReason',
    name: 'noReason',
    text: '---',
  },
  {
    id: 'clientCouldNotCall',
    name: 'clientCouldNotCall',
    text: 'Не дозвонилися',
  },
  {
    id: 'clientIsDrunk',
    name: 'clientIsDrunk',
    text: 'Пʼяні, наркомани',
  },
  {
    id: 'clientIsScammer',
    name: 'clientIsScammer',
    text: 'Шахраї',
  },
  {
    id: 'clientAskedCancel',
    name: 'clientAskedCancel',
    text: 'Клієнт попросив скасувати',
  },
  {
    id: 'driverIsRude',
    name: 'driverIsRude',
    text: 'Конфлікт із водієм',
  },
  {
    id: 'anotherReason',
    name: 'anotherReason',
    text: 'Свій варіант',
  },
];

export const CANCEL_DRIVE_CUSTOM_REASON = [
  {
    id: 'complaint',
    name: 'complaint',
    type: 'text',
    placeholder: '....',
    errorMessage: 'Має бути від 1 до 255 символів',
    label: 'СВІЙ ВАРІАНТ',
    pattern: '^[A-Za-z0-9]{1,255}$',
  },
];
