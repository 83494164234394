import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';

import { DEFAULT_PAGINATION } from '../../../../data/consts';
import { debounce } from '../../../../helpers/general/general';
import useHelpdeskService from '../../../../services/helpdeskService';
import useGetState from '../../../../store/hooks/useGetState';
import BackButton from '../../../assets/buttons/backButton/BackButton';
import ContentWrapper from '../../../assets/wrappers/content/ContentWrapper';
import Chat from '../chat/Chat';
import '../chatPage.scss';

const ChatPage = ({ websocket }) => {
  const { id } = useParams();
  const { getChatMessages, sendHelpdeskMessage, updateChatStatus } =
    useHelpdeskService();
  const [messages, setMessages] = useState([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [chatStatus, setChatStatus] = useState('');
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [chatMembers, setChatMembers] = useState([]);
  const { currentUserId } = useGetState();
  const chatRef = useRef();
  const paginationRef = useRef();
  const messagesEndRef = useRef(null);

  const uniqueMessageDates = Array.from(
    new Set(messages.map((item) => item.createdAt?.slice(0, 10))),
  );

  const messagesGroupedByDates = uniqueMessageDates.map((date) => ({
    date,
    id: date,
    messages: messages
      .filter((message) => message.createdAt.includes(date))
      ?.map((item) => ({
        ...item,
        side: currentUserId == item.user.id ? 'outbox' : 'inbox',
      })),
  }));

  const fetchMessages = async (page = 1, resetMessages = false) => {
    const { chatMessages, pagination, chat } = await getChatMessages(id, page);
    setPagination(pagination);
    setChatStatus(chat.status);
    setChatMembers(chat.members);

    if (resetMessages) {
      setMessages(chatMessages.reverse());
      return;
    }

    setMessages((oldMessages) => [...chatMessages.reverse(), ...oldMessages]);
  };

  const sendMessage = async (data) => await sendHelpdeskMessage(id, data);

  const changeStatus = async (data) => {
    await updateChatStatus(id, data);
    await fetchMessages(1, true);
  };

  const handleScroll = (e) => {
    setScrollToBottom(false);
    const { currentPage } = paginationRef.current;
    const { lastPage } = paginationRef.current;
    if (e.target.scrollTop === 0 && currentPage < lastPage) {
      e.target.scrollTop = e.target.scrollTop + 100;
      return;
    }
    if (e.target.scrollTop <= 600 && currentPage < lastPage) {
      fetchMessages(currentPage + 1);
    }
  };

  const debouncedScroll = debounce(handleScroll, 200);

  useEffect(() => {
    fetchMessages();

    if (paginationRef.current) {
      paginationRef.current = pagination;
    }

    if (chatRef.current) {
      chatRef.current.addEventListener('scroll', debouncedScroll);
    }

    return () => {
      if (chatRef.current) {
        chatRef.current.removeEventListener('scroll', debouncedScroll);
      }
    };
  }, []);

  const newMessageHandler = (ctx) => {
    const newMessage = ctx.data.payload;

    if (id === newMessage.chatId && ctx.data.type === 'chatMessage') {
      setMessages((oldMessages) => [
        ...oldMessages,
        {
          ...newMessage,
          side: currentUserId == newMessage.user.id ? 'outbox' : 'inbox',
        },
      ]);

      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };

  useEffect(() => {
    websocket?.addListener('publish', newMessageHandler);
    return () => {
      websocket?.removeListener('publish', newMessageHandler);
    };
  }, [websocket]);

  return (
    <ContentWrapper pageTitle="Служба підтримки">
      <BackButton />
      <Chat
        messages={messagesGroupedByDates}
        sendMessage={sendMessage}
        chatStatus={chatStatus}
        changeStatus={changeStatus}
        chatRef={chatRef}
        scrollToBottom={scrollToBottom}
        messagesEndRef={messagesEndRef}
        canSendMessage={true}
      />
    </ContentWrapper>
  );
};

export default ChatPage;

ChatPage.propTypes = {
  websocket: PropTypes.func,
};
