import {
  ALL_CLIENTS_ENDPOINT,
  getDeleteClientCardEndpoint,
  getClientEndpoint,
  getClientUpdateStatusEndpoint,
  UPDATE_CLIENTS_STATUSES,
  getClientUpdateInfoEndpoint,
  CLIENT_CREATE,
  getIncreaseClientBalanceEndpoint,
  getDecreaseClientBalanceEndpoint,
} from '../data/endpoints';
import notify from '../helpers/notify';
import useHttpService from './httpService';

const useClientsService = () => {
  const { request, loading } = useHttpService();

  const getAllClients = async (params) => {
    const clients = await request({ url: ALL_CLIENTS_ENDPOINT, params });
    return clients;
  };

  const getClient = async (id) => {
    const client = await request({ url: getClientEndpoint(id) });
    return client;
  };

  const deleteClientCard = async (clientId, cardId) => {
    await request({
      url: getDeleteClientCardEndpoint(clientId, cardId),
      method: 'DELETE',
    });
    notify('Картку видалено', 'info');
  };

  const updateClientStatus = async (id, data) => {
    await request({
      url: getClientUpdateStatusEndpoint(id),
      method: 'POST',
      data,
    });
    notify('Статус змінено', 'info');
  };

  const updateClientsStatuses = async (data) => {
    await request({ url: UPDATE_CLIENTS_STATUSES, method: 'POST', data });
    notify('Статуси змінено', 'info');
  };

  const createClient = async (data) => {
    await request({ url: CLIENT_CREATE, method: 'POST', data });
    notify('Клієнта успішно доданоє', 'info');
  };

  const updateClientInfo = async (id, data) => {
    await request({
      url: getClientUpdateInfoEndpoint(id),
      method: 'POST',
      data,
    });
    notify('Інформацію оновлено', 'info');
  };

  const increaseClientBalance = async (id, data, cb) => {
    try {
      await request({
        url: getIncreaseClientBalanceEndpoint(id),
        method: 'POST',
        data,
      });
      cb();
      notify('Баланс поповнено', 'info');
    } catch (e) {
      notify(e.response.data.message);
    }
  };

  const decreaseClientBalance = async (id, data, cb) => {
    try {
      await request({
        url: getDecreaseClientBalanceEndpoint(id),
        method: 'POST',
        data,
      });
      cb();
      notify('Кошти списано', 'info');
    } catch (e) {
      notify(e.response.data.message);
    }
  };

  return {
    getAllClients,
    getClient,
    deleteClientCard,
    updateClientStatus,
    loading,
    updateClientsStatuses,
    updateClientInfo,
    createClient,
    increaseClientBalance,
    decreaseClientBalance,
  };
};

export default useClientsService;
