import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const unitsApi = createApi({
  reducerPath: 'unitsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.auth?.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ['Units'],
  endpoints: (builder) => ({
    createUnit: builder.mutation({
      query: ({ placeId, lat, lng, name }) => ({
        url: `/director/unit/create`,
        method: 'POST',
        body: {
          placeId,
          latitude: lat,
          longitude: lng,
          name
        }
      }),
      invalidatesTags: ['Units'],
    }),
    deleteUnit: builder.mutation({
      query: (id) => ({
        url: `/director/unit/${id}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Units'],
    }),
    createUnitSettings: builder.mutation({
      query: (body) => ({
        url: `/director/unit/settings/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Units'],
    }),
    updateUnitSettings: builder.mutation({
      query: (body) => ({
        url: `/director/unit/settings/create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Units'],
    }),
    getAllUnits: builder.query({
      query: () => `/director/unit/all`,
      transformResponse: (response) => {
        if (!response?.units) return [];
        return response?.units.map(item => ({
          id: item.id,
          name: item.name,
          center: {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          },
          placeId: item.placeId,
          polygon: item.settings?.polygon?.map(item => ({
            lat: parseFloat(item[0]),
            lng: parseFloat(item[1])
          }))
        }))
      },
      providesTags: ['Units'],
    }),
  }),
});

export default unitsApi;

export const {
  useCreateUnitMutation,
  useLazyGetAllUnitsQuery,
  useGetAllUnitsQuery,
  useCreateUnitSettingsMutation,
  useUpdateUnitSettingsMutation,
  useDeleteUnitMutation,
} = unitsApi;
