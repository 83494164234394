import React from 'react';
import useRedirect from '../../../helpers/hooks/useRedirect';
import TableSearchField from '../../assets/inputs/tableSearch/TableSearchField';
import { ReactComponent as LockIcon } from '../../../img/icons/locked-icon.svg';
import CustomSelect from '../../assets/inputs/customSelect/CustomSelect';
import { CLIENTS_STATUSES } from '../../../data/clientss/clientsStatusDropdown/clientsStatusDropdown';
import TableControls from '../../assets/wrappers/tableControls/TableControls';
import { CLIENT_CREATE } from '../../../data/consts';
import Button from '../../assets/buttons/button/Button';

const ClientTableActions = ({
  fetchOrders,
  toggleStatusClients,
  changeStatus,
  disable,
}) => {
  const redirect = useRedirect();
  const onClick = () => redirect(CLIENT_CREATE);

  return (
    <div className="clientsActionRow">
      <div className="search__row">
        <Button withIcon={true} text="Додати клієнта" onClick={onClick} />
        <div className="clientsPhoneSearch">
          <TableSearchField
            name={'phoneNumber'}
            className={'allClients--phone'}
            handleSearch={fetchOrders}
            placeholder={'Пошук по номеру'}
          />
        </div>
        <div className="clientsPhoneSearch">
          <TableSearchField
            name={'name'}
            className={'allClients--name'}
            handleSearch={fetchOrders}
            placeholder={'Пошук по імені'}
          />
        </div>
        <TableControls>
          <CustomSelect options={CLIENTS_STATUSES} onClick={changeStatus} />
        </TableControls>
      </div>
      {/*TODO if you need multiple change status you can change in styles*/}
      <span
        className={`lock_icon ${disable ? 'disabled' : ''}`}
        onClick={toggleStatusClients}
      >
        <LockIcon />
      </span>
    </div>
  );
};

export default ClientTableActions;
