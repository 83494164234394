import { useState } from 'react';
import { curry } from '../../../../../helpers/general/curry';
import useFiltrationDropdowns from '../../../../../helpers/hooks/useFiltrationDropdowns';
import useDriversService from '../../../../../services/driversService';

const useGetDrivers = (driversType = 'all') => {
  const [drivers, setDrivers] = useState([]);
  const [pagination, setPagination] = useState({});
  const { getDrivers, loading } = useDriversService();

  const fetchDrivers = async (type, params) => {
    let { drivers, pagination } = await getDrivers(type, params);

    /**
     * @todo
     * в строке #23 - какая-то магия,
     * потому что из-за нее (во всех заявках) не отображаются заявки в статусе rejected
     * и + не отображаются заявки с фильтром [статус == rejected ]
     */

    /*
        if (type === "allPending") {
            drivers = drivers.filter(driver => driver.status !== "rejected");
        }
        */

    setDrivers(drivers);
    setPagination(pagination);
  };

  const fetchDriversCurried = curry(fetchDrivers);
  const getAllDrivers = (params) => fetchDriversCurried(driversType, params);
  const { changeStatus: onSelectOptionClick } =
    useFiltrationDropdowns(getAllDrivers);

  return { drivers, pagination, loading, getAllDrivers, onSelectOptionClick };
};

export default useGetDrivers;
