import React from "react";
import useBindCreators from "../../../../../store/hooks/useBindCreators";
import useGetState from "../../../../../store/hooks/useGetState";
import { tariffActions } from "../../../../../store/slices/tariffSlice";
import Checkbox from "../../../../assets/inputs/checkbox/Checkbox";
import FormInput from "../../../../assets/inputs/FormInput";
import FormInputWithIcon from "../../../../assets/inputs/formInputWithIcon/FormInputWithIcon";

export default function useTariff() {
  const { tariff } = useGetState();
  const { setTariff, setTariffErrors, setServicePrice, setName } = useBindCreators(tariffActions);
  const errors = tariff?.errors;

  const mapInputs = (data, withIcon = false, checkboxHandler, activeServices = []) => {
    const Input = withIcon ? FormInputWithIcon : FormInput;

    const createInputs = (input) => {

      if (input.name) {
        const serviceIsActive = !!activeServices?.find(item => item.id === input.id);
        const handleChange = (e, inputType) => {
          const { name: key, value } = e.target;
          if (errors[key]) {
            setTariffErrors({ [key]: '' });
          }

          switch (inputType) {
            case "service":
              setServicePrice({ inputId: input.id, value });
              break;
            case "name":
              setName({ language: input.name, value });
              break;
            default:
              setTariff({ key, value });
          }

        };

        const getInputValue = (inputType) => {
          switch (inputType) {
            case "service":
              return tariff.services.find(service => service.id == input.id)?.price;
            case "name":
              return tariff.name[input.name];
            default:
              return tariff[input.name]
          }
        };

        return <div key={input.id} style={{ position: 'relative' }}>
          <Input
            key={input.name}
            value={getInputValue(input.target)}
            onChange={(e) => handleChange(e, input.target)}
            errorClass={errors[input.name] ? 'error' : ''}
            customErrorMessage={errors[input.name] ? errors[input.name] : ''}
            id={input.name}
            {...input}
          />
          {input.target === "service" ?
            <div style={{ position: "absolute", right: serviceIsActive ? -200 : -220, top: "30%" }}>
              <Checkbox
                label={serviceIsActive ? "Активне" : "Неактивне"}
                className='checkbox'
                onChange={() => checkboxHandler(input.id, serviceIsActive)}
                checked={serviceIsActive}
              />
            </div>
            : ""
          }
        </div>
      }

      return <div key={input.id} style={{ height: '88px' }}></div>;
    }

    const column = data.map(createInputs);
    return <div className="form--item">{column}</div>
  };

  return mapInputs;
}
